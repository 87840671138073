import React, { useState, useEffect, useRef } from 'react';
import toastr from 'toastr';
import jwt_decode from 'jwt-decode';

import SweetAlert from 'react-bootstrap-sweetalert';
import { MDBDataTable } from 'mdbreact';
import { Row, Col, Card, CardBody, Button, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import {
  getUnits,
  addUnit,
  deleteUnit,
  updateUnit,
} from '../../../store/actions';

import { AvForm, AvField } from 'availity-reactstrap-validation';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import './unit.scss';

const Units = (props) => {
  const [unitObject, setUnitObject] = useState({});
  const [unitsTemp, setUnitsTemp] = useState([]);

  const [unitIdTobeUpdated, setUnitIdToBeUpdated] = useState(null);
  const [unitIdToBeDeleted, setUnitIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);

  const [userId, setUserId] = useState(1);
  const formRef = useRef();

  const {
    units,
    addingUnit,
    addUnitResponse,
    deleteUnitResponse,
    updateUnitResponse,
    error,
  } = useSelector((state) => state.units);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUnits());
  }, []);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = jwt_decode(localStorage.getItem('authUser'));
      console.log(obj);

      setUserId(obj.user);
      setUnitObject({ ['auth_userid']: userId });
    }
  }, [userId]);

  useEffect(() => {
    if (addUnitResponse.type === 'success') {
      toastr.success(addUnitResponse.message);
    } else if (addUnitResponse.type === 'failure') {
      toastr.error(error.data.message, addUnitResponse.message);
    }
  }, [addUnitResponse]);

  useEffect(() => {
    if (deleteUnitResponse.type === 'success') {
      toastr.success(deleteUnitResponse.message);
    } else if (deleteUnitResponse.type === 'failure') {
      toastr.error(error.data.message, deleteUnitResponse.message);
    }
  }, [deleteUnitResponse]);

  useEffect(() => {
    if (updateUnitResponse.type === 'success') {
      setUnitIdToBeUpdated(null);
      toastr.success(updateUnitResponse.message);
    } else if (updateUnitResponse.type === 'failure') {
      toastr.error(error.data.message, updateUnitResponse.message);
    }
  }, [updateUnitResponse]);

  let preUpdateUnit = (item) => {
    setUnitIdToBeUpdated(item.unit_id);
    setUnitObject(item);
  };

  useEffect(() => {
    let unitsDuplicate = JSON.parse(JSON.stringify(units));
    let unitData = [];
    unitsDuplicate.map((item, index) => {
      if(item.User!=null)
      {
        item.fname = item.User.fname + ' ' + item.User.lname;
      }
      else
      {
        item.fname="";
      }
     
      item.auth_userid = userId;
      item.action = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <i
            className="far fa-edit"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.5em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              preUpdateUnit(item);
            }}
          ></i>
          <i
            className="far fa-trash-alt"
            style={{ fontSize: '1em', cursor: 'pointer' }}
            onClick={() => {
              setUnitIdToBeDeleted(item.unit_id);
              setConfirmDeleteAlert(true);
            }}
          ></i>
        </div>
      );
      item.id = index + 1;

      unitData.push(item);
    });
    setUnitsTemp(unitData);
  }, [units]);

  const data = {
    columns: [
      {
        label: '#',
        field: 'id',
        sort: 'asc',
        width: 10,
      },

      {
        label: 'Name',
        field: 'unit_name',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Shortcode',
        field: 'unit_short',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Value',
        field: 'unit_value',
        sort: 'asc',
        width: 70,
      },

      {
        label: 'User',
        field: 'fname',
        sort: 'asc',
        width: 100,
      },

      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 100,
      },
    ],
    rows: unitsTemp,
  };

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setUnitObject({
      ...unitObject,
      [name]: value,
    });
  };
  const handleValidSubmitUnit = (event, values) => {
    unitIdTobeUpdated
      ? dispatch(updateUnit(unitObject))
      : dispatch(addUnit(unitObject));

    formRef.current.reset();
    setUnitObject({ ['auth_userid']: userId });
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteUnit(unitIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Units" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitUnit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Unit Name
                          </Label>
                          <AvField
                            name="createdBy"
                            value={userId}
                            type="hidden"
                            className="form-control"
                            //validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />

                          <AvField
                            name="unit_name"
                            value={unitObject.unit_name}
                            placeholder="Unit Name"
                            type="text"
                            errorMessage="Enter Unit Name"
                            className="form-control"
                            validate={{ required: { value: true },
                            pattern: {value: '^[A-Za-z ]+$', errorMessage: 'Unit Name must be only with letter and spaces'}
                          }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3" >
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Unit Shortcode</Label>
                          <AvField
                            name="unit_short"
                            value={unitObject.unit_short}
                            placeholder="Unit Shortcode"
                            type="text"
                            errorMessage="Enter Unit Shortcode"
                            className="form-control"
                            validate={{ required: { value: true },
                            pattern: {value: '^[A-Za-z ]+$', errorMessage: 'Unit shorcode must be only with letter and spaces'} }}
                            id="validationCustom02"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3" >
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                          Unit Value
                          </Label>
                          <AvField
                            name="unit_value"
                            value={unitObject.unit_value}
                            placeholder="Unit Value"
                            type="text"
                            errorMessage="Enter Unit Value"
                            className="form-control"
                            validate={{ required: { value: true },
                            pattern: {value: '^[0-9.]+$', errorMessage: 'Unit value must be only with numbers'} }}
                            id="validationCustom03"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mt-4">
                          {unitIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingUnit ? true : false}
                            >
                              {addingUnit ? 'Updating' : 'Update'}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingUnit ? true : false}
                            >
                              {addingUnit ? 'Adding' : 'Submit'}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bordered data={data} disableRetreatAfterSorting={true} entries={25} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Units;
