import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment';
import jwt_decode from "jwt-decode";
import { MDBDataTable } from "mdbreact";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import $ from "jquery";
import axios from "axios";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  InputGroup,
  Progress,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import {
  getSectionTypes,
  addSectionType,
  deleteSectionType,
  updateSectionType,
  toggleSectionTypeActiveStatus,
  apiError,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  AvForm,
  AvField,
  AvInput,
  AvGroup,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import "./user.scss";

const OfferTypes = (props) => {
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [sectionTableData, setSectionTableData] = useState([]);
  const [sectionData, setSectionData] = useState({});
  const [showWebIcon, setShowWebIcon] = useState('block');
  const [showAppIcon, setShowAppIcon] = useState('block');
  const [sectionTypeIdTobeUpdated, setSectionTypeIdToBeUpdated] =
    useState(null);
  const [sectionTypeToBeDeleted, setSectionTypeToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [uploadProgress, setUploadProgress] = useState();
  const [showModal, setShowModal] = useState(false);
  const formRef = useRef(null);
  const [userId, setUserId] = useState(1);
  const [activeStatus, setActiveStatus] = useState("");
  const [sectionForData, setSectionForData] = useState("");
  const [invalidwebicon, setinvalidwebicon] = useState('none');
  const [invalidappicon, setinvalidappicon] = useState('none');
  const [invalidsectionfor, setinvalidsectionfor] = useState('none');
  const [invalidactivestatus, setinvalidactivestatus] = useState('none');

  const {
    sectionTypes,
    addingSectionType,
    updatingSectionType,
    addSectionTypeResponse,
    updateSectionTypeResponse,
    deleteSectionTypeResponse,
    statusSectionTypeResponse,
    error,
  } = useSelector((state) => state.sectionType);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSectionTypes());
  }, []);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = jwt_decode(localStorage.getItem("authUser"));
      setUserId(obj.user);
      setSectionData({ ["auth_userid"]: userId });
    }
  }, [userId]);

  useEffect(() => {
    if (addSectionTypeResponse.type === "success") {
      toastr.success(addSectionTypeResponse.message);
      setSectionData({ ["auth_userid"]: userId });
    } else if (addSectionTypeResponse.type === "failure") {
      toastr.error(error.data.message, addSectionTypeResponse.message);
    }
  }, [addSectionTypeResponse]);

  useEffect(() => {
    if (updateSectionTypeResponse.type === "success") {
      setShowModal(false);
      setSectionTypeIdToBeUpdated(null);
      setSectionData({ ["auth_userid"]: userId });
      toastr.success(updateSectionTypeResponse.message);
    } else if (updateSectionTypeResponse.type === "failure") {
      toastr.error(error.data.message, updateSectionTypeResponse.message);
    }
  }, [updateSectionTypeResponse]);

  useEffect(() => {
    if (deleteSectionTypeResponse.type === "success") {
      toastr.success(deleteSectionTypeResponse.message);
    } else if (deleteSectionTypeResponse.type === "failure") {
      toastr.error(error.data.message, deleteSectionTypeResponse.message);
    }
  }, [deleteSectionTypeResponse]);

  useEffect(() => {
    if (statusSectionTypeResponse.type === "success") {
      toastr.success(statusSectionTypeResponse.message);
    } else if (statusSectionTypeResponse.type === "failure") {
      toastr.error(error.data.message, statusSectionTypeResponse.message);
    }
  }, [statusSectionTypeResponse]);

  const getSectionForName = (sectionForValue) => {
    return sectionForList.find((item) => item.value === sectionForValue).label;
  };

  let preUpdateOfferTypes = (item) => {
     console.log(item);
    if (item.section_for) {
      let newSectionFor = sectionForList.filter(
        (it) => it.label === item.section_for
      );
      handleUploadFields(newSectionFor[0]);
      setSectionForData(newSectionFor);
      sectionData.section_for = newSectionFor;
    }
    let newSectionActiveStatus = activeStatusList.filter(
      (it) => it.value == item.section_active_status
    );
    setActiveStatus(newSectionActiveStatus);
    sectionData.section_active_status = newSectionActiveStatus;

    if (item.section_showing_homepage == "Yes") {
      sectionData.section_showing_homepage = true;
    } else {
      sectionData.section_showing_homepage = false;
    }
    if (item.section_showing_on_top_bar == "Yes") {
      sectionData.section_showing_on_top_bar = true;
    } else {
      sectionData.section_showing_on_top_bar = false;
    }
    setSectionTypeIdToBeUpdated(item.section_id);
    setSectionData(item);
  };

  useEffect(() => {
    let sectionDuplicate = JSON.parse(JSON.stringify(sectionTypes));
    let sectionData = [];
    sectionDuplicate.map((item, index) => {
      item.name = item.section_name;
      item.auth_userid = userId;
      
      item.section_start_date1 = moment(item.section_start_date).format('DD/MM/YYYY');
      item.section_start_date = moment(item.section_start_date).format('YYYY-MM-DD');
      item.section_end_date1 = moment(item.section_end_date).format('DD/MM/YYYY');
      item.section_end_date = moment(item.section_end_date).format('YYYY-MM-DD');
      item.section_for = getSectionForName(item.section_for);
      item.section_showing_homepage =
        item.section_showing_homepage == 1 ? "Yes" : "No";

      item.section_showing_on_top_bar =
        item.section_showing_on_top_bar == 0 ? "No" : "Yes";
      item.section_status1 = (
        <div style={{ textAlign: "center", width: "100%" }}>
          <div
            class="form-check form-switch mb-3"
            style={{ textAlign: "center", marginLeft: "40%" }}
          >
            <input
              type="checkbox"
              class="form-check-input"
              id="customSwitch2"
              checked={(item.section_active_status==0)?true:false}
              onClick={(e) => {
                dispatch(
                  toggleSectionTypeActiveStatus(item.section_id, userId)
                );
                settoggleSwitch(!toggleSwitch);
              }}
            />
          </div>
        </div>
      );

      item.action = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            className="far fa-edit"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.5em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              preUpdateOfferTypes(item);
            }}
          ></i>
          <i
            className="far fa-trash-alt"
            style={{ fontSize: "1em", cursor: "pointer" }}
            onClick={() => {
              setSectionTypeToBeDeleted(item.section_id);
              setConfirmDeleteAlert(true);
            }}
          ></i>
        </div>
      );
      item.id = index + 1;
      item.section_icons = "";
      if (item.section_icon != "" && item.section_icon != null) {
        item.section_icons = (
          <div style={{ textAlign: "center" }}>
            <img
              src={API_URL + `public/uploads/sectiontype/${item.section_icon}`}
              style={{ width: "32px"}}
            />
          </div>
        );
      }
      item.section_icon_apps="";
      if (item.section_icon_app != "" && item.section_icon_app != null) {
        item.section_icon_apps = (
          <div style={{ textAlign: "center" }}>
            <img
              src={API_URL + `public/uploads/sectiontype/${item.section_icon_app}`}
              style={{ width: "32px"}}
            />
          </div>
        );
      }
      sectionData.push(item);
    });
    setSectionTableData(sectionData);
  }, [sectionTypes]);

  let onImageUpload = (e) => {
    if(e.target.files[0].name!="")
    {
      setinvalidwebicon('none');
        $("#section_icon").attr('class',"form-control");
    }
    else
    {
      setinvalidwebicon('none');
        $("#section_icon").attr('class',"form-control border border-danger");
    }
    let fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}section/uploadSectionSingleImage`, fd, {
        headers: {
          "x-access-token": accessToken,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setSectionData({
            ...sectionData,
            [`section_icon`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  let onImageUploadApp = (e) => {
    if(e.target.files[0].name!="")
    {
      setinvalidappicon('none');
        $("#section_icon_app").attr('class',"form-control");
    }
    else
    {
      setinvalidappicon('none');
        $("#section_icon_app").attr('class',"form-control border border-danger");
    }
    let fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}section/uploadSectionSingleImage`, fd, {
        headers: {
          "x-access-token": accessToken,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setSectionData({
            ...sectionData,
            [`section_icon_app`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 400,
      },
      {
        label: "For",
        field: "section_for",
        sort: "asc",
        width: 200,
      },
      {
        label: "Start",
        field: "section_start_date1",
        sort: "asc",
        width: 200,
      },
      {
        label: "End",
        field: "section_end_date1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Web",
        field: "section_icons",
        sort: "asc",
        width: 200,
      },
      {
        label: "App",
        field: "section_icon_apps",
        sort: "asc",
        width: 200,
      },
      {
        label: "Home",
        field: "section_showing_homepage",
        sort: "asc",
        width: 200,
      },
      {
        label: "Topbar",
        field: "section_showing_on_top_bar",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "section_status1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: sectionTableData,
  };

  const sectionForList = [
    {
      label: "All",
      value: 0,
    },
    {
      label: "Web",
      value: 1,
    },
    {
      label: "App",
      value: 2,
    },
  ];

  const activeStatusList = [
    {
      label: "Active",
      value: 0,
    },
    {
      label: "Inactive",
      value: 1,
    },
  ];

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setSectionData({
      ...sectionData,
      [name]: value,
    });
  };

  let handleChangeCheckbox = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    var element=(e.target.id);
    if(e.target.checked)
    {
      setSectionData({
        ...sectionData,
        [name]: 1,
      });
    }
    else
    {
      setSectionData({
        ...sectionData,
        [name]: 0,
      });
    }
   
    // let newValue = value === true ? 1 : 0;
    
  };

  const deleteSectionIcon = () => {
    setSectionData({
      ...sectionData,
      [`section_icon`]: "",
    });
  };
  const deleteSectionIconApp = () => {
    setSectionData({
      ...sectionData,
      [`section_icon_app`]: "",
    });
  };
  function handleUploadFields(e){
    if(typeof e.value!="undefined")
    {
      setinvalidsectionfor('none');
      $("#sectionFor").attr('class',"select2-selection");
    }
    else
    {
      setinvalidsectionfor('block');
      $("#sectionFor").attr('class',"select2-selection border border-danger");
    }
    setSectionForData(e);
    setSectionData({
      ...sectionData,
      [`section_for`]: e.value,
    });
    if(e.value==0)
    {
      setShowWebIcon('block');
      setShowAppIcon('block');
    }
    else if(e.value==1)
    {
      setShowWebIcon('block');
      setShowAppIcon('none');
    }
    else if(e.value==2)
    {
      setShowWebIcon('none');
      setShowAppIcon('block');
    }
    
  }
  function handleActiveStatus(e){
    if(typeof e.value!="undefined")
    {
      setinvalidactivestatus('none');
      $("#activeStatus").attr('class',"select2-selection");
    }
    else
    {
      setinvalidactivestatus('block');
      $("#activeStatus").attr('class',"select2-selection border border-danger");
    }
    setActiveStatus(e);
    setSectionData({
      ...sectionData,
      [`section_active_status`]: e.value,
    });
  }

  const handleValidSubmitUnit = (event, values) => {
    console.log(sectionData);
    event.preventDefault();
    if(typeof sectionData.section_for==="undefined" || sectionData.section_for==="" || sectionData.section_for===null)
    {
      setinvalidsectionfor('block');
      $("#sectionFor").attr('class',"select2-selection border border-danger");
      toTop();
    }
    else if(sectionForData.value==0 && (sectionData.section_icon=="" || sectionData.section_icon==null || typeof sectionData.section_icon=="undefined"))
    {
        setinvalidwebicon('block');
        $("#section_icon").attr('class',"form-control border border-danger");
        toTop();
    }
    else if(sectionForData.value==0 && (sectionData.section_icon_app=="" || sectionData.section_icon_app==null || typeof sectionData.section_icon_app=="undefined"))
      {
        setinvalidappicon('block');
        $("#section_icon_app").attr('class',"form-control border border-danger");
        toTop();
      }
    else if(sectionForData.value==1 && (sectionData.section_icon=="" || sectionData.section_icon==null || typeof sectionData.section_icon=="undefined"))
    {
      setinvalidwebicon('block');
      $("#section_icon").attr('class',"form-control border border-danger");
      toTop();
    }
    else if(sectionForData.value==2 && (sectionData.section_icon_app=="" || sectionData.section_icon_app==null || typeof sectionData.section_icon_app=="undefined"))
    {

        setinvalidappicon('block');
        $("#section_icon_app").attr('class',"form-control border border-danger");
        toTop();
    }
    // else if(typeof sectionData.section_active_status==="undefined" || sectionData.section_active_status==="" || sectionData.section_active_status===null)
    // {
    //   setinvalidactivestatus('block');
    //   $("#activeStatus").attr('class',"select2-selection border border-danger");
    // }
    else
    {
      if (sectionData.section_icon && sectionData.section_icon.length > 2) {
        if (sectionData.section_icon.indexOf("uploads/sectiontype/") > -1) {
          // categoryObject.category_icon_svg.slice(categoryObject.category_icon_svg.indexOf('uploads/category-icons/') + 23)
  
          setSectionData({
            ...sectionData,
            [`section_icon`]: sectionData.section_icon.slice(
              sectionData.section_icon.indexOf("uploads/sectiontype/") + 23
            ),
          });
        }
      }
      if(sectionData.section_showing_homepage=="Yes")
      {
        sectionData.section_showing_homepage=1;
      }
      else if(sectionData.section_showing_homepage=="No")
      {
        sectionData.section_showing_homepage=0;
      }
      if(sectionData.section_showing_on_top_bar=="Yes")
      {
        sectionData.section_showing_on_top_bar=1;
      }
      else if(sectionData.section_showing_on_top_bar=="No")
      {
        sectionData.section_showing_on_top_bar=0;
      }
     
      sectionTypeIdTobeUpdated
        ? dispatch(updateSectionType(sectionData))
        : dispatch(addSectionType(sectionData));
  
      formRef.current.reset();
      setSectionForData("");
      setActiveStatus("");
      setShowAppIcon('block');
      setShowWebIcon('block');
      setSectionData({["auth_userid"]: userId });
    }
    
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteSectionType(sectionTypeToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Offer /Section" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitUnit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="sectionFor">Section For</Label>
                          <Select
                            name="section_for"
                            value={sectionForData}
                            onChange={(value) => {
                              handleUploadFields(value);
                            }}
                            id="sectionFor"
                            options={sectionForList}
                            classNamePrefix="select2-selection"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                        <div style={{ display:invalidsectionfor,Width: '100%',marginTop:'0.25rem',color: '#f46a6a',fontSize:'87.5%'}}>Select Section For</div>

                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="sectionName">Section Name</Label>

                          <AvField
                            name="section_name"
                            placeholder="Section Name"
                            type="text"
                            value={sectionData.section_name}
                            errorMessage="Enter Section Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="sectionName"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Start Date</Label>
                          <InputGroup>
                            {/* <DatePicker
                          selected={startDate}
                          onChange={date => setStartDate(date)}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                        /> */}
                            {/* <Flatpickr
                              className="form-control d-block"
                              placeholder="dd M,yyyy"
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                              }}
                              value={sectionData.section_start_date}
                              onChange={(date) => {
                                sectionData.section_start_date = date[0];
                              }}
                            /> */}
                          </InputGroup>
                          <AvField
                            name="section_start_date"
                            placeholder="Section Start Date"
                            type="date"
                            value={sectionData.section_start_date}
                            errorMessage="Select Start Date"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="section_start_date"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>End Date</Label>
                          <InputGroup>
                            {/* <DatePicker
                          selected={startDate}
                          onChange={date => setStartDate(date)}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                        /> */}
                            {/* <Flatpickr
                              className="form-control d-block"
                              placeholder="dd M,yyyy"
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                              }}
                              value={sectionData.section_end_date}
                              onChange={(date) => {
                                sectionData.section_end_date = date[0];
                              }}
                            /> */}
                          </InputGroup>
                          <AvField
                            name="section_end_date"
                            placeholder="Section End Date"
                            type="date"
                            value={sectionData.section_end_date}
                            errorMessage="Select End Date"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="section_end_date"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3" style={{display:showWebIcon}}>
                        <div className="mb-3">
                          <Label htmlFor="sectionIcon">Web Icon(32x32)</Label>
                          {sectionData.section_icon &&
                          sectionData.section_icon.length > 2 ? (
                            <div div className="img-wraps">
                              {sectionData.section_icon &&
                              sectionData.section_icon.startsWith("http") ? (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${sectionData.section_icon}`}
                                />
                              ) : (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${API_URL}public/uploads/sectiontype/${sectionData.section_icon}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteSectionIcon}
                                style={{ width: "200px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="section_icon"
                              id="section_icon"
                              type="file"
                              errorMessage="Upload Section Icon"
                              className="form-control"
                              onChange={onImageUpload}
                              validate={{
                                required: {
                                  value: false,
                                },
                              }}
                            />
                          )}
                          {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )}
                        </div>
                        <div style={{ display:invalidwebicon,Width: '100%',marginTop:'0.25rem',color: '#f46a6a',fontSize:'87.5%'}}>Select Image</div>

                      </Col>

                      <Col md="3" style={{display:showAppIcon}}>
                        <div className="mb-3">
                          <Label htmlFor="sectionIcon">App Icon(32x32)</Label>
                          {sectionData.section_icon_app &&
                          sectionData.section_icon_app.length > 2 ? (
                            <div div className="img-wraps">
                              {sectionData.section_icon_app &&
                              sectionData.section_icon_app.startsWith("http") ? (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${sectionData.section_icon_app}`}
                                />
                              ) : (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${API_URL}public/uploads/sectiontype/${sectionData.section_icon_app}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteSectionIconApp}
                                style={{ width: "200px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="section_icon_app"
                              id="section_icon_app"
                              type="file"
                              errorMessage="Upload Section Icon App"
                              className="form-control"
                              onChange={onImageUploadApp}
                              validate={{
                                required: {
                                  value: false,
                                },
                              }}
                            />
                            
                          )}
                          {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )}
                          <div style={{ display:invalidappicon,Width: '100%',marginTop:'0.25rem',color: '#f46a6a',fontSize:'87.5%'}}>Select Image</div>
                        </div>
                      </Col>


                      <Col md="3" style={{display:'none'}}>
                        <div className="mb-3">
                          <Label htmlFor="activeStatus">Active Status</Label>
                          <Select
                            name="section_active_status"
                            value={activeStatus}
                            onChange={(value) => {
                              handleActiveStatus(value);
                            }}
                            id="activeStatus"
                            options={activeStatusList}
                            classNamePrefix="select2-selection"
                            validate={{ required: { value: false } }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="showingOrder">Showing order</Label>
                          <AvField
                            name="section_showing_order"
                            placeholder="Showing order"
                            type="number"
                            min="1"
                            value={sectionData.section_showing_order}
                            errorMessage="Showing order"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="showingOrder"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="showingHomepage">
                            showing homepage
                          </Label>
                          <AvInput
                            name="section_showing_homepage"
                            type="checkbox"
                            className="form-control"
                            id="showingHomepage"
                            checked={sectionData.section_showing_homepage=="Yes"?true:false}
                            onChange={handleChangeCheckbox}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="showingTopbar">showing topbar</Label>
                          <AvInput
                            name="section_showing_on_top_bar"
                            type="checkbox"
                            className="form-control"
                            id="showingTopbar"
                            checked={sectionData.section_showing_on_top_bar=="Yes"?true:false}
                            onChange={handleChangeCheckbox}
                          />
                        </div>
                      </Col>

                      

                      <Col md="3">
                        <div className="mt-4">
                          {sectionTypeIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="Submit"
                              disabled={updatingSectionType ? true : false}
                            >
                              {updatingSectionType ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingSectionType ? true : false}
                            >
                              {addingSectionType ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={25}
                    info={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(OfferTypes));