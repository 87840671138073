import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
  Input,
  FormGroup,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const UserLog = () => {
  const [data, setData] = useState([]);

  const dataTemp = [
    {
      date: "22-10-2021",
      time: "18:06:38",
      ip_address: "103.154.54.66",
      added_by: "shibily",
      activity: "Order Edited for stock - Order id 8911",
    },
    {
      date: "22-10-2021",
      time: "18:06:38",
      ip_address: "103.154.54.66",
      added_by: "shibily",
      activity: "Order Edited for stock - Order id 8911",
    },
    {
      date: "22-10-2021",
      time: "18:06:38",
      ip_address: "103.154.54.66",
      added_by: "shibily",
      activity: "Order Edited for stock - Order id 8911",
    },
  ];

  dataTemp.map((item, index) => {
    item.id = index + 1;
  });

  useEffect(() => {
    setData(dataTemp);
  }, []);

  const userlogData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Created IP",
        field: "ip_address",
        sort: "asc",
        width: 200,
      },
      {
        label: "Added By",
        field: "added_by",
        sort: "asc",
        width: 200,
      },
      {
        label: "Activity",
        field: "activity",
        sort: "disabled",
        width: 400,
      },
    ],
    rows: data,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="User Log" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle>View Rules</CardTitle> */}
                  {/* <hr /> */}
                  <MDBDataTable responsive bordered data={userlogData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserLog;
