import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import Select from "react-select";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import $ from "jquery";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import { formatMoney } from "../../../helpers/generalFunctions";
import { getProducts } from "../../../store/actions";
import { getArraySumByKey } from "../../../helpers/globalFunctions";
import { getDate } from "../../../helpers/globalFunctions";
import { CSVLink } from "react-csv";
import "./dailySalesReport.scss";
const DailySales = (props) => {
  const [details, setDetails] = useState([]);
  const [master, setMaster] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productOptions, setProductOptions] = useState([]);
  const [grandTotal, setGrandTotal] = useState(null);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);

  useEffect(() => {
    handleTableData();
    fetch_all_customer();
    dispatch(getProducts());
  }, []);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "invoice_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Customer",
        field: "customer_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Product ID",
        field: "product_sku_autogen",
        width: 200,
      },
      {
        label: "Item Details",
        field: "product_name_english",
        width: 200,
      },
      {
        label: "Qty",
        field: "qty",
        width: 200,
      },
      {
        label: "Rate",
        field: "amount",
        width: 200,
      },
      {
        label: "Discount",
        field: "invoice_discount_amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Charge",
        field: "shipping_charge",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "total",
        width: 200,
      },
      {
        label: "Total",
        field: "gridTotal",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };

  useEffect(() => {
    var data =
      products &&
      products.map((item) => {
        return {
          label: item.product_name_english,
          value: item.product_id,
        };
      });

    setProductOptions([
      {
        options: data,
      },
    ]);
  }, [products]);
  const fetch_all_customer = () => {
    var url = `${API_URL}customer/options`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var customer_data =
          res.data.data &&
          res.data.data.map((value) => {
            return {
              label: value.customer_name,
              value: value.customer_id,
            };
          });

        setCustomerOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
    let customer_id = master?.customer_id ? master.customer_id : "";
    let product_id = master?.product_id ? master.product_id : "";
    handleTableData(date1, date2, customer_id, product_id);
  };

  const handleSelectChange = (selected, name) => {
    let date1 = master?.from_date ? master.from_date : "";
    let date2 = master?.to_date ? master.to_date : "";
    let customer_id = master?.customer_id ? master.customer_id : "";
    let product_id = master?.product_id ? master.product_id : "";
    switch (name) {
      case "customer":
        setSelectedCustomer(selected);
        setMaster({
          ...master,
          ["customer_id"]: selected.value,
        });
        handleTableData(date1, date2, selected.value, product_id);
        break;
      case "product":
        setSelectedProduct(selected);
        setMaster({
          ...master,
          ["product_id"]: selected.value,
        });
        handleTableData(date1, date2, customer_id, selected.value);
        break;
      default:
        break;
    }
  };

  function handleTableData(
    from_date = "",
    to_date = "",
    customer_id = "",
    product_id = ""
  ) {
    var url = `${API_URL}report/sales_daily`;

    url =
      url +
      "?from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&customer_id=" +
      customer_id +
      "&product_id=" +
      product_id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let dataToBeExported = [];
        result.map((item, index) => {
          let exportItem = [];
          item.id = index + 1;
          item.invoice_date = moment(item.invoice_date).format("DD/MM/YYYY");
          let inventoryItems = item.inventoryItems;
          item.product_sku_autogen = (
            <div>
              <ul>
                {inventoryItems.map((element, index) => {
                  return <li>{element.product.product_sku_autogen}</li>;
                })}
              </ul>
            </div>
          );
          item.product_name_english = (
            <div>
              <ul>
                {inventoryItems.map((element, index) => {
                  return <li>{element.product.product_name_english}</li>;
                })}
              </ul>
            </div>
          );
          item.qty = (
            <div>
              <ul>
                {inventoryItems.map((element, index) => {
                  return <li>{element.inventory_item_quantity}</li>;
                })}
              </ul>
            </div>
          );
          item.amount = (
            <div>
              <ul id="rupee" className="a">
                {inventoryItems.map((element, index) => {
                  return (
                    <li>
                      {formatMoney(element.inventory_item_total_price_with_gst)}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
          // let grdTotal = 0;
          item.total = (
            <div>
              <ul id="rupee" className="a">
                {inventoryItems.map((element, index) => {
                  let totalAmount = Number(
                    parseFloat(element.inventory_item_total_price_with_gst)
                  );
                  // grdTotal = grdTotal + totalAmount;
                  return <li>{formatMoney(totalAmount)}</li>;
                })}
              </ul>
            </div>
          );
          // item.gridTotal = formatMoney(grdTotal);
          item.gridTotal = formatMoney(item.invoice_grand_total);
          item.invoice_discount_amount = formatMoney(
            item.invoice_discount_amount
          );
          item.shipping_charge = formatMoney(item.invoice_shipping_charge);

          
          item.inventoryItems&&item.inventoryItems.forEach((element) => {
            let values = {}
            // values.Id = item.id;
            values.Date = item.invoice_date;
            values.Customer = item.customer_name;
            values.Product_ID = element.product.product_sku_autogen;
            values.Product_Name = element.product.product_name_english;
            values.Qty = element.inventory_item_quantity;
            values.Rate = element.inventory_item_total_price_with_gst;
            values.Discount = item.invoice_discount_amount;
            values.Charge = item.invoice_shipping_charge;
            values.Amount = element.inventory_item_total_price_with_gst;
            exportItem.push(values);
          });

        
          dataToBeExported.push(...exportItem);
        });
        let grand_total = getArraySumByKey(result, "invoice_grand_total");
        // dataToBeExported.forEach(element => {
        //   element.grand_total = grand_total
        // });
        setDetails(result);
        setGrandTotal(grand_total);
        setDataToBeExported(dataToBeExported);
      });
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Daily Sales Report" />
          <Row>
            <Col className="col-12">
              <Row className="text-right">
                <h5>Grand Total : {formatMoney(grandTotal)}</h5>
              </Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            // min={master?.from_date}
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Customer</Label>
                        <Select
                          name="customer_id"
                          value={selectedCustomer}
                          onChange={(selected) => {
                            handleSelectChange(selected, "customer");
                          }}
                          options={customerOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Product</Label>
                        <Select
                          name="product_id"
                          value={selectedProduct}
                          onChange={(selected) => {
                            handleSelectChange(selected, "product");
                          }}
                          options={productOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mt-1 mb-2" style={{ paddingTop: "7px" }}>
                        <Button color="success" type="submit">
                          <CSVLink
                            data={dataToBeExported}
                            filename={
                              "Cococa_daily_sales_report_" +
                              getDate(new Date()) +
                              ".xls"
                            }
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="dailySalesReportId"
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DailySales;
