import {
  GET_SECTION_TYPES,
  GET_SECTION_TYPES_FAIL,
  GET_SECTION_TYPES_SUCCESS,
  ADD_SECTION_TYPE,
  ADD_SECTION_TYPE_SUCCESS,
  ADD_SECTION_TYPE_FAIL,
  DELETE_SECTION_TYPE,
  DELETE_SECTION_TYPE_SUCCESS,
  DELETE_SECTION_TYPE_FAIL,
  UPDATE_SECTION_TYPE,
  UPDATE_SECTION_TYPE_SUCCESS,
  UPDATE_SECTION_TYPE_FAIL,
  CHANGE_STATUS_SECTION_TYPE,
  CHANGE_STATUS_SECTION_TYPE_SUCCESS,
  CHANGE_STATUS_SECTION_TYPE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  sectionTypes: [],
  thirdCategories: [],
  addingSectionType: false,
  updatingSectionType: false,
  deletingSectionType: false,
  addSectionTypeResponse: {},
  updateSectionTypeResponse: {},
  deleteSectionTypeResponse: {},
  statusSectionTypeResponse: {},
  sectionIdToBeDeleted: "",
  sectiontype: {},
  error: {},
};

const sectionType = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SECTION_TYPES:
      return {
        ...state,
        params: action.payload,
      };

    case GET_SECTION_TYPES_SUCCESS:
      return {
        ...state,
        sectionTypes: action.payload.data,
      };

    case GET_SECTION_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // case GET_THIRDCATEGORIES:
    //   return {
    //     ...state,
    //     params: action.payload,
    //   };

    // case GET_THIRDCATEGORIES_SUCCESS:
    //   return {
    //     ...state,
    //     thirdCategories: action.payload.data,
    //   };

    // case GET_THIRDCATEGORIES_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };

    case ADD_SECTION_TYPE:
      return {
        ...state,
        addingSectionType: true,
        sectiontype: action.payload,
      };

    case ADD_SECTION_TYPE_SUCCESS:
      let newSectionTypes = [
        JSON.parse(JSON.stringify(action.payload.data[0])),
      ];
      newSectionTypes.push(...state.sectionTypes);

      return {
        ...state,
        addingSectionType: false,
        addSectionTypeResponse: {
          type: "success",
          message: "Section Type added successfully",
        },
        sectionTypes: newSectionTypes,
        error: {},
      };

    case ADD_SECTION_TYPE_FAIL:
      return {
        ...state,
        addingSectionType: false,
        addSectionTypeResponse: {
          type: "failure",
          message: "Adding Section Type failed",
        },
        error: action.payload,
      };
    case DELETE_SECTION_TYPE:
      return {
        ...state,
        deletingSectionType: true,
        sectionIdToBeDeleted: action.payload,
      };

    case DELETE_SECTION_TYPE_SUCCESS:
      let newSectionType = state.sectionTypes.filter((sectiontype) => {
        return sectiontype.section_id != state.sectionIdToBeDeleted;
      });
      return {
        ...state,
        sectionIdToBeDeleted: "",
        deletingSectionType: false,
        deleteSectionTypeResponse: {
          type: "success",
          message: "section type deleted successfully",
        },
        sectionTypes: newSectionType,
      };

    case DELETE_SECTION_TYPE_FAIL:
      return {
        ...state,
        deletingSectionType: false,
        deleteSectionTypeResponse: {
          type: "failure",
          message: "Deleting section type failed",
        },
        error: action.payload,
      };

    case UPDATE_SECTION_TYPE:
      return {
        ...state,
        updatingSectionType: true,
        sectiontype: action.payload,
      };

    case UPDATE_SECTION_TYPE_SUCCESS:
      let newsectiontypearr = [];
      state.sectionTypes.filter((sectiontype) => {
        if (sectiontype.section_id == state.sectiontype.section_id) {
          console.log(JSON.parse(JSON.stringify(action.payload.data[0])));
          newsectiontypearr.push(
            JSON.parse(JSON.stringify(action.payload.data[0]))
          );
        } else {
          //console.log(product);
          newsectiontypearr.push(sectiontype);
        }
      });

      return {
        ...state,
        updatingSectionType: false,
        sectiontype: "",
        updateSectionTypeResponse: {
          type: "success",
          message: "section type updated successfully",
        },
        sectionTypes: newsectiontypearr,
      };

    case UPDATE_SECTION_TYPE_FAIL:
      return {
        ...state,
        updatingSectionType: false,
        updateSectionTypeResponse: {
          type: "failure",
          message: "Updating section type failed",
        },
        error: action.payload,
      };

    case CHANGE_STATUS_SECTION_TYPE:
      return {
        ...state,
      };

    case CHANGE_STATUS_SECTION_TYPE_SUCCESS:
      return {
        ...state,
        sectionTypes: action.payload.data,
        statusSectionTypeResponse: {
          type: "success",
          message: "Status Changed successfully",
        },
        //products: newCategories2,
      };

    case CHANGE_STATUS_SECTION_TYPE_FAIL:
      return {
        ...state,
        statusSectionTypeResponse: {
          type: "failure",
          message: "Status change failed",
        },
        error: action.payload,
      };

    default:
      return state;
  }
};

export default sectionType;
