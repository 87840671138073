import React, { useState, useEffect,useRef } from "react";
import PropTypes from "prop-types";
import toastr from "toastr";
import axios from "axios";
import jwt_decode from "jwt-decode";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import SweetAlert from "react-bootstrap-sweetalert";
import $ from 'jquery';
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardThirdtitle,
  Button,
  Label,
  Modal,
  Progress,
} from "reactstrap";

import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import {
  getthirdcategories,
  addThirdcategory,
  deleteThirdcategory,
  toggleThirdActiveStatus,
  apiError,
  updateThirdcategory,
  getCategoriesOptions,
  getSubcategoryOptions,
  // getCategoryOptions
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

const Thirdcategories = (props) => {
  const [toggleSwitch, settoggleSwitch] = useState(true);

  const [thirdcategoryObject, setThirdcategoryObject] = useState({});
  const [thirdcategoriesTemp, setThirdcategoriesTemp] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [invalidcategory, setinvalidcategory] = useState('none');
  const [invalidsubcategory, setinvalidsubcategory] = useState('none');

  const [thirdcategoryIdTobeUpdated, setThirdcategoryIdToBeUpdated] =
    useState(null);
  const [thirdcategoryIdToBeDeleted, setThirdcategoryIdToBeDeleted] =
    useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState(1);
  const [uploadProgress, setUploadProgress] = useState();
  const formRef = useRef(null);
  const {
    thirdcategories,
    addingThirdcategory,
    addThirdcategoryResponse,
    deleteThirdcategoryResponse,
    updateThirdcategoryResponse,
    statusThirdcategoryResponse,
    error,
  } = useSelector((state) => state.thirdcategory);

  const { categoryOptions } = useSelector((state) => state.categories);
  const { subcategoryOptions } = useSelector((state) => state.subcategories);

  const dispatch = useDispatch();
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    dispatch(getthirdcategories());
    dispatch(getCategoriesOptions());
  }, []);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = jwt_decode(localStorage.getItem("authUser"));

      setUserId(obj.user);
      setThirdcategoryObject({ ["auth_userid"]: userId });
    }
  }, [props.success, props]);

  useEffect(() => {
    if (addThirdcategoryResponse.type === "success") {
      toastr.success(addThirdcategoryResponse.message);
      setThirdcategoryObject({});
    } else if (addThirdcategoryResponse.type === "failure") {
      toastr.error(error.data.message, addThirdcategoryResponse.message);
    }
  }, [addThirdcategoryResponse]);

  useEffect(() => {
    if (deleteThirdcategoryResponse.type === "success") {
      toastr.success(deleteThirdcategoryResponse.message);
    } else if (deleteThirdcategoryResponse.type === "failure") {
      toastr.error(error.data.message, deleteThirdcategoryResponse.message);
    }
  }, [deleteThirdcategoryResponse]);

  useEffect(() => {
    if (statusThirdcategoryResponse.type === "success") {
      dispatch(getthirdcategories());
      toastr.success(statusThirdcategoryResponse.message);
    } else if (statusThirdcategoryResponse.type === "failure") {
      toastr.error(error.data.message, statusThirdcategoryResponse.message);
    }
  }, [statusThirdcategoryResponse]);

  useEffect(() => {
    if (updateThirdcategoryResponse.type === "success") {
      setShowModal(false);
      setThirdcategoryIdToBeUpdated(null);
      // setPasswordObject({});
      setThirdcategoryIdToBeUpdated(null);
      setThirdcategoryObject({});
      toastr.success(updateThirdcategoryResponse.message);
    } else if (updateThirdcategoryResponse.type === "failure") {
      toastr.error(error.data.message, updateThirdcategoryResponse.message);
    }
  }, [updateThirdcategoryResponse]);

  let categoryOptionsData =
    categoryOptions &&
    categoryOptions.map((item) => {
      return {
        label: item.category_name_english,
        value: item.category_id,
      };
    });

  const categoryOptionsGroup = [
    {
      options: categoryOptionsData,
    },
  ];

  let subcategoryOptionsData =
    subcategoryOptions &&
    subcategoryOptions.map((item) => {
      return {
        label: item.subcategory_name_english,
        value: item.subcategory_id,
      };
    });

  const subcategoryOptionsGroup = [
    {
      options: subcategoryOptionsData,
    },
  ];

  let preupdateThirdcategory = (item) => {
    console.log(item, "item");
    if (item.subCategory) {
      let categories = {
        label: item.subCategory.category.category_name_english,
        value: item.subCategory.category.category_id,
      };
      let subcategories = {
        label: item.subCategory.subcategory_name_english,
        value: item.subCategory.subcategory_id,
      };
      
      handleSelectedCategory(categories);
      dispatch(getSubcategoryOptions(item.subCategory.category.category_id));
      handleSelectedSubCategory(subcategories);
    }
    setThirdcategoryIdToBeUpdated(item.thrdcate_id);
    setThirdcategoryObject(item);
  };

  useEffect(() => {
    let unitsDuplicate = JSON.parse(JSON.stringify(thirdcategories));
    let unitData = [];
    // console.log(unitsDuplicate);

    unitsDuplicate.map((item, index) => {
      item.fname = "";
    if(item.User!=null)
      {
        item.fname = item.User.fname + ' ' + item.User.lname;
      }
      item.auth_userid = userId;
      item.categoryname="";
      if(item.subCategory.category!=null)
      {
        item.categoryname =item.subCategory.category.category_name_english;
      }
      
      //item.categoryname="test";
      item.subcategory_name="";
      if(item.subCategory!=null)
      {
        item.subcategory_name =
        item.subCategory && item.subCategory.subcategory_name_english;
      }
      
      //item.thirdcategory_name="test2";

      item.action = (
        <div style={{ textAlign: "center" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* //   <i
            className="uil-key-skeleton"
            style={{ fontSize: "1.3em", cursor: "pointer" }}
            onClick={() => {
              preUpdateUserPassword(item);
            }}
          ></i> */}
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                preupdateThirdcategory(item);
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
              onClick={() => {
                setThirdcategoryIdToBeDeleted(item.thrdcate_id);
                setConfirmDeleteAlert(true);
              }}
            ></i>
          </div>
        </div>
      );
      item.id = index + 1;
      if (item.third_cat_img != "") {
        item.third_cat_image_name = (
          <div style={{ textAlign: "center" }}>
            <img
              src={
                API_URL + `public/uploads/thirdcategory/${item.third_cat_img}`
              }
              style={{ width: "20%", height: "50%" }}
            />
          </div>
        );
      }

      item.thrdcate_is_active = (
        <div style={{ textAlign: "center", width: "100%" }}>
          <div
            class="form-check form-switch mb-3"
            style={{ textAlign: "center", marginLeft: "40%" }}
          >
            <input
              type="checkbox"
              class="form-check-input"
              id="customSwitch2"
              checked={(item.thrdcate_is_active==0)?true:false}
              onClick={(e) => {
                dispatch(toggleThirdActiveStatus(item.thrdcate_id, userId));
                settoggleSwitch(!toggleSwitch);
              }}
            />
          </div>
        </div>
      );

      unitData.push(item);
    });
    setThirdcategoriesTemp(unitData);
  }, [thirdcategories]);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 10,
      },
      {
        label: "Category",
        field: "categoryname",
        sort: "asc",
        width: 10,
      },
      {
        label: "Sub Category",
        field: "subcategory_name",
        sort: "asc",
        width: 70,
      },
      {
        label: " English",
        field: "thrdcate_name_english",
        sort: "asc",
        width: 70,
      },
      {
        label: " Malayalam",
        field: "thrdcate_name_malayalam",
        sort: "asc",
        width: 70,
      },
      {
        label: "Image",
        field: "third_cat_image_name",
        sort: "asc",
        width: 70,
      },

      {
        label: "Status",
        field: "thrdcate_is_active",
        sort: "asc",
        width: 70,
      },

      {
        label: "User",
        field: "fname",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: thirdcategoriesTemp,
  };

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setThirdcategoryObject({
      ...thirdcategoryObject,
      [name]: value,
    });
  };

  const deleteCategoryIcon = () => {
    setThirdcategoryObject({
      ...thirdcategoryObject,
      [`third_cat_img`]: "",
    });
  };
  const uploadCategoryIcon = (e) => {
    e.preventDefault();
    let fd = new FormData();
    fd.append("image", e.target.files[0]);
    console.log(fd, "fd");
    console.log(e.target.files[0], "fd image");
    // https://cccapi.cococa.in/thirdcategory/uploadThirdcategorySingleImage
    axios
      .post(`${API_URL}thirdcategory/uploadThirdcategorySingleImage`, fd, {
        headers: {
          "x-access-token": accessToken,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setThirdcategoryObject({
            ...thirdcategoryObject,
            [`third_cat_img`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  function handleSelectedCategory(category) {
    // let newValue = {
    //   name: value.label,
    //   _id: value.value,
    // };
    if(category)
    {
      setinvalidcategory('none');
      $("#product_category").attr('class',"select2-selection");
    }
    else
    {
      setinvalidcategory('block');
      $("#product_category").attr('class',"select2-selection border border-danger");
    }
    dispatch(getSubcategoryOptions(category.value));
    setSelectedCategory(category);
    setSelectedSubCategory(null);
    setinvalidsubcategory('block');
    $("#product_sub_category").attr('class',"select2-selection border border-danger");
    //subcategoryOptionsGroup([]);

    // setThirdcategoryObject({ ...thirdcategoryObject, ['thrdcate_'] });
  }

  function handleSelectedSubCategory(category) {
    // let newValue = {
    //   name: value.label,
    //   _id: value.value,
    // };
    if(category)
    {
      setinvalidsubcategory('none');
      $("#product_sub_category").attr('class',"select2-selection");
    }
    else
    {
      setinvalidsubcategory('block');
      $("#product_sub_category").attr('class',"select2-selection border border-danger");
    }
    setSelectedSubCategory(category);

    setThirdcategoryObject({
      ...thirdcategoryObject,
      ["thrdcate_sub_category"]: category.value,
    });
  }

  const handleValidSubmitUnit = (event, values) => {
    event.preventDefault();
    if(selectedCategory==null)
    {
      setinvalidcategory('block');
      $("#product_category").attr('class',"select2-selection border border-danger");
      toTop();
    }
    else if(selectedSubCategory==null)
    {
      setinvalidsubcategory('block');
      $("#product_sub_category").attr('class',"select2-selection border border-danger");
      toTop();
    }
    else
    {
      if (
        thirdcategoryObject.third_cat_img &&
        thirdcategoryObject.third_cat_img.length > 2
      ) {
        if (
          thirdcategoryObject.third_cat_img.indexOf("uploads/thirdcategory/") > -1
        ) {
          // categoryObject.category_icon_svg.slice(categoryObject.category_icon_svg.indexOf('uploads/category-icons/') + 23)
          setThirdcategoryObject({
            ...thirdcategoryObject,
            [`third_cat_img`]: thirdcategoryObject.third_cat_img.slice(
              thirdcategoryObject.third_cat_img.indexOf(
                "uploads/thirdcategory/"
              ) + 23
            ),
          });
        }
      }
  
      console.log(thirdcategoryIdTobeUpdated, "thirdcategoryIdTobeUpdated");
      console.log(addThirdcategory, "addThirdcategory");
  
      thirdcategoryIdTobeUpdated
        ? dispatch(updateThirdcategory(thirdcategoryObject))
        : dispatch(addThirdcategory(thirdcategoryObject));
  
        formRef.current.reset();
        setSelectedCategory(null);
        setSelectedSubCategory(null);
        setThirdcategoryObject({ ['auth_userid']: userId });
    }
    

  };

  //   let handleChangeImageUpload =(event) => {
  // setCategoryObject({...categoryObject, unitlogo:event.target.files[0]})
  //   }

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteThirdcategory(thirdcategoryIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="3rd Category" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                  ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitUnit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Category</Label>
                          <Select
                            name="thrdcate_category"
                            id="product_category"
                            value={selectedCategory}
                            onChange={(value) => {
                              handleSelectedCategory(value);
                            }}
                            options={categoryOptionsGroup}
                            classNamePrefix="select2-selection"
                            validate={{ required: { value: true } }}
                          />
                           <div style={{ display:invalidcategory,Width: '100%',marginTop:'0.25rem',color: '#f46a6a',fontSize:'87.5%'}}>Select Category</div>

                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Sub Category
                          </Label>
                          <Select
                            name="thrdcate_sub_category"
                            id="product_sub_category"
                            value={selectedSubCategory}
                            onChange={(value) => {
                              handleSelectedSubCategory(value);
                            }}
                            options={subcategoryOptionsGroup}
                            classNamePrefix="select2-selection"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                        <div style={{ display:invalidsubcategory,Width: '100%',marginTop:'0.25rem',color: '#f46a6a',fontSize:'87.5%'}}>Select Sub Category</div>

                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            3rd Category Name(English)
                          </Label>

                          <AvField
                            name="thrdcate_name_english"
                            value={thirdcategoryObject.thrdcate_name_english}
                            placeholder="Category Name"
                            type="text"
                            errorMessage="Enter Category Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            3rd Category Name(Malayalam)
                          </Label>

                          <AvField
                            name="thrdcate_name_malayalam"
                            value={thirdcategoryObject.thrdcate_name_malayalam}
                            placeholder="Category Name"
                            type="text"
                            errorMessage="Enter Category Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            3rd Category JPG Image (400x400)
                          </Label>
                          {thirdcategoryObject.third_cat_img &&
                          thirdcategoryObject.third_cat_img.length > 2 ? (
                            <div div className="img-wraps">
                              {" "}
                              {thirdcategoryObject.third_cat_img &&
                              thirdcategoryObject.third_cat_img.startsWith(
                                "http"
                              ) ? (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${thirdcategoryObject.third_cat_img}`}
                                />
                              ) : (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${API_URL}public/uploads/thirdcategory/${thirdcategoryObject.third_cat_img}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteCategoryIcon}
                                style={{ width: "200px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="third_cat_img"
                              id="third_cat_img"
                              type="file"
                              errorMessage="Upload Category Icon"
                              className="form-control"
                              onChange={uploadCategoryIcon}
                            />
                          )}
                          {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              {" "}
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )}
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mt-4">
                          {thirdcategoryIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="Submit"
                              disabled={addingThirdcategory ? true : false}
                            >
                              {addingThirdcategory ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingThirdcategory ? true : false}
                            >
                              {addingThirdcategory ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bordered data={data} disableRetreatAfterSorting={true} entries={25} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  // const { error } = state.Users;
  // return { error };
};

export default withRouter(
  connect(mapStateToProps, { apiError })(Thirdcategories)
);

Thirdcategories.propTypes = {
  error: PropTypes.any,
  thirdcategories: PropTypes.array,
};
