import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import jwt_decode from 'jwt-decode';
import moment from 'moment';


import SweetAlert from 'react-bootstrap-sweetalert';
import { MDBDataTable } from 'mdbreact';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Modal,
  Badge,
} from 'reactstrap';

import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import {
  getOrders,
  getOrder,
  addOrder,
  deleteOrder,
  apiError,
  updateOrder,
  deliverOrder,
  outofdeliverOrder,
  cancelOrder,
} from '../../store/actions';

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import InvoiceDetail from '../Invoices/invoices-detail';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import './order.scss';

const Orders = (props) => {
  //   const [selectedorderpremium, setSelectedOrderPremium] = useState(null);

  const [orderObject, setOrderObject] = useState({});
  const [ordersTemp, setOrdersTemp] = useState([]);

  const [orderIdTobeUpdated, setOrderIdToBeUpdated] = useState(null);
  const [orderIdToBeDeleted, setOrderIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [orderIdTobeView, setOrderIdToBeView] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState(1);

  const {
    orders,
    order,
    addingOrder,
    addOrderResponse,
    deleteOrderResponse,
    updateOrderResponse,
    deliverOrderResponse,
    error,
  } = useSelector((state) => state.orders);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrders());
  }, []);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = jwt_decode(localStorage.getItem('authUser'));
      console.log(obj);

      setUserId(obj.user);
      setOrderObject({ ['auth_userid']: userId });
    }
  }, [props.success, props]);

  useEffect(() => {
    if (addOrderResponse.type === 'success') {
      toastr.success(addOrderResponse.message);
    } else if (addOrderResponse.type === 'failure') {
      toastr.error(error.data.message, addOrderResponse.message);
    }
  }, [addOrderResponse]);

  useEffect(() => {
    if (deleteOrderResponse.type === 'success') {
      toastr.success(deleteOrderResponse.message);
    } else if (deleteOrderResponse.type === 'failure') {
      toastr.error(error.data.message, deleteOrderResponse.message);
    }
  }, [deleteOrderResponse]);

  useEffect(() => {
    if (updateOrderResponse.type === 'success') {
      setShowModal(false);
      setOrderIdToBeUpdated(null);
      // setPasswordObject({});
      setOrderIdToBeUpdated(null);
      toastr.success(updateOrderResponse.message);
    } else if (updateOrderResponse.type === 'failure') {
      toastr.error(error.data.message, updateOrderResponse.message);
    }
  }, [updateOrderResponse]);

  useEffect(() => {
    if (deliverOrderResponse.type === 'success') {
      toastr.success(deliverOrderResponse.message);
      dispatch(getOrders());
    } else if (deliverOrderResponse.type === 'failure') {
      toastr.error(error.data.message, deliverOrderResponse.message);
    }
  }, [deliverOrderResponse]);

  let preUpdateOrder = (item) => {
    setOrderIdToBeUpdated(item.o_id);
    setOrderObject(item);
  };

  useEffect(() => {
    let ordersDuplicate = JSON.parse(JSON.stringify(orders));
    let orderitem = JSON.parse(JSON.stringify(order));
    let orderData = [];
    ordersDuplicate.map((item, index) => {
      // item.fname = item.User.fname + ' ' + item.User.lname;
      // item.auth_userid = userId;
      item.o_date = moment(item.o_date).format('DD/MM/YYYY');
      var time = item.o_time;
      item.o_time = moment(time, ['hh:mm:ss']).format('h:mm A');
      item.community_name = item.Community.community_name;

      item.customer_name = item.customer.customer_name;
      item.customer_mob = item.customer.customer_mob;

      if (item.o_pay_status === 0) {
        item.o_pay_status = 'Pending';
      } else if (item.o_pay_status === 1) {
        item.o_pay_status = 'Paid';
      } else if (item.o_pay_status === 2) {
        item.o_pay_status = 'Cancelled';
      } else {
        item.o_pay_status = 'Failed';
      }

      if (item.o_approved_status === 0) {
        item.o_approved_status = 'New';
        item.action = (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
              }}
              onClick={() => {
                dispatch(getOrder(item.o_id));
                setShowModal(true);
              }}
            ></i>

            {/* <i
              className="uil-print"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
              }}
              onClick={() => {
                dispatch(getOrder(item.o_id));
                setShowModal(true);
              }}
            ></i> */}
            {/* <i
              className="uil-whatsapp"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
              }}
              onClick={() => {
                dispatch(getOrder(item.o_id));
                setShowModal(true);
              }}
            ></i> */}
            <i
              className="far fa-edit"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
                paddingLeft: '5px',
              }}
              onClick={() => {
                // preUpdateOrder(item);
              }}
            ></i>

            <i
              className="far fa-trash-alt"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
                paddingLeft: '5px',
              }}
              onClick={() => {
                setOrderIdToBeDeleted(item.o_id);
                setConfirmDeleteAlert(true);
              }}
            ></i>

            <Button
              className="btn-rounded btn-soft-info waves-effect waves-light btn-sm"
              color="info"
              onClick={() => {
                dispatch(outofdeliverOrder(item.o_id, userId));
              }}
            >
              Out for Delivery
            </Button>

            <Button
              className="btn-rounded btn-soft-danger waves-effect waves-light btn-sm"
              color="danger"
              onClick={() => {
                dispatch(cancelOrder(item.o_id, userId));
              }}
            >
              Cancel
            </Button>

            {/* <i
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
              onClick={() => {
                dispatch(deliverOrder(item.o_id, userId));
              }}
            >
              <h6>
                <Badge className="bg-dark">Deliver</Badge>
              </h6>
            </i> */}
          </div>
        );
      } else if (item.o_approved_status === 1) {
        item.o_approved_status = 'Out for Delivery';
        item.action = (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
              }}
              onClick={() => {
                // setOrderIdToBeView(item.o_id);
                dispatch(getOrder(item.o_id));
                setShowModal(true);
              }}
            ></i>
            {/* <i
              className="uil-print"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
              }}
              onClick={() => {
                dispatch(getOrder(item.o_id));
                setShowModal(true);
              }}
            ></i> */}
            {/* <i
              className="uil-whatsapp"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
              }}
              onClick={() => {
                dispatch(getOrder(item.o_id));
                setShowModal(true);
              }}
            ></i> */}
            <i
              className="far fa-edit"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
                paddingLeft: '5px',
              }}
              onClick={() => {
                preUpdateOrder(item);
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
                paddingLeft: '5px',
              }}
              onClick={() => {
                setOrderIdToBeDeleted(item.o_id);
                setConfirmDeleteAlert(true);
              }}
            ></i>
            {/* <i
              style={{
                fontSize: '1em',
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
              onClick={() => {
                dispatch(outofdeliverOrder(item.o_id, userId));
              }}
            > */}

            <Button
              className="btn-rounded btn-soft-info waves-effect waves-light btn-sm"
              color="info"
              onClick={() => {
                dispatch(deliverOrder(item.o_id, userId));
              }}
            >
              Delivered
            </Button>

            <Button
              className="btn-rounded btn-soft-danger waves-effect waves-light btn-sm"
              color="danger"
              onClick={() => {
                dispatch(cancelOrder(item.o_id, userId));
              }}
            >
              Cancel
            </Button>
          </div>
        );
      } else if (item.o_approved_status === 3) {
        item.o_approved_status = 'Cancel';
        item.action = (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
              }}
              onClick={() => {
                // setOrderIdToBeView(item.o_id);
                dispatch(getOrder(item.o_id));
                setShowModal(true);
              }}
            ></i>
            {/* <i
              className="uil-print"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
              }}
              onClick={() => {
                dispatch(getOrder(item.o_id));
                setShowModal(true);
              }}
            ></i> */}
            {/* <i
              className="uil-whatsapp"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
              }}
              onClick={() => {
                dispatch(getOrder(item.o_id));
                setShowModal(true);
              }}
            ></i> */}
            <i
              className="far fa-edit"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
                paddingLeft: '5px',
              }}
              onClick={() => {
                preUpdateOrder(item);
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
                paddingLeft: '5px',
              }}
              onClick={() => {
                setOrderIdToBeDeleted(item.o_id);
                setConfirmDeleteAlert(true);
              }}
            ></i>
          </div>
        );
      } else {
        item.o_approved_status = 'Delivered';
        item.action = (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
              }}
              onClick={() => {
                // setOrderIdToBeView(item.o_id);
                dispatch(getOrder(item.o_id));
                // setShowModal(true);
                // dispatch(getOrder(item.o_id));
                setShowModal(true);
              }}
            ></i>
            {/* <i
              className="uil-print"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
              }}
              onClick={() => {
                dispatch(getOrder(item.o_id));
                setShowModal(true);
              }}
            ></i>
            <i
              className="uil-whatsapp"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
              }}
              onClick={() => {
                dispatch(getOrder(item.o_id));
                setShowModal(true);
              }}
            ></i> */}

            <i
              className="far fa-edit"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
              }}
              onClick={() => {
                preUpdateOrder(item);
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{
                fontSize: '1 em',
                cursor: 'pointer',
                paddingLeft: '5px',
              }}
              onClick={() => {
                setOrderIdToBeDeleted(item.o_id);
                setConfirmDeleteAlert(true);
              }}
            ></i>
          </div>
        );
      }

      item.id = index + 1;

      orderData.push(item);
    });
    setOrdersTemp(orderData);
  }, [orders]);

  const data = {
    columns: [
      {
        label: '#',
        field: 'id',
        sort: 'asc',
        width: 10,
      },

      // {
      //   label: 'ID',
      //   field: 'o_number',
      //   sort: 'asc',
      //   width: 70,
      // },
      {
        label: 'Date',
        field: 'o_date',
        sort: 'asc',
        width: 30,
      },

      {
        label: 'Time',
        field: 'o_time',
        sort: 'asc',
        width: 30,
      },
      {
        label: 'Order#',
        field: 'o_number',
        sort: 'asc',
        width: 60,
      },

      {
        label: 'Name',
        field: 'customer_name',
        sort: 'asc',
        width: 70,
      },

      {
        label: 'Mobile',
        field: 'customer_mob',
        sort: 'asc',
        width: 60,
      },

      {
        label: 'Amount',
        field: 'o_subtotal',
        sort: 'asc',
        width: 50,
      },
      {
        label: 'Community',
        field: 'community_name',
        sort: 'asc',
        width: 70,
      },

      {
        label: 'Payment',
        field: 'o_pay_status',
        sort: 'asc',
        width: 60,
      },

      {
        label: 'Status',
        field: 'o_approved_status',
        sort: 'asc',
        width: 30,
      },

      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 200,
      },
    ],
    rows: ordersTemp,
  };

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setOrderObject({
      ...orderObject,
      [name]: value,
    });
  };
  const handleValidSubmitOrder = (event, values) => {
    orderIdTobeUpdated
      ? dispatch(updateOrder(orderObject))
      : dispatch(addOrder(orderObject));
  };

  let closeModal = () => {
    setShowModal(false);
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteOrder(orderIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <Modal
        style={{
          maxWidth: '800px',
        }}
        isOpen={showModal}
        toggle={() => {
          closeModal();
        }}
      >
        <div style={{ maxWidth: '800px', paddingTop: '0px' }}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Order Details</h5>
            <button
              type="button"
              onClick={() => {
                closeModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
            <div className="modal-body">
              {order.customer ? (
                <InvoiceDetail
                  invoice={'00989'}
                  ShopName={'Bhoomi Farmers'}
                  ShopPhone={'9098777666'}
                  CustomerName={order.customer.customer_name}
                  CustomerMobile={order.customer.customer_mob}
                  // CommunityId={order.Community}
                  CommunityName={order.o_customer_apartment_no}
                  OrderId={order.o_number}
                  OrderNo={order.o_number}
                  OrderDate={order.o_date}
                  OrderItems={order.orderItems}
                  paystatus={order.o_pay_status}
                  subtotal={order.o_subtotal}
                />
              ) : null}
            </div>
          </AvForm>
        </div>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Orders" />
          {
            <Row hidden>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmitOrder(e, v);
                      }}
                    >
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Defaul Order
                            </Label>
                            <AvField
                              name="createdBy"
                              value={userId}
                              type="hidden"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              onChange={handleChangeInput}
                            />

                            <AvField
                              name="default_order"
                              value={orderObject.default_order}
                              placeholder="Default order"
                              type="text"
                              errorMessage="Enter Default order"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              onChange={handleChangeInput}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Sub Order
                            </Label>
                            <AvField
                              name="product_sub_order"
                              value={orderObject.product_sub_order}
                              placeholder="Sub order"
                              type="text"
                              errorMessage="Enter Sub Order"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              onChange={handleChangeInput}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Sub Order value
                            </Label>
                            <AvField
                              name="sub_order_value"
                              value={orderObject.sub_order_value}
                              placeholder="Sub order value"
                              type="text"
                              errorMessage="Enter sub order value"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              onChange={handleChangeInput}
                            />
                          </div>
                        </Col>
                      </Row>

                      {orderIdTobeUpdated ? (
                        <Button
                          color="primary"
                          type="submit"
                          disabled={addingOrder ? true : false}
                        >
                          {addingOrder ? 'Updating' : 'Update'}
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          type="submit"
                          disabled={addingOrder ? true : false}
                        >
                          {addingOrder ? 'Adding' : 'Submit'}
                        </Button>
                      )}
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  // const { error } = state.Users;
  // return { error };
};

export default withRouter(connect(mapStateToProps, { apiError })(Orders));

Orders.propTypes = {
  error: PropTypes.any,
  orders: PropTypes.array,
};
