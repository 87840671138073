import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
  Input,
  FormGroup,
  Grid,
} from "reactstrap";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "./../../components/Common/Breadcrumb";

const PushNotification = () => {
  const [data, setData] = useState([]);

  const dataTemp = [];

  useEffect(() => {
    setData(dataTemp);
  }, []);

  const ipData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Noti Heading",
        field: "heading",
        sort: "asc",
        width: 150,
      },
      {
        label: "Noti Message",
        field: "message",
        sort: "asc",
        width: 150,
      },
      {
        label: "Noti Image",
        field: "image",
        sort: "asc",
        width: 150,
      },
      {
        label: "URL",
        field: "url",
        sort: "asc",
        width: 270,
      },
      {
        label: "Noti User List",
        field: "list",
        sort: "asc",
        width: 270,
      },
      {
        label: "Noti For",
        field: "for",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 200,
      },
    ],
    rows: data,
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Create Notification" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Row>
                            <Col md="4">
                              <Label className="text-danger">
                                <i className="fas fa-user"></i> Notification For
                              </Label>
                            </Col>
                            <Col md="3">
                              <AvRadioGroup
                                name="notificationFor"
                                required
                                errorMessage="Pick one!"
                              >
                                <AvRadio label="Customer" value="customer" />
                              </AvRadioGroup>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Row>
                            <Col md="3">
                              <Label className="text-danger">
                                <i className="fas fa-mobile-alt"></i> Platform
                              </Label>
                            </Col>
                            <Col md="9">
                              <AvRadioGroup
                                name="platform"
                                inline
                                required
                                errorMessage="Pick one!"
                              >
                                <AvRadio label="All" value="all" />
                                <AvRadio label="Website" value="website" />
                                <AvRadio label="Andriod" value="andriod" />
                                <AvRadio
                                  label="IOS"
                                  value="ios"
                                  //   disabled
                                />
                              </AvRadioGroup>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <div className="form-check form-switch form-switch-lg mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizelg"
                            // defaultChecked
                            // onClick={() => {
                            //   this.setState({
                            //     toggleSwitchLarge: !this.state
                            //       .toggleSwitchLarge,
                            //   })
                            // }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customSwitchsizelg"
                          >
                            Specific
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <label>Notification Title</label>
                        <Input
                          type="textarea"
                          id="textarea"
                          onChange={(e) => null}
                          maxLength="225"
                          rows="3"
                        />
                      </Col>
                      <Col md="3">
                        <label>Notification Message</label>
                        <Input
                          type="textarea"
                          id="textarea"
                          onChange={(e) => null}
                          maxLength="225"
                          rows="3"
                        />
                      </Col>
                      <Col md="3">
                        <label>Notification URL</label>
                        <Input type="text" id="url" />
                      </Col>
                      <Col md="3">
                        <label>Notification Image</label>
                        <Input type="file" id="upload" />
                      </Col>
                      <Col md="2">
                        <div style={{ marginTop: "1.8em" }}>
                          <Button color="success" type="submit">
                            <i
                              className="bx bx-save"
                              style={{
                                fontSize: "1em",
                                cursor: "pointer",
                                marginRight: "0.5rem",
                              }}
                            ></i>
                            Save
                          </Button>
                        </div>
                      </Col>
                      <Col md="2">
                        <div style={{ marginTop: "1.8em" }}>
                          <Button color="danger">
                            <i
                              className="bx bx-sync"
                              style={{
                                fontSize: "1em",
                                cursor: "pointer",
                                marginRight: "0.5rem",
                              }}
                            ></i>{" "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle>View Rules</CardTitle> */}
                  {/* <hr /> */}
                  <MDBDataTable responsive bordered data={ipData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PushNotification;
