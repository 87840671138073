import {
    GET_CREDIT_DEBIT,
    GET_CREDIT_DEBIT_SUCCESS,
    GET_CREDIT_DEBIT_FAIL,
    ADD_CREDIT_DEBIT,
    ADD_CREDIT_DEBIT_SUCCESS,
    ADD_CREDIT_DEBIT_FAIL
} from "./actionTypes";

export const getAllCreditDebit = () => ({
    type: GET_CREDIT_DEBIT,
});

export const getAllCreditDebitSuccess = (data) => ({
    type: GET_CREDIT_DEBIT_SUCCESS,
    payload: data,
});

export const getAllCreditDebitFail = (error) => ({
    type: GET_CREDIT_DEBIT_FAIL,
    payload: error,
});

export const addCreditDebit = (data) => ({
    type: ADD_CREDIT_DEBIT,
    payload: data,
  });
  
  export const addCreditDebitSuccess = (data) => ({
    type: ADD_CREDIT_DEBIT_SUCCESS,
    payload: data,
  });
  
  export const addCreditDebitFail = (error) => ({
    type: ADD_CREDIT_DEBIT_FAIL,
    payload: error,
  });