import {
  GET_COMMUNITIES,
  GET_COMMUNITIES_FAIL,
  GET_COMMUNITIES_SUCCESS,
  ADD_COMMUNITY,
  ADD_COMMUNITY_FAIL,
  ADD_COMMUNITY_SUCCESS,
  GET_COMMUNITY,
  GET_COMMUNITY_FAIL,
  GET_COMMUNITY_SUCCESS,
  UPDATE_COMMUNITY,
  UPDATE_COMMUNITY_FAIL,
  UPDATE_COMMUNITY_SUCCESS,
  DELETE_COMMUNITY,
  DELETE_COMMUNITY_FAIL,
  DELETE_COMMUNITY_SUCCESS,
} from './actionTypes';

const INIT_STATE = {
  communities: [],
  addingCommunity: false,
  deletingCommunity: false,
  addCommunityResponse: {},
  updateCommunityResponse: {},
  deleteCommunityResponse: {},
  communityIdToBeDeleted: '',
  community: {},
  error: {},
};

const Community = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMMUNITIES:
      return {
        ...state,
        params: action.payload,
      };

    case GET_COMMUNITIES_SUCCESS:
      return {
        ...state,
        communities: action.payload.data,
      };

    case GET_COMMUNITIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_COMMUNITY:
      return {
        ...state,
        addingUnit: true,
        community: action.payload,
      };

    case ADD_COMMUNITY_SUCCESS:
      /*let newUnits = state.units.filter((unit) => {
            return unit;
           });*/
      let newCommunities = [JSON.parse(JSON.stringify(action.payload.data[0]))];
      newCommunities.push(...state.communities);
      console.log(newCommunities);
      //console.log(...state.units);
      //console.log(JSON.parse(JSON.stringify(action.payload.data[0])));
      return {
        ...state,
        addingCommunity: false,
        addCommunityResponse: {
          type: 'success',
          message: 'Community added successfully',
        },
        communities: newCommunities,
        error: {},
      };

    case ADD_COMMUNITY_FAIL:
      return {
        ...state,
        addingCommunity: false,
        addCommunityResponse: {
          type: 'failure',
          message: 'Adding community failed',
        },
        error: action.payload,
      };
    case DELETE_COMMUNITY:
      return {
        ...state,
        deletingCommunity: true,
        communityIdToBeDeleted: action.payload,
      };

    case DELETE_COMMUNITY_SUCCESS:
      let newcommunities2 = state.communities.filter((community) => {
        return community.community_id !== state.communityIdToBeDeleted;
      });
      return {
        ...state,
        deletingCommunity: false,
        communityIdToBeDeleted: '',
        deleteCommunityResponse: {
          type: 'success',
          message: 'Community deleted successfully',
        },
        communities: newcommunities2,
      };

    case DELETE_COMMUNITY_FAIL:
      return {
        ...state,
        deletingCommunity: false,
        deleteCommunityResponse: {
          type: 'failure',
          message: 'Community deleted successfully',
        },
        error: action.payload,
      };

    case UPDATE_COMMUNITY:
      return {
        ...state,
        updatingCommunity: true,
        community: action.payload,
      };

    // case UPDATE_COMMUNITY_SUCCESS:
    //   let newCommunities2 = state.communities.map((item) => {
    //     // if (item._id === state.user._id) {
    //     if (item.community_id === state.community.community_id) {
    //       return action.payload.data;
    //     } else {
    //       return item;
    //     }
    //   });
    //   return {
    //     ...state,
    //     updatingCommunity: false,
    //     community: '',
    //     updateCommunityResponse: {
    //       type: 'success',
    //       message: 'Community updated successfully',
    //     },
    //     communities: newCommunities2,
    //   };

    case UPDATE_COMMUNITY_SUCCESS:
      let newcommuntyarray = [];
      let newCommunities2 = state.communities.filter((community) => {
        if (community.community_id === state.communities.community_id) {
          console.log(JSON.parse(JSON.stringify(action.payload.data[0])));
          newcommuntyarray.push(
            JSON.parse(JSON.stringify(action.payload.data[0]))
          );
        } else {
          newcommuntyarray.push(community);
        }
      });

      return {
        ...state,
        updatingCommunity: false,
        community: '',
        updateCommunityResponse: {
          type: 'success',
          message: 'community updated successfully',
        },
        communities: newcommuntyarray,
      };

    case UPDATE_COMMUNITY_FAIL:
      return {
        ...state,
        updatingCommunity: false,
        updateCommunityResponse: {
          type: 'failure',
          message: 'Updating community failed',
        },
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Community;
