export const GET_AREAS = 'GET_AREAS';
export const GET_AREAS_SUCCESS = 'GET_AREAS_SUCCESS';
export const GET_AREAS_FAIL = 'GET_AREAS_FAIL';

export const GET_AREA = 'GET_AREA';
export const GET_AREA_SUCCESS = 'GET_AREA_SUCCESS';
export const GET_AREA_FAIL = 'GET_AREA_FAIL';

export const ADD_AREA = 'ADD_AREA';
export const ADD_AREA_SUCCESS = 'ADD_AREA_SUCCESS';
export const ADD_AREA_FAIL = 'ADD_AREA_FAIL';

export const UPDATE_AREA = 'UPDATE_AREA';
export const UPDATE_AREA_SUCCESS = 'UPDATE_AREA_SUCCESS';
export const UPDATE_AREA_FAIL = 'UPDATE_AREA_FAIL';

export const DELETE_AREA = 'DELETE_AREA';
export const DELETE_AREA_SUCCESS = 'DELETE_AREA_SUCCESS';
export const DELETE_AREA_FAIL = 'DELETE_AREA_FAIL';

export const GET_AREAS_OPTIONS = 'GET_AREAS_OPTIONS';
export const GET_AREAS_OPTIONS_SUCCESS = 'GET_AREAS_OPTIONS_SUCCESS';
export const GET_AREAS_OPTIONS_FAIL = 'GET_AREAS_OPTIONS_FAIL';