import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  Table,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  getUsers,
  addUser,
  deleteUser,
  apiError,
  getPrivilagesOptions,
  getCompaniesOptions,
  getBranchesOptions,
  updateUser,
  //getPrivilagesOptions,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import "./user.scss";

const AddPurchase = (props) => {
  const [usersForTable, setUsersForTable] = useState([]);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "district",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Acc Category",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Account",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Reference ID",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Debit",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "	Credit	",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Balance",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "	Remark	",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "User",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
    ],
    rows: usersForTable,
  };

  const [values, setValues] = useState([{}]);

  const addMore = () => {
    setValues([...values, {}]);
  };

  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    setValues(list);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Add Purchase" />

          <Col className="col-12">
            <Card>
              <CardBody>
                <AvForm
                  className="needs-validation"
                  // onValidSubmit={(e, v) => {
                  //   handleValidSubmit(e, v);
                  // }}
                >
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">Bill Date</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            defaultValue="2019-08-19"
                            id="example-date-input"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">Bill No.</Label>
                        <AvField
                          name="BillNo"
                          placeholder="Bill No."
                          type="text"
                          errorMessage="Enter Bill No."
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom05"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">
                          Purchase Order No.
                        </Label>
                        <AvField
                          name="OrderNo"
                          placeholder="Purchase Order No."
                          type="text"
                          errorMessage="Enter Purchase Order No."
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom05"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Cash</Label>
                        <Select
                          name="customer_community_id"
                          //   value={selectCommunity}
                          //   onChange={(value) => {
                          //     handleSelectedCommunities(value);
                          //   }}
                          //   options={communitiesOptionsGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>ICIC Bank</Label>
                        <Select
                          name="customer_community_id"
                          //   value={selectCommunity}
                          //   onChange={(value) => {
                          //     handleSelectedCommunities(value);
                          //   }}
                          //   options={communitiesOptionsGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">
                          Supplier Name
                        </Label>
                        <AvField
                          name="SupplierName"
                          placeholder="Supplier Name"
                          type="text"
                          errorMessage="Enter Supplier Name"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom05"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">
                          Supplier Address
                        </Label>
                        <AvField
                          name="SupplierAddress"
                          placeholder="SupplierAddress"
                          type="text"
                          errorMessage="Enter SupplierAddress"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom05"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">
                          Supplier State
                        </Label>
                        <AvField
                          name="SupplierState"
                          placeholder="Supplier State"
                          type="text"
                          errorMessage="Enter Supplier State"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom05"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">
                          Supplier GSTIN
                        </Label>
                        <AvField
                          name="SupplierGSTIN"
                          placeholder="Supplier GSTIN"
                          type="text"
                          errorMessage="Enter Supplier GSTIN"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom05"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="1">
                      <div className="mt-4">
                        <Button color="success" type="submit">
                          Save
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                  <div className="mt-4 mt-lg-0">
                        <h5 className="font-size-14 mb-3">Service</h5>
                        <div className="d-flex">
                          <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              switch="none"
                              //   checked={sq1}
                              //   onChange={() => {
                              //     setsq1(!sq1)
                              //   }}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label="ON"
                              data-off-label="OFF"
                            />
                          </div>
                        </div>
                      </div>
                  </Row>
                </AvForm>

                <Table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product</th>
                      <th>HSN</th>
                      <th>Unit</th>
                      <th>Qty</th>
                      <th>Rate</th>
                      <th>MRP</th>
                      <th>Discount%</th>
                      <th>GST%</th>
                      <th>Amount</th>
                      {values.length !== 1 ? <th>Action</th> : null}
                    </tr>
                  </thead>
                  <tbody>
                    {values.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            {values.length !== 1 && (
                              <i
                                className="far fa-trash-alt"
                                style={{
                                  fontSize: "23px",
                                  cursor: "pointer",
                                  paddingLeft: "5px",
                                }}
                                onClick={() => handleRemove(index)}
                              ></i>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <br />
                <Button
                  onClick={() => addMore()}
                  color="success"
                  className="mt-1"
                >
                  Add New Item
                </Button>
                <div className="float-end">
                  <div className="mt-4">
                    <input
                      type="text"
                      id="lname"
                      style={{ textAlign: "center" }}
                      name="lname"
                      disabled
                      placeholder="Total Value"
                    />
                    <input />
                  </div>

                  <div className="mt-1">
                    <input
                      type="text"
                      id="lname"
                      style={{ textAlign: "center" }}
                      name="lname"
                      disabled
                      placeholder="Total Discount"
                    />
                    <input />
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="lname"
                      style={{ textAlign: "center" }}
                      name="lname"
                      disabled
                      placeholder="Taxable Value"
                    />
                    <input />
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="lname"
                      style={{ textAlign: "center" }}
                      name="lname"
                      disabled
                      placeholder="GST"
                    />
                    <input />
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="lname"
                      style={{ textAlign: "center" }}
                      name="lname"
                      disabled
                      placeholder="Round Off"
                    />
                    <input />
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="lname"
                      style={{ textAlign: "center" }}
                      name="lname"
                      disabled
                      placeholder="Grand Total"
                    />
                    <input />
                  </div>
                  <br />
                  <div className="float-end">
                    <Button
                      color="primary"
                      to="#"
                      className="btn btn-primary w-md waves-effect waves-light"
                    >
                      Create Order
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(AddPurchase));
