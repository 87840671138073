import {
  GET_COMMUNITIES_OPTIONS_SUCCESS,
  GET_COMMUNITIES_OPTIONS_FAIL,
  GET_COMMUNITIES_OPTIONS,
} from './actionTypes';

const INIT_STATE = {
  communitiesOptions: [],
};

const Communities = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMMUNITIES_OPTIONS:
      return {
        ...state,
        params: action.payload,
      };
    case GET_COMMUNITIES_OPTIONS_SUCCESS:
      console.log('Community Option' + action.payload);
      return {
        ...state,
        communitiesOptions: action.payload,
      };

    case GET_COMMUNITIES_OPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
  
  export default Communities;