import {
  GET_SECTION_PRODUCT,
  GET_SECTION_PRODUCT_SUCCESS,
  GET_SECTION_PRODUCT_FAIL,
  ADD_SECTION_PRODUCT,
  ADD_SECTION_PRODUCT_SUCCESS,
  ADD_SECTION_PRODUCT_FAIL,
  UPDATE_SECTION_PRODUCT,
  UPDATE_SECTION_PRODUCT_SUCCESS,
  UPDATE_SECTION_PRODUCT_FAIL,
  DELETE_SECTION_PRODUCT,
  DELETE_SECTION_PRODUCT_SUCCESS,
  DELETE_SECTION_PRODUCT_FAIL,
  GET_SECTION_PRODUCT_SEARCH,
  GET_SECTION_PRODUCT_SEARCH_SUCCESS,
  GET_SECTION_PRODUCT_SEARCH_FAIL,
} from "./actionTypes";

export const getAllSectionProduct = () => ({
    type: GET_SECTION_PRODUCT,
  });
  
  export const getAllSectionProductSuccess = (data) => ({
    type: GET_SECTION_PRODUCT_SUCCESS,
    payload: data,
  });
  
  export const getAllSectionProductFail = (error) => ({
    type: GET_SECTION_PRODUCT_FAIL,
    payload: error,
  });
  
  export const addSectionProduct = (data) => ({
    type: ADD_SECTION_PRODUCT,
    payload: data,
  });
  
  export const addSectionProductSuccess = (data) => ({
    type: ADD_SECTION_PRODUCT_SUCCESS,
    payload: data,
  });
  
  export const addSectionProductFail = (error) => ({
    type: ADD_SECTION_PRODUCT_FAIL,
    payload: error,
  });
  
  export const updateSectionProduct = (data) => ({
    type: UPDATE_SECTION_PRODUCT,
    payload: data,
  });
  
  export const updateSectionProductSuccess = (data) => ({
    type: UPDATE_SECTION_PRODUCT_SUCCESS,
    payload: data,
  });
  
  export const updateSectionProductFail = (error) => ({
    type: UPDATE_SECTION_PRODUCT_FAIL,
    payload: error,
  });
  
  export const deleteSectionProduct = (data, auth_user) => ({
    type: DELETE_SECTION_PRODUCT,
    payload: data,
    auth_data: auth_user,
  });
  
  export const deleteSectionProductSuccess = (data) => ({
    type:DELETE_SECTION_PRODUCT_SUCCESS,
    payload: data,
  });
  
  export const deleteSectionProductFail = (error) => ({
    type: DELETE_SECTION_PRODUCT_FAIL,
    payload: error,
  });
  export const getAllSectionProductSearch = (data) => ({
    type:GET_SECTION_PRODUCT_SEARCH,
    payload:data
  });
  export const getAllSectionProductSearchSuccess = (data) => ({
    type: GET_SECTION_PRODUCT_SEARCH_SUCCESS,
    payload: data,
  });
  
  export const getAllSectionProductSearchFail = (error) => ({
    type: GET_SECTION_PRODUCT_SEARCH_FAIL,
    payload: error,
  });