import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import toastr from "toastr";
import jwt_decode from "jwt-decode";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { MDBDataTable } from "mdbreact";
import $ from 'jquery';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Modal,
  Progress,
} from "reactstrap";

import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import {
  getCustomers,
  addCustomer,
  deleteCustomer,
  toggleActiveStatus,
  apiError,
  updateCustomer,
} from "../../store/actions";


// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Brands = (props) => {
  const [toggleSwitch, settoggleSwitch] = useState(true);

  const [customerObject, setCustomerObject] = useState({});
  const [customersTemp, setBrandsTemp] = useState([]);

  const [customerIdTobeUpdated, setCustomerIdToBeUpdated] = useState(null);
  const [customerIdToBeDeleted, setCustomerIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState(1);
  const [uploadProgress, setUploadProgress] = useState()
  const [selectedType, setselectedType] = useState(null);
  const [invalidtype, setinvalidtype] = useState('none');
  let premiumOptionsData =[{label:"Yes",value:1},{label:"No",value:0}];

  const premiumOptionsGroup = [
    {
      options: premiumOptionsData,
    },
  ];
  const {
    customers,
    addingCustomer,
    addCustomerResponse,
    deleteCustomerResponse,
    updateCustomerResponse,
    statusCustomerResponse,
    error,
  } = useSelector((state) => state.customers);

  const dispatch = useDispatch();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    }); 
  };
  useEffect(() => {
    dispatch(getCustomers());
  }, []);

  useEffect(() => { 
    if (localStorage.getItem("authUser")) {
      const obj = jwt_decode(localStorage.getItem("authUser"));

      setUserId(obj.user);
      setCustomerObject({
        ["auth_userid"]: userId,
      });
      console.log(userId);
    }
  }, [props.success, props]);

  useEffect(() => {
    if (addCustomerResponse.type === "success") {
      toastr.success(addCustomerResponse.message);
      setCustomerObject({})
    } else if (addCustomerResponse.type === "failure") {
      toastr.error(error.data.message, addCustomerResponse.message);
    }
  }, [addCustomerResponse]);

  useEffect(() => {
    if (deleteCustomerResponse.type === "success") {
      toastr.success(deleteCustomerResponse.message);
    } else if (deleteCustomerResponse.type === "failure") {
      toastr.error(error.data.message, deleteCustomerResponse.message);
    }
  }, [deleteCustomerResponse]);

 
const clearCustomerDetails = () => {
  // setCustomerObject({})
  // setCustomerObject({
  //   ...customerObject,
  //   [`customer_name`]: " ",
  //   [`customer_icon_svg`]: " ",
  //   [`customer_seo_title`]: " ",
  //   [`customer_seo_keys`]: " ",
  //   [`customer_seo_desc`]: " ",
  // });
}
  useEffect(() => {
    if (updateCustomerResponse.type === "success") {
      setShowModal(false);
      setCustomerIdToBeUpdated(null);
      // setPasswordObject({});
      setCustomerIdToBeUpdated(null);
      // setCustomerObject({})
      clearCustomerDetails()

      console.log("=====UPDATED CATEGORY======");
      toastr.success(updateCustomerResponse.message);
    } else if (updateCustomerResponse.type === "failure") {
      toastr.error(error.data.message, updateCustomerResponse.message);
    }
  }, [updateCustomerResponse]);

  let preupdateCustomer = (item) => {
    setCustomerIdToBeUpdated(item.customer_id);
    setCustomerObject(item);
    // setCustomerObject({
    //   ...item,
    //   [`customer_icon_svg`]: item.customer_icon_svg
    //     ? item.customer_icon_svg.props.children.props.src
    //     : "",
    // });


    if (item.customer_icon_svg &&
      item.customer_icon_svg.props.children.props.src.length > 2) {
      if (item.customer_icon_svg.props.children.props.src.indexOf('uploads/customer-icons/') > -1) {            
        setCustomerObject({
          ...item,
          [`customer_icon_svg`]: item.customer_icon_svg.props.children.props.src.slice(item.customer_icon_svg.props.children.props.src.indexOf('uploads/customer-icons/') + 23)
        });
      
      }else{
      
        setCustomerObject({
          ...item,
          [`customer_icon_svg`]: item.customer_icon_svg.props.children.props.src,
        });
      }
    }else{
      setCustomerObject({
        ...item,
        [`customer_icon_svg`]: "",
      });
    }

   
  };

  useEffect(() => {
    console.log(customers);
    let unitsDuplicate = JSON.parse(JSON.stringify(customers));
    console.log(unitsDuplicate);
    let unitData = [];
    unitsDuplicate.map((item, index) => {
      if(item.User!=null)
      {
        item.fname = item.User.fname + " " + item.User.lname;
      }
      else
      {
        item.fname="";
      }
      if(item.area!=null)
      {
        item.area_name=item.area.area_name;
      }
     
      item.auth_userid = userId;

      item.action = (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            {/* //   <i
                        className="uil-key-skeleton"
                        style={{ fontSize: "1.3em", cursor: "pointer" }}
                        onClick={() => {
                          preUpdateUserPassword(item);
                        }}
                      ></i> */}{" "}
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                toTop();
                preupdateCustomer(item);
              }}
            ></i>{" "}
            <i
              className="far fa-trash-alt"
              style={{
                fontSize: "1em",
                cursor: "pointer",
              }}
              onClick={() => {
                setCustomerIdToBeDeleted(item.customer_id);
                setConfirmDeleteAlert(true);
              }}
            ></i>{" "}
          </div>{" "}
        </div>
      );
      item.id = index + 1;
      item.customer_wallet_amount1=(
        <div className="text-right">{item.customer_wallet_amount.toFixed(2)}</div>
      ); 
      if (item.customer_icon_svg != "") {
        item.customer_icon_svg = (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <img
              src={`https://cccapi.cococa.in/public/uploads/brand/${item.brand_image}`}
              height="50"
              width="50"
            />
          </div>
        );
      }

      if (item.brand_is_active === 0) {
        item.brand_is_active = (
          <div
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            <div
              class="form-check form-switch mb-3"
              style={{
                textAlign: "center",
                marginLeft: "40%",
              }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="customSwitch2"
                defaultChecked
                onClick={(e) => {
                  dispatch(toggleActiveStatus(item.customer_id, userId));
                  settoggleSwitch(!toggleSwitch);
                }}
              />{" "}
            </div>{" "}
          </div>
        );
      } else {
        console.log(item.brand_is_active);
        item.brand_is_active = (
          <div
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            <div
              class="form-check form-switch mb-3"
              style={{
                textAlign: "center",
                marginLeft: "40%",
              }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="customSwitch2"
                onClick={(e) => {
                  dispatch(toggleActiveStatus(item.customer_id, userId));
                  settoggleSwitch(!toggleSwitch);
                }}
              />{" "}
            </div>{" "}
          </div>
        );
      }

      unitData.push(item);
    });
    setBrandsTemp(unitData);
  }, [customers]);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "ID",
        field: "customers_unique_id",
        sort: "asc",
        width: 100,
      },

      {
        label: "Name",
        field: "customer_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Mobile",
        field: "customer_contact_number",
        sort: "asc",
        width: 100,
      },
      {
        label: "Email ID",
        field: "customer_email_id",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "Address",
      //   field: "customer_address",
      //   sort: "asc",
      //   width: 100,
      // },
      {
        label: "Area",
        field: "area_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "GST",
        field: "customer_gst",
        sort: "asc",
        width: 100,
      },
      
      {
        label: "Wallet",
        field: "customer_wallet_amount1",
        sort: "asc",
        width: 100,
      },
      {
        label: "Added By",
        field: "fname",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 10,
      },
    ],
    rows: customersTemp,
  };

  const deleteCustomerIcon = () => {
    setCustomerObject({
      ...customerObject,
      [`customer_icon_svg`]: "",
    });
  };
  const uploadCustomerIcon = (e) => {
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}api/uploadCustomerSingleImage`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar          
          setUploadProgress(Math.round((100 * data.loaded) / data.total))
        }
      })
      .then((response) => {
        if (response.data.status === "success") {
          setCustomerObject({
            ...customerObject,
            [`customer_icon_svg`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setCustomerObject({
      ...customerObject,
      [name]: value,
    });
  };
  function handleSelectedTypes(e) {
    console.log(e);
    let name = 'brand_premium_status';
    let value = e.value;
    if(e.value==0||e.value)
    {
      $("#brand_premium_status").attr('class',"select2-selection");
      setinvalidtype('none');
    }
    setselectedType(e);
    setCustomerObject({
      ...customerObject,
      [name]: value,
    });
  }
  let onChangeHandler = (event) => {
    //console.log(event.target.files[0])
    //setSelectedFile(event.target.files[0]);
    //customerObject.push(image:event.target.files[0])
    //setCustomerObject({...customerObject, file:event.target.files[0]})
  };

  const handleValidSubmitUnit = (event, values) => {
    if(selectedType==null)
    {
        setinvalidtype('block');
        $("#brand_premium_status").attr('class',"select2-selection border border-danger");
      return;
    }
    // var formData = new FormData();
    // var imagefile = document.querySelector('#customer_icon_svg');
    // formData.append('customer_name', 'test');
    // formData.append('file', imagefile.files[0]);

    // console.log(Object.keys(formData));
    console.log("===customerObject======",customerObject);
    if (customerObject.customer_icon_svg &&
      customerObject.customer_icon_svg.length > 2) {
      if (customerObject.customer_icon_svg.indexOf('uploads/customer-icons/') > -1) {
        console.log("====exist uploads in name===");
        // customerObject.customer_icon_svg.slice(customerObject.customer_icon_svg.indexOf('uploads/customer-icons/') + 23)
        setCustomerObject({
          ...customerObject,
          [`customer_icon_svg`]:  customerObject.customer_icon_svg.slice(customerObject.customer_icon_svg.indexOf('uploads/customer-icons/') + 23),
        });
        console.log("================================customerObject.customer_icon_svg after slice=======",customerObject.customer_icon_svg)
      }else{
        console.log("====dose not exist uploads in name===");
      }
    }
    console.log("===customerObject2222======",customerObject);

    customerIdTobeUpdated
      ? dispatch(updateCustomer(customerObject))
      : dispatch(addCustomer(customerObject));
  };

  //   let handleChangeImageUpload =(event) => {
  // setCustomerObject({...customerObject, unitlogo:event.target.files[0]})
  //   }

  return (
    <React.Fragment>
      {" "}
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteCustomer(customerIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it ?
        </SweetAlert>
      ) : null}{" "}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Customers" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <AvField
                          type="text"
                          name="customer_name"
                          label="Customer Name"
                          // helpMessage="please select role"
                        ></AvField>
                      </Col>
                      <Col md="3">
                        <AvField
                          type="text"
                          name="mobile_no"
                          label="Mobile No."
                          // helpMessage="please select role"
                        ></AvField>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            type="text"
                            name="landline_no"
                            label="Landline No."
                            // helpMessage="please select role"
                          ></AvField>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            type="text"
                            name="email_id"
                            label="Email ID"
                          ></AvField>
                        </div>
                      </Col>
                      <Col md="3" >
                        <div className="mb-3">
                          <AvField
                            type="textarea"
                            name="address"
                            label="Address"
                            // helpMessage="please select role"
                          ></AvField>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            type="select"
                            name="state"
                            label="Select State"
                            // helpMessage="please select role"
                          ></AvField>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            type="select"
                            name="city"
                            label="Select City"
                            // helpMessage="please select role"
                          ></AvField>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            type="text"
                            name="pincode"
                            label="Pincode"
                            // helpMessage="please select role"
                          ></AvField>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            type="text"
                            name="gst"
                            label="GSTIN"
                            // helpMessage="please select role"
                          ></AvField>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            type="text"
                            name="pan_no"
                            label="PAN_NO"
                            // helpMessage="please select role"
                          ></AvField>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            type="file"
                            name="customer_img"
                            label="Customer Image 300x400"
                            // helpMessage="please select role"
                          ></AvField>
                        </div>
                      </Col>
                      <Col md="1" className="p-0">
                        <div style={{ marginTop: "1.8em" }}>
                          <Button color="success" type="submit">
                            <i
                              className="bx bx-save"
                              style={{
                                fontSize: "1em",
                                cursor: "pointer",
                                marginRight: "0.5rem",
                              }}
                            ></i>
                            Save
                          </Button>
                        </div>
                      </Col>
                      <Col md="1" className="p-0">
                        <div style={{ marginTop: "1.8em" }}>
                          <Button
                            color="danger"
                            style={{
                              fontSize: "1em",
                              cursor: "pointer",
                            }}
                          >
                            <i className="bx bx-sync"></i> Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bordered data={data}  disableRetreatAfterSorting={true}
                    entries={25} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  // const { error } = state.Users;
  // return { error };
};

export default withRouter(connect(mapStateToProps, { apiError })(Brands));

Brands.propTypes = {
  error: PropTypes.any,
  customers: PropTypes.array,
};
