import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';

import {
  GET_DELIVERYLIST,
  
  GET_DELIVERYLISTS,
} from './actionTypes';

import {
  getDeliverylistsSuccess,
  getDeliverylistsFail,
  getDeliverylistSuccess,
  getDeliverylistFail,
 
} from './actions';

//Include Both Helper File with needed methods
import {
  getDeliverylists,
  getDeliverylist,
  
} from '../../helpers/backend_helper';

function* fetchDeliverylists() {
  try {
    const response = yield call(getDeliverylists);
    yield put(getDeliverylistsSuccess(response));
  } catch (error) {
    yield put(getDeliverylistsFail(error));
  }
}

function* onGetDeliverylist() {
  try {
    const response = yield call(getDeliverylist);
    yield put(getDeliverylistSuccess(response));
  } catch (error) {
    yield put(getDeliverylistFail(error.response));
  }
}




function* deliverylistSaga() {
  yield takeEvery(GET_DELIVERYLISTS, fetchDeliverylists);
  yield takeEvery(GET_DELIVERYLIST, onGetDeliverylist);
  
}

export default deliverylistSaga;
