import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  Progress,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  getUsers,
  addUser,
  deleteUser,
  apiError,
  getPrivilagesOptions,
  getCompaniesOptions,
  getBranchesOptions,
  updateUser,
  //getPrivilagesOptions,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import "./user.scss";

const ViewPurchase = (props) => {
  const [usersForTable, setUsersForTable] = useState([]);
  const [accountType, setAccountType] = useState("");

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Bill Date",
        field: "district",
        sort: "asc",
        width: 400,
      },
      {
        label: "Purchase Date",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Purchase ID",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Bill No.",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Supplier Name",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Discount",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Net Amount",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "GST",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Payable",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
    ],
    rows: usersForTable,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="View Purchase" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    paging={false}
                    info={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(ViewPurchase));
