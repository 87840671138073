import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  getUsers,
  addUser,
  deleteUser,
  apiError,
  getPrivilagesOptions,
  getCompaniesOptions,
  getBranchesOptions,
  updateUser,
  //getPrivilagesOptions,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import "./user.scss";

const ViewStockLogs = (props) => {
  const [usersForTable, setUsersForTable] = useState([]);
  const [accountType, setAccountType] = useState("");

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "district",
        sort: "asc",
        width: 400,
      },
      {
        label: "Type",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Sku(Sku-Old)",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Qty",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Log Qty",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Current Qty",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Cost",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Comments",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Added By",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
    ],
    rows: usersForTable,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="View Stock Logs" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">Date</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    paging={false}
                    info={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(
  connect(mapStateToProps, { apiError })(ViewStockLogs)
);
