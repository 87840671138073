import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Label } from "reactstrap";
import $ from "jquery";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const MostSearched = (props) => {
  const [details, setDetails] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    handleTableData();
  }, []);
  let handleDate = () => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    handleTableData(date1, date2);
  };
  function handleTableData(from_date = "", to_date = "") {
    var url = `${API_URL}report/most_searched`;
    url = url + "?from_date=" + from_date + "&to_date=" + to_date;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let reportData = [];
        var result = res.data.data;
        result.map((item, index) => {
          item.id = index + 1;
          reportData.push(item);
        });
        setDetails(reportData);
      });
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Last Searched Date",
        field: "product_search_updated_at",
        sort: "asc",
        width: 150,
      },
      {
        label: "Keyword",
        field: "product_search_keyword",
        sort: "asc",
        width: 400,
      },
      {
        label: "Result",
        field: "product_search_result",
        sort: "asc",
        width: 200,
      },
      {
        label: "Search Count",
        field: "product_search_count",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Most Searched" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <MDBDataTable
                    id="mostSearchedId"
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MostSearched;
