import {
    GET_INCOME_EXPENSE_ACC_TYPE,
    GET_INCOME_EXPENSE_ACC_TYPE_SUCCESS,
    GET_INCOME_EXPENSE_ACC_TYPE_FAIL,
    ADD_INCOME_EXPENSE,
    ADD_INCOME_EXPENSE_SUCCESS,
    ADD_INCOME_EXPENSE_FAIL,
    GET_INCOME_EXPENSE,
    GET_INCOME_EXPENSE_SUCCESS,
    GET_INCOME_EXPENSE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    incomeExpenses: [],
    incomeExpenseAccTypes: [],
    addingIncomeExpense: false,
    addIncomeExpenseResponse: {},
    incomeExpense: {},
    error: {}
};

const incomeExpenses = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_INCOME_EXPENSE:
            return {
                ...state,
                params: action.payload,
            };

        case GET_INCOME_EXPENSE_SUCCESS:
            return {
                ...state,
                incomeExpenses: action.payload.data,
            };

        case GET_INCOME_EXPENSE_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case GET_INCOME_EXPENSE_ACC_TYPE:
            return {
                ...state,
                params: action.payload,
            };

        case GET_INCOME_EXPENSE_ACC_TYPE_SUCCESS:
            return {
                ...state,
                incomeExpenseAccTypes: action.payload.data,
            };

        case GET_INCOME_EXPENSE_ACC_TYPE_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case ADD_INCOME_EXPENSE:
            return {
                ...state,
                addingIncomeExpense: true,
                incomeExpense: action.payload,
            };

        case ADD_INCOME_EXPENSE_SUCCESS:
            let newIncomeExpense = [JSON.parse(JSON.stringify(action.payload.data[0]))];
            newIncomeExpense.push(...state.incomeExpenses);
            return {
                ...state,
                addingIncomeExpense: false,
                addIncomeExpenseResponse: {
                    type: 'success',
                    message: 'Income expense added successfully',
                },
                incomeExpenses: newIncomeExpense,
                error: {},
            };

        case ADD_INCOME_EXPENSE_FAIL:
            return {
                ...state,
                addingIncomeExpense: false,
                addIncomeExpenseResponse: { type: 'failure', message: 'Adding income expense failed' },
                error: action.payload,
            };
        default:
            return state;
    }
};

export default incomeExpenses;