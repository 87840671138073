import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import Select from "react-select";
import $ from "jquery";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { apiError } from "../../../store/actions";
import moment from 'moment';
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const TransactionLog = (props) => {
  const [transationLogList, setTransactionLogList] = useState([]);
  const [selectedTransationType, setSelectedTransationType] = useState(null);
  const [master, setMaster] = useState({});

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const handleSelectedTransactionType = (selected) => {
    let date1 = master?.from_date ? master.from_date : null;
    let date2 = master?.to_date ? master.to_date : null;
    let type = selected.value;
    setSelectedTransationType(selected);
    handleTableData(type, date1, date2);
  };
  useEffect(() => {
    handleTableData();
  }, []);

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
    let type = null;
    if (selectedTransationType !== null) {
      type = selectedTransationType.value;
    }
    handleTableData(type, date1, date2);
  };
  function handleTableData(type = null, from_date = null, to_date = null) {
    var url = `${API_URL}transaction_log`;
    if (type != null && from_date != null && to_date != null) {
      url =
        url +
        "?type=" +
        type +
        "&from_date=" +
        from_date +
        "&to_date=" +
        to_date;
    } else if (type != null) {
      url = url + "?type=" + type;
    } else if (from_date != null) {
      url = url + "?from_date=" + from_date;
    } else if (to_date != null) {
      url = url + "?to_date=" + to_date;
    }
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let Data = [];
        result.map((item, index) => {
          if (item.transaction_log_type == 0) {
            item.n_Credit =(
              <div className="text-right">{item.transaction_log_amount.toFixed(2)}</div>
            ); 

          } else item.n_Debit =(
            <div className="text-right">{item.transaction_log_amount.toFixed(2)}</div>
          );
          
          item.amount_column = (
              <div className="text-right">{item.transaction_log_fundsource_crntamt.toFixed(2)}</div>
          );
          item.id = index + 1;
          item.trns_log_date = moment(item.trns_log_date).format("DD/MM/YYYY");
          Data.push(item);
        });
        setTransactionLogList(Data);
      });
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "trns_log_date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "trns_log_time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Type",
        field: "acc_type_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Account",
        field: "acc_chart_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Ref. ID",
        field: "log_unique_id",
        sort: "asc",
        width: 200,
      },
      {
        label: "Debit",
        field: "n_Debit",
        sort: "asc",
        width: 200,
      },
      {
        label: "Credit",
        field: "n_Credit",
        sort: "asc",
        width: 200,
      },
      {
        label: "Balance",
        field: "amount_column",
        sort: "asc",
        width: 200,
      },
      {
        label: "Remark",
        field: "transaction_log_comment",
        sort: "asc",
        width: 200,
      },
      {
        label: "User",
        field: "fname",
        sort: "asc",
        width: 200,
      },
    ],
    rows: transationLogList,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Transaction Log" />

          <Col className="col-12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="4">
                    <div className="mb-3">
                      <Label>Transaction Type</Label>
                      <Select
                        name="customer_community_id"
                        value={selectedTransationType}
                        onChange={(selected) => {
                          handleSelectedTransactionType(selected);
                        }}
                        options={[
                          { label: "Credit", value: 0 },
                          { label: "Debit", value: 1 },
                        ]}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">From</Label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          onChange={handleDate}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">To</Label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          onChange={handleDate}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <MDBDataTable
                  responsive
                  bordered
                  data={data}
                  searching={true}
                  info={false}
                  disableRetreatAfterSorting={true}
                  entries={25}
                />
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(
  connect(mapStateToProps, { apiError })(TransactionLog)
);

// Users.propTypes = {
//   error: PropTypes.any,
//   users: PropTypes.array,
// };
