import React, { useState, useEffect, useRef } from 'react';
import toastr from 'toastr';
import jwt_decode from 'jwt-decode';

import SweetAlert from 'react-bootstrap-sweetalert';
import { MDBDataTable } from 'mdbreact';
import { Row, Col, Card, CardBody, Button, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import Select from "react-select";
import $ from "jquery";
import {
  getAllAccountDetails,
  addAccountDetail,
  deleteAccountDetail,
  updateAccountDetail,
} from '../../../store/actions';

import { AvForm, AvField } from 'availity-reactstrap-validation';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';


const Accdetails = (props) => {
  const [accdetailObject, setAccdetailObject] = useState({});
  const [accdetailTemp, setaccdetailTemp] = useState([]);

  const [accdetailIdTobeUpdated, setAccdetaildToBeUpdated] = useState(null);
  const [accdetailIdToBeDeleted, setAccdetailIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [selectedAccType, setSelectedAccType] = useState(null);
  const [acctypeGroup, setacctypeGroup] = useState([]);
  const [userId, setUserId] = useState(1);
  const [invalidacctype, setinvalidacctype] = useState('none');
  const formRef = useRef();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const {
    accdetails,
    addingAccdetail,
    addAccdetailResponse,
    deleteAccdetailResponse,
    updateAccdetailResponse,
    error,
  } = useSelector((state) => state.accdetails);

  const dispatch = useDispatch();
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
   
    dispatch(getAllAccountDetails());
    fetch_all_accounttypes();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = jwt_decode(localStorage.getItem('authUser'));
      console.log(obj);

      setUserId(obj.user);
      setAccdetailObject({ ['auth_userid']: userId });
    }
  }, [userId]);

  useEffect(() => {
    if (addAccdetailResponse.type === 'success') {
      toastr.success(addAccdetailResponse.message);
    } else if (addAccdetailResponse.type === 'failure') {
      toastr.error(error.data.message, addAccdetailResponse.message);
    }
  }, [addAccdetailResponse]);

  useEffect(() => {
    if (deleteAccdetailResponse.type === 'success') {
      toastr.success(deleteAccdetailResponse.message);
      dispatch(getAllAccountDetails());
    } else if (deleteAccdetailResponse.type === 'failure') {
      toastr.error(error.data.message, deleteAccdetailResponse.message);
    }
  }, [deleteAccdetailResponse]);

  useEffect(() => {
    if (updateAccdetailResponse.type === 'success') {
      setAccdetaildToBeUpdated(null);
      toastr.success(updateAccdetailResponse.message);
      dispatch(getAllAccountDetails());
    } else if (updateAccdetailResponse.type === 'failure') {
      toastr.error(error.data.message, updateAccdetailResponse.message);
    }
  }, [updateAccdetailResponse]);

  let preupdateAccountDetail = (item) => {
    
    if (item.acc_type_id!=null) {
      let acctype = {
        label: item.acc_type_name,
        value: item.acc_type_id,
      };
      handleSelectedAccType(acctype);  
    }
    setAccdetaildToBeUpdated(item.acc_detail_id);
    setAccdetailObject(item);
  };

  useEffect(() => {
    let accdetailDuplicate = JSON.parse(JSON.stringify(accdetails));
    let accdetailData = [];
    accdetailDuplicate.map((item, index) => {
      item.fname="";
      item.fname = item.fname + ' ' + item.lname;
      item.auth_userid = userId;
      item.action = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <i
            className="far fa-edit"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.5em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              preupdateAccountDetail(item);
            }}
          ></i>
          <i
            className="far fa-trash-alt"
            style={{ fontSize: '1em', cursor: 'pointer' }}
            onClick={() => {
              setAccdetailIdToBeDeleted(item.acc_detail_id);
              setConfirmDeleteAlert(true);
            }}
          ></i>
        </div>
      );
      item.id = index + 1;

      accdetailData.push(item);
    });
    setaccdetailTemp(accdetailData);
  }, [accdetails]);

  const data = {
    columns: [
      {
        label: '#',
        field: 'id',
        sort: 'asc',
        width: 10,
      },

      {
        label: 'Account Type',
        field: 'acc_type_name',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Account Detail Name',
        field: 'acc_detail_name',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Added By',
        field: 'fname',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 100,
      },
    ],
    rows: accdetailTemp,
  };
  function fetch_all_accounttypes() {
    axios.get(`${API_URL}account_type/options`,
    {
      headers: {
        'x-access-token': accessToken,
      },
    }).then((res) => {
      var brand_data =
        res.data.data &&
        res.data.data.map((item) => {
          return {
            label: item.acc_type_name,
            value: item.acc_type_id,
          };
        });

        setacctypeGroup([
        {
          options: brand_data,
        },
      ]);
    });
  }

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setAccdetailObject({
      ...accdetailObject,
      [name]: value,
    });
  };

  function handleSelectedAccType(type) {
    if(type.value)
    {
      setinvalidacctype('none');
      $("#acc_type_id").attr('class',"select2-selection");
    }
    else
    {
      setinvalidacctype('block');
      $("#acc_type_id").attr('class',"select2-selection border border-danger");
    }

    setSelectedAccType(type);
    setAccdetailObject({
      ...accdetailObject,
      ["acc_type_id"]: type.value,
    });
  }

  const handleValidSubmitAccdetail = (event, values) => {
    if(selectedAccType==null)
    {
      setinvalidacctype('block');
      $("#acc_type_id").attr('class',"select2-selection border border-danger");
      toTop();
    }
    else
    {
      accdetailIdTobeUpdated
      ? dispatch(updateAccountDetail(accdetailObject))
      : dispatch(addAccountDetail(accdetailObject));

    formRef.current.reset();
    setAccdetailObject({ ['auth_userid']: userId });
    dispatch(getAllAccountDetails());
    setSelectedAccType(null);
    }
    
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteAccountDetail(accdetailIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Account Detail" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitAccdetail(e, v);
                    }}
                  >
                    <Row>
                    <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Account Type</Label>
                          <Select
                            name="acc_type_id"
                            value={selectedAccType}
                            onChange={(value) => {
                              handleSelectedAccType(value);
                            }}
                            id="acc_type_id"
                            options={acctypeGroup}
                            classNamePrefix="select2-selection"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                        <div style={{ display:invalidacctype,Width: '100%',marginTop:'0.25rem',color: '#f46a6a',fontSize:'87.5%'}}>Select Account Type</div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Account Detail Name
                          </Label>

                          <AvField
                            name="acc_detail_name"
                            value={accdetailObject.acc_detail_name}
                            placeholder="Account Detail Name"
                            type="text"
                            errorMessage="Enter Account Detail Name"
                            className="form-control"
                            validate={{ required: { value: true },
                            pattern: {value: '^[A-Za-z ]+$', errorMessage: 'Account Details Name must be only with letter and spaces'}
                          }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3" className="mt-4">
                        <div >
                          {accdetailIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingAccdetail ? true : false}
                            >
                              {addingAccdetail ? 'Updating' : 'Update'}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingAccdetail ? true : false}
                            >
                              {addingAccdetail ? 'Adding' : 'Submit'}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bordered data={data}  disableRetreatAfterSorting={true}
                    entries={25} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Accdetails;
