export const GET_CHARTOFACC = 'GET_CHARTOFACC';
export const GET_CHARTOFACC_SUCCESS = 'GET_CHARTOFACC_SUCCESS';
export const GET_CHARTOFACC_FAIL = 'GET_CHARTOFACC_FAIL';

export const ADD_CHARTOFACC = 'ADD_CHARTOFACC';
export const ADD_CHARTOFACC_SUCCESS = 'ADD_CHARTOFACC_SUCCESS';
export const ADD_CHARTOFACC_FAIL = 'ADD_CHARTOFACC_FAIL';

export const UPDATE_CHARTOFACC = 'UPDATE_CHARTOFACC';
export const UPDATE_CHARTOFACC_SUCCESS = 'UPDATE_CHARTOFACC_SUCCESS';
export const UPDATE_CHARTOFACC_FAIL = 'UPDATE_CHARTOFACC_FAIL';

export const DELETE_CHARTOFACC = 'DELETE_CHARTOFACC';
export const DELETE_CHARTOFACC_SUCCESS = 'DELETE_CHARTOFACC_SUCCESS';
export const DELETE_CHARTOFACC_FAIL = 'DELETE_CHARTOFACC_FAIL';

// export const GET_ACCTYPE_OPTIONS = 'GET_ACCTYPE_OPTIONS';
// export const GET_ACCTYPE_OPTIONS_SUCCESS = 'GET_ACCTYPE_OPTIONS_SUCCESS';
// export const GET_ACCTYPE_OPTIONS_FAIL = 'GET_ACCTYPE_OPTIONS_FAIL';