import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
  Input,
  FormGroup,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const BlockUnblockIP = () => {
  const [data, setData] = useState([]);

  const dataTemp = [
    {
      date: "22-10-2021 12:05 AM",
      ipaddress: "10.1.122.58",
    },
    {
      date: "22-10-2021 12:05 AM",
      ipaddress: "10.1.122.58",
    },
    {
      date: "22-10-2021 12:05 AM",
      ipaddress: "10.1.122.58",
    },
  ];

  dataTemp.map((item, index) => {
    item.id = index + 1;
    item.action = (
      <div
        style={{
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <i
            className="far fa-edit"
            style={{
              fontSize: "1.3em",
              cursor: "pointer",
              marginLeft: "1rem",
              marginRight: "1rem",
            }}
            onClick={() => null}
          ></i>{" "}
          <i
            className="far fa-trash-alt"
            style={{
              fontSize: "1.3em",
              cursor: "pointer",
            }}
            onClick={() => null}
          ></i>{" "}
        </div>{" "}
      </div>
    );
  });

  useEffect(() => {
    setData(dataTemp);
  }, []);

  const ipData = {
    columns: [
      {
        label: "SI#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date & Time",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "IP Address",
        field: "ipaddress",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 200,
      },
    ],
    rows: data,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Blocked IP" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          {/* <Label htmlFor="validationCustom01">Set Rules</Label> */}
                          <AvField
                            name="ipaddress"
                            placeholder="IP Address"
                            type="text"
                            errorMessage="Enter IP Address"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            label="Blocked IP"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div style={{ marginTop: "1.9em" }}>
                          <Button color="success" type="submit">
                            <i
                              className="fas fa-filter"
                              style={{
                                fontSize: "1em",
                                cursor: "pointer",
                                marginRight: "0.5rem",
                              }}
                            ></i>{" "}
                            Search
                          </Button>
                        </div>
                      </Col>
                      <Col md="2">
                        <div style={{ marginTop: "1.9em" }}>
                          <Button color="danger" type="submit">
                            <i
                              className="fas fa-sync"
                              style={{
                                fontSize: "1em",
                                cursor: "pointer",
                                marginRight: "0.5rem",
                              }}
                            ></i>{" "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle>View Rules</CardTitle> */}
                  {/* <hr /> */}
                  <MDBDataTable responsive bordered data={ipData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlockUnblockIP;
