import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';

import {
  GET_COMMUNITY,
  ADD_COMMUNITY,
  DELETE_COMMUNITY,
  UPDATE_COMMUNITY,
  GET_COMMUNITIES,
} from './actionTypes';

import {
  getCommunitiesSuccess,
  getCommunitiesFail,
  getCommunitySuccess,
  getCommunityFail,
  addCommunityFail,
  addCommunitySuccess,
  updateCommunityFail,
  updateCommunitySuccess,
  deleteCommunityFail,
  deleteCommunitySuccess,
} from './actions';

//Include Both Helper File with needed methods
import {
  getCommunites,
  getCommunity,
  addCommunity,
  updateCommunity,
  deleteCommunity,
} from '../../helpers/backend_helper';

function* fetchCommunities() {
  try {
    const response = yield call(getCommunites);
    yield put(getCommunitiesSuccess(response));
  } catch (error) {
    yield put(getCommunitiesFail(error));
  }
}

function* onGetCommunity() {
  try {
    const response = yield call(getCommunity);
    yield put(getCommunitySuccess(response));
  } catch (error) {
    yield put(getCommunityFail(error.response));
  }
}

function* onAddCommunity({ payload: community }) {
  try {
    const response = yield call(addCommunity, community);
    yield put(addCommunitySuccess(response));
  } catch (error) {
    yield put(addCommunityFail(error.response));
  }
}

function* onUpdateCommunity({ payload: community }) {
  //console.log(unit);
  try {
    const response = yield call(updateCommunity, community);
    yield put(updateCommunitySuccess(response));
  } catch (error) {
    yield put(updateCommunityFail(error.response));
  }
}

function* onDeleteCommunity({ payload: communityId, auth_data: auth_user }) {
  try {
    const response = yield call(deleteCommunity, communityId, auth_user);
    yield put(deleteCommunitySuccess(response));
  } catch (error) {
    yield put(deleteCommunityFail(error.response));
  }
}

function* communitySaga() {
  yield takeEvery(GET_COMMUNITIES, fetchCommunities);
  yield takeEvery(GET_COMMUNITY, onGetCommunity);
  yield takeEvery(ADD_COMMUNITY, onAddCommunity);
  yield takeEvery(UPDATE_COMMUNITY, onUpdateCommunity);
  yield takeEvery(DELETE_COMMUNITY, onDeleteCommunity);
}

export default communitySaga;
