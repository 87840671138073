import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  Table,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  getUsers,
  addUser,
  deleteUser,
  apiError,
  getPrivilagesOptions,
  getCompaniesOptions,
  getBranchesOptions,
  updateUser,
  getOrder
  //getPrivilagesOptions,
} from "../../../store/actions";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";


const AddOrder = (props) => {
  const [usersForTable, setUsersForTable] = useState([]);

  //const dispatch = useDispatch();
  // const  {orderNo} = props.match.params;
  
  // useEffect(()=>{
  //   if(orderNo){
  //     dispatch(getOrder(orderNo));
  //   }
  // },[]);
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "district",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Acc Category",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Account",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Reference ID",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Debit",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "	Credit	",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Balance",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "	Remark	",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "User",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
    ],
    rows: usersForTable,
  };

  const [values, setValues] = useState([{}]);

  const addMore = () => {
    setValues([...values, {}]);
  };

  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    setValues(list);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Add Order" />

          <Col className="col-12">
            <Card>
              <CardBody>
                <AvForm
                  className="needs-validation"
                  // onValidSubmit={(e, v) => {
                  //   handleValidSubmit(e, v);
                  // }}
                >
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">Order Date</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            defaultValue="2019-08-19"
                            id="example-date-input"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Cash</Label>
                        <Select
                          name="customer_community_id"
                          //   value={selectCommunity}
                          //   onChange={(value) => {
                          //     handleSelectedCommunities(value);
                          //   }}
                          //   options={communitiesOptionsGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>ICIC Bank</Label>
                        <Select
                          name="customer_community_id"
                          //   value={selectCommunity}
                          //   onChange={(value) => {
                          //     handleSelectedCommunities(value);
                          //   }}
                          //   options={communitiesOptionsGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Retail</Label>
                        <Select
                          name="customer_community_id"
                          //   value={selectCommunity}
                          //   onChange={(value) => {
                          //     handleSelectedCommunities(value);
                          //   }}
                          //   options={communitiesOptionsGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">
                          Commission Customer Name/Mobile No.
                        </Label>
                        <AvField
                          name="CommissionCustomer"
                          placeholder="Commission Customer Name/Mobile No."
                          type="text"
                          errorMessage="Enter Commission Customer Name/Mobile No."
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom05"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">Commission%</Label>
                        <AvField
                          name="Commission%"
                          placeholder="Commission%"
                          type="text"
                          errorMessage="EnterCommission%"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom05"
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">
                          Common Discount
                        </Label>
                        <AvField
                          name="CommonDiscount"
                          placeholder="Common Discount"
                          type="text"
                          errorMessage="Enter Common Discount"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom05"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">
                          Customer Name
                        </Label>
                        <AvField
                          name="CustomerName"
                          placeholder="Customer Name"
                          type="text"
                          errorMessage="Enter Customer Name"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom05"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">
                          Customer Address
                        </Label>
                        <AvField
                          name="CustomerAddress"
                          placeholder="Customer Address"
                          type="text"
                          errorMessage="Enter Customer Address"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom05"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">
                          Customer State
                        </Label>
                        <AvField
                          name="CustomerState"
                          placeholder="Customer State"
                          type="text"
                          errorMessage="Enter Customer State"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom05"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">
                          Customer GSTIN
                        </Label>
                        <AvField
                          name="CustomerGSTIN"
                          placeholder="Customer GSTIN"
                          type="text"
                          errorMessage="Enter Customer GSTIN"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom05"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mt-4 mt-lg-0">
                        <h5 className="font-size-14 mb-3">Quick Order</h5>
                        <div className="d-flex">
                          <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              switch="none"
                              //   checked={sq1}
                              //   onChange={() => {
                              //     setsq1(!sq1)
                              //   }}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label="ON"
                              data-off-label="OFF"
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row></Row>
                </AvForm>
                <Table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product</th>
                      <th>Sku</th>
                      <th>Available</th>
                      <th>HSN</th>
                      <th>Unit</th>
                      <th>Qty</th>
                      <th>Rate</th>
                      <th>MRP</th>
                      <th>Discount%</th>
                      <th>GST%</th>
                      <th>Amount</th>
                      {values.length !== 1 ? <th>Action</th> : null}
                    </tr>
                  </thead>
                  <tbody>
                    {values.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            {values.length !== 1 && (
                              <i
                                className="far fa-trash-alt"
                                style={{
                                  fontSize: "23px",
                                  cursor: "pointer",
                                  paddingLeft: "5px",
                                }}
                                onClick={() => handleRemove(index)}
                              ></i>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <br />
                <Button
                  onClick={() => addMore()}
                  color="success"
                  className="mt-1"
                >
                  Add New Item
                </Button>
                <br /> <br /> <br />
                <Row>
                  <Col lg={6}>
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>GST%</th>
                            <th>Taxable Value ₹</th>
                            <th>IGST %</th>
                            <th>IGST ₹</th>
                            <th>GST ₹</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>18</td>
                            <td>0.00</td>
                            <td>18.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="float-end">
                      <div className="mt-4">
                        <input
                          type="text"
                          id="lname"
                          style={{ textAlign: "center" }}
                          name="lname"
                          disabled
                          placeholder="Total Value"
                        />
                        <input />
                      </div>

                      <div className="mt-1">
                        <input
                          type="text"
                          id="lname"
                          style={{ textAlign: "center" }}
                          name="lname"
                          disabled
                          placeholder="Total Discount"
                        />
                        <input />
                      </div>
                      <div className="mt-1">
                        <input
                          type="text"
                          id="lname"
                          style={{ textAlign: "center" }}
                          name="lname"
                          disabled
                          placeholder="Taxable Value"
                        />
                        <input />
                      </div>
                      <div className="mt-1">
                        <input
                          type="text"
                          id="lname"
                          style={{ textAlign: "center" }}
                          name="lname"
                          disabled
                          placeholder="GST"
                        />
                        <input />
                      </div>
                      <div className="mt-1">
                        <input
                          type="text"
                          id="lname"
                          style={{ textAlign: "center" }}
                          name="lname"
                          disabled
                          placeholder="Round Off"
                        />
                        <input />
                      </div>
                      <div className="mt-1">
                        <input
                          type="text"
                          id="lname"
                          style={{ textAlign: "center" }}
                          name="lname"
                          disabled
                          placeholder="Shipping"
                        />
                        <input />
                      </div>
                      <div className="mt-1">
                        <input
                          type="text"
                          id="lname"
                          style={{ textAlign: "center" }}
                          name="lname"
                          disabled
                          placeholder="Grand Total"
                        />
                        <input />
                      </div>
                      <br />
                      <div className="float-end">
                        <Button
                          color="primary"
                          to="#"
                          className="btn btn-primary w-md waves-effect waves-light"
                        >
                          Create Order
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(AddOrder));
