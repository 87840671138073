import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  getUsers,
  addUser,
  deleteUser,
  apiError,
  getPrivilagesOptions,
  getCompaniesOptions,
  getBranchesOptions,
  updateUser,
  //getPrivilagesOptions,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import "./user.scss";

const OfferCodes = (props) => {
  const [usersForTable, setUsersForTable] = useState([]);
  const [accountType, setAccountType] = useState("");

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Offer Name",
        field: "district",
        sort: "asc",
        width: 400,
      },
      {
        label: "Offer Code",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Offer For",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount/Price",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Value",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Min Purc Amount",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Max Off Amount",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Valid From ",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Valid To",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Max Usage",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Off Type",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Checkout",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
    ],
    rows: usersForTable,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Offer Codes" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    // onValidSubmit={(e, v) => {
                    //   handleValidSubmit(e, v);
                    // }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Website</Label>
                          <Select
                            name="customer_community_id"
                            //   value={selectCommunity}
                            //   onChange={(value) => {
                            //     handleSelectedCommunities(value);
                            //   }}
                            //   options={communitiesOptionsGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Offer Type</Label>
                          <Select
                            name="customer_community_id"
                            //   value={selectCommunity}
                            //   onChange={(value) => {
                            //     handleSelectedCommunities(value);
                            //   }}
                            //   options={communitiesOptionsGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Offer Name</Label>
                          <AvField
                            name="OfferName"
                            placeholder="Offer Name"
                            type="text"
                            errorMessage="Enter Offer Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>{" "}
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Offer Code</Label>
                          <AvField
                            name="OfferCode"
                            placeholder="Offer Code"
                            type="text"
                            errorMessage="Enter Offer Code"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Row>
                        <Col md="2">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Amount/Percentage
                            </Label>
                          </div>
                        </Col>

                        <Col md="1">
                          <div className="form-check mb-3">
                            <AvField
                              className="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              // value={date.date}
                              // onChange={(e) => setDate(e.target.value)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Amount
                              {/* {moment(date.date).format("ddd, DD MMM")} */}
                            </label>
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="form-check mb-3">
                            <AvField
                              className="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              // value={date.date}
                              // onChange={(e) => setDate(e.target.value)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Percentage
                              {/* {moment(date.date).format("ddd, DD MMM")} */}
                            </label>
                          </div>
                        </Col>

                        <Col md="2">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Offer Code Status
                            </Label>
                          </div>
                        </Col>

                        <Col md="1">
                          <div className="form-check mb-3">
                            <AvField
                              className="form-check-input"
                              type="radio"
                              name="OfferCodestatus"
                              // value={date.date}
                              // onChange={(e) => setDate(e.target.value)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Active
                              {/* {moment(date.date).format("ddd, DD MMM")} */}
                            </label>
                          </div>
                        </Col>
                        <Col md="1">
                          <div className="form-check mb-3">
                            <AvField
                              className="form-check-input"
                              type="radio"
                              name="OfferCodestatus"
                              // value={date.date}
                              // onChange={(e) => setDate(e.target.value)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Inactive
                              {/* {moment(date.date).format("ddd, DD MMM")} */}
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Amount/Percentage Value
                          </Label>
                          <AvField
                            name="Value"
                            placeholder="Amount/Percentage Value"
                            type="text"
                            errorMessage="Enter TAmount/Percentage Value"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Minimum Purchase Value
                          </Label>
                          <AvField
                            name="MinimumPurchaseValue"
                            placeholder="Minimum Purchase Value"
                            type="text"
                            errorMessage="Enter Minimum Purchase Value"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Maximum Offer Value
                          </Label>
                          <AvField
                            name="MaximumOfferValue"
                            placeholder="Maximum Offer Value"
                            type="text"
                            errorMessage="Enter Maximum Offer Value"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Valid From</Label>
                          <div className="col-md-10">
                            <input
                              className="form-control"
                              type="date"
                              defaultValue="2019-08-19"
                              id="example-date-input"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Valid To</Label>
                          <div className="col-md-10">
                            <input
                              className="form-control"
                              type="date"
                              defaultValue="2019-08-19"
                              id="example-date-input"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Offer Code Max Usage
                          </Label>
                          <AvField
                            name="OfferCodeMaxUsage"
                            placeholder="Default One time for one customer"
                            type="text"
                            errorMessage="Enter Offer Code Max Usage"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="1">
                        <div className="mt-4">
                          <Button color="primary" type="submit">
                            Save
                          </Button>
                        </div>
                      </Col>
                      <Col md="1">
                        <div className="mt-4">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => setAccountType("")}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    paging={false}
                    info={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(OfferCodes));
