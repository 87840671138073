import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import Select from "react-select";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import toastr from "toastr";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import "./user.scss";
import {
  addChartOfAcc,
  updateChartOfAcc,
  getAllChartOfAcc,
  deleteChartOfAcc,
} from "../../../store/actions";
const Chart = (props) => {
  const [chartOfAccList, setChartOfAccList] = useState([]);
  const [accountDetailType, setAccountDetailType] = useState([]);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [chartOfAccIdTobeUpdated, setChartOfAccIdTobeUpdated] = useState(null);
  const [chartOfAccIdToBeDeleted, setChartOfAccIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [master, setMaster] = useState({});
  const [userId, setUserId] = useState(1);

  const dispatch = useDispatch();
  const formRef = useRef();

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const {
    chartOfAccounts,
    addingChartOfAccount,
    addChartOfAccountResponse,
    updateChartOfAccountResponse,
    error,
    deleteChartOfAccountResponse,
  } = useSelector((state) => state.chartOfAccounts);

  useEffect(() => {
    fetch_account_type();
  }, []);
  useEffect(() => {
    dispatch(getAllChartOfAcc());
  }, []);
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = jwt_decode(localStorage.getItem("authUser"));
      setUserId(obj.user);
      setMaster({ ...master, ["auth_userid"]: obj.user });
    }
  }, [userId]);

  useEffect(() => {
    if (addChartOfAccountResponse.type === "success") {
      toastr.success(addChartOfAccountResponse.message);
    } else if (addChartOfAccountResponse.type === "failure") {
      toastr.error(error.data.message, addChartOfAccountResponse.message);
    }
  }, [addChartOfAccountResponse]);

  useEffect(() => {
    if (updateChartOfAccountResponse.type === "success") {
      setChartOfAccIdTobeUpdated(null);
      toastr.success(updateChartOfAccountResponse.message);
      dispatch(getAllChartOfAcc());
    } else if (updateChartOfAccountResponse.type === "failure") {
      toastr.error(error.data.message, updateChartOfAccountResponse.message);
    }
  }, [updateChartOfAccountResponse]);

  useEffect(() => {
    if (deleteChartOfAccountResponse.type === "success") {
      toastr.success(deleteChartOfAccountResponse.message);
      dispatch(getAllChartOfAcc());
    } else if (deleteChartOfAccountResponse.type === "failure") {
      toastr.error(error.data.message, deleteChartOfAccountResponse.message);
    }
  }, [deleteChartOfAccountResponse]);
  useEffect(() => {
    let chartOfAccountData = JSON.parse(JSON.stringify(chartOfAccounts));
    console.log("chartOfAccountData: ", chartOfAccountData);
    let data = [];
    chartOfAccountData.map((item, index) => {
      item.fname = "";
      if (item.User) {
        item.fname = item.User.fname + " " + item.User.lname;
      }
      item.auth_userid = userId;
      item.amount_column = (
        <div className="text-right">{item.acc_chart_balance.toFixed(2)}</div>
      );
      item.action = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            className="far fa-edit"
            style={{
              fontSize: "1.3em",
              cursor: "pointer",
              marginLeft: "1rem",
              marginRight: "1rem",
            }}
            onClick={() => {
              preupdateChartOfAccount(item);
            }}
          ></i>
          <i
            className="far fa-trash-alt"
            style={{ fontSize: "1.3em", cursor: "pointer" }}
            onClick={() => {
              setChartOfAccIdToBeDeleted(item.acc_chart_id);
              setConfirmDeleteAlert(true);
            }}
          ></i>
        </div>
      );
      item.id = index + 1;

      data.push(item);
    });
    setChartOfAccList(data);
  }, [chartOfAccounts]);

  let preupdateChartOfAccount = (item) => {
    let options = {
      label: item.acc_detail_name,
      value: item.acc_detail_id,
    };
    setSelectedAccountType(options);
    setChartOfAccIdTobeUpdated(item.acc_chart_id);
    setMaster(item);
  };
  function fetch_account_type() {
    axios
      .get(`${API_URL}account_detail/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        console.log("res: ", res);
        var account_type_data =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label: item.acc_detail_name,
              value: item.acc_detail_id,
            };
          });

        setAccountDetailType([
          {
            options: account_type_data,
          },
        ]);
      });
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Account Type",
        field: "acc_type_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Account Detail Type",
        field: "acc_detail_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Chart Name",
        field: "acc_chart_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Balance",
        field: "amount_column",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: chartOfAccList,
  };
  function handleSelectedAccountType(selected) {
    setSelectedAccountType(selected);
    console.log('master: ', master);
    setMaster({
      ...master,
      ["acc_detail_id"]: selected.value,
      // ["auth_userid"]: userId,
    });
  }
  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setMaster({
      ...master,
      [name]: value,
    });
  };

  const handleValidSubmit = (event, values) => {
    if (selectedAccountType == null) {
      toastr.error("Account detail type is required");
      return;
    }
    chartOfAccIdTobeUpdated
      ? dispatch(updateChartOfAcc(master))
      : dispatch(addChartOfAcc(master));

    formRef.current.reset();
    setSelectedAccountType(null);
    setMaster({ ["auth_userid"]: userId });
    dispatch(getAllChartOfAcc());
  };
  const reset = () => {
    formRef.current.reset();
    setMaster({});
    setSelectedAccountType(null);
  };
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteChartOfAcc(chartOfAccIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Chart of account" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Account Detail Type</Label>
                          <Select
                            name="customer_community_id"
                            value={selectedAccountType}
                            onChange={(value) => {
                              handleSelectedAccountType(value);
                            }}
                            options={accountDetailType}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Chart Name</Label>
                          <AvField
                            name="acc_chart_name"
                            placeholder="Chart Name"
                            type="text"
                            errorMessage="Enter account type"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.acc_chart_name}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Balance</Label>
                          <AvField
                            name="acc_chart_balance"
                            placeholder="Balance"
                            type="number"
                            errorMessage="Enter balance"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.acc_chart_balance}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="1" className="mt-4">
                        <div>
                          {chartOfAccIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingChartOfAccount ? true : false}
                            >
                              {addingChartOfAccount ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingChartOfAccount ? true : false}
                            >
                              {addingChartOfAccount ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                        {/* <div className="mt-4">
                          <Button
                            color="primary"
                            type="submit"
                          >
                            Save
                          </Button>
                        </div> */}
                      </Col>
                      <Col md="1">
                        <div className="mt-4">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={false}
                    disableRetreatAfterSorting={true}
                    entries={25}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Chart;
