import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  Progress,
  Table,
  Container,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { apiError } from "../../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import $ from "jquery";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { formatMoney } from "../../../helpers/generalFunctions";
import moment from "moment";
import jwt_decode from "jwt-decode";
import "./viewInvoice.scss";

const ViewInvoice = (props) => {
  const [invoiceForTable, setInvoiceForTable] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [invoiceGstSlab, setInvoiceGstSlab] = useState(null);
  const [returnItems, setReturnItems] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    handleTableData();
  }, []);
  const fetch_invoice_details = (invoice_id) => {
    axios
      .get(`${API_URL}invoice/` + invoice_id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        console.log("res: ", res);
        let result = res.data.data;
        let gst_slab = res.data.gst_slab;
        let return_items = res.data.return_items;
        console.log("gst_slab: ", gst_slab);
        setInvoiceDetails(result);
        setInvoiceGstSlab(gst_slab);
        setReturnItems(return_items);
      });
  };
  const cancelInvoice = (auth_userid, invoice_id) => {
    axios({
      method: "post",
      url: `${API_URL}invoice/cancel`,
      data: {
        auth_userid: auth_userid,
        id: invoice_id,
      },
      headers: {
        "x-access-token": accessToken,
      },
    }).then((res) => {
      console.log("res: ", res);
      let response = res.data.response;
      if (response === "Success") {
        toastr.success(res.data.message);
        handleTableData();
      } else {
        toastr.error(res.data.message);
        return;
      }
    });
  };
  const handleTableData = (from_date = "", to_date = "") => {
    var url =
      `${API_URL}invoice` + "?from_date=" + from_date + "&to_date=" + to_date;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let auth_userid = 0;
        if (localStorage.getItem("authUser")) {
          const obj = jwt_decode(localStorage.getItem("authUser"));
          auth_userid = obj.user;
        }
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            if (item.invoice_status === 0) {
              item.status = (
                <div
                  class="btn-group btn-group-sm"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    class="btn btn-success"
                    style={{ padding: "1px", minWidth: "50px" }}
                  >
                    Approved
                  </button>
                </div>
              );
            } else if (item.invoice_status === 2) {
              item.status = (
                <div
                  class="btn-group btn-group-sm"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    class="btn btn-danger"
                    style={{ padding: "1px", minWidth: "50px" }}
                  >
                    Cancelled
                  </button>
                </div>
              );
            }
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.5em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    fetch_invoice_details(item.invoice_id);
                    setShowModal(true);
                  }}
                ></i>
                {item.invoice_status === 2 ? null : (
                  <div
                    class="btn-group btn-group-sm"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      class="btn btn-danger"
                      type="submit"
                      style={{ padding: "1px", minWidth: "50px" }}
                      onClick={() =>
                        cancelInvoice(auth_userid, item.invoice_id)
                      }
                    >
                      <i
                        className="far fa-trash-alt"
                        // style={{ fontSize: "1em", cursor: "pointer" }}
                      ></i>
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            );
            item.invoice_net_amount = formatMoney(item.invoice_net_amount);
            item.invoice_discount_amount = formatMoney(
              item.invoice_discount_amount
            );
            item.invoice_sub_total = formatMoney(item.invoice_sub_total);
            item.invoice_shipping_charge = formatMoney(
              item.invoice_shipping_charge
            );
            item.invoice_gst_amount = formatMoney(item.invoice_gst_amount);
            item.invoice_grand_total = formatMoney(item.invoice_grand_total);
          });
        setInvoiceForTable(result);
      });
  };
  const handleDate = () => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    handleTableData(date1, date2);
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "invoice_date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Time",
        field: "invoice_time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Invoice ID",
        field: "inventory_unique_id",
        sort: "asc",
        width: 400,
      },
      {
        label: "Name",
        field: "invoice_cutomer_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Amount",
        field: "invoice_net_amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Discount",
        field: "invoice_discount_amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Net Amount",
        field: "invoice_sub_total",
        sort: "asc",
        width: 400,
      },
      {
        label: "Charge",
        field: "invoice_shipping_charge",
        sort: "asc",
        width: 400,
      },
      {
        label: "GST",
        field: "invoice_gst_amount",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "CESS/Others",
      //   field: "localbodytype",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "Net Payable",
      //   field: "localbodytype",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "TDS",
      //   field: "localbodytype",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Payable",
        field: "invoice_grand_total",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Order From",
      //   field: "localbodytype",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: invoiceForTable,
  };
 
  return (
    <React.Fragment>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        size="xl"
      >
        {/* <ModalHeader charCode="Y">Order Details</ModalHeader> */}
        <div className="modal-header">
          <h5 className="modal-title mt-0">View Invoice Details</h5>
          <button
            type="button"
            onClick={() => {
              setShowModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      <h4 className="float-end font-size-16">
                        {/* Order Estimate # {order?.orders_unique_id} */}
                        <span>
                          {" INVOICE "}
                          &nbsp;
                        </span>
                        <span>#</span>
                        <span style={{ color: "blue" }}>
                          {invoiceDetails?.inventory_unique_id}
                        </span>
                      </h4>
                      <div className="mb-0">
                        <img
                          src={"/static/media/cococa_crm_image.fd6a39c7.png"}
                          alt="logo"
                          height="83"
                        />
                      </div>
                      <div className="text-muted">
                        <h4 className="mb-1">
                          {invoiceDetails.branch?.branch_name}
                        </h4>
                        <p className="mb-1">
                          {invoiceDetails.branch?.branch_address}
                        </p>
                        <p className="mb-1">
                          <i className="uil uil-envelope-alt me-1"></i>{" "}
                          {invoiceDetails.branch?.branch_email}
                        </p>
                        <p className="mb-1">
                          <i className="uil uil-phone me-1"></i>{" "}
                          {invoiceDetails.branch?.branch_mob}
                        </p>
                        <p className="mb-1">
                          <i className="uil uil-google me-1"></i>{" "}
                          {invoiceDetails.branch?.branch_web}
                        </p>
                        <p className="mb-1">
                          {invoiceDetails.branch?.branch_gstin + "(GSTIN)"}
                        </p>
                      </div>
                    </div>
                    <hr className="my-4" />
                    <Row>
                      <Col sm="6">
                        <div className="text-muted">
                          {/* <h5 className="font-size-16 mb-3">Billed To:</h5> */}
                          <h5 className="font-size-15 mb-2">
                            {invoiceDetails.customer?.customer_name}
                          </h5>
                          <p className="mb-1">
                            {invoiceDetails.customer?.customer_address}
                          </p>
                          <p className="mb-1">
                            <i className="uil uil-envelope-alt me-1"></i>{" "}
                            {invoiceDetails.customer?.customer_email_id}
                          </p>
                          <p className="mb-1">
                            {" "}
                            <i className="uil uil-phone me-1"></i>
                            {invoiceDetails.customer?.customer_contact_number}
                          </p>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="text-muted text-sm-end">
                          <div>
                            <h5 className="font-size-16 mb-1">Invoice Date:</h5>
                            <p>
                              {moment(invoiceDetails?.invoice_date).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                          </div>
                          <div className="mt-4">
                            <h5 className="font-size-16 mb-1">Invoice Time:</h5>
                            <p>
                              {moment(
                                invoiceDetails?.invoice_time,
                                "h:mm:ss.*"
                              ).format("hh:mm a")}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="py-2">
                      {/* <h5 className="font-size-15"></h5> */}
                      <div className="table-responsive mb-3">
                        <Table className="table-nowrap table-centered mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: "70px" }}>#</th>
                              <th>SKU</th>
                              <th>Name</th>
                              <th>HSN</th>
                              <th>Qty</th>
                              <th
                                className="text-end"
                                style={{ width: "120px" }}
                              >
                                MRP
                              </th>
                              <th
                                className="text-end"
                                style={{ width: "120px" }}
                              >
                                Rate
                              </th>
                              <th
                                className="text-end"
                                style={{ width: "120px" }}
                              >
                                Amount
                              </th>
                              <th>Disc</th>
                              <th
                                className="text-end"
                                style={{ width: "120px" }}
                              >
                                Net
                              </th>
                              <th>GST(%)</th>
                              <th
                                className="text-end"
                                style={{ width: "120px" }}
                              >
                                GST(Amt)
                              </th>
                              <th
                                className="text-end"
                                style={{ width: "120px" }}
                              >
                                Total
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {invoiceDetails.inventoryItems &&
                              invoiceDetails.inventoryItems.map((item, key) => (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>{item.product?.product_sku_autogen}</td>
                                  <td>{item.inventory_product_name}</td>
                                  <td>{item.product?.product_hsn}</td>
                                  <td>
                                    {item.inventory_invoiced_item_quanity}
                                  </td>
                                  <td className="text-end">
                                    {formatMoney(item.inventory_item_mrp)}
                                  </td>
                                  <td className="text-end">
                                    {formatMoney(item.inventory_item_price)}
                                  </td>
                                  <td className="text-end">
                                    {formatMoney(
                                      Number(
                                        parseFloat(
                                          item.inventory_item_total_price -
                                            item.item_discount_amount
                                        )
                                      )
                                    )}
                                  </td>
                                  <td className="text-end">
                                    {formatMoney(item.invoice_discount_amount)}
                                  </td>
                                  <td className="text-end">
                                    {formatMoney(
                                      item.inventory_item_total_price
                                    )}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.inventory_item_gst}
                                  </td>
                                  <td className="text-end">
                                    {formatMoney(
                                      item.inventory_item_gst_amount
                                    )}
                                  </td>
                                  <td className="text-end">
                                    {formatMoney(
                                      item.inventory_invoiced_item_total_price
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                      {returnItems && returnItems.length > 0 ? (
                        <div>
                          <h5 className="font-size-15">Returned Items</h5>
                        </div>
                      ) : null}

                      {returnItems && returnItems.length > 0 ? (
                        <div className="table-responsive mb-3">
                          <Table className="table-nowrap table-centered mb-0">
                            <thead>
                              <tr>
                                <th style={{ width: "70px" }}>#</th>
                                <th>SKU</th>
                                <th>Name</th>
                                <th>HSN</th>
                                <th>Qty</th>
                                <th
                                  className="text-end"
                                  style={{ width: "120px" }}
                                >
                                  MRP
                                </th>
                                <th
                                  className="text-end"
                                  style={{ width: "120px" }}
                                >
                                  Rate
                                </th>
                                <th
                                  className="text-end"
                                  style={{ width: "120px" }}
                                >
                                  Amount
                                </th>
                                <th>Disc</th>
                                <th
                                  className="text-end"
                                  style={{ width: "120px" }}
                                >
                                  Net
                                </th>
                                <th>GST(%)</th>
                                <th
                                  className="text-end"
                                  style={{ width: "120px" }}
                                >
                                  GST(Amt)
                                </th>
                                <th
                                  className="text-end"
                                  style={{ width: "120px" }}
                                >
                                  Total
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {returnItems &&
                                returnItems.map((item, key) => (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>{item.product_sku_autogen}</td>
                                    <td>{item.inventory_product_name}</td>
                                    <td>{item.product_hsn}</td>
                                    <td>
                                      {item.inventory_invoiced_item_quanity}
                                    </td>
                                    <td className="text-end">
                                      {formatMoney(item.inventory_item_mrp)}
                                    </td>
                                    <td className="text-end">
                                      {formatMoney(item.inventory_item_price)}
                                    </td>
                                    <td className="text-end">
                                      {formatMoney(
                                        Number(
                                          parseFloat(
                                            item.inventory_item_total_price -
                                              item.item_discount_amount
                                          )
                                        )
                                      )}
                                    </td>
                                    <td className="text-end">
                                      {formatMoney(
                                        item.invoice_discount_amount
                                      )}
                                    </td>
                                    <td className="text-end">
                                      {formatMoney(
                                        item.inventory_item_total_price
                                      )}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.inventory_item_gst}
                                    </td>
                                    <td className="text-end">
                                      {formatMoney(
                                        item.inventory_item_gst_amount
                                      )}
                                    </td>
                                    <td className="text-end">
                                      {formatMoney(
                                        item.inventory_invoiced_item_total_price
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </div>
                      ) : null}
                      <Row className="pt-4">
                        <Col lg={7}>
                          <Row>
                            <Col md={12}>
                              <div className="table-responsive">
                                <Table className="table-nowrap table-centered mb-0">
                                  <thead>
                                    <tr>
                                      <th>GST SLAB</th>
                                      <th
                                        className="text-end"
                                        style={{ width: "120px" }}
                                      >
                                        Taxable(₹)
                                      </th>
                                      <th>CGST(%)</th>
                                      <th
                                        className="text-end"
                                        style={{ width: "120px" }}
                                      >
                                        CGST(₹)
                                      </th>
                                      <th>SGST(%)</th>
                                      <th
                                        className="text-end"
                                        style={{ width: "120px" }}
                                      >
                                        SGST(₹)
                                      </th>
                                      <th
                                        className="text-end"
                                        style={{ width: "120px" }}
                                      >
                                        GST(₹)
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {invoiceGstSlab &&
                                      invoiceGstSlab.map((item, key) => (
                                        <tr key={key}>
                                          <td>{item.inventory_item_gst}</td>
                                          <td className="text-end">
                                            {formatMoney(item.taxable)}
                                          </td>
                                          <td style={{ textAlign: "center" }}>
                                            {parseFloat(item.cgst_perc)}
                                          </td>
                                          <td className="text-end">
                                            {formatMoney(item.cgst_amount)}
                                          </td>
                                          <td style={{ textAlign: "center" }}>
                                            {parseFloat(item.sgst_perc)}
                                          </td>
                                          <td className="text-end">
                                            {formatMoney(item.sgst_amount)}
                                          </td>
                                          <td className="text-end">
                                            {formatMoney(item.gst)}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </Table>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={1}></Col>
                        <Col>
                          <Table className="table-nowrap table-centered mb-0">
                            <tbody>
                              <tr>
                                <th colSpan="4" className="text-end">
                                  Total Amount :
                                </th>
                                <td className="text-end">
                                  {formatMoney(
                                    invoiceDetails?.invoice_net_amount
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th colSpan="4" className="border-0 text-end">
                                  Taxable Amount :
                                </th>
                                <td className="border-0 text-end">
                                  {formatMoney(
                                    Number(
                                      parseFloat(
                                        invoiceDetails.invoice_net_amount -
                                          invoiceDetails.invoice_discount_amount
                                      )
                                    )
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th colSpan="4" className="border-0 text-end">
                                  CGST :
                                </th>
                                <td className="border-0 text-end">
                                  {formatMoney(
                                    Number(
                                      parseFloat(
                                        invoiceDetails.invoice_gst_amount / 2
                                      )
                                    )
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th colSpan="4" className="border-0 text-end">
                                  SGST :
                                </th>
                                <td className="border-0 text-end">
                                  {formatMoney(
                                    Number(
                                      parseFloat(
                                        invoiceDetails.invoice_gst_amount / 2
                                      )
                                    )
                                  )}
                                </td>
                              </tr>
                              {invoiceDetails.invoice_shipping_charge > 0 ? (
                                <tr>
                                  <th colSpan="4" className="border-0 text-end">
                                    Delivery Charge :
                                  </th>
                                  <td className="border-0 text-end">
                                    {formatMoney(
                                      invoiceDetails?.invoice_shipping_charge
                                    )}
                                  </td>
                                </tr>
                              ) : null}
                              {/* <tr>
                                <th colSpan="4" className="border-0 text-end">
                                  Return Amount :
                                </th>
                                <td className="border-0 text-end"></td>
                              </tr> */}
                              <tr>
                                <th colSpan="4" className="border-0 text-end">
                                  Payable Amount
                                </th>
                                <td className="border-0 text-end">
                                  <h4 className="m-0">{formatMoney(invoiceDetails?.invoice_grand_total)}</h4>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                      {/* <div className="d-print-none mt-4">
                        <div className="float-end">
                          <Link to="#" onClick={printOrder} className="btn btn-success waves-effect waves-light me-1"><i className="fa fa-print"></i></Link>{" "}
                          <Link to="#" className="btn btn-primary w-md waves-effect waves-light">Close</Link>
                        </div>
                      </div> */}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="View Invoice" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="viewInvoiceId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    paging={true}
                    disableRetreatAfterSorting={true}
                    info={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(ViewInvoice));
