/* USERS */
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';

export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAIL = 'GET_CATEGORY_FAIL';

export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAIL = 'ADD_CATEGORY_FAIL';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';

export const CHANGE_STATUS_CATEGORY = 'CHANGE_STATUS_CATEGORY';
export const CHANGE_STATUS_CATEGORY_SUCCESS = 'CHANGE_STATUS_CATEGORY_SUCCESS';
export const CHANGE_STATUS_CATEGORY_FAIL = 'CHANGE_STATUS_CATEGORY_FAIL';

export const GET_CATEGORIES_OPTIONS = 'GET_CATEGORIES_OPTIONS';
export const GET_CATEGORIES_OPTIONS_SUCCESS = 'GET_CATEGORIES_OPTIONS_SUCCESS';
export const GET_CATEGORIES_OPTIONS_FAIL = 'GET_CATEGORIES_OPTIONS_FAIL';

