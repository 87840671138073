import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import jwt_decode from 'jwt-decode';

import SweetAlert from 'react-bootstrap-sweetalert';
import { MDBDataTable } from 'mdbreact';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
} from 'reactstrap';

import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCommunities,
  addCommunity,
  deleteCommunity,
  updateCommunity,
  getStatesOptions,
} from '../../../store/actions';

// Redux

import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import './manage.scss';

const Communities = (props) => {
  //   const [selectedunitpremium, setSelectedUnitPremium] = useState(null);

  const [communityObject, setCommunityObject] = useState({});
  const [communityTemp, setCommunityTemp] = useState([]);
  const [selectedStates, setSelectedState] = useState(null);
  const [communityIdTobeUpdated, setCommunityIdToBeUpdated] = useState(null);
  const [communityIdToBeDeleted, setCommunityIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);

  const [userId, setUserId] = useState(1);

  const {
    communities,
    addingCommunity,
    addCommunityResponse,
    deleteCommunityResponse,
    updateCommunityResponse,
    error,
  } = useSelector((state) => state.Communities);

  const stateListOptions = useSelector((state) => state.states.statesOptions);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCommunities());
    dispatch(getStatesOptions());
  }, [dispatch]);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = jwt_decode(localStorage.getItem('authUser'));
      console.log(obj);

      setUserId(obj.user);
      setCommunityObject({ ['auth_userid']: userId });
    }
  }, [userId]);

  useEffect(() => {
    if (addCommunityResponse.type === 'success') {
      toastr.success(addCommunityResponse.message);
    } else if (addCommunityResponse.type === 'failure') {
      toastr.error(error.data.message, addCommunityResponse.message);
    }
  }, [addCommunityResponse]);

  useEffect(() => {
    if (deleteCommunityResponse.type === 'success') {
      toastr.success(deleteCommunityResponse.message);
    } else if (deleteCommunityResponse.type === 'failure') {
      toastr.error(error.data.message, deleteCommunityResponse.message);
    }
  }, [deleteCommunityResponse]);

  useEffect(() => {
    if (updateCommunityResponse.type === 'success') {
      setCommunityIdToBeUpdated(null);
      setCommunityIdToBeUpdated(null);
      toastr.success(updateCommunityResponse.message);
    } else if (updateCommunityResponse.type === 'failure') {
      toastr.error(error.data.message, updateCommunityResponse.message);
    }
  }, [updateCommunityResponse]);

  let preUpdateCommunity = (item) => {
    if (item.privilage) {
      let states = {
        label: item.privilage.privilege_name,
        value: item.privilage.id,
      };
      handleSelectedStates(states);
    }
    setCommunityIdToBeUpdated(item.community_id);
    setCommunityObject({ ...item });
  };

  useEffect(() => {
    let communityArray = JSON.parse(JSON.stringify(communities));

    let communityData = [];
    communityArray.map((item, index) => {
      if (item.User) {
        //item.User &&
        item.fname = item.User.fname + ' ' + item.User.lname;
        item.statename = item.State && item.State.name;
        item.auth_userid = userId;
        console.log(item.User.id);
        item.action = (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <i
              className="uil-edit-alt"
              style={{
                fontSize: '1.3em',
                cursor: 'pointer',
                marginLeft: '1rem',
                marginRight: '1rem',
              }}
              onClick={() => {
                preUpdateCommunity(item);
              }}
            ></i>
            <i
              className="uil-trash-alt"
              style={{ fontSize: '1.3em', cursor: 'pointer' }}
              onClick={() => {
                setCommunityIdToBeDeleted(item.community_id);
                setConfirmDeleteAlert(true);
              }}
            ></i>
          </div>
        );
        item.id = index + 1;

        communityData.push(item);
      }
    });
    setCommunityTemp(communityData);
  }, [communities, userId]);

  const data = {
    columns: [
      {
        label: '#',
        field: 'id',
        sort: 'asc',
        width: 10,
      },

      {
        label: 'Community Name',
        field: 'community_name',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Community Address',
        field: 'community_address1',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'City',
        field: 'community_city',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'State',
        field: 'statename',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Added by',
        field: 'fname',
        sort: 'asc',
        width: 100,
      },

      {
        label: 'Action',
        field: 'action',
        sort: 'asc',
        width: 100,
      },
    ],
    rows: communityTemp,
  };

  let statesOptionsData =
    stateListOptions &&
    stateListOptions.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });

  const statessOptionsGroup = [
    {
      options: statesOptionsData,
    },
  ];

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setCommunityObject({
      ...communityObject,
      [name]: value,
    });
  };

  function handleSelectedStates(value) {
    let newValue = {
      name: value.label,
      _id: value.value,
    };

    setSelectedState(value);
    console.log(newValue);
    setCommunityObject({ ...communityObject, newValue });
  }
  const handleValidSubmitCommunity = (event, values) => {
    communityIdTobeUpdated
      ? dispatch(updateCommunity(communityObject))
      : dispatch(addCommunity(communityObject));
  };

  //   let handleChangeImageUpload =(event) => {
  // setUnitObject({...unitObject, unitlogo:event.target.files[0]})
  //   }

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteCommunity(communityIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Community" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitCommunity(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Community Name
                          </Label>
                          <AvField
                            name="createdBy"
                            value={userId}
                            type="hidden"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />

                          <AvField
                            name="community_name"
                            value={communityObject.community_name}
                            placeholder="Community Name"
                            type="text"
                            errorMessage="Enter Community Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Address Line 1
                          </Label>
                          <AvField
                            name="community_address1"
                            value={communityObject.community_address1}
                            placeholder="community address"
                            type="text"
                            errorMessage="Address line"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Address Line 2
                          </Label>
                          <AvField
                            name="community_address2"
                            value={communityObject.community_address2}
                            placeholder="Address Line 2"
                            type="text"
                            errorMessage="Enter address line"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">City</Label>
                          <AvField
                            name="community_city"
                            value={communityObject.community_city}
                            placeholder="City"
                            type="text"
                            errorMessage="Enter City"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Pincode</Label>
                          <AvField
                            name="community_pincode"
                            value={communityObject.community_pincode}
                            placeholder="Pincode"
                            type="text"
                            errorMessage="Enter pincode"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3">
                          <Label>State</Label>
                          <Select
                            name="community_state_id"
                            value={selectedStates}
                            onChange={(value) => {
                              handleSelectedStates(value);
                            }}
                            options={statessOptionsGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Latitude</Label>
                          <AvField
                            name="community_latitude"
                            value={communityObject.community_latitude}
                            placeholder="Latitude"
                            type="text"
                            errorMessage="Enter Latitude"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Longitude</Label>
                          <AvField
                            name="community_longitude"
                            value={communityObject.community_longitude}
                            placeholder="Longitude"
                            type="text"
                            errorMessage="Enter Longitude"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                    </Row>

                    {communityIdTobeUpdated ? (
                      <Button
                        color="primary"
                        type="submit"
                        disabled={addingCommunity ? true : false}
                      >
                        {addingCommunity ? 'Updating' : 'Update'}
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        type="submit"
                        disabled={addingCommunity ? true : false}
                      >
                        {addingCommunity ? 'Adding' : 'Submit'}
                      </Button>
                    )}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  // const { error } = state.Users;
  // return { error };
};
export default Communities;

// export default withRouter(connect(mapStateToProps, { apiError })(Communities));

// Communities.propTypes = {
//   error: PropTypes.any,
//   communities: PropTypes.array,
// };
