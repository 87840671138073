import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
  Input,
  FormGroup,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const Rules = () => {
  const [data, setData] = useState([]);

  const dataTemp = [
    {
      menu: "Accounts",
      submenu: [
        "Account Type",
        "Account Detail Type",
        "Chart of Accounts",
        "Fund Transfer",
      ],
      view: true,
      edit: true,
      delete: true,
      export: true,
    },
    {
      menu: "Customer",
      submenu: ["Add Customer", "Privilege Points", "Block Customer"],
      view: true,
      edit: true,
      delete: true,
      export: true,
    },
  ];

  const isChecked = (value) => {
    return (
      <FormGroup check>
        <Input type="checkbox" name="check" id="exampleCheck" checked={value} />
      </FormGroup>
    );
  };

  const attachCheckbox = (value) => {
    return (
      <FormGroup check>
        <Input type="checkbox" name="check" id="exampleCheck" checked={true} />
        <Label for="exampleCheck" check>
          {value}
        </Label>
      </FormGroup>
    );
  };

  dataTemp.map((item, index) => {
    item.submenu = item.submenu.map((sub) => attachCheckbox(sub));
    item.view = isChecked(item.view);
    item.edit = isChecked(item.edit);
    item.delete = isChecked(item.delete);
    item.export = isChecked(item.export);
  });

  useEffect(() => {
    setData(dataTemp);
  }, []);

  const rulesData = {
    columns: [
      {
        label: "Main Menu",
        field: "menu",
        sort: "asc",
        width: 150,
      },
      {
        label: "Sub Menu",
        field: "submenu",
        sort: "asc",
        width: 150,
      },
      {
        label: (
          <FormGroup check>
            <Label for="exampleCheck" check>
              View
            </Label>
            <Input type="checkbox" name="viewAllCheck" id="exampleCheck" />
          </FormGroup>
        ),
        field: "view",
        sort: "disabled",
        width: 270,
      },
      {
        label: (
          <FormGroup check>
            <Label for="exampleCheck" check>
              Edit
            </Label>
            <Input type="checkbox" name="viewAllCheck" id="exampleCheck" />
          </FormGroup>
        ),
        field: "edit",
        sort: "disabled",
        width: 270,
      },
      {
        label: (
          <FormGroup check>
            <Label for="exampleCheck" check>
              Delete
            </Label>
            <Input type="checkbox" name="viewAllCheck" id="exampleCheck" />
          </FormGroup>
        ),
        field: "delete",
        sort: "disabled",
        width: 200,
      },
      {
        label: (
          <FormGroup check>
            <Label for="exampleCheck" check>
              Export
            </Label>
            <Input type="checkbox" name="viewAllCheck" id="exampleCheck" />
          </FormGroup>
        ),
        field: "export",
        sort: "disabled",
        width: 200,
      },
    ],
    rows: data,
  };

  const options = [
    { label: "Option 1", value: 1 },
    { label: "Option 2", value: 2 },
    { label: "Option 3", value: 3 },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Rules" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          {/* <Label htmlFor="validationCustom01">Set Rules</Label> */}
                          <AvField
                            type="select"
                            name="set_rule"
                            label="Set Rules"
                            // helpMessage="please select role"
                          >
                            <option>Admin</option>
                            <option>Manager</option>
                            <option>Sales</option>
                            <option>User</option>
                          </AvField>
                        </div>
                      </Col>
                      <Col md="2">
                        <div style={{ marginTop: "1.9em" }}>
                          <Button color="primary" type="submit">
                            <i
                              className="fas fa-cog"
                              style={{
                                fontSize: "1em",
                                cursor: "pointer",
                                marginRight: "0.5rem",
                              }}
                            ></i>{" "}
                            Set Rules
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle>View Rules</CardTitle> */}
                  {/* <hr /> */}
                  <MDBDataTable responsive bordered data={rulesData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Rules;
