import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, Button, Label, Modal } from 'reactstrap'
import { MDBDataTable } from 'mdbreact'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import $ from 'jquery'
import accessToken from '../../../helpers/jwt-token-access/accessToken'
import axios from 'axios'
import { apiError } from '../../../store/actions'
import {getDate} from "../../../helpers/globalFunctions";

const SalesHSNWise = props => {
  const [salesHsnForTable, setSalesHsnForTable] = useState([]);
  const [date] = useState(getDate(new Date()));
  console.log('date:----> ', date);
 
  const API_URL = process.env.REACT_APP_APIURL || 'http://localhost:3099/'
  
  useEffect(() => {
    //handleTableData();
  }, []);
 
  const data = {
    columns: [
      {
        label: '#',
        field: 'id',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Date',
        field: 'd_date',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Invoice ID',
        field: 'n_InvoiceID',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Name',
        field: 'x_Name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'State',
        field: 'x_State',
        sort: 'asc',
        width: 150
      },
      {
        label: 'GSTIN',
        field: 'n_GSTIN',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Amount',
        field: 'n_Amount',
        sort: 'asc',
        width: 150
      },
      {
        label: 'CGST',
        field: 'n_CGST',
        sort: 'asc',
        width: 150
      },
      {
        label: 'SGST',
        field: 'n_SGST',
        sort: 'asc',
        width: 150
      },
      {
        label: 'IGST',
        field: 'n_IGST',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Total',
        field: 'n_Total',
        sort: 'asc',
        width: 150
      }
    ],
    rows: salesHsnForTable
  }
  let handleDate = () => {
    var date1 = $('#from_date').val()
    var date2 = $('#to_date').val()
    //handleTableData(date1,date2);
  }
  function handleTableData(from_date=null,to_date=null){
    var url=`${API_URL}report/sales_hsn`;
    if(from_date!=null && to_date!=null)
    {
      url=url+"?from_date="+from_date+"&to_date="+to_date;
    }
    else if(from_date!=null)
    {
      url=url+"?from_date="+from_date;
    }
    else if(to_date!=null)
    {
      url=url+"?to_date="+to_date;
    }
    axios.get(url,
    {
      headers: {
        'x-access-token': accessToken,
      },
    })
    .then((res) => {  
      var result = res.data.data;
      let reportData=[];
      result.map((item, index) => {
        item.id = index + 1;
        // item.total_amount = parseFloat(item.amount)+parseFloat(item.gst);
        // item.total_amount = item.total_amount.toFixed(2);
        // item.total_profit = parseFloat(item.amount)-parseFloat(item.cost);
        // item.total_profit = item.total_profit.toFixed(2);

        // item.amount1 =(
        //   <div className="text-right">{(item.amount).toFixed(2)}</div>
        // );
        // item.cost1 =(
        //   <div className="text-right">{(item.cost).toFixed(2)}</div>
        // );
        // item.gst1 =(
        //   <div className="text-right">{(item.gst).toFixed(2)}</div>
        // );
        // item.total_amount1 =(
        //   <div className="text-right">{item.total_amount}</div>
        // );
        // item.total_profit1 =(
        //   <div className="text-right">{item.total_profit}</div>
        // );
        reportData.push(item);
      });
      setSalesHsnForTable(reportData);

    });
  }
  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumbs title='Home' breadcrumbItem='Sales HSN Wise' />
        </div>
      </div>
      <Row>
        <Col className='col-12'>
          <Card>
            <CardBody>
              <Row>
                <Col md='3'>
                  <div className='mb-3'>
                    <Label htmlFor='validationCustom05'>From</Label>
                    <div className='col-md-10'>
                      <input
                        className='form-control'
                        type='date'
                        id='from_date'
                        name='from_date'
                        onChange={handleDate}
                      />
                    </div>
                  </div>
                </Col>
                <Col md='3'>
                  <div className='mb-3'>
                    <Label htmlFor='validationCustom05'>To</Label>
                    <div className='col-md-10'>
                      <input
                        className='form-control'
                        type='date'
                        id='to_date'
                        name='to_date'
                        defaultValue={date}
                        onChange={handleDate}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <MDBDataTable
                responsive
                striped
                bordered
                data={data}
                info={true}
                searching={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}
const mapStateToProps = state => {}

export default withRouter(connect(mapStateToProps, { apiError })(SalesHSNWise))
