import {
  GET_DELIVERYORDERLISTS,
  GET_DELIVERYORDERLISTS_FAIL,
  GET_DELIVERYORDERLISTS_SUCCESS,
} from './actionTypes';

const INIT_STATE = {
  deliverylists: [],
  // deliverylist: {},
  error: {},
};

const DeliveryOrderlist = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DELIVERYORDERLISTS:
      console.log('Reducer Action Payload' + action.payload);
      return {
        ...state,
        params: action.payload,
      };

    case GET_DELIVERYORDERLISTS_SUCCESS:
      return {
        ...state,
        deliverylists: action.payload,
      };

    case GET_DELIVERYORDERLISTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default DeliveryOrderlist;
