import React, { useState, useEffect, useRef } from 'react';
import toastr from 'toastr';
import jwt_decode from 'jwt-decode';

import SweetAlert from 'react-bootstrap-sweetalert';
import { MDBDataTable } from 'mdbreact';
import { Row, Col, Card, CardBody, Button, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAllAccountTypes,
  addAccountType,
  deleteAccountType,
  updateAccountType,
} from '../../../store/actions';

import { AvForm, AvField } from 'availity-reactstrap-validation';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import './type.scss';

const Acctypes = (props) => {
  const [acctypeObject, setAcctypeObject] = useState({});
  const [acctypeTemp, setacctypeTemp] = useState([]);

  const [acctypeIdTobeUpdated, setAcctypedToBeUpdated] = useState(null);
  const [acctypeIdToBeDeleted, setAcctypeIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);

  const [userId, setUserId] = useState(1);
  const formRef = useRef();

  const {
    acctypes,
    addingAcctype,
    addAcctypeResponse,
    deleteAcctypeResponse,
    updateAcctypeResponse,
    error,
  } = useSelector((state) => state.acctypes);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllAccountTypes());
  }, []);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = jwt_decode(localStorage.getItem('authUser'));
      console.log(obj);

      setUserId(obj.user);
      setAcctypeObject({ ['auth_userid']: userId });
    }
  }, [userId]);

  useEffect(() => {
    if (addAcctypeResponse.type === 'success') {
      toastr.success(addAcctypeResponse.message);
    } else if (addAcctypeResponse.type === 'failure') {
      toastr.error(error.data.message, addAcctypeResponse.message);
    }
  }, [addAcctypeResponse]);

  useEffect(() => {
    if (deleteAcctypeResponse.type === 'success') {
      toastr.success(deleteAcctypeResponse.message);
      dispatch(getAllAccountTypes());
    } else if (deleteAcctypeResponse.type === 'failure') {
      toastr.error(error.data.message, deleteAcctypeResponse.message);
    }
  }, [deleteAcctypeResponse]);

  useEffect(() => {
    if (updateAcctypeResponse.type === 'success') {
      setAcctypedToBeUpdated(null);
      toastr.success(updateAcctypeResponse.message);
      dispatch(getAllAccountTypes());
    } else if (updateAcctypeResponse.type === 'failure') {
      toastr.error(error.data.message, updateAcctypeResponse.message);
    }
  }, [updateAcctypeResponse]);

  let preupdateAccountType = (item) => {
    setAcctypedToBeUpdated(item.acc_type_id);
    setAcctypeObject(item);
  };

  useEffect(() => {
    let acctypeDuplicate = JSON.parse(JSON.stringify(acctypes));
    let acctypeData = [];
    acctypeDuplicate.map((item, index) => {
      item.fname="";
      if(item.User)
      {
        item.fname = item.User.fname + ' ' + item.User.lname;
      }
      item.auth_userid = userId;
      item.action = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <i
            className="far fa-edit"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.5em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              preupdateAccountType(item);
            }}
          ></i>
          <i
            className="far fa-trash-alt"
            style={{ fontSize: '1em', cursor: 'pointer' }}
            onClick={() => {
              setAcctypeIdToBeDeleted(item.acc_type_id);
              setConfirmDeleteAlert(true);
            }}
          ></i>
        </div>
      );
      item.id = index + 1;

      acctypeData.push(item);
    });
    setacctypeTemp(acctypeData);
  }, [acctypes]);

  const data = {
    columns: [
      {
        label: '#',
        field: 'id',
        sort: 'asc',
        width: 10,
      },

      {
        label: 'Name',
        field: 'acc_type_name',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Added By',
        field: 'fname',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 100,
      },
    ],
    rows: acctypeTemp,
  };

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setAcctypeObject({
      ...acctypeObject,
      [name]: value,
    });
  };
  const handleValidSubmitAcctype = (event, values) => {
    acctypeIdTobeUpdated
      ? dispatch(updateAccountType(acctypeObject))
      : dispatch(addAccountType(acctypeObject));

    formRef.current.reset();
    setAcctypeObject({ ['auth_userid']: userId });
    dispatch(getAllAccountTypes());
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteAccountType(acctypeIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Account Type" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitAcctype(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Account Type
                          </Label>

                          <AvField
                            name="acc_type_name"
                            value={acctypeObject.acc_type_name}
                            placeholder="Account Type"
                            type="text"
                            errorMessage="Enter Account Type"
                            className="form-control"
                            validate={{ required: { value: true },
                            pattern: {value: '^[A-Za-z ]+$', errorMessage: 'AAccount Type must be only with letter and spaces'}
                          }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3" className="mt-4">
                        <div >
                          {acctypeIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingAcctype ? true : false}
                            >
                              {addingAcctype ? 'Updating' : 'Update'}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingAcctype ? true : false}
                            >
                              {addingAcctype ? 'Adding' : 'Submit'}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bordered data={data} disableRetreatAfterSorting={true}
                    entries={25} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Acctypes;
