import {
  GET_PRODUCTS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  ADD_PRODUCT,
  ADD_PRODUCT_FAIL,
  ADD_PRODUCT_SUCCESS,
  GET_PRODUCT,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_SUCCESS,
  CHANGE_STATUS_PRODUCT,
  CHANGE_STATUS_PRODUCT_SUCCESS,
  CHANGE_STATUS_PRODUCT_FAIL,
  CHANGE_STATUS_BESTSELLER,
  CHANGE_STATUS_BESTSELLER_SUCCESS,
  CHANGE_STATUS_BESTSELLER_FAIL,
} from './actionTypes';

export const getProducts = () => ({
  type: GET_PRODUCTS,
});

export const getProductsSuccess = (product) => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: product,
});

export const getProductsFail = (error) => ({
  type: GET_PRODUCTS_FAIL,
  payload: error,
});

export const addProduct = (product) => ({
  type: ADD_PRODUCT,
  payload: product,
});

export const addProductSuccess = (product) => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: product,
});

export const addProductFail = (product) => ({
  type: ADD_PRODUCT_FAIL,
  payload: product,
});

export const getProduct = (product) => ({
  type: GET_PRODUCT,
  payload: product,
});

export const getProductSuccess = (product) => ({
  type: GET_PRODUCT_SUCCESS,
  payload: product,
});

export const getProductFail = (error) => ({
  type: GET_PRODUCT_FAIL,
  payload: error,
});

export const updateProduct = (product) => ({
  type: UPDATE_PRODUCT,
  payload: product,
});

export const updateProductSuccess = (product) => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: product,
});

export const updateProductFail = (error) => ({
  type: UPDATE_PRODUCT_FAIL,
  payload: error,
});

export const deleteProduct = (product, auth_user) => ({
  type: DELETE_PRODUCT,
  payload: product,
  auth_data: auth_user,
});

export const deleteProductSuccess = (product) => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: product,
});

export const deleteProductFail = (error) => ({
  type: DELETE_PRODUCT_FAIL,
  payload: error,
});
export const toggleProductActiveStatus = (product, auth_user) => ({
  type: CHANGE_STATUS_PRODUCT,
  payload: product,
  auth_data: auth_user,
});

export const toggleProductActiveStatusSuccess = (product) => ({
  type: CHANGE_STATUS_PRODUCT_SUCCESS,
  payload: product,
});

export const toggleProductActiveStatusFail = (error) => ({
  type: CHANGE_STATUS_PRODUCT_FAIL,
  payload: error,
});
export const toggleBestSellerActivesStatus = (product, auth_user) => ({
  type: CHANGE_STATUS_BESTSELLER,
  payload: product,
  auth_data: auth_user,
});

export const toggleBestSellerActivesStatusSuccess = (product) => ({
  type: CHANGE_STATUS_BESTSELLER_SUCCESS,
  payload: product,
});

export const toggleBestSellerActivesStatusFail = (error) => ({
  type: CHANGE_STATUS_BESTSELLER_FAIL,
  payload: error,
});