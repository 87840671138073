export const GET_FUND_TRANSFER = 'GET_FUND_TRANSFER';
export const GET_FUND_TRANSFER_SUCCESS = 'GET_FUND_TRANSFER_SUCCESS';
export const GET_FUND_TRANSFER_FAIL = 'GET_FUND_TRANSFER_FAIL';

export const ADD_FUND_TRANSFER = 'ADD_FUND_TRANSFER';
export const ADD_FUND_TRANSFER_SUCCESS = 'ADD_FUND_TRANSFER_SUCCESS';
export const ADD_FUND_TRANSFER_FAIL = 'ADD_FUND_TRANSFER_FAIL';

export const GET_FUND_SOURCE = "GET_FUND_SOURCE";
export const GET_FUND_SOURCE_SUCCESS = 'GET_FUND_SOURCE_SUCCESS';
export const GET_FUND_SOURCE_FAIL = 'GET_FUND_SOURCE_FAIL';