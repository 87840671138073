import {
  GET_BRANDS,
  GET_BRANDS_FAIL,
  GET_BRANDS_SUCCESS,
  ADD_BRAND,
  ADD_BRAND_FAIL,
  ADD_BRAND_SUCCESS,
  DELETE_BRAND,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_FAIL, 
  UPDATE_BRAND,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAIL,
  CHANGE_STATUS_BRAND,
  CHANGE_STATUS_BRAND_SUCCESS,
  CHANGE_STATUS_BRAND_FAIL,
  GET_BRANDS_OPTIONS_SUCCESS,
  GET_BRANDS_OPTIONS_FAIL,
} from './actionTypes';

const INIT_STATE = {
  brands: [],
  addingBrand: false,
  deletingBrand: false,
  addBrandResponse: {},
  updateBrandResponse: {},
  deleteBrandResponse: {},
  statusBrandResponse: {},
  brandIdToBeDeleted: '',
  brand: {},
  error: {},
  brandOptions: [],
};

const Brand = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRANDS:
      return {
        ...state,
        params: action.payload,
      };

    case GET_BRANDS_SUCCESS:
      return {
        ...state,
        brands: action.payload.data,
      };

    case GET_BRANDS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_BRAND:
      return {
        ...state,
        addingBrand: true,
        brand: action.payload,
      };

    case ADD_BRAND_SUCCESS:
      let newCategories = [JSON.parse(JSON.stringify(action.payload.data[0]))];
      newCategories.push(...state.brands);

      return {
        ...state,
        addingBrand: false,
        addBrandResponse: {
          type: 'success',
          message: 'Brand added successfully',
        },
        brands: newCategories,
        error: {},
      };

    case ADD_BRAND_FAIL:
      return {
        ...state,
        addingBrand: false,
        addBrandResponse: {
          type: 'failure',
          message: 'Adding brand failed',
        },
        error: action.payload,
      };
    case DELETE_BRAND:
      return {
        ...state,
        deletingBrand: true,
        brandIdToBeDeleted: action.payload,
      };

    case DELETE_BRAND_SUCCESS:
      let newCategories2 = state.brands.filter((brand) => {
        return brand.brand_id != state.brandIdToBeDeleted;
      });
      return {
        ...state,
        deletingBrand: false,
        brandIdToBeDeleted: '',
        deletingBrand: false,
        deleteBrandResponse: {
          type: 'success',
          message: 'Brand deleted successfully',
        },
        brands: newCategories2,
      };

    case DELETE_BRAND_FAIL:
      return {
        ...state,
        deletingBrand: false,
        deleteBrandResponse: {
          type: 'failure',
          message: 'Deleting Brand failed',
        },
        error: action.payload,
      };

    case UPDATE_BRAND:
      return {
        ...state,
        updatingBrand: true,
        brand: action.payload,
      };

    case UPDATE_BRAND_SUCCESS:
      let newbrandarr = [];
      let newCategories1 = state.brands.filter((brand) => {
        if (brand.brand_id == state.brand.brand_id) {
          console.log(JSON.parse(JSON.stringify(action.payload.data[0])));
          newbrandarr.push(
            JSON.parse(JSON.stringify(action.payload.data[0]))
          );
        } else {
          //console.log(brand);
          newbrandarr.push(brand);
        }
      });

      return {
        ...state,
        updatingBrand: false,
        brand: '',
        updatingBrand: false,
        updateBrandResponse: {
          type: 'success',
          message: 'Brand updated successfully',
        },
        brands: newbrandarr,
      };

    case UPDATE_BRAND_FAIL:
      return {
        ...state,
        updatingBrand: false,
        updateBrandResponse: {
          type: 'failure',
          message: 'Updating brand failed',
        },
        error: action.payload,
      };

    case CHANGE_STATUS_BRAND:
      return {
        ...state,
        // deletingBrand: true,
        // brandIdToBeDeleted: action.payload,
      };

    case CHANGE_STATUS_BRAND_SUCCESS:
      /* let newCategories2 = state.brands.filter((brand) => {
          return brand.brand_id != state.brandIdToBeDeleted;
        });*/
      return {
        ...state,
        //deletingBrand: false,
        //brandIdToBeDeleted: "",
        //deletingBrand: false,
        brands: action.payload.data,
        statusBrandResponse: {
          type: 'success',
          message: 'Status Changed successfully',
        },
        //brands: newCategories2,
      };

    case CHANGE_STATUS_BRAND_FAIL:
      return {
        ...state,
        //deletingBrand: false,
        statusBrandResponse: {
          type: 'failure',
          message: 'Deleting Brand failed',
        },
        error: action.payload,
      };
      case GET_BRANDS_OPTIONS_SUCCESS:
        return {
          ...state,
          brandOptions: action.payload,
        };
  
      case GET_BRANDS_OPTIONS_FAIL:
        return {
          ...state,
          error: action.payload,
        };
    default:
      return state;
  }
};

export default Brand;
