import React from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";

const DatatableTables = (props) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Tables" breadcrumbItem="Data Table" /> */}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle>Customer Datatable </CardTitle> */}
                  <CardSubtitle className="mb-3">
                    {/* <code>&lt;MDBDataTable /&gt;</code>. */}
                  </CardSubtitle>

                  <MDBDataTable responsive bordered data={props.customerdata} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
