import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';

import { GET_HARVESTORDERLISTS } from './actionTypes';

import { getHarvestlistSuccess, getHarvestlistsFail } from './actions';

//Include Both Helper File with needed methods
import { getHarvestlist } from '../../helpers/backend_helper';

function* fetchHarvestlists({ payload: orderDate }) {
  console.log('Saga order date' + orderDate);
  try {
    const response = yield call(getHarvestlist, orderDate);
    yield put(getHarvestlistSuccess(response.data));
  } catch (error) {
    yield put(getHarvestlistsFail(error));
  }
}

function* harvestlistSaga() {
  yield takeEvery(GET_HARVESTORDERLISTS, fetchHarvestlists);
}

export default harvestlistSaga;
