import { takeEvery, put, call } from 'redux-saga/effects';

import { GET_COMMUNITIES_OPTIONS } from './actionTypes';

import {
  getCommunitiesOptionsSuccess,
  getCommunitiesOptionsFail,
} from './actions';

//Include Both Helper File with needed methods
import { getCommunitiesOptions } from '../../helpers/backend_helper';

function* fetchCommunityOption() {
  try {
    const response = yield call(getCommunitiesOptions);
    yield put(getCommunitiesOptionsSuccess(response.data));
  } catch (error) {
    yield put(getCommunitiesOptionsFail(error));
  }
}

function* communityOptionSaga() {
  yield takeEvery(GET_COMMUNITIES_OPTIONS, fetchCommunityOption);
}

export default communityOptionSaga;
