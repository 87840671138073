export const GET_ACCDETAILS = 'GET_ACCDETAILS';
export const GET_ACCDETAILS_SUCCESS = 'GET_ACCDETAILS_SUCCESS';
export const GET_ACCDETAILS_FAIL = 'GET_ACCDETAILS_FAIL';

export const ADD_ACCDETAIL = 'ADD_ACCDETAIL';
export const ADD_ACCDETAIL_SUCCESS = 'ADD_ACCDETAIL_SUCCESS';
export const ADD_ACCDETAIL_FAIL = 'ADD_ACCDETAIL_FAIL';

export const UPDATE_ACCDETAIL = 'UPDATE_ACCDETAIL';
export const UPDATE_ACCDETAIL_SUCCESS = 'UPDATE_ACCDETAIL_SUCCESS';
export const UPDATE_ACCDETAIL_FAIL = 'UPDATE_ACCDETAIL_FAIL';

export const DELETE_ACCDETAIL = 'DELETE_ACCDETAIL';
export const DELETE_ACCDETAIL_SUCCESS = 'DELETE_ACCDETAIL_SUCCESS';
export const DELETE_ACCDETAIL_FAIL = 'DELETE_ACCDETAIL_FAIL';

export const GET_ACCDETAIL_OPTIONS = 'GET_ACCDETAIL_OPTIONS';
export const GET_ACCDETAIL_OPTIONS_SUCCESS = 'GET_ACCDETAIL_OPTIONS_SUCCESS';
export const GET_ACCDETAIL_OPTIONS_FAIL = 'GET_ACCDETAIL_OPTIONS_FAIL';