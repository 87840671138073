import React, { useState, useEffect, useRef } from "react";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import PropTypes from "prop-types";
import toastr from "toastr";
import jwt_decode from "jwt-decode";
import axios from "axios";
// import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import $ from "jquery";
import {
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TableContainer,
} from "@material-ui/core";

import SweetAlert from "react-bootstrap-sweetalert";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Modal,
  Progress,
  Table,
} from "reactstrap";

import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import {
  getProducts,
  addProduct,
  deleteProduct,
  apiError,
  updateProduct,
  toggleProductActiveStatus,
  getCategoriesOptions,
  getUnitsOptions,
  toggleBestSellerActivesStatus,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./product.scss";
import AvCheckbox from "availity-reactstrap-validation/lib/AvCheckbox";
import { sortedLastIndex } from "lodash";

const Products = (props) => {
  const [productsOptionsGroup, setproductsOptionsGroup] = useState([]);
  const [thirdcategoryptionsGroup, setthirdcategoryOptionsGroup] = useState([]);
  const [showFileField, setshowFileField] = useState("block");
  const [brandGroup, setbrandGroup] = useState([]);
  const [gstGroup, setGSTGroup] = useState([]);
  const [unitGroup, setUNITGroup] = useState([]);
  const formRef = useRef();
  const [invalidcategory, setinvalidcategory] = useState("none");
  const [invalidsubcategory, setinvalidsubcategory] = useState("none");
  const [invalidthirdcategory, setinvalidthirdcategory] = useState("none");
  const [invalidbrand, setinvalidbrand] = useState("none");
  const [FileModal, setFileModal] = useState(false);
  // #########Product View CloseEvent####################
  let closeModal = () => {
    setShowModal(false);
  };
  let closeFileModal = () => {
    setFileModal(false);
  };
  // ###############################################

  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [productObject, setProductObject] = useState({
    product_timing_sunday: 0,
    product_timing_monday: 0,
    product_timing_tuesday: 0,
    product_timing_wednesday: 0,
    product_timing_thursday: 0,
    product_timing_friday: 0,
    product_timing_saturday: 0,
    product_type: 0,
    gst_slab_id:0
  });
  const [productImageObject, setProductImageObject] = useState({});
  const [productUpdateObject, setProductUpdateObject] = useState({});
  const [productsTemp, setProductsTemp] = useState([]);
  const [values, setValues] = useState([
    {
      product_name_english: "",
      product_name_malayalam: "",
      unit: "",
      quantity: "",
      product_codes: "",
      Barcodes: "",
      cost: "",
      mrp: "",
      web: "",
      Android: "",
      Ios: "",
      initial_stock: "",

      //product_name_english: "",
      // product_name_malayalam: "",
      // unit: "",
      // quantity: "",
      // product_code: "",
      // Barcode: "",
      // cost: "",
      // mrp: "",
      // web: "",
      // Android: "",
      // Ios: "",
      // initial_stock: "",
    },
  ]);
  const handleRemove = (index) => {
    const list = [...values];
    if(validProductName&&validProductName.length>0){
      const validProductName = [...validProductName];
      validProductName.splice(index,1);
      setValidProductName(validProductName);
    }
    list.splice(index, 1);
    setValues(list);
   
    if (productIdTobeUpdated) {
      productUpdateObject.product_details.splice(index, 1);
      setProductUpdateObject((productUpdateObject) => {
        return {
          ...productUpdateObject,
          ["product_details"]: productUpdateObject.product_details,
        };
      });
    }
  };

  const addMore = () => {
    setValues([
      ...values,
      {
        product_name_english: "",
        product_name_malayalam: "",
        unit: "",
        quantity: "",
        product_codes: "",
        Barcodes: "",
        cost: "",
        mrp: "",
        web: "",
        Android: "",
        Ios: "",
        initial_stock: "",
      },
    ]);
    if (productIdTobeUpdated) {
      productUpdateObject.product_details.push({
        product_name_english: "",
        product_name_malayalam: "",
        unit: "",
        quantity: "",
        product_codes: "",
        Barcodes: "",
        cost: "",
        mrp: "",
        web: "",
        Android: "",
        Ios: "",
        initial_stock: "",
      });
      setProductUpdateObject((productUpdateObject) => {
        return {
          ...productUpdateObject,
          ["product_details"]: productUpdateObject.product_details,
        };
      });
    }
  };

  const [productIdTobeUpdated, setProductIdToBeUpdated] = useState(null);
  const [productIdToBeDeleted, setProductIdToBeDeleted] = useState(null);
  const [productIdToBeEdit, setProductIdToBeEdited] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedsubCategory, setsubSelectedCategory] = useState(null);
  const [selectedthirdCategory, setthirdSelectedCategory] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState(null);
  const [product_description_short, setproduct_description_short] =
    useState(null);
  const [product_description, setproduct_description] = useState(null);
  const [best_before_days, setbest_before_days] = useState(null);
  const [gst_slab_id, setgst_slab_id] = useState(null);
  const [maxoq, setmaxoq] = useState(null);
  const [minoq, setminoq] = useState(null);
  const [HSN, setHSN] = useState(null);
  const [reorder, setreorder] = useState(null);
  const [initial_stock, setinitial_stock] = useState(null);
  const [keywords, setkeywords] = useState(null);
  const [product_type, setproduct_type] = useState(null);
  const [topselling, settopselling] = useState(null);
  const [video_link, setvideo_link] = useState(null);
  const [monday_availible, setmonday_availible] = useState("checked");
  const [tuesday_availible, settuesday_availible] = useState("checked");
  const [wednesday_availible, setwednesday_availible] = useState("checked");
  const [thursday_availible, setthursday_availible] = useState("checked");
  const [friday_availible, setfriday_availible] = useState("checked");
  const [saturday_availible, setsaturday_availible] = useState("checked");
  const [sunday_availible, setsunday_availible] = useState("checked");
  const [product_is_diffrent_code, setproduct_is_diffrent_code] =
    useState(null);
  const [subCategoryOptionsForSearch, setSubCategoryOptionsForSearch] =
    useState([]);
  const [selectedSubCategoryForSearch, setSelectedSubCategoryForSearch] =
    useState(null);
  const [selectedCategoryForSearch, setSelectedCategoryForSearch] =
    useState(null);
  const [selectedBrandForSearch, setSelectedBrandForSearch] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [Productitems, setProductitems] = useState([]);

  const [selectedUnit, setSelectedUnit] = useState(null);
  const [showModal, setShowModal] = useState(false);
  // const [product_details, setproductdetails] = useState();

  const [single_product_details, setproductdetails] = useState([]);

  const [userId, setUserId] = useState(1);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  // const API_URL = 'https://cccapi.cococa.in';
  // console.log("---------------------API_URL-----------", API_URL);
  const [uploadProgress, setUploadProgress] = useState();
  const [validProductName, setValidProductName] = useState([]);
  const {
    products,
    addingProduct,
    updatingProduct,
    addProductResponse,
    deleteProductResponse,
    updateProductResponse,
    statusProductResponse,
    error,
  } = useSelector((state) => state.products);
  const categoryListOptions = useSelector(
    (state) => state.categories.categoryOptions
  );

  const unitListOptions = useSelector((state) => state.units.unitOptions);
  const dispatch = useDispatch();
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    //dispatch(getProducts());
    handleTableData();
    dispatch(getCategoriesOptions());
    fetch_all_brands();
    fetch_all_gst();
    fetch_all_units();
    //   dispatch(getUnitsOptions());
  }, []);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = jwt_decode(localStorage.getItem("authUser"));
      console.log("obj:>>>>>>>>>> ", obj);

      setUserId(obj.user);
      setProductObject({
        ...productObject,
        ["auth_userid"]: obj.user,
      });
      setProductUpdateObject((productUpdateObject) => {
        return {
          ...productUpdateObject,
          ["auth_userid"]: obj.user,
        };
      });
    }
  }, [props.success, props]);

  useEffect(() => {
    if (addProductResponse.type === "success") {
      toastr.success(addProductResponse.message);
      handleTableData();
      //dispatch(getProducts());
    } else if (addProductResponse.type === "failure") {
      toastr.error(error.data.message, addProductResponse.message);
    }
  }, [addProductResponse]);

  useEffect(() => {
    if (deleteProductResponse.type === "success") {
      toastr.success(deleteProductResponse.message);
      handleTableData();
    } else if (deleteProductResponse.type === "failure") {
      toastr.error(error.data.message, deleteProductResponse.message);
    }
  }, [deleteProductResponse]);

  useEffect(() => {
    if (statusProductResponse.type === "success") {
      toastr.success(statusProductResponse.message);
      handleTableData();
    } else if (statusProductResponse.type === "failure") {
      toastr.error(error.data.message, statusProductResponse.message);
    }
  }, [statusProductResponse]);

  useEffect(() => {
    if (updateProductResponse.type === "success") {
      setShowModal(false);
      setProductIdToBeUpdated(null);
      handleTableData();
      //dispatch(getProducts());
      toastr.success(updateProductResponse.message);
    } else if (updateProductResponse.type === "failure") {
      toastr.error("Something went wrong");
    }
  }, [updateProductResponse]);

  let preUpdateProduct = (item) => {
    console.log('item:------------ ', item);
    let auth_user = 0;
    if (localStorage.getItem("authUser")) {
      const obj = jwt_decode(localStorage.getItem("authUser"));
      auth_user = obj?.user;
    }
    item.auth_userid = auth_user;
    setshowFileField("block");
    setProductUpdateObject(item);
    setProductIdToBeUpdated(item.product_id);
    setProductUpdateObject((productUpdateObject) => {
      return {
        ...productUpdateObject,
        ["auth_userid"]: auth_user,
        ["product_type"]: item.product_type ? item.product_type : 0,
      };
    });
    if (item.category != null) {
      let cats = {
        label: item.category.category_name_english,
        value: item.category.category_id,
      };
      handleSelectedCategories(cats);
    }

    if (item.subCategory != null) {
      let subcat = {
        label: item.subCategory.subCategory_name_english,
        value: item.subCategory.subcategory_id,
      };
      handleSelectedSubCategories(subcat);
    }
    if (item.third_category != null) {
      let thirdcat = {
        label: item.third_category.thrdcate_name_english,
        value: item.third_category.thrdcate_id,
      };
      handleSelectedthirdCategories(thirdcat);
      setthirdSelectedCategory(thirdcat);
    }
    // Brands
    if (item.brand) {
      let brands = {
        label: item.brand.brand_name_english,
        value: item.brand.brand_id,
      };
      handlebrands(brands);
    }
    // Description Short
    // if (item.product_description_short) {
    setproduct_description_short(item.product_description_short);
    //}
    setProductUpdateObject((productUpdateObject) => {
      return {
        ...productUpdateObject,
        ["description_short"]: item.product_description_short,
      };
    });
    //Description
    // if (item.product_description) {
    setproduct_description(item.product_description);
    // }
    setProductUpdateObject((productUpdateObject) => {
      return {
        ...productUpdateObject,
        ["description"]: item.product_description_short,
      };
    });
    //setbest_before_days
    item.best_before_days = item.product_best_before_days;
    setbest_before_days(item.product_best_before_days);
   
    setProductUpdateObject((productUpdateObject) => {
      return {
        ...productUpdateObject,
        ["best_before_days"]: item.product_best_before_days,
      };
    });
    //setgst_slab_id
    if (item.tax) {
      let gst = {
        label: item.tax.tax_slab_name,
        value: item.tax.slab_id,
      };
      fetch_all_gst(gst);
    }
    //Max OQ
    // if (item.max_order_quantity) {
      setmaxoq(item.max_order_quantity);
    //}
    // MIN OQ
    // if (item.min_order_quantity) {
      setminoq(item.min_order_quantity);
   // }
    // HSN Code
    // if (item.product_hsn) {
      item.hsn_code = item.product_hsn;
    
      setHSN(item.product_hsn);
      setProductUpdateObject((productUpdateObject) => {
        return {
          ...productUpdateObject,
          ["hsn_code"]: item.product_hsn,
        };
      });
    //}
    //reorder
    // if (item.reorder_point) {
      setreorder(item.reorder_point);
   //}
    //setinitial_stock
    if (item.initial_stock) {
      setinitial_stock(item.initial_stock);
    }
    //setkeywords
    if (item.product_keywords) {
      setkeywords(item.product_keywords);
    }
    //setproduct_type
    if (item.product_type == 0) {
      item.product_type1 = "";
    } else {
      item.product_type1 = "checked";
    }
    setproduct_type(item.product_type1);
    //settopselling
    if (item.top_selling_products == 0) {
      item.top_selling_products1 = "";
    } else {
      item.top_selling_products1 = "checked";
    }
    settopselling(item.top_selling_products1);
    //setvideo_link
    setvideo_link(item.video_link);
    //Monday Availibility
    if (item.product_timing_monday == 1) {
      item.product_timing_monday1 = "";
    } else {
      item.product_timing_monday1 = "checked";
    }
    setmonday_availible(item.product_timing_monday1);
    //Tuesday Availibility
    if (item.product_timing_tuesday == 1) {
      item.product_timing_tuesday1 = "";
    } else {
      item.product_timing_tuesday1 = "checked";
    }
    settuesday_availible(item.product_timing_tuesday1);
    // Wednesday Availibility
    if (item.product_timing_wednesday == 1) {
      item.product_timing_wednesday1 = "";
    } else {
      item.product_timing_wednesday1 = "checked";
    }
    setwednesday_availible(item.product_timing_wednesday1);
    // Thursday  Availibility
    if (item.product_timing_thursday == 1) {
      item.product_timing_thursday1 = "";
    } else {
      item.product_timing_thursday1 = "checked";
    }
    setthursday_availible(item.product_timing_thursday1);
    //setfriday_availible
    if (item.product_timing_friday == 1) {
      item.product_timing_friday1 = "";
    } else {
      item.product_timing_friday1 = "checked";
    }
    setfriday_availible(item.product_timing_friday1);
    //setsaturday_availible
    console.log("item: ", item);
    console.log("item.product_timing_saturday: ", item.product_timing_saturday);
    if (item.product_timing_saturday == 1) {
      item.product_timing_saturday1 = "";
    } else {
      item.product_timing_saturday1 = "checked";
    }
    setsaturday_availible(item.product_timing_saturday1);
    // setsunday_availible
    if (item.product_timing_sunday == 1) {
      item.product_timing_sunday1 = "";
    } else {
      item.product_timing_sunday1 = "checked";
    }
    // Diffrent SKU Code
    if (item.products_is_diffrent_code == 0) {
      item.products_is_diffrent_code1 = "";
    } else {
      item.products_is_diffrent_code1 = "checked";
    }
    setProductUpdateObject((productUpdateObject) => {
      return {
        ...productUpdateObject,
        ["sku_type"]: item.products_is_diffrent_code,
      };
    });

    setsunday_availible(item.product_timing_sunday1);
    setproduct_is_diffrent_code(item.products_is_diffrent_code1);
    setProductObject(item);
    console.log('item:=========>>>> ', item);
    if (
      item.product_is_master_product == 0 ||
      item.product_is_master_product == 1
    ) {
      // master Product Allenkil
      var product_items = item;
      if (item.productUnit) {
        let unit_id = {
          label: item.productUnit.unit_short,
          value: item.productUnit.unit_id,
        };
      }
      //  setProductitems(product_items);

      //Axios For Fetch Sub Products

      axios
        .get(`${API_URL}product/fetch_subproduct?id=` + item.product_group_id, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          var temp = res;
          var temp_items = [];
          temp.data.data.map((item) => {
            temp_items.push({
              product_id: item.product_id,
              product_name_english: item.product_name_english,
              product_name_malayalam: item.product_name_malayalam,
              quantity: item.product_unit_quantity,
              product_code: item.product_sku,
              Barcode: item.barcode,
              mrp: item.product_mrp,
              cost: item.product_cost,
              web: item.product_web_price,
              Android: item.product_and_price,
              Ios: item.product_ios_price,
              product_unit:
                item.productUnit != null ? item.productUnit.unit_id : "",
              initial_stock: item.initial_stock,
            });
          });
          setProductUpdateObject((productUpdateObject) => {
            return {
              ...productUpdateObject,
              ["product_details"]: temp_items,
            };
          });

          setValues(
            (res.data.data = res.data.data
              ? res.data.data.map((item1) => {
                  return {
                    product_name_english: item1.product_name_english,
                    product_name_malayalam: item1.product_name_malayalam,
                    product_unit_quantity: item1.product_unit_quantity,
                    product_sku: item1.product_sku,
                    barcode: item1.barcode,
                    product_mrp: item1.product_mrp,
                    product_cost: item1.product_cost,
                    product_web_price: item1.product_web_price,
                    product_and_price: item1.product_and_price,
                    product_ios_price: item1.product_ios_price,
                    product_unit_id:
                      item1.productUnit != null
                        ? item1.productUnit.unit_id
                        : "",
                    product_unit_name:
                      item1.productUnit != null
                        ? item1.productUnit.unit_short
                        : "",

                    product_id: item.product_id,
                    quantity: item1.product_unit_quantity,
                    product_code: item1.product_sku,
                    Barcode: item1.barcode,
                    mrp: item1.product_mrp,
                    cost: item1.product_cost,
                    web: item1.product_web_price,
                    Android: item1.product_and_price,
                    Ios: item1.product_ios_price,
                    product_unit:
                      item1.productUnit != null
                        ? item1.productUnit.unit_id
                        : "",
                    initial_stock: item1.initial_stock,
                  };
                })
              : null)
          );

          //  console.log(sub_product_array);

          //setValues
        });

      //      setProductObject({
      //   ...productObject,
      //   [`product_details`]: {'product_name_english':10,
      //   'product_name_malayalam':10,
      //   'unit':10,
      //   'quantity':10,
      //   'product_code':10,
      //   'Barcode':10,
      //   'cost':10,
      //   'mrp':10,
      //   'web':10,
      //   'Android':10,
      //   'Ios':10,
      //   'initial_stock':0

      // },
      // });
    }

    // setProductObject({
    //   ...item,
    //   [`product_image_main`]: item.product_image_main
    //     ? item.product_image_main.props.children.props.src
    //     : "",
    // });

    // if (
    //   item.product_image_main &&
    //   item.product_image_main.props.children.props.src.length > 2
    // ) {
    //   if (
    //     item.product_image_main.props.children.props.src.indexOf(
    //       "uploads/product-images/"
    //     ) > -1
    //   ) {
    //     setProductObject({
    //       ...item,
    //       [`product_image_main`]:
    //         item.product_image_main.props.children.props.src.slice(
    //           item.product_image_main.props.children.props.src.indexOf(
    //             "uploads/product-images/"
    //           ) + 23
    //         ),
    //     });
    //   } else {
    //     setProductObject({
    //       ...item,
    //       [`product_image_main`]:
    //         item.product_image_main.props.children.props.src,
    //     });
    //   }
    // } else {
    //   setProductObject({
    //     ...item,
    //     [`product_image_main`]: "",
    //   });
    // }
  };

  //   useEffect(() => {
  //     let productsDuplicate = JSON.parse(JSON.stringify(products));
  //     let productData = [];
  //     productsDuplicate.map((item, index) => {
  //       var temp = item;
  //       if(item.category!=null)
  //       {
  //         item.category_name = item.category.category_name_english;
  //       }
  //       else
  //       {
  //         item.category_name = '';
  //       }

  //       // item.brand_name=item.brand.brand_name_english;

  //       if (item.brand == null) {
  //         item.brand_name = "";
  //       } else {
  //         item.brand_name = item.brand.brand_name_english;
  //       }
  //       if(item.User)
  //       {
  //         item.added_by = item.User.fname + " " + item.User.lname;
  //       }
  //       else
  //       {
  //         item.added_by = '';
  //       }
  //       item.amount_column = (
  //         <div className="text-right">{item.product_mrp.toFixed(2)}</div>
  //        );
  //       // item.auth_userid = userId;
  //       // item.product_qty = item.product_qty + ' ' + item.unit.default_unit;
  //       item.action = (
  //         <div style={{ display: "flex", justifyContent: "center" }}>
  //           <i
  //             className="fas fa-eye"
  //             style={{
  //               fontSize: "1em",
  //               cursor: "pointer",
  //               marginLeft: "0.5em",
  //               marginRight: "0.5em",
  //             }}
  //             onClick={() => {
  //               fetch_product_details(item.product_id);
  //               setShowModal(true);
  //             }}
  //           ></i>

  // <i
  //             className="far fa-image"
  //             style={{
  //               fontSize: "1em",
  //               cursor: "pointer",
  //               marginLeft: "0.5em",
  //               marginRight: "0.5em",
  //             }}
  //             onClick={() => {
  //               setFileModal(true);
  //               setProductImageObject(productImageObject=>{
  //                 return{
  //                   ...productImageObject,
  //                   ["product_id"]:item.product_id
  //                 }
  //               })
  //               setProductImageObject(productImageObject=>{
  //                 return{
  //                   ...productImageObject,
  //                   ["auth_userid"]:userId
  //                 }
  //               })
  //               setProductIdToBeEdited(item.product_id);
  //               //preImageUpdate(item);
  //             }}
  //           ></i>

  //           <i
  //             className="far fa-edit"
  //             style={{
  //               fontSize: "1em",
  //               cursor: "pointer",
  //               marginLeft: "0.5em",
  //               marginRight: "0.5em",
  //             }}
  //             onClick={() => {
  //               toTop();
  //               preUpdateProduct(temp);
  //             }}
  //           ></i>
  //           <i
  //             className="far fa-trash-alt"
  //             style={{ fontSize: "1em", cursor: "pointer" }}
  //             onClick={() => {
  //               setProductIdToBeDeleted(item.product_id);
  //               setConfirmDeleteAlert(true);
  //             }}
  //           ></i>
  //         </div>
  //       );
  //       item.id = index + 1;
  //       item.product_image_main1="";
  //       if (item.product_image_main != "") {
  //         item.product_image_main1 = (
  //           <div style={{ textAlign: "center" }}>
  //             <img
  //               src={`${API_URL}public/uploads/product_images/product_image_60x60/${item.product_image_main}`}
  //               height="50"
  //               width="50"
  //             />
  //           </div>
  //         );
  //       }

  //         item.display_status = (
  //           <div style={{ textAlign: "center", width: "100%", display: "flex" }}>
  //             <div
  //               className="form-check form-switch mb-3"
  //               style={{ textAlign: "center" }}
  //             >
  //               <input
  //                 type="checkbox"
  //                 className="form-check-input"
  //                 id="customSwitch2"
  //                 checked={item.product_status}
  //                 onChange={(e) => {
  //                   dispatch(toggleProductActiveStatus(item.product_id, userId));
  //                   settoggleSwitch(!toggleSwitch);
  //                 }}
  //               />
  //             </div>
  //           </div>
  //         );

  //       productData.push(item);
  //     });
  //     setProductsTemp(productData);
  //   }, [products]);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 10,
      },

      {
        label: "Name",
        field: "product_name_english",
        sort: "asc",
        width: 70,
      },
      {
        label: "SKU",
        field: "product_sku_autogen",
        sort: "asc",
        width: 70,
      },
      {
        label: "Category",
        field: "category_name",
        sort: "asc",
        width: 70,
      },
      {
        label: "Brand",
        field: "brand_name",
        sort: "asc",
        width: 70,
      },
      {
        label: "Price",
        field: "amount_column",
        sort: "asc",
        width: 70,
      },
      {
        label: "Image",
        field: "product_image_main1",
        sort: "asc",
        width: 70,
      },

      {
        label: "Status",
        field: "display_status",
        sort: "asc",
        width: 70,
      },
      {
        label: "User",
        field: "added_by",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: productsTemp,
  };

  let categoryOptionsData =
    categoryListOptions &&
    categoryListOptions.map((item) => {
      return {
        label: item.category_name_english,
        value: item.category_id,
      };
    });

  const categoriesOptionsGroup = [
    {
      options: categoryOptionsData,
    },
  ];
  let unitOptionsData =
    unitListOptions &&
    unitListOptions.map((item) => {
      return {
        label: item.default_unit,
        value: item.unit_id,
      };
    });

  const unitsOptionsGroup = [
    {
      options: unitOptionsData,
    },
  ];

  const deleteProductImage = () => {
    setProductObject({
      ...productObject,
      [`product_image_main`]: "",
    });
    setProductUpdateObject((productUpdateObject) => {
      return {
        ...productUpdateObject,
        ["product_image_main"]: "",
      };
    });
  };

  let handleChangeInput = (e) => {
    console.log("productObject: ", productObject);
    let name = e.target.name;
    console.log("name: ", name);
    let value = e.target.value;
    console.log("value: ", value);

    if (name === "initial_stock" || name === "best_before_days") {
      value = Number(e.target.value);
    }

    setProductObject({
      ...productObject,
      [name]: value,
    });
    setProductUpdateObject((productUpdateObject) => {
      return {
        ...productUpdateObject,
        [name]: value,
      };
    });
  };
  let handleChangeInputproduct = (e, index) => {
    let subProduct = [];
    let id = "";
    
    const { name, value } = e.target;
    if(productUpdateObject.product_details && productUpdateObject.product_details.length>0){
      subProduct = productUpdateObject.product_details[index]||{};
      id = subProduct?.product_id ? subProduct.product_id : "";
    }
    
    const list = [...values];
    list[index][name] = value;

    if (name === "product_name_english") {
      axios
        .get(
          `${API_URL}product/check_duplicate_name` +
            "?name=" +
            value +
            "&id=" +
            id,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          let values = [...validProductName];
          console.log('index:-------> ', index);
          console.log('values:------> ', values);
          let itemToEdit = values[index] || {};
          itemToEdit.success = res.data.success;
          itemToEdit.data = res.data.data ? res.data.data : {};
          values[index] = itemToEdit;
          setValidProductName(values);
          console.log('values: ', values);
          if (res.data.success === false) {
            toastr.error(
              res.data.data.product_name_english +
                " " +
                "already" +
                " " +
                "exists"
            );
            return;
          }
        });
    }
    setValues(list);

    setProductObject({
      ...productObject,
      [`product_details`]: list,
    });
    if (productIdTobeUpdated) {
      productUpdateObject.product_details[index][name] = value;
      setProductUpdateObject((productUpdateObject) => {
        return {
          ...productUpdateObject,
          [`product_details`]: productUpdateObject.product_details,
        };
      });
    }
  };
  let handleChangeInputproductunit = (e, index) => {
    let name = "product_unit";
    let value = e.value;
    const list = [...values];
    list[index][name] = value;
    list[index]["product_unit_name"] = e.label;

    setValues(list);

    if (productIdTobeUpdated) {
      productUpdateObject.product_details[index][name] = value;
      setProductUpdateObject((productUpdateObject) => {
        return {
          ...productUpdateObject,
          [`product_details`]: productUpdateObject.product_details,
        };
      });
    } else {
      productObject.product_details[index][name] = value;
      setProductObject({
        ...productObject,
        [`product_details`]: productObject.product_details,
      });
    }

    console.log(values);
  };

  // ##################Sub Category Fetchinbg##################################
  function handleSelectedCategories(e) {
    let name = "product_category";
    let value = e.value;
    if (value) {
      setinvalidcategory("none");
      $("#product_category").attr("class", "select2-selection");
    } else {
      setinvalidcategory("block");
      $("#product_category").attr(
        "class",
        "select2-selection border border-danger"
      );
    }
    setSelectedCategory(e);
    setProductObject({
      ...productObject,
      [name]: value,
    });
    setProductUpdateObject((productUpdateObject) => {
      return {
        ...productUpdateObject,
        product_category: value,
      };
    });
    setsubSelectedCategory(null);
    setinvalidsubcategory("block");
    $("#product_sub_category").attr(
      "class",
      "select2-selection border border-danger"
    );
    setthirdSelectedCategory(null);
    setinvalidthirdcategory("block");
    $("#product_third_category").attr(
      "class",
      "select2-selection border border-danger"
    );
    setthirdcategoryOptionsGroup([]);

    var productsOptionsData = "";
    axios
      .get(`${API_URL}subcategory?category_id=` + value, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var productsOptionsData =
          res.data &&
          res.data.map((item) => {
            return {
              label: item.subcategory_name_english,
              value: item.subcategory_id,
            };
          });
        setproductsOptionsGroup([
          {
            options: productsOptionsData,
          },
        ]);
      });

    // #####################################################################
  }
  // ##################3rd Category Fetchinbg##################################

  function handleSelectedSubCategories(e) {
    let name = "product_sub_category";
    let value = e.value;
    if (value) {
      setinvalidsubcategory("none");
      $("#product_sub_category").attr("class", "select2-selection");
    } else {
      setinvalidsubcategory("block");
      $("#product_sub_category").attr(
        "class",
        "select2-selection border border-danger"
      );
    }
    setsubSelectedCategory(e);
    setProductObject({
      ...productObject,
      [name]: value,
    });
    setProductUpdateObject((productUpdateObject) => {
      return {
        ...productUpdateObject,
        product_sub_category: value,
      };
    });
    setthirdSelectedCategory(null);
    setinvalidthirdcategory("block");
    $("#product_third_category").attr(
      "class",
      "select2-selection border border-danger"
    );
    setthirdcategoryOptionsGroup([]);
    var third_category_data = "";
    axios
      .get(`${API_URL}thirdcategory?sub_category_id=` + value, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var third_category_data =
          res.data.result &&
          res.data.result.map((item) => {
            return {
              label: item.thrdcate_name_english,
              value: item.thrdcate_id,
            };
          });
        setthirdcategoryOptionsGroup([
          {
            options: third_category_data,
          },
        ]);
      });
  }

  // #####################################################################
  // ##################3rd Category Adding For Submission#############
  function handleSelectedthirdCategories(e) {
    let name = "product_third_category";
    let value = e.value;
    if (value) {
      setinvalidthirdcategory("none");
      $("#product_third_category").attr("class", "select2-selection");
    } else {
      setinvalidthirdcategory("block");
      $("#product_third_category").attr(
        "class",
        "select2-selection border border-danger"
      );
    }
    setthirdSelectedCategory(e);
    setProductObject({
      ...productObject,
      [name]: value,
    });
    setProductUpdateObject((productUpdateObject) => {
      return {
        ...productUpdateObject,
        [name]: value,
      };
    });
  }

  // ####################################################
  // ##################################Brand For Submission##########################
  function handlebrands(e) {
    setSelectedBrands(e);
    let name = "brand_id";
    let value = e.value;
    if (value) {
      setinvalidbrand("none");
      $("#product_brand").attr("class", "select2-selection");
    } else {
      setinvalidbrand("block");
      $("#product_brand").attr(
        "class",
        "select2-selection border border-danger"
      );
    }
    setProductObject({
      ...productObject,
      [name]: value,
    });
    setProductUpdateObject((productUpdateObject) => {
      return {
        ...productUpdateObject,
        [name]: value,
      };
    });
  }
  // ########################################################################

  // #####################################brand Fetching #########################
  function fetch_all_brands() {
    axios
      .get(`${API_URL}brands`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var brand_data =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label: item.brand_name_english,
              value: item.brand_id,
            };
          });

        setbrandGroup([
          {
            options: brand_data,
          },
        ]);
      });
  }

  // #########################################################################
  // ####################################Gst Slab#################################

  function fetch_all_gst(e) {
    setgst_slab_id(e);
    axios
      .get(`${API_URL}gst_slab`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var gst_data =
          res.data.result &&
          res.data.result.map((item) => {
            return {
              label: item.tax_slab_name,
              value: item.slab_id,
            };
          });

        setGSTGroup([
          {
            options: gst_data,
          },
        ]);
      });
  }

  function setgstslab(e) {
    let name = "gst_slab_id";
    let value = e.value;
    var Label = e.label;
    let gst = {
      label: Label,
      value: e.value,
    };
    setgst_slab_id(gst);
    setProductObject({
      ...productObject,
      [name]: value,
    });
    setProductUpdateObject((productUpdateObject) => {
      return {
        ...productUpdateObject,
        [name]: value,
      };
    });
  }

  // #######################################################################################
  // ######################################product Units####################################

  function fetch_all_units() {
    axios
      .get(`${API_URL}unit`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var unit_data =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label: item.unit_short,
              value: item.unit_id,
            };
          });

        setUNITGroup([
          {
            options: unit_data,
          },
        ]);
      });
  }

  // function setunits(e) {
  //   let name = "gst_slab_id";
  //   let value = e.value;
  //   setProductObject({
  //     ...productObject,
  //     [name]: value,
  //   });
  // }
  // ####################################################################################

  // ########################################################################################
  // #############################product Image#########################################
  //Title Image
  const uploadImage_title = (e) => {
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}api/uploadProductSingleImage`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setProductObject({
            ...productObject,
            [`product_image_main`]: response.data.file.filename,
          });
          setProductUpdateObject((productUpdateObject) => {
            return {
              ...productUpdateObject,
              [`product_image_main`]: response.data.file.filename,
            };
          });
          toastr.success("Title Image Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const uploadImage_title_forEdit = (e) => {
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}api/uploadProductSingleImage`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setProductImageObject({
            ...productImageObject,
            [`product_image_main`]: response.data.file.filename,
          });
          //
        } else {
          // toastr.error(response.data.message);
        }
      });
  };
  // Sub Image 1

  const uploadImage1 = (e) => {
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}api/uploadProductSingleImage`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setProductObject({
            ...productObject,
            [`product_image1`]: response.data.file.filename,
          });
          setProductUpdateObject((productUpdateObject) => {
            return {
              ...productUpdateObject,
              [`product_image1`]: response.data.file.filename,
            };
          });
          toastr.success("Sub Image 2 Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const uploadImage1_forEdit = (e) => {
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}api/uploadProductSingleImage`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setProductImageObject({
            ...productImageObject,
            [`product_image1`]: response.data.file.filename,
          });
          toastr.success("Sub Image 2 Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  // Sub Image 2
  const uploadImage2 = (e) => {
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}api/uploadProductSingleImage`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setProductObject({
            ...productObject,
            [`product_image2`]: response.data.file.filename,
          });
          setProductUpdateObject((productUpdateObject) => {
            return {
              ...productUpdateObject,
              [`product_image2`]: response.data.file.filename,
            };
          });

          toastr.success("Sub Image 2 Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const uploadImage2_forEdit = (e) => {
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}api/uploadProductSingleImage`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setProductImageObject({
            ...productImageObject,
            [`product_image2`]: response.data.file.filename,
          });

          toastr.success("Sub Image 2 Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  // Sub Image 3
  const uploadImage3 = (e) => {
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}api/uploadProductSingleImage`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setProductObject({
            ...productObject,
            [`product_image3`]: response.data.file.filename,
          });
          setProductUpdateObject((productUpdateObject) => {
            return {
              ...productUpdateObject,
              [`product_image3`]: response.data.file.filename,
            };
          });
          toastr.success("Sub Image 3 Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const uploadImage3_forEdit = (e) => {
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}api/uploadProductSingleImage`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setProductImageObject({
            ...productImageObject,
            [`product_image3`]: response.data.file.filename,
          });
          toastr.success("Sub Image 3 Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  // Sub Image 4
  const uploadImage4 = (e) => {
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}api/uploadProductSingleImage`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setProductObject({
            ...productObject,
            [`product_image4`]: response.data.file.filename,
          });
          setProductUpdateObject((productUpdateObject) => {
            return {
              ...productUpdateObject,
              [`product_image4`]: response.data.file.filename,
            };
          });
          toastr.success("Sub Image 4 Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const uploadImage4_forEdit = (e) => {
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}api/uploadProductSingleImage`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setProductImageObject({
            ...productImageObject,
            [`product_image4`]: response.data.file.filename,
          });
          toastr.success("Sub Image 4 Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  // ####################################################################################
  // Unit Set To Variable
  function handleSelectedUnits(e) {
    let name = "product_unit_id";
    let value = e.value;

    setSelectedUnit(e);
    setProductObject({
      ...productObject,
      [name]: value,
    });
    setProductUpdateObject((productUpdateObject) => {
      return {
        ...productUpdateObject,
        [name]: value,
      };
    });
  }
  //Product Details Fetching
  function fetch_product_details(product_id) {
    setproductdetails([]);
    let product_fetch_result = {};
    axios
      .get(`${API_URL}product?id=` + product_id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        product_fetch_result = res.data.result;
        // Category
        if (product_fetch_result.category != null) {
          product_fetch_result.category_name =
            product_fetch_result.category.category_name_english;
        } else {
          product_fetch_result.category_name = "";
        }
        // Sub Category
        if (product_fetch_result.subCategory != null) {
          product_fetch_result.subCategory =
            product_fetch_result.subCategory.subCategory_name_english;
        } else {
          product_fetch_result.subCategory = "";
        }
        //Third Category
        if (product_fetch_result.third_category != null) {
          product_fetch_result.third_category =
            product_fetch_result.third_category.thrdcate_name_malayalam;
        } else {
          product_fetch_result.third_category = "";
        }
        //Brands
        if (product_fetch_result.brand != null) {
          product_fetch_result.brand =
            product_fetch_result.brand.brand_name_english;
        } else {
          product_fetch_result.brand = "";
        }
        //slab
        if (
          product_fetch_result.tax != "" &&
          product_fetch_result.tax != null
        ) {
          product_fetch_result.tax = product_fetch_result.tax.tax_slab_name;
        } else {
          product_fetch_result.tax = "";
        }
        //Product type
        if (product_fetch_result.product_type == 0) {
          product_fetch_result.product_type = "VEG";
        } else {
          product_fetch_result.product_type = "NonVEG";
        }
        // Top selling
        if (product_fetch_result.top_selling_products == 0) {
          product_fetch_result.top_selling_products = "None";
        } else {
          product_fetch_result.top_selling_products = "Yes";
        }
        //Product Title iMage Null
        if (product_fetch_result.product_image_main == "") {
          product_fetch_result.product_image_main = "no-photo-available.png";
        }
        //Product Sub Image1
        if (product_fetch_result.product_image_subone == "") {
          product_fetch_result.product_image_subone = "no-photo-available.png";
        }
        //product Sub Image 2
        if (product_fetch_result.product_image_subtwo == "") {
          product_fetch_result.product_image_subtwo = "no-photo-available.png";
        }
        // product Sub Image 3
        if (product_fetch_result.product_image_subthree == "") {
          product_fetch_result.product_image_subthree =
            "no-photo-available.png";
        }
        // Product Image 4
        if (product_fetch_result.product_image_subfour == "") {
          product_fetch_result.product_image_subfour = "no-photo-available.png";
        }
        // Sunday Availibility
        if (product_fetch_result.product_timing_sunday == 0) {
          product_fetch_result.product_timing_sunday = "checked";
        } else {
          product_fetch_result.product_timing_sunday = "";
        }
        // Monday Availibility
        if (product_fetch_result.product_timing_monday == 0) {
          product_fetch_result.product_timing_monday = "checked";
        } else {
          product_fetch_result.product_timing_monday = "";
        }
        // Tuesday Availibility
        if (product_fetch_result.product_timing_tuesday == 0) {
          product_fetch_result.product_timing_tuesday = "checked";
        } else {
          product_fetch_result.product_timing_tuesday = "";
        }
        //Wednesday Availibility
        if (product_fetch_result.product_timing_wednesday == 0) {
          product_fetch_result.product_timing_wednesday = "checked";
        } else {
          product_fetch_result.product_timing_wednesday = "";
        }
        // Thursday Availibility
        if (product_fetch_result.product_timing_thursday == 0) {
          product_fetch_result.product_timing_thursday = "checked";
        } else {
          product_fetch_result.product_timing_thursday = "";
        }
        //_friday Timing
        if (product_fetch_result.product_timing_friday == 0) {
          product_fetch_result.product_timing_friday = "checked";
        } else {
          product_fetch_result.product_timing_friday = "";
        }
        // Saturday Timing
        if (product_fetch_result.product_timing_saturday == 0) {
          product_fetch_result.product_timing_saturday = "checked";
        } else {
          product_fetch_result.product_timing_saturday = "";
        }

        product_fetch_result.category_name =
          product_fetch_result.category.category_name_english;
        setproductdetails(product_fetch_result);
      });
  }
  const handleTableData = (category = "", sub_category = "", brand = "") => {
    var url = `${API_URL}product`;
    url =
      url +
      "?category=" +
      category +
      "&subcategory=" +
      sub_category +
      "&brand=" +
      brand;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        console.log('result: ', result);
        let productData = [];
        result &&
          result.map((item, index) => {
            var temp = item;
            if (item.category != null) {
              item.category_name = item.category.category_name_english;
            } else {
              item.category_name = "";
            }

            // item.brand_name=item.brand.brand_name_english;

            if (item.brand == null) {
              item.brand_name = "";
            } else {
              item.brand_name = item.brand.brand_name_english;
            }
            if (item.User) {
              item.added_by = item.User.fname + " " + item.User.lname;
            } else {
              item.added_by = "";
            }
            item.amount_column = (
              <div className="text-right">{item.product_mrp.toFixed(2)}</div>
            );
            // item.auth_userid = userId;
            // item.product_qty = item.product_qty + ' ' + item.unit.default_unit;
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.5em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    fetch_product_details(item.product_id);
                    setShowModal(true);
                  }}
                ></i>

                <i
                  className="far fa-image"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.5em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    setFileModal(true);
                    setProductImageObject((productImageObject) => {
                      return {
                        ...productImageObject,
                        ["product_id"]: item.product_id,
                      };
                    });
                    setProductImageObject((productImageObject) => {
                      return {
                        ...productImageObject,
                        ["auth_userid"]: userId,
                      };
                    });
                    setProductIdToBeEdited(item.product_id);
                    //preImageUpdate(item);
                  }}
                ></i>

                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.5em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    toTop();
                    preUpdateProduct(temp);
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setProductIdToBeDeleted(item.product_id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );
            item.id = index + 1;
            item.product_image_main1 = "";
            if (item.product_image_main != "") {
              item.product_image_main1 = (
                <div style={{ textAlign: "center" }}>
                  <img
                    src={`${API_URL}public/uploads/product_images/product_image_60x60/${item.product_image_main}`}
                    height="50"
                    width="50"
                  />
                </div>
              );
            }

            item.display_status = (
              <div
                style={{ textAlign: "center", width: "100%", display: "flex" }}
              >
                <div
                  className="form-check form-switch mb-3"
                  style={{ textAlign: "center" }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitch2"
                    checked={item.product_status}
                    onChange={(e) => {
                      dispatch(
                        toggleProductActiveStatus(item.product_id, userId)
                      );
                      settoggleSwitch(!toggleSwitch);
                    }}
                  />
                </div>
              </div>
            );

            productData.push(item);
          });
        setProductsTemp(productData);
      });
  };
  const handleSelectChange = (selected, name) => {
    let category = searchData?.category_id ? searchData.category_id : "";
    let sub_category = searchData?.sub_category_id
      ? searchData.sub_category_id
      : "";
    let brand = searchData?.brand_id ? searchData.brand_id : "";
    switch (name) {
      case "category":
        setSelectedCategoryForSearch(selected);
        setSelectedSubCategoryForSearch(null);
        setSearchData({
          ...searchData,
          ["category_id"]: selected.value,
          ["sub_category_id"]: "",
        });
        axios
          .get(`${API_URL}subcategory?category_id=` + selected.value, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            var subCategoryData =
              res.data &&
              res.data.map((item) => {
                return {
                  label: item.subcategory_name_english,
                  value: item.subcategory_id,
                };
              });
            setSubCategoryOptionsForSearch([
              {
                options: subCategoryData,
              },
            ]);
          });
        handleTableData(selected.value, "", brand);
        break;
      case "subcategory":
        setSelectedSubCategoryForSearch(selected);
        setSearchData({
          ...searchData,
          ["sub_category_id"]: selected.value,
        });
        handleTableData(category, selected.value, brand);
        break;
      case "brand":
        setSelectedBrandForSearch(selected);
        setSearchData({
          ...searchData,
          ["brand_id"]: selected.value,
        });
        handleTableData(category, sub_category, selected.value);
        break;
      default:
        break;
    }
  };
  const handleValidSubmitProduct = (event, values) => {
    if (selectedCategory == null) {
      setinvalidcategory("block");
      $("#product_category").attr(
        "class",
        "select2-selection border border-danger"
      );
      toTop();
    } else if (selectedsubCategory == null) {
      setinvalidsubcategory("block");
      $("#product_sub_category").attr(
        "class",
        "select2-selection border border-danger"
      );
      toTop();
    } else if (selectedthirdCategory == null) {
      setinvalidthirdcategory("block");
      $("#product_third_category").attr(
        "class",
        "select2-selection border border-danger"
      );
      toTop();
    } else if (selectedBrands == null) {
      setinvalidbrand("block");
      $("#product_brand").attr(
        "class",
        "select2-selection border border-danger"
      );
      toTop();
    } else {
      var url = `${API_URL}api/check_token`;
      axios.post(url, { token: accessToken }).then((res) => {
        if (res && res.data) {
          if (res && res.data.message === "Valid Token") {
            let valid =true;
            let invalidProductDetail = {}
            validProductName&&validProductName.forEach(element => {
              console.log('element: ', element);
              if(element&&element.success === false) {
                valid = false;
                invalidProductDetail = element;
              }
            });
            console.log('valid:--------------> ', valid);
            if (valid === false) {
              toastr.error(
                invalidProductDetail.data.product_name_english +
                  " " +
                  "already" +
                  " " +
                  "exists"
              );
              return;
            }
            else {
              productIdTobeUpdated
              ? dispatch(updateProduct(productUpdateObject))
              : dispatch(addProduct(productObject));
            formRef.current.reset();
            setProductObject({ ["auth_userid"]: userId });
            setProductUpdateObject({ ["auth_userid"]: userId });
            //setTimeout(function(){ window.location.reload(); }, 3000);
            setproductsOptionsGroup([]);
            setthirdcategoryOptionsGroup([]);
            setValues([
              {
                product_name_english: "",
                product_name_malayalam: "",
                unit: "",
                quantity: "",
                product_codes: "",
                product_sku: "",
                Barcodes: "",
                cost: "",
                mrp: "",
                web: "",
                Android: "",
                Ios: "",
                initial_stock: "",
                barcode: "",
                product_mrp: "",
                product_cost: "",
                product_web_price: "",
                product_and_price: "",
                product_ios_price: "",

                //product_name_english: "",
                // product_name_malayalam: "",
                // unit: "",
                // quantity: "",
                // product_code: "",
                // Barcode: "",
                // cost: "",
                // mrp: "",
                // web: "",
                // Android: "",
                // Ios: "",
                // initial_stock: "",
              },
            ]);
            setproduct_is_diffrent_code(0);
            setmonday_availible(0);
            settuesday_availible(0);
            setwednesday_availible(0);
            setthursday_availible(0);
            setfriday_availible(0);
            setsaturday_availible(0);
            setsunday_availible(0);
            setproduct_type(0);
            settopselling(0);
            setgst_slab_id(null);
            setSelectedCategory(null);
            setsubSelectedCategory(null);
            setthirdSelectedCategory(null);
            setSelectedBrands(null);
            setSelectedCategoryForSearch(null);
            setSelectedSubCategoryForSearch(null);
            setSelectedBrandForSearch(null);
            window.location.reload();
            }
          } else {
            toastr.error(res.data.message);
            return;
          }
        }
      });
    }
  };
  const handleCheckboxChange = (e) => {
    let name = e.target.name;
    let value = e.target.checked === true ? 1 : 0;
    setProductObject({
      ...productObject,
      [name]: value,
    });
    setProductUpdateObject((productUpdateObject) => {
      return {
        ...productUpdateObject,
        [name]: value,
      };
    });
  };
  const updateCheckbox = (e) => {
    let name = e.target.name;
    let value = e.target.checked === true ? 0 : 1;
    setProductObject({
      ...productObject,
      [name]: value,
    });
    setProductUpdateObject((productUpdateObject) => {
      return {
        ...productUpdateObject,
        [name]: value,
      };
    });
  };
  const handleUpdateImages = (event, values) => {
    axios
      .post(`${API_URL}product/change_images`, productImageObject, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.response == "success") {
          toastr.success("Images Updated Successfully");
          closeFileModal();
          handleTableData();
          //dispatch(getProducts());
        } else {
          toastr.error("Something went wrong");
        }
      })
      .catch((err) => {
        toastr.error("Something went wrong");
      });
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteProduct(productIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Products" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitProduct(e, v);
                    }}
                    encType="multipart/form-data"
                  >
                    <Row>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Category</Label>
                          <AvField
                            name="createdBy"
                            value={userId}
                            type="hidden"
                            className="form-control"
                            errorMessage="Enter Description Short"
                            validate={{ required: { value: false } }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />
                          <Select
                            name="product_category"
                            id="product_category"
                            required
                            value={selectedCategory}
                            required
                            validate={{ required: { value: true } }}
                            errorMessage="Choose Product Category"
                            onChange={(value) => {
                              handleSelectedCategories(value);
                            }}
                            options={categoriesOptionsGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                        <div
                          style={{
                            display: invalidcategory,
                            Width: "100%",
                            marginTop: "0.25rem",
                            color: "#f46a6a",
                            fontSize: "87.5%",
                          }}
                        >
                          Select Category
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Sub Category</Label>
                          <AvField
                            name="sub_category"
                            value={userId}
                            type="hidden"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            errorMessage="Choose SubCategory"
                            id="subcategory"
                            options={productsOptionsGroup}
                            onChange={handleChangeInput}
                          />
                          <Select
                            name="product_sub_category"
                            id="product_sub_category"
                            onChange={(value) => {
                              handleSelectedSubCategories(value);
                            }}
                            value={selectedsubCategory}
                            options={productsOptionsGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                        <div
                          style={{
                            display: invalidsubcategory,
                            Width: "100%",
                            marginTop: "0.25rem",
                            color: "#f46a6a",
                            fontSize: "87.5%",
                          }}
                        >
                          Select Sub Category
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="mb-3">
                          <Label>3rd Category</Label>
                          <AvField
                            name="third_category"
                            value={userId}
                            type="hidden"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />
                          <Select
                            name="product_third_category"
                            id="product_third_category"
                            onChange={(value) => {
                              handleSelectedthirdCategories(value);
                            }}
                            options={thirdcategoryptionsGroup}
                            classNamePrefix="select2-selection"
                            value={selectedthirdCategory}
                          />
                        </div>
                        <div
                          style={{
                            display: invalidthirdcategory,
                            Width: "100%",
                            marginTop: "0.25rem",
                            color: "#f46a6a",
                            fontSize: "87.5%",
                          }}
                        >
                          Select Third Category
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Brands</Label>
                          <AvField
                            name="brands"
                            value={userId}
                            type="hidden"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />
                          <Select
                            name="product_brand"
                            id="product_brand"
                            value={selectedBrands}
                            onChange={(value) => {
                              handlebrands(value);
                            }}
                            options={brandGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                        <div
                          style={{
                            display: invalidbrand,
                            Width: "100%",
                            marginTop: "0.25rem",
                            color: "#f46a6a",
                            fontSize: "87.5%",
                          }}
                        >
                          Select Brand
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Description Short
                          </Label>

                          <AvField
                            name="description_short"
                            value={product_description_short}
                            placeholder="Description Short"
                            type="text"
                            errorMessage="Enter Description Short"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Description
                          </Label>

                          <AvField
                            name="description"
                            value=""
                            value={product_description}
                            placeholder="Description "
                            type="text"
                            errorMessage="Enter Description"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Best Before Days
                          </Label>

                          <AvField
                            name="best_before_days"
                            value={productObject?.best_before_days}
                            placeholder="Best Before Days "
                            type="text"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            errorMessage="Enter Best Before Days"
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">GST Slab</Label>
                          <Select
                            value={gst_slab_id}
                            name="gst_slab_id"
                            classNamePrefix="select2-selection"
                            onChange={setgstslab}
                            options={gstGroup}
                          />
                        </div>
                      </Col>

                      <Col md="1">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">MAX.OQ </Label>
                          <AvField
                            name="max_order_quantity"
                            value={maxoq}
                            placeholder="Max.Order Quantity"
                            type="text"
                            errorMessage="Enter Max.Order Quantity"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="1">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Min.OQ</Label>
                          <AvField
                            placeholder="MIn.Order Quantity"
                            name="min_order_quantity"
                            value={productObject?.min_order_quantity}
                            type="text"
                            validate={{ required: { value: true } }}
                            errorMessage="Enter Min.Order Quantity"
                            className="form-control"
                            id="validationCustom03"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">HSN Code</Label>
                          <AvField
                            // placeholder="HSN"
                            name="hsn_code"
                            value={productObject?.hsn_code}
                            type="text"
                            // validate={{ required: { value: true } }}
                            errorMessage="Enter HSN Code"
                            className="form-control"
                            id="validationCustom03"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="1">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Reorder</Label>
                          <AvField
                            name="reorder_point"
                            type="text"
                            value={reorder}
                            validate={{ required: { value: true } }}
                            errorMessage="Reorder Point"
                            className="form-control"
                            id="validationCustom03"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="1">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Init.Stock</Label>
                          <AvField
                            value={productObject?.initial_stock}
                            name="initial_stock"
                            type="text"
                            validate={{ required: { value: true } }}
                            errorMessage=""
                            className="form-control"
                            id="validationCustom03"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Product Keywords
                          </Label>
                          <AvField
                            name="product_keywords"
                            value={productObject?.product_keywords}
                            placeholder="Product Keywords"
                            type="textarea"
                            errorMessage="Enter Product Keywords"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="validationCustom03"
                            onChange={handleChangeInput}
                            rows="1"
                          />
                        </div>
                      </Col>
                      <Col md="1">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Non Veg</Label>
                          <input
                            style={{
                              width: "47px",
                              height: "23px",
                              margintop: "7px",
                            }}
                            //defaultChecked={product_type}
                            name="product_type"
                            id="product_type"
                            type="checkbox"
                            checked={
                              productObject?.product_type === 1 ? true : false
                            }
                            onChange={handleCheckboxChange}
                          ></input>
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Top Selling
                          </Label>

                          <input
                            style={{
                              width: "85px",
                              height: "23px",
                              marginleft: "-3px",
                            }}
                            //defaultChecked={topselling}
                            name="top_selling_products"
                            id="premium_product"
                            type="checkbox"
                            checked={
                              productObject?.top_selling_products === 1
                                ? true
                                : false
                            }
                            onChange={handleCheckboxChange}
                          ></input>
                        </div>
                      </Col>

                      <Col md="2" style={{ display: showFileField }}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Title Image JPG (1200x1200)
                          </Label>
                          <AvField
                            name="product_title"
                            value={productObject.product_seo_keys}
                            placeholder="Product Title"
                            type="file"
                            errorMessage="Add Title image"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="validationCustom03"
                            onChange={uploadImage_title}
                            rows="1"
                          />
                          {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              {" "}
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )}
                        </div>
                      </Col>

                      <Col md="2" style={{ display: showFileField }}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Sub Image1 JPG (1200x1200)
                          </Label>
                          <AvField
                            name="product_image1"
                            value={productObject.product_seo_keys}
                            placeholder="Product Image 1"
                            type="file"
                            errorMessage="Add Product Image 1"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="validationCustom03"
                            onChange={uploadImage1}
                            rows="1"
                          />
                        </div>
                      </Col>

                      <Col md="2" style={{ display: showFileField }}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Sub Image2 JPG (1200x1200)
                          </Label>
                          <AvField
                            name="product_image2"
                            value={productObject.product_seo_keys}
                            placeholder="Product Image 2"
                            type="file"
                            // errorMessage="Add Product Image 2"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="validationCustom03"
                            onChange={uploadImage2}
                            rows="1"
                          />
                        </div>
                      </Col>
                      <Col md="2" style={{ display: showFileField }}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Sub Image3 JPG (1200x1200)
                          </Label>
                          <AvField
                            name="product_image3"
                            value={productObject.product_seo_keys}
                            placeholder="Product Image 3"
                            type="file"
                            //errorMessage="Add Product Image 3"
                            // validate={{ required: { value: true } }}
                            className="form-control"
                            id="validationCustom03"
                            onChange={uploadImage3}
                            rows="1"
                          />
                        </div>
                      </Col>
                      <Col md="2" style={{ display: showFileField }}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Sub Image 4 JPG (1200x1200)
                          </Label>
                          <AvField
                            name="product_image4"
                            value={productObject.product_seo_keys}
                            placeholder="Product Image 4"
                            type="file"
                            //errorMessage="Add Product Image 4"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="validationCustom03"
                            onChange={uploadImage4}
                            rows="1"
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Video URL</Label>
                          <AvField
                            name="video_link"
                            value={productObject.product_seo_keys}
                            placeholder="Product Video"
                            type="text"
                            value={video_link}
                            errorMessage="Add Video Link"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="validationCustom03"
                            onChange={handleChangeInput}
                            rows="1"
                          />
                        </div>
                      </Col>
                      <Col md="8">
                        <Label htmlFor="validationCustom03">
                          Product Availability
                        </Label>

                        <div
                          className="mb-3"
                          style={{ width: "50%", height: "90px" }}
                        >
                          <div class="weekDays-selector">
                            <input
                              //defaultChecked={sunday_availible}
                              name="product_timing_sunday"
                              type="checkbox"
                              id="weekday-sun"
                              checked={
                                productObject?.product_timing_sunday === 0
                                  ? true
                                  : false
                              }
                              onChange={updateCheckbox}
                              class="weekday"
                            />
                            <label for="weekday-sun">S</label>
                            <input
                              //defaultChecked={monday_availible}
                              name="product_timing_monday"
                              type="checkbox"
                              id="weekday-mon"
                              checked={
                                productObject?.product_timing_monday === 0
                                  ? true
                                  : false
                              }
                              onChange={updateCheckbox}
                              class="weekday"
                            />
                            <label for="weekday-mon">M</label>
                            <input
                              name="product_timing_tuesday"
                              //defaultChecked={tuesday_availible}
                              type="checkbox"
                              id="weekday-tue"
                              checked={
                                productObject?.product_timing_tuesday === 0
                                  ? true
                                  : false
                              }
                              onChange={updateCheckbox}
                              class="weekday"
                            />
                            <label for="weekday-tue">T</label>
                            <input
                              name="product_timing_wednesday"
                              //defaultChecked={wednesday_availible}
                              type="checkbox"
                              id="weekday-wed"
                              checked={
                                productObject?.product_timing_wednesday === 0
                                  ? true
                                  : false
                              }
                              onChange={updateCheckbox}
                              class="weekday"
                            />
                            <label for="weekday-wed">W</label>
                            <input
                              name="product_timing_thursday"
                              //defaultChecked={thursday_availible}
                              type="checkbox"
                              id="weekday-thu"
                              checked={
                                productObject?.product_timing_thursday === 0
                                  ? true
                                  : false
                              }
                              onChange={updateCheckbox}
                              class="weekday"
                            />
                            <label for="weekday-thu">T</label>
                            <input
                              name="product_timing_friday"
                              //defaultChecked={friday_availible}
                              type="checkbox"
                              id="weekday-fri"
                              checked={
                                productObject?.product_timing_friday === 0
                                  ? true
                                  : false
                              }
                              onChange={updateCheckbox}
                              class="weekday"
                            />
                            <label for="weekday-fri">F</label>
                            <input
                              name="product_timing_saturday"
                              //defaultChecked={saturday_availible}
                              type="checkbox"
                              id="weekday-sat"
                              checked={
                                productObject?.product_timing_saturday === 0
                                  ? true
                                  : false
                              }
                              onChange={updateCheckbox}
                              class="weekday"
                            />
                            <label for="weekday-sat">S</label>
                          </div>
                          {/* <table
                            id="product_available_day"
                            className="table table-bordered dataTable"
                          >
                            <thead style={{ textAlign: "center" }}>
                              <tr>
                                <th>Sun</th>
                                <th>Mon</th>
                                <th>Tue</th>
                                <th>Wed </th>
                                <th>Thu</th>
                                <th>Fri </th>
                                <th>Sat</th>
                              </tr>
                              <tr>
                                <th>
                                  <input
                                    name="product_timing_sunday"
                                    value="1"
                                    type="checkbox"
                                    onChange={handleChangeInput}
                                  ></input>
                                </th>
                                <th>
                                  <input
                                    name="product_timing_monday"
                                    value="1"
                                    type="checkbox"
                                    onChange={handleChangeInput}
                                  ></input>
                                </th>
                                <th>
                                  <input
                                    name="product_timing_tuesday"
                                    value="1"
                                    type="checkbox"
                                    onChange={handleChangeInput}
                                  ></input>
                                </th>
                                <th>
                                  <input
                                    name="product_timing_wednesday"
                                    value="1"
                                    type="checkbox"
                                    onChange={handleChangeInput}
                                  ></input>{" "}
                                </th>
                                <th>
                                  <input
                                    name="product_timing_thursday"
                                    value="1"
                                    type="checkbox"
                                    onChange={handleChangeInput}
                                  ></input>
                                </th>
                                <th>
                                  <input
                                    name="product_timing_friday"
                                    value="1"
                                    type="checkbox"
                                    onChange={handleChangeInput}
                                  ></input>{" "}
                                </th>
                                <th>
                                  <input
                                    name="product_timing_saturday"
                                    value="1"
                                    type="checkbox"
                                    onChange={handleChangeInput}
                                  ></input>
                                </th>
                              </tr>
                            </thead>
                          </table> */}
                        </div>
                      </Col>

                      <Col style={{ height: "30px" }} md="12">
                        {/* <Label htmlFor="validationCustom03">
                          Diffrent Sku Code
                        </Label> */}
                        <input
                          style={{ width: "30px", height: "20px" }}
                          name="sku_type"
                          defaultChecked={product_is_diffrent_code}
                          value="1"
                          type="checkbox"
                          onChange={handleChangeInput}
                        ></input>{" "}
                        <b>
                          {" "}
                          <span>Diffrent Sku Code </span>
                        </b>
                      </Col>

                      <Table
                        style={{ textAlign: "center" }}
                        id="product_tale_id"
                        className="table table-bordered dataTable"
                      >
                        <TableHead>
                          <TableRow style={{ textAlign: "center" }}>
                            <TableCell
                              style={{ width: "10px", textAlign: "center" }}
                            >
                              #
                            </TableCell>
                            <TableCell
                              style={{ width: "250px", textAlign: "center" }}
                            >
                              Display Name
                            </TableCell>
                            <TableCell
                              style={{ width: "80px", textAlign: "center" }}
                            >
                              Quantity
                            </TableCell>
                            <TableCell
                              style={{ width: "170px", textAlign: "center" }}
                            >
                              Code
                            </TableCell>
                            <TableCell
                              style={{ width: "90px", textAlign: "center" }}
                            >
                              <i class="fas fa-rupee-sign"></i>
                            </TableCell>
                            <TableCell
                              style={{ width: "90px", textAlign: "center" }}
                            >
                              <i class="fas fa-rupee-sign"></i>
                            </TableCell>
                            <TableCell
                              style={{ width: "90px", textAlign: "center" }}
                            >
                              <i class="fas fa-rupee-sign"></i>
                            </TableCell>
                            <TableCell
                              style={{ width: "10px", textAlign: "center" }}
                            ></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {values &&
                            values.map((item, index) => (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  {index + 1}
                                </TableCell>

                                <AvField
                                  onChange={(e) =>
                                    handleChangeInputproduct(e, index)
                                  }
                                  validate={{
                                    required: {
                                      value: true,
                                    },
                                  }}
                                  style={{ width: "280px" }}
                                  name="{index}"
                                  type="text"
                                  value={item.product_name_english}
                                  placeholder="English"
                                  errorMessage="Enter product name"
                                  className="form-control"
                                  name="product_name_english"
                                  id={`product_name_en_${index}`}
                                />
                                <AvField
                                  onChange={(e) =>
                                    handleChangeInputproduct(e, index)
                                  }
                                  validate={{ required: { value: true } }}
                                  style={{ width: "280px" }}
                                  name="product_name_malayalam"
                                  value={item.product_name_malayalam}
                                  type="text"
                                  placeholder="Malayalam"
                                  errorMessage="Enter product name"
                                  className="form-control"
                                  id={`validationCustom03${index}`}
                                />
                                <TableCell>
                                  <input
                                    onChange={(e) =>
                                      handleChangeInputproduct(e, index)
                                    }
                                    validate={{ required: { value: true } }}
                                    style={{
                                      width: "70px",
                                      marginBottom: "10px",
                                    }}
                                    placeholder="Qty"
                                    value={item.quantity}
                                    name="quantity"
                                    type="number"
                                    errorMessage="Quantity"
                                    className="form-control"
                                    id={`validationCustom03${index}`}
                                  />
                                  <Select
                                    onChange={(e) =>
                                      handleChangeInputproductunit(e, index)
                                    }
                                    name="product_unit"
                                    validate={{ required: { value: true } }}
                                    style={{ width: "70px" }}
                                    classNamePrefix="select2-selection"
                                    options={unitGroup}
                                    value={{
                                      label: item.product_unit_name,
                                      value: item.product_unit_id,
                                    }}
                                    errorMessage="Choose Unit"
                                    id={`validationCustom03${index}`}
                                  />
                                </TableCell>
                                <TableCell>
                                  <AvField
                                    onChange={(e) =>
                                      handleChangeInputproduct(e, index)
                                    }
                                    // validate={{ required: { value: true } }}
                                    style={{
                                      width: "180px",
                                      marginBottom: "10px",
                                    }}
                                    name="product_code"
                                    type="text"
                                    value={item.product_sku}
                                    placeholder="Code"
                                    // errorMessage="Enter Product Code"
                                    className="form-control"
                                    id={`validationCustom03${index}`}
                                  />
                                  <AvField
                                    onChange={(e) =>
                                      handleChangeInputproduct(e, index)
                                    }
                                    // validate={{ required: { value: true } }}
                                    style={{ width: "180px" }}
                                    name="Barcode"
                                    value={item.barcode}
                                    type="text"
                                    placeholder="Barcode"
                                    // errorMessage="Enter Barcode"
                                    className="form-control"
                                    id={`validationCustom03${index}`}
                                  />
                                </TableCell>

                                <TableCell>
                                  <AvField
                                    onChange={(e) =>
                                      handleChangeInputproduct(e, index)
                                    }
                                    validate={{
                                      required: { value: true },
                                      min: { value: 1, errorMessage: "MRP" },
                                    }}
                                    style={{
                                      width: "80px",
                                      marginBottom: "10px",
                                    }}
                                    value={item.product_mrp}
                                    placeholder="Mrp"
                                    name="mrp"
                                    type="text"
                                    errorMessage="MRP"
                                    className="form-control"
                                    id={`validationCustom03${index}`}
                                  />
                                  <AvField
                                    onChange={(e) =>
                                      handleChangeInputproduct(e, index)
                                    }
                                    validate={{
                                      required: { value: true },
                                      min: { value: 1, errorMessage: "Cost" },
                                    }}
                                    style={{ width: "80px", marginBottom: "" }}
                                    placeholder="Cost"
                                    value={item.product_cost}
                                    name="cost"
                                    type="text"
                                    errorMessage="Cost"
                                    className="form-control"
                                    id={`validationCustom03${index}`}
                                  />
                                </TableCell>
                                <TableCell>
                                  <AvField
                                    onChange={(e) =>
                                      handleChangeInputproduct(e, index)
                                    }
                                    validate={{
                                      required: { value: true },
                                      min: { value: 1, errorMessage: "Web" },
                                    }}
                                    style={{
                                      width: "80px",
                                      marginBottom: "10px",
                                    }}
                                    value={item.product_web_price}
                                    placeholder="Web"
                                    name="web"
                                    type="text"
                                    errorMessage="Web Price"
                                    className="form-control"
                                    id={`validationCustom03${index}`}
                                  />
                                  <AvField
                                    onChange={(e) =>
                                      handleChangeInputproduct(e, index)
                                    }
                                    validate={{
                                      required: { value: true },
                                      min: {
                                        value: 1,
                                        errorMessage: "Android",
                                      },
                                    }}
                                    style={{
                                      width: "80px",
                                      marginBottom: "0px",
                                    }}
                                    value={item.product_and_price}
                                    placeholder="Android"
                                    name="Android"
                                    type="text"
                                    errorMessage="Android Price"
                                    className="form-control"
                                    id={`validationCustom03${index}`}
                                  />
                                </TableCell>
                                <TableCell>
                                  <AvField
                                    onChange={(e) =>
                                      handleChangeInputproduct(e, index)
                                    }
                                    validate={{
                                      required: { value: false },
                                      min: { value: 1, errorMessage: "Ios" },
                                    }}
                                    style={{
                                      width: "80px",
                                      marginBottom: "10px",
                                    }}
                                    value={item.product_ios_price}
                                    placeholder="Ios"
                                    name="Ios"
                                    type="text"
                                    errorMessage="IOS Price"
                                    className="form-control"
                                    id={`validationCustom03${index}`}
                                  />
                                </TableCell>
                                <TableCell>
                                  {values.length !== 1 && (
                                    <button
                                      type="button"
                                      className="removeBtn"
                                      onClick={() => handleRemove(index)}
                                    >
                                      <i className="fas fa-times"></i>
                                    </button>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>

                      <span
                        onClick={() => addMore()}
                        style={{ width: "190px", cursor: "pointer" }}
                      >
                        {" "}
                        + Add New Row{" "}
                      </span>
                    </Row>
                    <Row>
                      <Col md="3">
                        <div className="mt-4">
                          {productIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={updatingProduct ? true : false}
                            >
                              {updatingProduct ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingProduct ? true : false}
                            >
                              {addingProduct ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>

                  {/* <i onClick={() => addMore()}>test</i> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Category</Label>
                        <Select
                          name="category_id"
                          value={selectedCategoryForSearch}
                          onChange={(selected) => {
                            handleSelectChange(selected, "category");
                          }}
                          options={categoriesOptionsGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Sub-Category</Label>
                        <Select
                          name="sub_category_id"
                          value={selectedSubCategoryForSearch}
                          onChange={(selected) => {
                            handleSelectChange(selected, "subcategory");
                          }}
                          options={subCategoryOptionsForSearch}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Brand</Label>
                        <Select
                          name="brand_id"
                          value={selectedBrandForSearch}
                          onChange={(selected) => {
                            handleSelectChange(selected, "brand");
                          }}
                          options={brandGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                    entries={25}
                    // info={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {/* #######################Product View Modal############################### */}

      <Modal
        style={{
          maxWidth: "800px",
        }}
        isOpen={showModal}
        toggle={() => {
          closeModal();
        }}
      >
        <div style={{ maxWidth: "800px", paddingTop: "0px" }}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Product Details </h5>
            <button
              type="button"
              onClick={() => {
                closeModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
            <div className="modal-body">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <td style={{ width: "160px" }}>Name(English) :</td>
                  <td style={{ width: "220px" }}>
                    {single_product_details.product_name_english}
                  </td>
                  <td style={{ width: "160px" }}>Name(Malayalam) :</td>
                  <td style={{ width: "220px" }}>
                    {single_product_details.product_name_malayalam}
                  </td>
                </tr>
                <tr>
                  <td>Category :</td>
                  <td>{single_product_details.category_name}</td>
                  <td>Subcategory :</td>
                  <td>{single_product_details.subCategory}</td>
                </tr>
                <tr>
                  <td>Third Category :</td>
                  <td>{single_product_details.thrdcate_name_english}</td>
                  <td>Brand :</td>
                  <td>{single_product_details.brand}</td>
                </tr>
                <tr>
                  <td>Description Short :</td>
                  <td>{single_product_details.product_description_short}</td>
                  <td>Description :</td>
                  <td>{single_product_details.product_description}</td>
                </tr>
                <tr>
                  <td>Best Before Day :</td>
                  <td>10</td>
                  <td>GST Slab :</td>
                  <td>{single_product_details.tax}</td>
                </tr>
                <tr>
                  <td>Max. O Q:</td>
                  <td>{single_product_details.max_order_quantity}</td>
                  <td>Min. O Q :</td>
                  <td>{single_product_details.min_order_quantity}</td>
                </tr>

                <tr>
                  <td>Reorder Point :</td>
                  <td>{single_product_details.reorder_point}</td>
                  <td>Initial Stock :</td>
                  <td>{single_product_details.initial_stock}</td>
                </tr>
                <tr>
                  <td>Type:</td>
                  <td>{single_product_details.product_type}</td>
                  <td>Top Selling :</td>
                  <td>{single_product_details.min_order_quantity}</td>
                </tr>
                <tr>
                  <td>SKU:</td>
                  <td>{single_product_details.product_sku_autogen}</td>
                  <td> Barcode :</td>
                  <td>{single_product_details.barcode}</td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <b>Product Images</b>
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <table>
                      <tr>
                        <th style={{ width: "190px" }}>
                          <img
                            style={{ width: "150px", height: "150px" }}
                            src={`${API_URL}public/uploads/product_images/product_image_300x300/${single_product_details.product_image_main}`}
                          ></img>
                        </th>
                        <th style={{ width: "190px" }}>
                          <img
                            style={{ width: "150px", height: "150px" }}
                            src={`${API_URL}public/uploads/product_images/product_image_300x300/${single_product_details.product_image_subone}`}
                          ></img>
                        </th>
                        <th style={{ width: "190px" }}>
                          <img
                            style={{ width: "150px", height: "150px" }}
                            src={`${API_URL}public/uploads/product_images/product_image_300x300/${single_product_details.product_image_subtwo}`}
                          ></img>
                        </th>
                        <th style={{ width: "190px" }}>
                          <img
                            style={{ width: "150px", height: "150px" }}
                            src={`${API_URL}public/uploads/product_images/product_image_300x300/${single_product_details.product_image_subthree}`}
                          ></img>
                        </th>
                        <th style={{ width: "190px" }}>
                          <img
                            style={{ width: "150px", height: "150px" }}
                            src={`${API_URL}public/uploads/product_images/product_image_300x300/${single_product_details.product_image_subfour}`}
                          ></img>
                        </th>
                      </tr>
                      <tr>
                        {" "}
                        <th colSpan={5}> Product Availability </th>
                      </tr>
                      <tr>
                        <th colSpan={5}>
                          <div class="weekDays-selector">
                            <input
                              type="checkbox"
                              id="weekday-mon"
                              defaultChecked={
                                single_product_details.product_timing_sunday
                              }
                              class="weekday"
                            />
                            <label for="weekday-mon">S</label>
                            <input
                              type="checkbox"
                              id="weekday-tue"
                              defaultChecked={
                                single_product_details.product_timing_monday
                              }
                              class="weekday"
                            />
                            <label for="weekday-tue">M</label>
                            <input
                              type="checkbox"
                              id="weekday-wed"
                              defaultChecked={
                                single_product_details.product_timing_tuesday
                              }
                              class="weekday"
                            />
                            <label for="weekday-wed">T</label>
                            <input
                              type="checkbox"
                              id="weekday-thu"
                              defaultChecked={
                                single_product_details.product_timing_wednesday
                              }
                              class="weekday"
                            />
                            <label for="weekday-thu">W</label>
                            <input
                              type="checkbox"
                              id="weekday-fri"
                              defaultChecked={
                                single_product_details.product_timing_thursday
                              }
                              class="weekday"
                            />
                            <label for="weekday-fri">T</label>
                            <input
                              type="checkbox"
                              id="weekday-sat"
                              defaultChecked={
                                single_product_details.product_timing_friday
                              }
                              class="weekday"
                            />
                            <label for="weekday-sat">F</label>
                            <input
                              type="checkbox"
                              id="weekday-sun"
                              defaultChecked={
                                single_product_details.product_timing_saturday
                              }
                              class="weekday"
                            />
                            <label for="weekday-sun">S</label>
                          </div>
                        </th>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </AvForm>
        </div>
      </Modal>
      <Modal
        style={{
          maxWidth: "800px",
        }}
        isOpen={FileModal}
        toggle={() => {
          closeFileModal();
        }}
      >
        <div style={{ maxWidth: "800px", paddingTop: "0px" }}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Edit Images </h5>
            <button
              type="button"
              onClick={() => {
                closeFileModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <AvForm
            className="needs-validation"
            onValidSubmit={(e, v) => {
              handleUpdateImages(e, v);
            }}
          >
            <div className="modal-body">
              <Row>
                <Col md="3" style={{ display: showFileField }}>
                  <div className="mb-3">
                    <Label htmlFor="validationCustom03">Title Image</Label>
                    <AvField
                      name="product_title"
                      value={productObject.product_seo_keys}
                      placeholder="Product Title"
                      type="file"
                      errorMessage="Add Title image"
                      className="form-control"
                      validate={{ required: { value: false } }}
                      id="validationCustom03"
                      onChange={uploadImage_title_forEdit}
                      rows="1"
                    />
                    {uploadProgress && uploadProgress < 100 && (
                      <div className="mt-4">
                        {" "}
                        <Progress color="primary" value={uploadProgress}>
                          Uploading {uploadProgress}%
                        </Progress>
                      </div>
                    )}
                  </div>
                </Col>

                <Col md="3" style={{ display: showFileField }}>
                  <div className="mb-3">
                    <Label htmlFor="validationCustom03">Sub Image1</Label>
                    <AvField
                      name="product_image1"
                      value={productObject.product_seo_keys}
                      placeholder="Product Image 1"
                      type="file"
                      errorMessage="Add Product Image 1"
                      className="form-control"
                      validate={{ required: { value: false } }}
                      id="validationCustom03"
                      onChange={uploadImage1_forEdit}
                      rows="1"
                    />
                  </div>
                </Col>

                <Col md="3" style={{ display: showFileField }}>
                  <div className="mb-3">
                    <Label htmlFor="validationCustom03">Sub Image2</Label>
                    <AvField
                      name="product_image2"
                      value={productObject.product_seo_keys}
                      placeholder="Product Image 2"
                      type="file"
                      // errorMessage="Add Product Image 2"
                      className="form-control"
                      // validate={{ required: { value: true } }}
                      id="validationCustom03"
                      onChange={uploadImage2_forEdit}
                      rows="1"
                    />
                  </div>
                </Col>
                <Col md="3" style={{ display: showFileField }}>
                  <div className="mb-3">
                    <Label htmlFor="validationCustom03">Sub Image3</Label>
                    <AvField
                      name="product_image3"
                      value={productObject.product_seo_keys}
                      placeholder="Product Image 3"
                      type="file"
                      //errorMessage="Add Product Image 3"
                      // validate={{ required: { value: true } }}
                      className="form-control"
                      id="validationCustom03"
                      onChange={uploadImage3_forEdit}
                      rows="1"
                    />
                  </div>
                </Col>
                <Col md="3" style={{ display: showFileField }}>
                  <div className="mb-3">
                    <Label htmlFor="validationCustom03">Sub Image 4</Label>
                    <AvField
                      name="product_image4"
                      value={productObject.product_seo_keys}
                      placeholder="Product Image 4"
                      type="file"
                      //errorMessage="Add Product Image 4"
                      className="form-control"
                      // validate={{ required: { value: true } }}
                      id="validationCustom03"
                      onChange={uploadImage4_forEdit}
                      rows="1"
                    />
                  </div>
                </Col>
                <Col md="3">
                  <div className="mt-4">
                    <Button color="primary" type="submit">
                      Update
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </AvForm>
        </div>
      </Modal>

      {/* ################################################################################## */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(Products));

Products.propTypes = {
  error: PropTypes.any,
  products: PropTypes.array,
};
