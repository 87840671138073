import React, { useState, useEffect,useRef } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import jwt_decode from 'jwt-decode';

import SweetAlert from 'react-bootstrap-sweetalert';
import { MDBDataTable } from 'mdbreact';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Modal,
} from 'reactstrap';

import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import {
  getUsers,
  addUser,
  deleteUser,
  updateUser,
  getPrivilagesOptions,
} from '../../store/actions';

// Redux

import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import './user.scss';

const Users = (props) => {
  const [userObject, setUserObject] = useState({});
  const [userTemp, setUserTemp] = useState([]);

  const [userIdTobeUpdated, setUserIdToBeUpdated] = useState(null);
  const [userIdToBeDeleted, setUserIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);

  const [userId, setUserId] = useState(1);
  const [selectedPrivilage, setSelectedPrivilage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [passwordObject, setPasswordObject] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });

  const {
    users,
    addingUser,
    addUserResponse,
    deleteUserResponse,
    updateUserResponse,
    error,
  } = useSelector((state) => state.Users);

  const privilagesOptions = useSelector(
    (state) => state.privilages.privilagesOptions
  );

  const dispatch = useDispatch();
  const formRef = useRef();
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };


  useEffect(() => {
    dispatch(getUsers());
    dispatch(getPrivilagesOptions());
  }, [dispatch]);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = jwt_decode(localStorage.getItem('authUser'));
      console.log(obj);

      setUserId(obj.user);
      setUserObject({ ['auth_userid']: obj.user });
    }
  }, [userId]);

  useEffect(() => {
    if (addUserResponse.type === 'success') {
      toastr.success(addUserResponse.message);
    } else if (addUserResponse.type === 'failure') {
      toastr.error(error.data.message, addUserResponse.message);
    }
  }, [addUserResponse]);

  useEffect(() => {
    if (deleteUserResponse.type === 'success') {
      toastr.success(deleteUserResponse.message);
    } else if (deleteUserResponse.type === 'failure') {
      toastr.error(error.data.message, deleteUserResponse.message);
    }
  }, [deleteUserResponse]);

  useEffect(() => {
    if (updateUserResponse.type === 'success') {
      setUserIdToBeUpdated(null);
      setUserIdToBeUpdated(null);
      toastr.success(updateUserResponse.message);
    } else if (updateUserResponse.type === 'failure') {
      toastr.error(error.data.message, updateUserResponse.message);
    }
  }, [updateUserResponse]);

  let preUpdateUser = (item) => {
    if (item.Privilege) {
      let privilege = {
        label: item.Privilege.privilege_name,
        value: item.Privilege.id,
      };
      handleSelectedPrivilage(privilege);
    }

    setUserIdToBeUpdated(item.id);

    setUserObject({ ...item, password: null });
  };
  let preUpdateUserPassword = (item) => {
    setUserIdToBeUpdated(item.id);
    setShowModal(true);
  };

  useEffect(() => {
    let userArray = JSON.parse(JSON.stringify(users));

    let userData = [];
    userArray.map((item, index) => {
      if (item.Privilege) {
        item.serial_id = (index+1);
        item.name1 = `${item.fname} ${item.lname}`;
        item.auth_userid = userId;
        item.privilage1 = item.Privilege.privilege_name;

        item.action = (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <i
              className="fas fa-key"
              style={{ fontSize: '1em', cursor: 'pointer' }}
              onClick={() => {
                ///////password update
                preUpdateUserPassword(item);
              }}
            >
              {' '}
            </i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                toTop();
                preUpdateUser(item);
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: '1em', cursor: 'pointer' }}
              onClick={() => {
                setUserIdToBeDeleted(item.id);
                setConfirmDeleteAlert(true);
              }}
            ></i>
          </div>
        );
        // item.id = index + 1;

        userData.push(item);
      }
    });
    setUserTemp(userData);
  }, [users, userId]);

  const data = {
    columns: [
      {
        label: '#',
        field: 'serial_id',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Name',
        field: 'name1',
        sort: 'asc',
        width: 270,
      },
      {
        label: 'User name',
        field: 'username',
        sort: 'asc',
        width: 270,
      },

      {
        label: 'Email',
        field: 'user_email',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'Mobile',
        field: 'mobile',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Privilege',
        field: 'privilage1',
        sort: 'asc',
        width: 150,
      },

      {
        label: 'Action',
        field: 'action',
        width: 100,
      },
    ],

    rows: userTemp,
  };

  let privilagesOptionsData =
    privilagesOptions &&
    privilagesOptions.map((item) => {
      return {
        label: item.privilege_name,
        value: item.id,
      };
    });

  const privilagesOptionsGroup = [
    {
      options: privilagesOptionsData,
    },
  ];

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setUserObject({
      ...userObject,
      [name]: value,
    });
  };

  function handleSelectedPrivilage(value) {
    console.log('value: ', value);
    let newValue = {
      name: value.label,
      _id: value.value,
    };

    setSelectedPrivilage(value);
    console.log(newValue);
    setUserObject({ ...userObject, newValue });
  }

  const handleValidSubmit = (event, values) => {
    userIdTobeUpdated
      ? dispatch(updateUser(userObject))
      : dispatch(addUser(userObject));

      formRef.current.reset();
      setSelectedPrivilage(null);
  };

  function handleChangePassword(e) {
    let name = e.target.name;
    let value = e.target.value;
    setPasswordObject({ ...passwordObject, [name]: value });
  }

  const handleValidSubmitPassword = (event, values) => {
    if (passwordObject.password === passwordObject.confirmPassword) {
      let item = {
        id: userIdTobeUpdated,
        password: passwordObject.password,
      };
      dispatch(updateUser(item));
    } else {
      toastr.error('Passwords are not matching');
    }
  };
  let closeModal = () => {
    setShowModal(false);
    setUserIdToBeUpdated(null);
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteUser(userIdToBeDeleted));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <Modal
        isOpen={showModal}
        toggle={() => {
          closeModal();
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Confirmation</h5>
          <button
            type="button"
            onClick={() => {
              closeModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => {
            handleValidSubmitPassword(e, v);
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Password</Label>
                  <AvField
                    name="password"
                    placeholder="Password"
                    type="password"
                    errorMessage=" Please provide a valid password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={passwordObject.password}
                    onChange={handleChangePassword}
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Confirm Password</Label>
                  <AvField
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    errorMessage=" Please confirm the password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={passwordObject.confirmPassword}
                    onChange={handleChangePassword}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-dark"
              style={{ marginRight: '1rem' }}
              onClick={closeModal}
            >
              Close
            </button>
            <button className="btn btn-primary" type="submit">
              Confirm
            </button>
          </div>
        </AvForm>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Users" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">First name</Label>
                          <AvField
                            name="fname"
                            placeholder="First name"
                            type="text"
                            errorMessage="Enter First Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={userObject.fname}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Last name</Label>
                          <AvField
                            name="lname"
                            placeholder="Last name"
                            type="text"
                            errorMessage="Enter Last name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={userObject.lname}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Email</Label>
                          <AvField
                            name="user_email"
                            placeholder="Email"
                            type="email"
                            errorMessage="Enter valid Email"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            value={userObject.user_email}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Username</Label>
                          <AvField
                            name="username"
                            placeholder="Username"
                            type="text"
                            errorMessage="Enter valid Username"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            value={userObject.username}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">Mobile</Label>
                          <AvField
                            name="mobile"
                            placeholder="Mobile"
                            type="text"
                            errorMessage="Please provide a valid mobile."
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom04"
                            value={userObject.mobile}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label>Privilege</Label>
                          <Select
                            name="previlage"
                            value={selectedPrivilage}
                            onChange={(value) => {
                              handleSelectedPrivilage(value);
                            }}
                            options={privilagesOptionsGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      {userIdTobeUpdated ? null : (
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Password</Label>
                            <AvField
                              name="password"
                              placeholder="Password"
                              type="password"
                              errorMessage=" Please provide a valid password"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom05"
                              value={userObject.password}
                              onChange={handleChangeInput}
                            />
                          </div>
                        </Col>
                      )}
                      {/* <AvField
                        name="createdBy"
                        value={userId}
                        type="hidden"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        onChange={handleChangeInput}
                      /> */}
                    </Row>

                    {userIdTobeUpdated ? (
                      <Button
                        color="primary"
                        type="submit"
                        disabled={addingUser ? true : false}
                      >
                        {addingUser ? 'Updating' : 'Update'}
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        type="submit"
                        disabled={addingUser ? true : false}
                      >
                        {addingUser ? 'Adding' : 'Submit'}
                      </Button>
                    )}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  // const { error } = state.Users;
  // return { error };
};
export default Users;
