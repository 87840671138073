import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import $ from "jquery";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { formatMoney } from "../../../helpers/generalFunctions";
import { CSVLink } from "react-csv";
import { getDate } from "../../../helpers/globalFunctions";
import "./categoryWise.scss";
const PurchaseCategoryWise = (props) => {
  const [purchaseCategoryWiseList, setPurchaseCategoryWiseList] = useState([]);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    handleTableData();
  }, []);
  const handleTableData = (from_date = "", to_date = "") => {
    let url = `${API_URL}report/purchase_category_wise`;
    if (from_date != "" && to_date != "") {
      url = url + "?from_date=" + from_date + "&to_date=" + to_date;
    } else if (from_date != "") {
      url = url + "?from_date=" + from_date;
    } else if (to_date != "") {
      url = url + "?to_date=" + to_date;
    }
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let dataToBeExported = [];
        result.map((item, index) => {
          let exportItem = {};

          item.id = index + 1;
          item.total_amount = formatMoney(item.total_amount);

          //setting export data
          exportItem.Id = item.id;
          exportItem.Name = item.category_name_english;
          exportItem.Qty = item.total_quantity;
          exportItem.Amount = item.total_amount;

          dataToBeExported.push(exportItem);
        });
        setPurchaseCategoryWiseList(result);
        setDataToBeExported(dataToBeExported);
      });
  };

  let handleDate = () => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    handleTableData(date1, date2);
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "category_name_english",
        sort: "asc",
        width: 400,
      },
      {
        label: "Qty",
        field: "total_quantity",
        sort: "asc",
        width: 400,
      },
      {
        label: "Amount",
        field: "total_amount",
        sort: "asc",
        width: 400,
      },
    ],
    rows: purchaseCategoryWiseList,
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Home"
            breadcrumbItem="Report - Purchase Category Wise"
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mt-4" style={{ paddingTop: "7px" }}>
                        <Button color="success" type="submit">
                          <CSVLink
                            data={dataToBeExported}
                            filename={"Cococa_purchase_category_report_"+getDate(new Date())+".xls"}
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="purchaseCategoryWise"
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={25}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PurchaseCategoryWise;
