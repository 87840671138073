export const GET_THIRDCATEGORIES = "GET_THIRDCATEGORY";
export const GET_THIRDCATEGORIES_SUCCESS = "GET_THIRDCATEGORIES_SUCCESS";
export const GET_THIRDCATEGORIES_FAIL = "GET_THIRDCATEGORIES_FAIL";

export const ADD_THIRDCATEGORY = "ADD THIRDCATEGORY";
export const ADD_THIRDCATEGORY_SUCCESS = "ADD_THIRDCATEGORY_SUCCESS";
export const ADD_THIRDCATEGORY_FAIL = "ADD_THIRDCATEGORY_FAIL";

export const UPDATE_THIRDCATEGORY = "UPDATE_THIRDCATEGORY";
export const UPDATE_THIRDCATEGORY_SUCCESS = "UPDATE_THIRDCATEGORY_SUCCESS";
export const UPDATE_THIRDCATEGORY_FAIL = "UPDATE_THIRDCATEGORY_FAIL";

export const DELETE_THIRDCATEGORY = "DELETE_THIRDCATEGORY";
export const DELETE_THIRDCATEGORY_SUCCESS = "DELETE_THIRDCATEGORY_SUCCESS";
export const DELETE_THIRDCATEGORY_FAIL = "DELETE_THIRDCATEGORY_FAIL";

export const CHANGE_STATUS_THIRDCATEGORY = "CHANGE_STATUS_THIRDCATEGORY";
export const CHANGE_STATUS_THIRDCATEGORY_SUCCESS =
  "CHANGE_STATUS_THIRDCATEGORY_SUCCESS";
export const CHANGE_STATUS_THIRDCATEGORY_FAIL =
  "CHANGE_STATUS_THIRDCATEGORY_FAIL";

export const GET_THIRDCATEGORY_OPTIONS = "GET_THIRDCATEGORY_OPTIONS";
export const GET_THIRDCATEGORY_OPTIONS_SUCCESS =
  "GET_THIRDCATEGORY_OPTIONS_SUCCESS";
export const GET_THIRDCATEGORY_OPTIONS_FAIL = "GET_THIRDCATEGORY_OPTIONS_FAIL";
