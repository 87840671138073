export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAIL = 'GET_ORDERS_FAIL';

export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAIL = 'GET_ORDER_FAIL';

export const ADD_ORDER = 'ADD_ORDER';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_FAIL = 'ADD_ORDER_FAIL';

export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL';

export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAIL = 'DELETE_ORDER_FAIL';

export const DELIVER_ORDER = 'DELIVER_ORDER';
export const DELIVER_ORDER_SUCCESS = 'DELIVER_ORDER_SUCCESS';
export const DELIVER_ORDER_FAIL = 'DELIVER_ORDER_FAIL';

export const OUTOF_DELIVER_ORDER = 'OUTOF_DELIVER_ORDER';
export const OUTOF_DELIVER_ORDER_SUCCESS = 'OUTOF_DELIVER_ORDER_SUCCESS';
export const OUTOF_DELIVER_ORDER_FAIL = 'OUTOF_DELIVER_ORDER_FAIL';

export const CANCEL_DELIVER_ORDER = 'CANCEL_DELIVER_ORDER';
export const CANCEL_DELIVER_ORDER_SUCCESS = 'CANCEL_DELIVER_ORDER_SUCCESS';
export const CANCEL_DELIVER_ORDER_FAIL = 'CANCEL_DELIVER_ORDER_FAIL';
