export const GET_SPOT_PRICE = 'GET_SPOT_PRICE';
export const GET_SPOT_PRICE_SUCCESS = 'GET_SPOT_PRICE_SUCCESS';
export const GET_SPOT_PRICE_FAIL = 'GET_SPOT_PRICE_FAIL'; 

export const ADD_SPOT_PRICE = 'ADD_SPOT_PRICE';
export const ADD_SPOT_PRICE_SUCCESS = 'ADD_SPOT_PRICE_SUCCESS';
export const ADD_SPOT_PRICE_FAIL = 'ADD_SPOT_PRICE_FAIL';

export const ADD_MULTIPLE_SPOT_PRICE = "ADD_MULTIPLE_SPOT_PRICE";
export const ADD_MULTIPLE_SPOT_PRICE_SUCCESS = 'ADD_MULTIPLE_SPOT_PRICE_SUCCESS';
export const ADD_MULTIPLE_SPOT_PRICE_FAIL = 'ADD_MULTIPLE_SPOT_PRICE_FAIL';