import {
    GET_FUND_TRANSFER,
    GET_FUND_TRANSFER_SUCCESS,
    GET_FUND_TRANSFER_FAIL,
    ADD_FUND_TRANSFER,
    ADD_FUND_TRANSFER_SUCCESS,
    ADD_FUND_TRANSFER_FAIL,
    GET_FUND_SOURCE,
    GET_FUND_SOURCE_SUCCESS,
    GET_FUND_SOURCE_FAIL
} from "./actionTypes";

const INIT_STATE = {
    fundTransfers: [],
    addingFundTransfer: false,
    addFundTransferResponse: {},
    fundTransfer: {},
    error: {},
    fundSource: []
};

const fundTransfers = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FUND_TRANSFER:
            return {
                ...state,
                params: action.payload,
            };

        case GET_FUND_TRANSFER_SUCCESS:
            return {
                ...state,
                fundTransfers: action.payload.data,
            };

        case GET_FUND_TRANSFER_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case ADD_FUND_TRANSFER:
            return {
                ...state,
                addingFundTransfer: true,
                fundTransfer: action.payload,
            };

        case ADD_FUND_TRANSFER_SUCCESS:
            let newFundTransfer = [JSON.parse(JSON.stringify(action.payload.data[0]))];
            newFundTransfer.push(...state.fundTransfers);
            return {
                ...state,
                addingFundTransfer: false,
                addFundTransferResponse: {
                    type: 'success',
                    message: 'Fund transfer added successfully',
                },
                fundTransfers: newFundTransfer,
                error: {},
            };

        case ADD_FUND_TRANSFER_FAIL:
            return {
                ...state,
                addingFundTransfer: false,
                addFundTransferResponse: { type: 'failure', message: 'Adding fund transfer failed' },
                error: action.payload,
            };
        case GET_FUND_SOURCE:
            return {
                ...state,
                params: action.payload,
            };

        case GET_FUND_SOURCE_SUCCESS:
            return {
                ...state,
                fundSource: action.payload.data,
            };

        case GET_FUND_SOURCE_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default fundTransfers;