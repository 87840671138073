import { takeEvery, put, call, takeLatest } from "redux-saga/effects";
import {
    GET_FUND_TRANSFER,
    ADD_FUND_TRANSFER,
    GET_FUND_SOURCE
} from "./actionTypes";
import {
    getAllFundTransferSuccess,
    getAllFundTransferFail,
    addFundTransferSuccess,
    addFundTransferFail,
    getAllFundSourceSuccess,
    getAllFundSourceFail
} from "./actions";
import {
    getAllFundTransfer,
    addFundTransfer,
    getAllFundSource
} from "../../helpers/backend_helper";

function* fetchFundTransfer() {
    try {
        const response = yield call(getAllFundTransfer);
        yield put(getAllFundTransferSuccess(response));
    } catch (error) {
        yield put(getAllFundTransferFail(error));
    }
}
function* fetchFundSource() {
    try {
        const response = yield call(getAllFundSource);
        yield put(getAllFundSourceSuccess(response));
    } catch (error) {
        yield put(getAllFundSourceFail(error));
    }
}
function* onAddFundTransfer({ payload: data }) {
    try {
        const response = yield call(addFundTransfer, data);
        yield put(addFundTransferSuccess(response));
    } catch (error) {
        yield put(addFundTransferFail(error.response));
    }
}
function* fundTransferSaga() {
    yield takeEvery(GET_FUND_TRANSFER, fetchFundTransfer);
    yield takeEvery(ADD_FUND_TRANSFER, onAddFundTransfer);
    yield takeEvery(GET_FUND_SOURCE,fetchFundSource);
}

export default fundTransferSaga;