export const GET_INCOME_EXPENSE_ACC_TYPE = 'GET_INCOME_EXPENSE_ACC_TYPE';
export const GET_INCOME_EXPENSE_ACC_TYPE_SUCCESS = 'GET_INCOME_EXPENSE_ACC_TYPE_SUCCESS';
export const GET_INCOME_EXPENSE_ACC_TYPE_FAIL = 'GET_INCOME_EXPENSE_ACC_TYPE_FAIL';

export const GET_INCOME_EXPENSE = 'GET_INCOME_EXPENSE';
export const GET_INCOME_EXPENSE_SUCCESS = 'GET_INCOME_EXPENSE_SUCCESS';
export const GET_INCOME_EXPENSE_FAIL = 'GET_INCOME_EXPENSE_FAIL'; 

export const ADD_INCOME_EXPENSE = 'ADD_INCOME_EXPENSE';
export const ADD_INCOME_EXPENSE_SUCCESS = 'ADD_INCOME_EXPENSE_SUCCESS';
export const ADD_INCOME_EXPENSE_FAIL = 'ADD_INCOME_EXPENSE_FAIL';