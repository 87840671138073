import { takeEvery, put, call, takeLatest } from "redux-saga/effects";
import {
    GET_CREDIT_DEBIT,
    ADD_CREDIT_DEBIT
} from "./actionTypes";
import {
    getAllCreditDebitSuccess,
    getAllCreditDebitFail,
    addCreditDebitSuccess,
    addCreditDebitFail
} from "./actions";
import {
    getAllCreditDebit,
    addCreditDebit
} from "../../helpers/backend_helper";

function* fetchCreditDebit() {
    try {
        const response = yield call(getAllCreditDebit);
        yield put(getAllCreditDebitSuccess(response));
    } catch (error) {
        yield put(getAllCreditDebitFail(error));
    }
}
function* onAddCreditDebit({ payload: data }) {
    try {
        const response = yield call(addCreditDebit, data);
        yield put(addCreditDebitSuccess(response));
    } catch (error) {
        yield put(addCreditDebitFail(error.response));
    }
}
function* creditDebitSaga() {
    yield takeEvery(GET_CREDIT_DEBIT, fetchCreditDebit);
    yield takeEvery(ADD_CREDIT_DEBIT, onAddCreditDebit);
}

export default creditDebitSaga;