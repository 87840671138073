export const GET_TAXES = 'GET_TAXES';
export const GET_TAXES_SUCCESS = 'GET_TAXES_SUCCESS';
export const GET_TAXES_FAIL = 'GET_TAXES_FAIL';

export const GET_TAX = 'GET_TAX';
export const GET_TAX_SUCCESS = 'GET_TAX_SUCCESS';
export const GET_TAX_FAIL = 'GET_TAX_FAIL';

export const ADD_TAX = 'ADD_TAX';
export const ADD_TAX_SUCCESS = 'ADD_TAX_SUCCESS';
export const ADD_TAX_FAIL = 'ADD_TAX_FAIL';

export const UPDATE_TAX = 'UPDATE_TAX';
export const UPDATE_TAX_SUCCESS = 'UPDATE_TAX_SUCCESS';
export const UPDATE_TAX_FAIL = 'UPDATE_TAX_FAIL';

export const DELETE_TAX = 'DELETE_TAX';
export const DELETE_TAX_SUCCESS = 'DELETE_TAX_SUCCESS';
export const DELETE_TAX_FAIL = 'DELETE_TAX_FAIL';
