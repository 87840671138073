import {
  GET_SPOT_PRICE,
  GET_SPOT_PRICE_SUCCESS,
  GET_SPOT_PRICE_FAIL,
  ADD_SPOT_PRICE,
  ADD_SPOT_PRICE_SUCCESS,
  ADD_SPOT_PRICE_FAIL,
  ADD_MULTIPLE_SPOT_PRICE,
  ADD_MULTIPLE_SPOT_PRICE_SUCCESS,
  ADD_MULTIPLE_SPOT_PRICE_FAIL,
} from "./actionTypes";

export const getAllSpotPrice = () => ({
  type: GET_SPOT_PRICE,
});

export const getAllSpotPriceSuccess = (data) => ({
  type: GET_SPOT_PRICE_SUCCESS,
  payload: data,
});

export const getAllSpotPriceFail = (error) => ({
  type: GET_SPOT_PRICE_FAIL,
  payload: error,
});

export const addSpotPrice = (data) => ({
  type: ADD_SPOT_PRICE,
  payload: data,
});

export const addSpotPriceSuccess = (data) => ({
  type: ADD_SPOT_PRICE_SUCCESS,
  payload: data,
});

export const addSpotPriceFail = (error) => ({
  type: ADD_SPOT_PRICE_FAIL,
  payload: error,
});

export const addMultipleSpotPrice = (data) => ({
  type:ADD_MULTIPLE_SPOT_PRICE,
  payload:data
});

export const addMultipleSpotPriceSuccess = (data) => ({
  type: ADD_MULTIPLE_SPOT_PRICE_SUCCESS,
  payload: data,
});

export const addMultipleSpotPriceFail = (error) => ({
  type: ADD_MULTIPLE_SPOT_PRICE_FAIL,
  payload: error,
});