import React, { useState, useEffect, useRef } from 'react';
import toastr from 'toastr';
import jwt_decode from 'jwt-decode';

import SweetAlert from 'react-bootstrap-sweetalert';
import { MDBDataTable } from 'mdbreact';
import { Row, Col, Card, CardBody, Button, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAreas,
  addArea,
  deleteArea,
  updateArea,
} from '../../../store/actions';

import { AvForm, AvField } from 'availity-reactstrap-validation';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import './area.scss';

const Areas = (props) => {
  const [areaObject, setAreaObject] = useState({});
  const [areasTemp, setAreasTemp] = useState([]);

  const [areaIdTobeUpdated, setAreaIdToBeUpdated] = useState(null);
  const [areaIdToBeDeleted, setAreaIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);

  const [userId, setUserId] = useState(1);
  const formRef = useRef();

  const {
    areas,
    addingArea,
    addAreaResponse,
    deleteAreaResponse,
    updateAreaResponse,
    error,
  } = useSelector((state) => state.areas);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAreas());
  }, []);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = jwt_decode(localStorage.getItem('authUser'));
      console.log(obj);

      setUserId(obj.user);
      setAreaObject({ ['auth_userid']: userId });
    }
  }, [userId]);

  useEffect(() => {
    if (addAreaResponse.type === 'success') {
      toastr.success(addAreaResponse.message);
    } else if (addAreaResponse.type === 'failure') {
      toastr.error(error.data.message, addAreaResponse.message);
    }
  }, [addAreaResponse]);

  useEffect(() => {
    if (deleteAreaResponse.type === 'success') {
      toastr.success(deleteAreaResponse.message);
    } else if (deleteAreaResponse.type === 'failure') {
      toastr.error(error.data.message, deleteAreaResponse.message);
    }
  }, [deleteAreaResponse]);

  useEffect(() => {
    if (updateAreaResponse.type === 'success') {
      setAreaIdToBeUpdated(null);
      toastr.success(updateAreaResponse.message);
    } else if (updateAreaResponse.type === 'failure') {
      toastr.error(error.data.message, updateAreaResponse.message);
    }
  }, [updateAreaResponse]);

  let preUpdateArea = (item) => {
    setAreaIdToBeUpdated(item.area_id);
    setAreaObject(item);
  };

  useEffect(() => {
    let areasDuplicate = JSON.parse(JSON.stringify(areas));
    let areaData = [];
    areasDuplicate.map((item, index) => {
      item.fname = item.User.fname + ' ' + item.User.lname;
      item.auth_userid = userId;
      item.action = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <i
            className="far fa-edit"
            style={{
              fontSize: '1.3em',
              cursor: 'pointer',
              marginLeft: '1rem',
              marginRight: '1rem',
            }}
            onClick={() => {
              preUpdateArea(item);
            }}
          ></i>
          <i
            className="far fa-trash-alt"
            style={{ fontSize: '1.3em', cursor: 'pointer' }}
            onClick={() => {
              setAreaIdToBeDeleted(item.area_id);
              setConfirmDeleteAlert(true);
            }}
          ></i>
        </div>
      );
      item.id = index + 1;

      areaData.push(item);
    });
    setAreasTemp(areaData);
  }, [areas]);

  const data = {
    columns: [
      {
        label: '#',
        field: 'id',
        sort: 'asc',
        width: 10,
      },

      {
        label: 'Name',
        field: 'area_name',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Short Code',
        field: 'area_short_code',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Latitude',
        field: 'area_latitude',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Longitude',
        field: 'area_longitude',
        sort: 'asc',
        width: 70,
      },

      {
        label: 'Added by',
        field: 'fname',
        sort: 'asc',
        width: 100,
      },

      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 100,
      },
    ],
    rows: areasTemp,
  };

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setAreaObject({
      ...areaObject,
      [name]: value,
    });
  };
  const handleValidSubmitArea = (event, values) => {
    areaIdTobeUpdated
      ? dispatch(updateArea(areaObject))
      : dispatch(addArea(areaObject));

    formRef.current.reset();
    setAreaObject({ ['auth_userid']: userId });
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteArea(areaIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Areas" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitArea(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Name
                          </Label>

                          <AvField
                            name="area_name"
                            value={areaObject.area_name}
                            placeholder="Area Name"
                            type="text"
                            errorMessage="Enter Area Name"
                            className="form-control"
                            validate={{ required: { value: true },
                            pattern: {value: '^[A-Za-z ]+$', errorMessage: 'Area must be only with letter and spaces'}
                          }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Short Code</Label>
                          <AvField
                            name="area_short_code"
                            value={areaObject.area_short_code}
                            placeholder="Enter Short Code"
                            type="text"
                            errorMessage="Enter Short Code"
                            className="form-control"
                            validate={{ required: { value: true }
                            }}
                            id="validationCustom02"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Latitude
                          </Label>
                          <AvField
                            name="area_latitude"
                            value={areaObject.area_latitude}
                            placeholder="Enter Area Latitude"
                            type="text"
                            errorMessage="Enter Area Latitude"
                            className="form-control"
                            validate={{ required: { value: true },
                            pattern: {value: '^[0-9.]+$', errorMessage: 'Invalid Latitude'} }}
                            id="validationCustom03"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Longitude
                          </Label>
                          <AvField
                            name="area_longitude"
                            value={areaObject.area_longitude}
                            placeholder="Enter Area Longitude"
                            type="text"
                            errorMessage="Enter Area Longitude"
                            className="form-control"
                            validate={{ required: { value: true },
                            pattern: {value: '^[0-9.]+$', errorMessage: 'Invalid Longitude'} }}
                            id="validationCustom04"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div >
                          {areaIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingArea ? true : false}
                            >
                              {addingArea ? 'Updating' : 'Update'}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingArea ? true : false}
                            >
                              {addingArea ? 'Adding' : 'Submit'}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Areas;
