import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  getUsers,
  addUser,
  deleteUser,
  apiError,
  getPrivilagesOptions,
  getCompaniesOptions,
  getBranchesOptions,
  updateUser,
  //getPrivilagesOptions,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import "./user.scss";

const SalesDailyWise = (props) => {
  const [usersForTable, setUsersForTable] = useState([]);
  const [accountType, setAccountType] = useState("");

  const data = {
    columns: [
      {
        label: "Date",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Number of Sales",
        field: "district",
        sort: "asc",
        width: 400,
      },
      {
        label: "Number of Cash Payments",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Number of Online Payment",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Number of Card Payment",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Number of COD payment",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Cash Amount",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "COD Amount",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Online Payment Amount",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Card Amount",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total Amount",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
    ],
    rows: usersForTable,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Sales Daily Wise Report" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(
  connect(mapStateToProps, { apiError })(SalesDailyWise)
);
