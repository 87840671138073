import {
  GET_COMMUNITIES,
  GET_COMMUNITIES_FAIL,
  GET_COMMUNITIES_SUCCESS,
  ADD_COMMUNITY,
  ADD_COMMUNITY_FAIL,
  ADD_COMMUNITY_SUCCESS,
  GET_COMMUNITY,
  GET_COMMUNITY_FAIL,
  GET_COMMUNITY_SUCCESS,
  UPDATE_COMMUNITY,
  UPDATE_COMMUNITY_FAIL,
  UPDATE_COMMUNITY_SUCCESS,
  DELETE_COMMUNITY,
  DELETE_COMMUNITY_FAIL,
  DELETE_COMMUNITY_SUCCESS,
} from './actionTypes';

export const getCommunities = () => ({
  type: GET_COMMUNITIES,
});

export const getCommunitiesSuccess = (community) => ({
  type: GET_COMMUNITIES_SUCCESS,
  payload: community,
});

export const getCommunitiesFail = (error) => ({
  type: GET_COMMUNITIES_FAIL,
  payload: error,
});

export const addCommunity = (community) => ({
  type: ADD_COMMUNITY,
  payload: community,
});

export const addCommunitySuccess = (community) => ({
  type: ADD_COMMUNITY_SUCCESS,
  payload: community,
});

export const addCommunityFail = (error) => ({
  type: ADD_COMMUNITY_FAIL,
  payload: error,
});

export const getCommunity = (community) => ({
  type: GET_COMMUNITY,
  payload: community,
});

export const getCommunitySuccess = (community) => ({
  type: GET_COMMUNITY_SUCCESS,
  payload: community,
});

export const getCommunityFail = (error) => ({
  type: GET_COMMUNITY_FAIL,
  payload: error,
});

export const updateCommunity = (community) => ({
  type: UPDATE_COMMUNITY,
  payload: community,
});

export const updateCommunitySuccess = (community) => ({
  type: UPDATE_COMMUNITY_SUCCESS,
  payload: community,
});

export const updateCommunityFail = (error) => ({
  type: UPDATE_COMMUNITY_FAIL,
  payload: error,
});

export const deleteCommunity = (community, auth_user) => ({
  type: DELETE_COMMUNITY,
  payload: community,
  auth_data: auth_user,
});

export const deleteCommunitySuccess = (community) => ({
  type: DELETE_COMMUNITY_SUCCESS,
  payload: community,
});

export const deleteCommunityFail = (error) => ({
  type: DELETE_COMMUNITY_FAIL,
  payload: error,
});
