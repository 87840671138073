/* USERS */
export const GET_BRANDS = 'GET_BRANDS';
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
export const GET_BRANDS_FAIL = 'GET_BRANDS_FAIL';

export const GET_BRAND = 'GET_BRAND';
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS';
export const GET_BRAND_FAIL = 'GET_BRAND_FAIL';

export const ADD_BRAND = 'ADD_BRAND'; 
export const ADD_BRAND_SUCCESS = 'ADD_BRAND_SUCCESS';
export const ADD_BRAND_FAIL = 'ADD_BRAND_FAIL';

export const UPDATE_BRAND = 'UPDATE_BRAND';
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_FAIL = 'UPDATE_BRAND_FAIL';

export const DELETE_BRAND = 'DELETE_BRAND';
export const DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS';
export const DELETE_BRAND_FAIL = 'DELETE_BRAND_FAIL';

export const CHANGE_STATUS_BRAND = 'CHANGE_STATUS_BRAND';
export const CHANGE_STATUS_BRAND_SUCCESS = 'CHANGE_STATUS_BRAND_SUCCESS';
export const CHANGE_STATUS_BRAND_FAIL = 'CHANGE_STATUS_BRAND_FAIL';

export const GET_BRANDS_OPTIONS = 'GET_BRANDS_OPTIONS';
export const GET_BRANDS_OPTIONS_SUCCESS = 'GET_BRANDS_OPTIONS_SUCCESS';
export const GET_BRANDS_OPTIONS_FAIL = 'GET_BRANDS_OPTIONS_FAIL';

