import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { formatMoney } from "../../../helpers/generalFunctions";
import { getDate } from "../../../helpers/globalFunctions";
import { CSVLink } from "react-csv";
import { getArraySumByKey } from "../../../helpers/globalFunctions";
import "./salesAll.scss";

const TopCustomers = (props) => {
  const [reportForTable, setreportForTable] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [grandTotal, setGrandTotal] = useState(null);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    handleTableData();
    fetch_all_customer();
  }, []);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "invoice_date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 400,
      },
      {
        label: "Invoice ID",
        field: "inventory_unique_id",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "customer_name",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "State",
      //   field: "state_name",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "GSTIN",
      //   field: "cost",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Amount",
        field: "amount_sub",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "GST",
      //   field: "amount_gst",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "SGST",
        field: "sgst",
        sort: "asc",
        width: 200,
      },
      {
        label: "CGST",
        field: "cgst",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "CGST",
      //   field: "cgst",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "IGST",
      //   field: "igst",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Charge",
        field: "amount_charge",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total",
        field: "amount_tot",
        sort: "asc",
        width: 200,
      },
    ],
    rows: reportForTable,
  };
  const fetch_all_customer = () => {
    var url = `${API_URL}customer/options`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var customer_data =
          res.data.data &&
          res.data.data.map((value) => {
            return {
              label: value.customer_name,
              value: value.customer_id,
            };
          });

        setCustomerOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };
  function handleTableData(from_date = "", to_date = "", customer_id = "") {
    var url = `${API_URL}report/sales_all`;

    url =
      url +
      "?from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&customer_id=" +
      customer_id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let reportData = [];
        let dataToBeExported = [];

        result.map((item, index) => {
          let exportItem = {};

          item.id = index + 1;
          item.invoice_date = moment(item.invoice_date).format("DD/MM/YYYY");
          item.time = moment(item.invoice_time, "HHmmss").format("hh:mm a");

          if (item.state_id != 19) {
            item.sgst = "";
            item.cgst = "";
            item.igst = formatMoney(item.invoice_gst_amount);
          } else {
            item.sgst = formatMoney(item.invoice_gst_amount / 2);
            item.cgst = formatMoney(item.invoice_gst_amount / 2);
            item.igst = "";
          }
          // item.invoice_net_amount = item.invoice_net_amount.toFixed(2);
          // item.invoice_shipping_charge = item.invoice_shipping_charge.toFixed(2);
          // item.invoice_grand_total = item.invoice_grand_total.toFixed(2);

          item.amount_gst = formatMoney(item.invoice_gst_amount);
          item.amount_sub = formatMoney(item.invoice_net_amount);
          item.amount_tot = formatMoney(item.invoice_grand_total);
          item.amount_charge = formatMoney(item.invoice_shipping_charge);
          // set export data
          exportItem.id = item.id;
          exportItem.Date = item.invoice_date;
          exportItem.Time = item.time;
          exportItem.InvoiceID = item.inventory_unique_id;
          exportItem.Name = item.customer_name;
          exportItem.Amount = item.amount_sub;
          // exportItem.Gst = item.amount_gst;
          exportItem.SGST = item.sgst;
          exportItem.CGST = item.cgst;
          exportItem.Charge = item.amount_charge;
          exportItem.Total = item.amount_tot;

          reportData.push(item);
          dataToBeExported.push(exportItem);
        });
        let grand_total = getArraySumByKey(reportData, "invoice_grand_total");
        setreportForTable(reportData);
        setDataToBeExported(dataToBeExported);
        setGrandTotal(grand_total);
      });
  }

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    let customer_id = searchData?.customer_id ? searchData.customer_id : "";
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value,
    });
    handleTableData(date1, date2, customer_id);
  };
  const handleSelectedCustomer = (selected) => {
    setSelectedCustomer(selected);
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    setSearchData({
      ...searchData,
      ["customer_id"]: selected.value,
    });
    handleTableData(date1, date2, selected.value);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Report - Sales All" />
          <Row>
            <Col className="col-12">
              <Row className="text-right">
                <h5>Grand Total : {formatMoney(grandTotal)}</h5>
              </Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Customer</Label>
                        <Select
                          name="customer_id"
                          value={selectedCustomer}
                          onChange={(selected) => {
                            handleSelectedCustomer(selected);
                          }}
                          options={customerOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mt-4" style={{ paddingTop: "7px" }}>
                        <Button color="success" type="submit">
                          {/* <i className="mdi mdi-microsoft-excel"></i> */}
                          <CSVLink
                            data={dataToBeExported}
                            filename={
                              "Cococa_sales_report_" +
                              getDate(new Date()) +
                              ".xls"
                            }
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="reportSalesAll"
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={25}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, {})(TopCustomers));
