import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from 'moment';
import $ from 'jquery';
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CSVLink } from "react-csv";
import { getDate } from "../../../helpers/globalFunctions";
// import "./user.scss";

const TopCustomers = (props) => {
  const [reportForTable, setreportForTable] = useState([]);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    handleTableData();
  }, []);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Sku Code",
        field: "product_sku_autogen",
        sort: "asc",
        width: 400,
      },

      {
        label: "Product Name",
        field: "product_name_english",
        sort: "asc",
        width: 200,
      },
      {
        label: "Product Name (Mal)",
        field: "product_name_malayalam",
        sort: "asc",
        width: 200,
      },
      {
        label: "Reorder Point",
        field: "reorder_point",
        sort: "asc",
        width: 200,
      },
      {
        label: "Stock ",
        field: "stock_item_stock",
        sort: "asc",
        width: 200,
      },
    ],
    rows: reportForTable,
  };

  function handleTableData(from_date=null,to_date=null){
    var url=`${API_URL}report/reorder_point`;
    if(from_date!=null && to_date!=null)
    {
      url=url+"?from_date="+from_date+"&to_date="+to_date;
    }
    else if(from_date!=null)
    {
      url=url+"?from_date="+from_date;
    }
    else if(to_date!=null)
    {
      url=url+"?to_date="+to_date;
    }
    axios.get(url,
    {
      headers: {
        'x-access-token': accessToken,
      },
    })
    .then((res) => {  
      var result = res.data.data;
      let reportData=[];
      let dataToBeExported = [];
      result.map((item, index) => {
        let exportItem = {};

        item.id = index + 1;
        item.product_sku_autogen="";
        item.product_name_english="";
        item.product_name_malayalam ="";
        item.reorder_point="";
        if(item.product!=null)
        {
          item.product_sku_autogen = item.product.product_sku_autogen;
          item.product_name_english = item.product.product_name_english;
          item.product_name_malayalam = item.product.product_name_malayalam;
          item.reorder_point = item.product.reorder_point;
        }

        //setting export data
        exportItem.Id = item.id;
        exportItem.Code = item.product_sku_autogen;
        exportItem.Product_Name = item.product_name_english;
        exportItem.Product_Name_Malayalam = item.product_name_malayalam;
        exportItem.Reorder_Point = item.reorder_point;
        exportItem.Stock = item.stock_item_stock;

        reportData.push(item);
        dataToBeExported.push(exportItem);
      });
      setreportForTable(reportData);
      setDataToBeExported(dataToBeExported);
    });
  }


  let handleDate=()=>{
    var date1=$("#from_date").val();
    var date2=$("#to_date").val();
    handleTableData(date1,date2);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Report - Reorder Point" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-3 mt-1">
                  <Col md="1">
                      <div>
                        <Button color="success" type="submit">
                          <CSVLink
                            data={dataToBeExported}
                            filename={"Cococa_reorder_report_"+getDate(new Date())+".xls"}
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
    
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, {  })(TopCustomers));
