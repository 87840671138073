import React from "react";
import { Redirect } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import User from "../pages/User/index";
import Unit from "../pages/Product/unit/index";
import Community from "../pages/Community/manage/index";
import Category from "../pages/Product/category/index";
import sub_Category from "../pages/Product/subcategory/index";
import third_category from "../pages/Product/thirdcategory/index";
import brands from "../pages/Product/brands/index";
import PushNotification from "../pages/PushNotification";
import BlockUnblockIP from "../pages/MasterSettings/BlockUnblockIP";
import BlockUnblockUser from "../pages/MasterSettings/BlockUnblockUser";
import UserLog from "../pages/MasterSettings/UserLog";

import Product from "../pages/Product/index";
import Communityrequest from "../pages/Community/communityrequest/index?v1";
// import Community from "../pages/Community/index";
import Banner from "../pages/Banner";
import Slider from "../pages/Slider";
import Taxes from "../pages/Manage/Tax/index";
import Company from "../pages/MasterSettings/Company";
import Privilage from "../pages/MasterSettings/Privilage";
import Rules from "../pages/MasterSettings/Rules";
import Stock from "../pages/Stock/managestock/index";
import Stocklog from "../pages/Stock/stocklogs/index";
import Orders from "../pages/Orders/index";
import ViewOrder from "../pages/Orders/ViewOrder";
import Customers from "../pages/Customer/addcustomer";
import Customer from "../pages/Customer";
import CommunitySchedule from "../pages/Community/schedule/index";
// import Deliverylist from '../pages/Reports/deliverylist/index';
import Deliverylist from "../pages/Orders/OrderDelivery/index";
import Harvestlist from "../pages/Reports/HarvestView/index";
import ReportDeliverylist from "../pages/Reports/deliverylist/index";
//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UiImageCropper from "../pages/Ui/ui-image-cropper";

// Forms
import BasicElements from "../pages/Forms/BasicElements";
import FormLayouts from "../pages/Forms/FormLayouts";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormRepeater from "../pages/Forms/FormRepeater";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import EChart from "../pages/Charts/EChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/charts-knob";

//Icons

import IconUnicons from "../pages/Icons/IconUnicons";
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";
import MapsLeaflet from "../pages/Maps/MapsLeaflet";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

//User
import ViewUser from "../pages/User/ViewUser";

//account
import AccountType from "../pages/Accounts/Account-Type";
import AccountDetails from "../pages/Accounts/AccountDetails";
import Chart from "../pages/Accounts/Chart";
import FundTransfer from "../pages/Accounts/FundTransfer";
import CreditDebit from "../pages/Accounts/CreditDebit";
import IncomeExpenses from "../pages/Accounts/IncomeExpenses";
import TransactionLog from "../pages/Accounts/TransactionLog";

//suppliers
import AddSuppliers from "../pages/Suppliers/AddSuppliers";

//purchase
import AddPurchase from "../pages/Purchase/AddPurchase";
import ViewPurchase from "../pages/Purchase/ViewPurchase";
import Report1 from "../pages/Purchase/Report1";

//Inventory
import ViewInvoice from "../pages/Inventory/ViewInvoice";

//manage
import OfferCodes from "../pages/Manage/OfferCodes";
import OfferBanner from "../pages/Manage/OfferBanner";
import OfferTypes from "../pages/Manage/OfferTypes";
import OfferProducts from "../pages/Manage/OfferProducts";
import ManageCategory from "../pages/Manage/ManageCategory";
import ManageFAQ from "../pages/Manage/ManageFAQ";

//Reports
import Sales from "../pages/Reports/Sales";
import SalesHourlyWiseReport from "../pages/Reports/SalesHourlyWiseReport";
import SalesDailyWise from "../pages/Reports/SalesDailyWise";
import SalesMonthlyWiseReport from "../pages/Reports/SalesMonthlyWiseReport";
import MostSearched from "../pages/Reports/MostSearched";
import SearchedNotFound from "../pages/Reports/SearchedNotFound";
import TopSellingProducts from "../pages/Reports/TopSellingProducts";
import TopSellingCategory from "../pages/Reports/TopSellingCategory";
import TopCustomer from "../pages/Reports/TopCustomer";
import StockReport from "../pages/Reports/stock";
import StockLogReport from "../pages/Reports/stockLog";
import SalesHSNWise from "../pages/Reports/salesHsnWise";
import GstInOut from "../pages/Reports/gstInOut";
import purchaseItemWise from "../pages/Reports/purchaseItemWise";
import PurchaseCategoryWise from "../pages/Reports/purchaseCategoryWise";
import DailySales from "../pages/Reports/dailySalesReport";
import NotifyMe from "../pages/Reports/notifyMe";
//Stocks
import ViewStock from "../pages/Stock/ViewStock";
import ViewStockLogs from "../pages/Stock/ViewStockLogs";

//Rules
// import Rules from "../pages/Rules";

//order
import CartReport from "../pages/Orders/CartReport";
import AddOrder from "../pages/Orders/AddOrder";
import ReturnOrder from "../pages/Orders/ReturnOrder";

//Manage Area
import Area from "../pages/Manage/Area";

import SpotPrice from "../pages/Dashboard/SpotPrice";
import PurchaseReturn from "../pages/Purchase/PurchaseReturn";
import Sitemap from "../pages/Dashboard/Sitemap";
import POS from "../pages/POS";
import DeliverySlot from "../pages/Orders/DeliverySlot";
import UrlShortner from "../pages/Dashboard/UrlShortner";
import ReOrderReport from "../pages/Orders/ReOrderReport";
import DeliveryBoyReport from "../pages/Orders/DeliveryBoyReport";
import SubArea from "../pages/Orders/SubArea";
import Settings from "../pages/Dashboard/Settings";
//import DirectInvoice from "../pages/Inventory/directInvoice";
const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/user", component: User },
  { path: "/community", component: Community },
  { path: "/communityrequest", component: Communityrequest },
  { path: "/stock", component: Stock },
  { path: "/stocklogs", component: Stocklog },
  { path: "/category", component: Category },
  { path: "/sub_category", component: sub_Category },
  { path: "/third_category", component: third_category },
  { path: "/brands", component: brands },
  { path: "/banner", component: Banner },
  { path: "/slider", component: Slider },
  { path: "/customer", component: Customer },

  { path: "/product", component: Product },
  { path: "/pushnotification", component: PushNotification },
  { path: "/orders", component: Dashboard },

  { path: "/product", component: Product },
  { path: "/view-order", component: ViewOrder },
  { path: "/website", component: Dashboard },
  { path: "/subscription", component: Dashboard },
  { path: "/report", component: Dashboard },
  { path: "/unit", component: Unit },
  { path: "/tax", component: Taxes },
  { path: "/company", component: Company },
  { path: "/privilage", component: Privilage },
  { path: "/rules", component: Rules },
  { path: "/blockedip", component: BlockUnblockIP },
  { path: "/blockeduser", component: BlockUnblockUser },
  { path: "/userlog", component: UserLog },
  { path: "/vieworders", component: Orders },
  { path: "/customer", component: Customer },
  { path: "/community/schedule", component: CommunitySchedule },
  { path: "/deliverylist", component: Deliverylist },
  { path: "/harvestlist", component: Harvestlist },
  { path: "/report/deliverylist", component: ReportDeliverylist },

  //Orders
  { path: "/cart-report", component: CartReport },
  { path: "/add-order", component: AddOrder },
  { path: "/add-order/:orderNo", component: AddOrder },
  { path: "/return-order", component: ReturnOrder },

  //User
  { path: "/user", component: User },
  { path: "/view-user", component: ViewUser },
  { path: "/user-log", component: UserLog },
  { path: "/block-unblock-user", component: BlockUnblockUser },
  { path: "/block-unblock-ip", component: BlockUnblockIP },

  //Suppliers
  { path: "/add-Supplies", component: AddSuppliers },

  //Purchase
  { path: "/add-purchase", component: AddPurchase },
  { path: "/view-purchase", component: ViewPurchase },
  { path: "/purchase-report", component: Report1 },

  //inventory
  { path: "/view-invoice", component: ViewInvoice },
  // { path: "/direct", component: DirectInvoice },
  
  //manage
  { path: "/offer-codes", component: OfferCodes },
  { path: "/section-banner", component: OfferBanner },
  { path: "/manage_section", component: OfferTypes },
  { path: "/section-product", component: OfferProducts },
  { path: "/faq-category", component: ManageCategory },
  { path: "/faq_questions", component: ManageFAQ },

  //Reports
  { path: "/sales", component: Sales },
  { path: "/sales-hourly-wise-report", component: SalesHourlyWiseReport },
  { path: "/sales-daily-wise-report", component: SalesDailyWise },
  { path: "/sales-monthly-wise-report", component: SalesMonthlyWiseReport },
  { path: "/most-searched", component: MostSearched },
  { path: "/searched-not-found", component: SearchedNotFound },
  { path: "/top-selling-products", component: TopSellingProducts },
  { path: "/top-selling-category", component: TopSellingCategory },
  { path: "/top-customers", component: TopCustomer },
  { path: "/report/stock", component: StockReport},
  { path: "/report/stock_log", component: StockLogReport},
  { path: "/gst", component: GstInOut},
  { path: "/sales_hsn", component: SalesHSNWise},
  { path: "/purchase-item-wise", component: purchaseItemWise},
  { path: "/purchase-category-wise", component: PurchaseCategoryWise},
  { path: "/daily-sales-report", component: DailySales},
  { path: "/notifyMe", component: NotifyMe},
  
  //stocks
  { path: "/stock", component: Stock },
  { path: "/stocklogs", component: Stocklog },
  { path: "/view-stocks", component: ViewStock },
  { path: "/view-stocks-logs", component: ViewStockLogs },

  //Rules
  { path: "/set-rules", component: Rules },

  //accounts
  { path: "/account-type", component: AccountType },
  { path: "/account-details", component: AccountDetails },
  { path: "/accounts-chart", component: Chart },
  { path: "/fund-transfer", component: FundTransfer },
  { path: "/credit-debit-fund", component: CreditDebit },
  { path: "/income-expense", component: IncomeExpenses },
  { path: "/transaction-log", component: TransactionLog },

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-colors", component: UiColors },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },
  { path: "/ui-notifications", component: UiNotifications },
  { path: "/ui-image-cropper", component: UiImageCropper },

  // Forms
  { path: "/basic-elements", component: BasicElements },
  { path: "/form-layouts", component: FormLayouts },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-repeater", component: FormRepeater },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },

  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartjs-charts", component: ChartjsChart },
  { path: "/e-charts", component: EChart },
  { path: "/sparkline-charts", component: SparklineChart },
  { path: "/charts-knob", component: ChartsKnob },

  // Icons
  { path: "/icons-unicons", component: IconUnicons },
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },
  { path: "/maps-leaflet", component: MapsLeaflet },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "/pushnotification", component: PushNotification },
  { path: "/banner", component: Banner },
  { path: "/slider", component: Slider },

  //Area
  { path: "/area", component: Area },
  { path: "/spot-price", component:SpotPrice },
  { path: "/purchase-return", component: PurchaseReturn },
  { path: "/pos", component:POS },
  { path: "/deliverySlot", component: DeliverySlot },
  { path: "/urlshortner", component: UrlShortner },
  { path: "/sitemap", component: Sitemap },
  { path: "/reOrderReport", component: ReOrderReport },
  { path: "/deliveryboyreport", component: DeliveryBoyReport },
  { path: "/subarea", component: SubArea },
  { path: "/settings", component: Settings }
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-register", component: Register1 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/auth-lock-screen", component: LockScreen },

  //accounts
  // {path:"/account-type" , component:AccountType},
  // { path: "/account-details", component: AccountDetails },
  // { path: "/accounts-chart", component: Chart },
  // { path: "/fund-transfer", component: FundTransfer },
  // { path: "/credit-debit-fund", component: CreditDebit },
  // { path: "/income-expense", component: IncomeExpenses },
  // { path: "/transaction-log", component: TransactionLog },
];

export { userRoutes, authRoutes };