import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import jwt_decode from 'jwt-decode';
import Moment from 'moment';



import SweetAlert from 'react-bootstrap-sweetalert';
import { MDBDataTable } from 'mdbreact';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Modal,
} from 'reactstrap';

import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDeliverylists,
  apiError,
  
} from '../../../store/actions';

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';


const Deliverylists = (props) => {
  //   const [selecteddeliverylistpremium, setSelectedMessagePremium] = useState(null);

  const [deliverylistObject, setMessageObject] = useState({});
  const [deliverylistsTemp, setDeliverylistsTemp] = useState([]);

  // const [deliverylistIdTobeUpdated, setMessageIdToBeUpdated] = useState(null);
  // const [deliverylistIdToBeDeleted, setMessageIdToBeDeleted] = useState(null);
  // const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState(1);

  const {
    deliverylists,
  
    error,
  } = useSelector((state) => state.deliverylist);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDeliverylists()); 
  }, []);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = jwt_decode(localStorage.getItem('authUser'));
      console.log(obj);

      setUserId(obj.user);
      setMessageObject({ ['auth_userid']: userId });
    }
  }, [props.success, props]);

  

 

  useEffect(() => {
    let deliverylistsDuplicate = JSON.parse(JSON.stringify(deliverylists));
    let deliverylistData = [];
    deliverylistsDuplicate.map((item, index) => {
      item.o_customer_community_id = item.Community.community_address1 + ' ' + item.Community.community_address2;
      item.customer_name = item.customer.customer_name;
      item.customer_mob = item.customer.customer_mob;
      item.o_date = Moment(item.o_date).format('DD/MM/YYYY');

      item.id = index + 1; 

      deliverylistData.push(item);
    });
    setDeliverylistsTemp(deliverylistData);
  }, [deliverylists]);

  const data = {
    columns: [
      {
        label: '#',
        field: 'id',
        sort: 'asc',
        width: 10,
      },

      {
        label: 'Order No',
        field: 'o_number',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Date',
        field: 'o_date',
        sort: 'asc',
        width: 70,
      },
      
      {
        label: 'Name',
        field: 'customer_name',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Mobile Number',
        field: 'customer_mob',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Appartment',
        field: 'o_customer_apartment_no',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Address',
        field: 'o_customer_community_id',
        sort: 'asc',
        width: 70,
      },
      
    ],
    rows: deliverylistsTemp,
  };

  

  return (
    <React.Fragment>
      {/* {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteMessage(deliverylistIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null} */}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Deliverylists" />
          {/* <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitMessage(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Defaul Message
                          </Label>
                          <AvField
                            name="createdBy"
                            value={userId}
                            type="hidden"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />

                          <AvField
                            name="default_deliverylist"
                            value={deliverylistObject.default_deliverylist}
                            placeholder="Default deliverylist"
                            type="text"
                            errorMessage="Enter Default deliverylist"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Sub Message</Label>
                          <AvField
                            name="product_sub_deliverylist"
                            value={deliverylistObject.product_sub_deliverylist}
                            placeholder="Sub deliverylist"
                            type="text"
                            errorMessage="Enter Sub Message"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Sub Message value
                          </Label>
                          <AvField
                            name="sub_deliverylist_value"
                            value={deliverylistObject.sub_deliverylist_value}
                            placeholder="Sub deliverylist value"
                            type="text"
                            errorMessage="Enter sub deliverylist value"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                    </Row>

                    {deliverylistIdTobeUpdated ? (
                      <Button
                        color="primary"
                        type="submit"
                        disabled={addingMessage ? true : false}
                      >
                        {addingMessage ? 'Updating' : 'Update'}
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        type="submit"
                        disabled={addingMessage ? true : false}
                      >
                        {addingMessage ? 'Adding' : 'Submit'}
                      </Button>
                    )}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bdeliverylisted data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  // const { error } = state.Users;
  // return { error };
};

export default withRouter(connect(mapStateToProps, { apiError })(Deliverylists));

Deliverylists.propTypes = {
  error: PropTypes.any,
  deliverylists: PropTypes.array,
};
