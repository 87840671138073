import {
  GET_SECTIONBANNERS,
  GET_SECTIONBANNERS_SUCCESS,
  GET_SECTIONBANNERS_FAIL,
  ADD_SECTIONBANNER,
  ADD_SECTIONBANNER_SUCCESS,
  ADD_SECTIONBANNER_FAIL,
  UPDATE_SECTIONBANNER,
  UPDATE_SECTIONBANNER_SUCCESS,
  UPDATE_SECTIONBANNER_FAIL,
  DELETE_SECTIONBANNER,
  DELETE_SECTIONBANNER_SUCCESS,
  DELETE_SECTIONBANNER_FAIL,
  CHANGE_STATUS_SECTIONBANNER,
  CHANGE_STATUS_SECTIONBANNER_SUCCESS,
  CHANGE_STATUS_SECTIONBANNER_FAIL,
} from "./actionTypes";

export const getSectionBanners = () => ({
  type: GET_SECTIONBANNERS,
});

export const getSectionBannersSuccess = (sectionBanners) => ({
  type: GET_SECTIONBANNERS_SUCCESS,
  payload: sectionBanners,
});

export const getSectionBannersFail = (error) => ({
  type: GET_SECTIONBANNERS_FAIL,
  payload: error,
});

export const addSectionBanner = (sectionBanner) => ({
  type: ADD_SECTIONBANNER,
  payload: sectionBanner,
});

export const addSectionBannerSuccess = (sectionBanner) => ({
  type: ADD_SECTIONBANNER_SUCCESS,
  payload: sectionBanner,
});

export const addSectionBannerFail = (sectionBanner) => ({
  type: ADD_SECTIONBANNER_FAIL,
  payload: sectionBanner,
});

export const updateSectionBanner = (sectionBanner) => ({
  type: UPDATE_SECTIONBANNER,
  payload: sectionBanner,
});

export const updateSectionBannerSuccess = (sectionBanner) => ({
  type: UPDATE_SECTIONBANNER_SUCCESS,
  payload: sectionBanner,
});

export const updateSectionBannerFail = (error) => ({
  type: UPDATE_SECTIONBANNER_FAIL,
  payload: error,
});

export const deleteSectionBanner = (sectionBanner, auth_user) => ({
  type: DELETE_SECTIONBANNER,
  payload: sectionBanner,
  auth_data: auth_user,
});

export const deleteSectionBannerSuccess = (sectionBanner) => ({
  type: DELETE_SECTIONBANNER_SUCCESS,
  payload: sectionBanner,
});

export const deleteSectionBannerFail = (error) => ({
  type: DELETE_SECTIONBANNER_FAIL,
  payload: error,
});

export const toggleSectionBannerActiveStatus = (sectionBanner, auth_user) => ({
  type: CHANGE_STATUS_SECTIONBANNER,
  payload: sectionBanner,
  auth_data: auth_user,
});

export const toggleSectionBannerActiveStatusSuccess = (sectionBanner) => ({
  type: CHANGE_STATUS_SECTIONBANNER_SUCCESS,
  payload: sectionBanner,
});

export const toggleSectionBannerActiveStatusFail = (error) => ({
  type: CHANGE_STATUS_SECTIONBANNER_FAIL,
  payload: error,
});
