import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable, MDBInput } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Table } from "reactstrap";
import Select from "react-select";
import {
  getCategoriesOptions,
  apiError,
  //getAllSpotPrice,
  addSpotPrice,
  addMultipleSpotPrice,
} from "../../../store/actions";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import jwt_decode from "jwt-decode";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
const SpotPrice = (props) => {
  const [categoryOption, setCategoryOption] = useState([]);
  const [master, setMaster] = useState({});
  const [subCategory, setSubCategory] = useState([]);
  const [brandGroup, setBrandGroup] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [values, setValues] = useState([]);
  const [userId, setUserId] = useState(1);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const dispatch = useDispatch();
  const { categoryOptions } = useSelector((state) => state.categories);
  const {
    addingMultipleSpotPrice,
    addMultipleSpotPriceResponse,
    addSpotPriceResponse,
    error,
  } = useSelector((state) => state.spotPrices);
  useEffect(() => {
    dispatch(getCategoriesOptions());
    fetch_all_brands();
    //dispatch(getAllSpotPrice());
  }, []);
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = jwt_decode(localStorage.getItem("authUser"));
      setUserId(obj.user);
      setMaster({
        ["auth_userid"]: obj.user,
      });
    }
  }, [userId]);
  useEffect(() => {
    console.log("categoryOptions: ", categoryOptions);
    var data =
      categoryOptions &&
      categoryOptions.map((item) => {
        return {
          label: item.category_name_english,
          value: item.category_id,
        };
      });

    setCategoryOption([
      {
        options: data,
      },
    ]);
  }, [categoryOptions]);

  // useEffect(() => {
  //   let spotPriceData = JSON.parse(JSON.stringify(spotPrices));
  //   let data = [];
  //   spotPriceData.map((item, index) => {
  //     item.id = index + 1;
  //     item.auth_userid = userId;
  //     data.push(item);
  //   });
  //   console.log('data: ', data);
  //   setValues(data);
  // }, [spotPrices]);

  useEffect(() => {
    if (addSpotPriceResponse.type === "success") {
      toastr.success(addSpotPriceResponse.message);
      let category = master?.category_id ? master.category_id : "";
      let sub_category = master?.sub_category_id ? master.sub_category_id : "";
      let brand = master?.brand_id ? master.brand_id : "";
      handleTableData(category, sub_category, brand);
    } else if (addSpotPriceResponse.type === "failure") {
      toastr.error(error.data.message, addSpotPriceResponse.message);
    }
  }, [addSpotPriceResponse]);
  useEffect(() => {
    if (addMultipleSpotPriceResponse.type === "success") {
      toastr.success(addMultipleSpotPriceResponse.message);
      let category = master?.category_id ? master.category_id : "";
      let sub_category = master?.sub_category_id ? master.sub_category_id : "";
      let brand = master?.brand_id ? master.brand_id : "";
      handleTableData(category, sub_category, brand);
    } else if (addMultipleSpotPriceResponse.type === "failure") {
      toastr.error(error.data.message, addMultipleSpotPriceResponse.message);
    }
  }, [addMultipleSpotPriceResponse]);

  function fetch_all_brands() {
    axios
      .get(`${API_URL}brands`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var brand_data =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label: item.brand_name_english,
              value: item.brand_id,
            };
          });

        setBrandGroup([
          {
            options: brand_data,
          },
        ]);
      });
  }
  const handleSelectChange = (selected, name) => {
    let category = master?.category_id ? master.category_id : "";
    let sub_category = master?.sub_category_id ? master.sub_category_id : "";
    let brand = master?.brand_id ? master.brand_id : "";
    switch (name) {
      case "category":
        setSelectedCategory(selected);
        setSelectedSubCategory(null);
        setMaster({
          ...master,
          ["category_id"]: selected.value,
          ["sub_category_id"]: "",
        });
        axios
          .get(`${API_URL}subcategory?category_id=` + selected.value, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            var subCategoryData =
              res.data &&
              res.data.map((item) => {
                return {
                  label: item.subcategory_name_english,
                  value: item.subcategory_id,
                };
              });
            setSubCategory([
              {
                options: subCategoryData,
              },
            ]);
          });
        handleTableData(selected.value, "", brand);
        break;
      case "subcategory":
        setSelectedSubCategory(selected);
        setMaster({
          ...master,
          ["sub_category_id"]: selected.value,
        });
        handleTableData(category, selected.value, brand);
        break;
      case "brand":
        setSelectedBrand(selected);
        setMaster({
          ...master,
          ["brand_id"]: selected.value,
        });
        handleTableData(category, sub_category, selected.value);
      default:
        break;
    }
  };
  const handleTableData = (category, sub_category, brand) => {
    var url = `${API_URL}spotprice`;
    url =
      url +
      "?category_id=" +
      category +
      "&subcategory_id=" +
      sub_category +
      "&brand_id=" +
      brand;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let data = [];
        result.map((item, index) => {
          item.id = index + 1;
          item.auth_userid = userId;
          data.push(item);
        });
        setValues(data);
      });
  };
  const handleTableInputChange = (e, index) => {
    console.log("values: ", values);
    const list = [...values];
    list[index][e.target.name] = e.target.value;
    setValues(list);
  };
  const submitSpotPrice = (index) => {
    let data = values[index] || {};
    console.log("data: ", data);
    dispatch(addSpotPrice(data));
    setValues([]);
  };
  const handleValidSubmit = (e, v) => {
    let data = {
      auth_userid: master?.auth_userid,
      product_data: [...values],
    };
    dispatch(addMultipleSpotPrice(data));
    setValues([]);
  };
  const clear = () => {
    setMaster({
      ["auth_userid"]: userId,
    });
    setSelectedBrand(null);
    setSelectedCategory(null);
    setSelectedSubCategory(null);
    setSubCategory([]);
    setValues([]);
  };
  // const data = {
  //   columns: [
  //     {
  //       label: "#",
  //       field: "id",
  //       sort: "asc",
  //       width: 150,
  //     },
  //     {
  //       label: "SKU",
  //       field: "product_sku_autogen",
  //       sort: "asc",
  //       width: 150,
  //     },
  //     {
  //       label: "Name",
  //       field: "product_name_english",
  //       sort: "asc",
  //       width: 400,
  //     },
  //     {
  //       label: "Qty",
  //       field: "product_unit_quantity",
  //       sort: "asc",
  //       width: 200,
  //     },
  //     {
  //       label: "Cost",
  //       field: "product_cost",
  //       sort: "asc",
  //       width: 100,
  //     },
  //     {
  //       label: "MRP",
  //       field: "product_mrp",
  //       sort: "asc",
  //       width: 100,
  //     },
  //     {
  //       label: "Web",
  //       field: "product_web_price",
  //       sort: "asc",
  //       width: 100,
  //     },
  //     {
  //       label: "Android",
  //       field: "product_and_price",
  //       sort: "asc",
  //       width: 100,
  //     },
  //     {
  //       label: "Ios",
  //       field: "product_ios_price",
  //       sort: "asc",
  //       width: 100,
  //     },
  //     {
  //       label: "Action",
  //       field: "action",
  //       sort: "asc",
  //       width: 100,
  //     },
  //   ],
  //   rows: values,
  // };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Spot Price" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Category</Label>
                          <Select
                            name="category_id"
                            value={selectedCategory}
                            onChange={(selected) => {
                              handleSelectChange(selected, "category");
                            }}
                            options={categoryOption}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Sub-Category</Label>
                          <Select
                            name="sub_category_id"
                            value={selectedSubCategory}
                            onChange={(selected) => {
                              handleSelectChange(selected, "subcategory");
                            }}
                            options={subCategory}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label>Third Category</Label>
                          <Select
                            name="customer_community_id"
                            //   value={selectCommunity}
                            //   onChange={(value) => {
                            //     handleSelectedCommunities(value);
                            //   }}
                            //   options={communitiesOptionsGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col> */}
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Brand</Label>
                          <Select
                            name="brand_id"
                            value={selectedBrand}
                            onChange={(selected) => {
                              handleSelectChange(selected, "brand");
                            }}
                            options={brandGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="1">
                        <div className="mt-3">
                          <Button
                            color="primary"
                            type="submit"
                            disabled={addingMultipleSpotPrice ? true : false}
                          >
                            {addingMultipleSpotPrice ? "Adding" : "Submit"}
                          </Button>
                        </div>
                      </Col>
                      <Col md="1">
                        <div className="mt-3">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => clear()}
                          >
                            Clear
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
              <Card>
                {/* <CardBody style={{paddingBottom: "41px"}}> */}
                <CardBody style={{ paddingTop: "28px", paddingBottom: "31px" }}>
                  <AvForm>
                    <Row>
                      <div className="table-responsive">
                        <Table
                          style={{ textAlign: "center" }}
                          id="product_tale_id"
                          className="table table-bordered dataTable"
                        >
                          <TableHead>
                            <TableRow style={{ textAlign: "center" }}>
                              <TableCell
                                style={{ width: "10px", textAlign: "center" }}
                              >
                                #
                              </TableCell>
                              <TableCell
                                style={{ width: "100px", textAlign: "center" }}
                              >
                                SKU
                              </TableCell>
                              <TableCell
                                style={{ width: "400px", textAlign: "center" }}
                              >
                                Name
                              </TableCell>
                              <TableCell
                                style={{ width: "90px", textAlign: "center" }}
                              >
                                Qty
                              </TableCell>
                              <TableCell
                                style={{ width: "130px", textAlign: "center" }}
                              >
                                Cost
                              </TableCell>
                              <TableCell
                                style={{ width: "130px", textAlign: "center" }}
                              >
                                Mrp
                              </TableCell>
                              <TableCell
                                style={{ width: "130px", textAlign: "center" }}
                              >
                                Web
                              </TableCell>
                              <TableCell
                                style={{ width: "130px", textAlign: "center" }}
                              >
                                Android
                              </TableCell>
                              <TableCell
                                style={{ width: "130px", textAlign: "center" }}
                              >
                                Ios
                              </TableCell>
                              <TableCell
                                style={{ width: "90px", textAlign: "center" }}
                              >
                                Action
                              </TableCell>
                              {/* <TableCell
                              style={{ width: "10px", textAlign: "center" }}
                            ></TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values &&
                              values.map((item, index) => (
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell>
                                    <div>{item?.product_sku_autogen}</div>
                                  </TableCell>
                                  <TableCell>
                                    <div>{item?.product_name_english}</div>
                                  </TableCell>
                                  <TableCell>
                                    <div>{item?.product_unit_quantity}</div>
                                  </TableCell>
                                  <TableCell>
                                    <AvField
                                      onChange={(e) =>
                                        handleTableInputChange(e, index)
                                      }
                                      type="number"
                                      value={item?.product_cost}
                                      className="form-control"
                                      name="product_cost"
                                      id={`product_cost_${index}`}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <AvField
                                      onChange={(e) =>
                                        handleTableInputChange(e, index)
                                      }
                                      type="number"
                                      value={item?.product_mrp}
                                      className="form-control"
                                      name="product_mrp"
                                      id={`product_mrp_${index}`}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <AvField
                                      onChange={(e) =>
                                        handleTableInputChange(e, index)
                                      }
                                      type="number"
                                      value={item?.product_web_price}
                                      className="form-control"
                                      name="product_web_price"
                                      id={`product_web_price_${index}`}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <AvField
                                      onChange={(e) =>
                                        handleTableInputChange(e, index)
                                      }
                                      type="number"
                                      value={item?.product_and_price}
                                      className="form-control"
                                      name="product_and_price"
                                      id={`product_and_price_${index}`}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <AvField
                                      onChange={(e) =>
                                        handleTableInputChange(e, index)
                                      }
                                      type="number"
                                      value={item?.product_ios_price}
                                      className="form-control"
                                      name="product_ios_price"
                                      id={`product_ios_price${index}`}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      color="primary"
                                      type="submit"
                                      //disabled={addingSpotPrice ? true : false}
                                      onClick={() => submitSpotPrice(index)}
                                    >
                                      {/* {addingSpotPrice ? "Adding" : "Submit"} */}
                                      Save
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </div>
                    </Row>
                  </AvForm>

                  {/* <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    paging={true}
                    info={false}
                  /> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};
export default withRouter(connect(mapStateToProps, { apiError })(SpotPrice));
