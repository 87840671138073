import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import { Collapse, Divider } from "antd";
import "antd/dist/antd.css";
import toastr from "toastr";
import {
  Row,
  Col,
  Card,
  FormGroup,
  CardBody,
  Button,
  Label,
  Modal,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  getUsers,
  addUser,
  deleteUser,
  apiError,
  getPrivilagesOptions,
  getCompaniesOptions,
  getBranchesOptions,
  updateUser,
  //getPrivilagesOptions,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";
import { makeStyles } from "@material-ui/core";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./POS.scss";

import Avatar from "@mui/material/Avatar";
import { red } from "@mui/material/colors";

import PreviewIcon from "@mui/icons-material/Preview";
import PrintIcon from "@mui/icons-material/Print";
import AddCardIcon from "@mui/icons-material/AddCard";
import MoneyIcon from "@mui/icons-material/Money";
import PaymentsIcon from "@mui/icons-material/Payments";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import TableCell from "@mui/material/TableCell";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CancelIcon from "@mui/icons-material/Cancel";

import { useReactToPrint } from "react-to-print";

import ReactToPrint from "react-to-print";

//import img from "../../assets/images/logo_dark header.jpeg";

class ComponentToPrint extends React.Component {
  render() {
    return (
      <>
        {/* <img src={img} style={{ width: "100%" }} alt="" /> */}
      </>
    );
  }
}

const { Panel } = Collapse;
const useStyles = makeStyles({
  footer1: {
    position: "absolute",

    width: "98%",
  },
  footer2: {
    position: "absolute",
    bottom: 10,
    width: "95%",
  },
});

const POS = (props) => {
  const classes = useStyles();

  const [openModal, setOpenModal] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const print = () => {
    window.print();
  };

  // const componentRef = useRef(null);
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="POS" />

          <Row>
            <Col xl="6">
              <Card>
                <CardBody>
                  {" "}
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="specificSizeInputGroupUsername"
                      placeholder="Quick Search"
                    />
                    <div className="input-group-text">
                      <i className="fas fa-search"></i>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Row>
                <Col md="3">
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "-webkit-center",
                        padding: "10px 0px 8px",
                      }}
                    >
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        BG
                      </Avatar>
                      <Label>Baby Gear</Label>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "-webkit-center",
                        padding: "10px 0px 8px",
                      }}
                    >
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        NB
                      </Avatar>
                      <Label>New Born</Label>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "-webkit-center",
                        padding: "10px 0px 8px",
                      }}
                    >
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        TY
                      </Avatar>
                      <Label>Toys</Label>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "-webkit-center",
                        padding: "10px 0px 8px",
                      }}
                    >
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        IN
                      </Avatar>
                      <Label>Indoor</Label>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "-webkit-center",
                        padding: "10px 0px 8px",
                      }}
                    >
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        BS
                      </Avatar>
                      <Label>Books & Stationery</Label>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "-webkit-center",
                        padding: "10px 0px 8px",
                      }}
                    >
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        OU
                      </Avatar>
                      <Label>Outdoor play unit</Label>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "-webkit-center",
                        padding: "10px 0px 8px",
                      }}
                    >
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        TY
                      </Avatar>
                      <Label>Toy</Label>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "-webkit-center",
                        padding: "10px 0px 8px",
                      }}
                    >
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        PT
                      </Avatar>
                      <Label>Pvt. toys</Label>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "-webkit-center",
                        padding: "10px 0px 8px",
                      }}
                    >
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        BC
                      </Avatar>
                      <Label>Bicycle</Label>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "-webkit-center",
                        padding: "10px 0px 8px",
                      }}
                    >
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        HT
                      </Avatar>
                      <Label>H toys</Label>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "-webkit-center",
                        padding: "10px 0px 8px",
                      }}
                    >
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        CT
                      </Avatar>
                      <Label>Chargeable in toys</Label>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "-webkit-center",
                        padding: "10px 0px 8px",
                      }}
                    >
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        TC
                      </Avatar>
                      <Label>Tricycle</Label>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "-webkit-center",
                        padding: "10px 0px 8px",
                      }}
                    >
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        BW
                      </Avatar>
                      <Label>Baby Walker</Label>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "-webkit-center",
                        padding: "10px 0px 8px",
                      }}
                    >
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        SD
                      </Avatar>
                      <Label>Soft Doll</Label>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "-webkit-center",
                        padding: "10px 0px 8px",
                      }}
                    >
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        CD
                      </Avatar>
                      <Label>Cradle</Label>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "-webkit-center",
                        padding: "10px 0px 8px",
                      }}
                    >
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        CK
                      </Avatar>
                      <Label>Clock</Label>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Card className={classes.footer1}>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <Card>
                        <CardBody
                          style={{
                            textAlign: "-webkit-center",
                            padding: "10px 0px 8px",
                          }}
                        >
                          <Label>SRV123</Label>
                          <br />
                          <PreviewIcon
                            onClick={handleClickOpenModal}
                            style={{ color: "#5b73e8", cursor: "pointer" }}
                          />
                          <PrintIcon color="error" />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="3">
                      <Card>
                        <CardBody
                          style={{
                            textAlign: "-webkit-center",
                            padding: "10px 0px 8px",
                          }}
                        >
                          <Label>SRV123</Label>
                          <br />
                          <PreviewIcon
                            onClick={handleClickOpenModal}
                            style={{ color: "#5b73e8", cursor: "pointer" }}
                          />
                          <PrintIcon color="error" />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="3">
                      <Card>
                        <CardBody
                          style={{
                            textAlign: "-webkit-center",
                            padding: "10px 0px 8px",
                          }}
                        >
                          <Label>SRV123</Label>
                          <br />
                          <PreviewIcon
                            onClick={handleClickOpenModal}
                            style={{ color: "#5b73e8", cursor: "pointer" }}
                          />

                          <ComponentToPrint ref={componentRef} />
                          <PrintIcon color="error" onClick={handlePrint} />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card style={{ height: "660px" }}>
                <CardBody>
                  <Row>
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="far fa-user"></i>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="specificSizeInputGroupUsername"
                        placeholder="Quick Search"
                      />
                      <div className="input-group-text">
                        <i className="fas fa-user-plus"></i>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    {" "}
                    <Col md="4">
                      <div className="mt-3">
                        <Select
                          name="customer_community_id"
                          placeholder="Staff"
                          //   value={selectCommunity}
                          //   onChange={(value) => {
                          //     handleSelectedCommunities(value);
                          //   }}
                          //   options={communitiesOptionsGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className={classes.footer2}>
                    <Collapse>
                      <Panel header="Sub Total ₹ 0.00" key="1">
                        <Row>
                          <Col md="4">Offer Code</Col>
                          <Col md="4">Discount %</Col>
                          <Col md="4">Discount Amount</Col>
                        </Row>
                        <Row>
                          <Col md="4">
                            <input type="text" className="form-control" />
                          </Col>
                          <Col md="4">
                            <input type="text" className="form-control" />
                          </Col>
                          <Col md="4">
                            <input
                              type="text"
                              className="form-control"
                              disabled
                            />
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                    <br></br>
                    <Row>
                      <Col md="4">Payment Type</Col>
                      <Col md="4"></Col>
                      <Col md="4">Given Price</Col>
                    </Row>
                    <Row>
                      <Col md="7">
                        <Button
                          type="submit"
                          className="waves-effect waves-light me-1"
                          color="primary"
                        >
                          <CurrencyRupeeIcon />
                          Cash
                        </Button>
                        <Button
                          type="submit"
                          className="waves-effect waves-light me-1"
                          color="primary"
                        >
                          <AddCardIcon />
                          &nbsp; Card
                        </Button>
                        <Button
                          type="submit"
                          className="waves-effect waves-light me-1"
                          color="primary"
                        >
                          <MoneyIcon /> &nbsp; Cash & Card
                        </Button>
                      </Col>
                      <Col md="1"></Col>
                      <Col md="4">
                        <input type="text" className="form-control" />
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                      <Col md="4">
                        <div>
                          Balance : <CurrencyRupeeIcon /> 0.00
                        </div>
                      </Col>
                      <Col md="8">
                        <Button
                          type="submit"
                          color="primary"
                          className="btn-block inner"
                          style={{ width: "100%" }}
                        >
                          <PaymentsIcon />
                          &nbsp; Pay ₹ 0.00
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {/* Start Modal Form */}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openModal}
        onClose={handleCloseModal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <ShoppingCartIcon />
          View Order
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon onClick={handleCloseModal} />
          </IconButton>
        </DialogTitle>
        <Divider style={{ marginTop: "0%" }} />

        <DialogContent>
          <Row>
            <Col xl="12">
              <AvForm className="needs-validation">
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <div className="table-responsive">
                        <div className="react-bootstrap-table">
                          <table
                            id="customers"
                            className="table table table-nowrap table-hover mb-0"
                          >
                            <tbody
                              style={{
                                fontFamily: "IBM Plex Sans,sans-serif",
                              }}
                            >
                              <tr>
                                <td
                                  style={{
                                    color: "#009688",
                                    fontSize: "large",
                                    fontWeight: "500",
                                  }}
                                >
                                  Bill No
                                </td>
                                <td>SRV123</td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: "#009688",
                                    fontSize: "large",
                                    fontWeight: "500",
                                  }}
                                >
                                  Customer Name
                                </td>
                                <td>CASH</td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    color: "#009688",
                                    fontSize: "large",
                                    fontWeight: "500",
                                  }}
                                >
                                  Contact No
                                </td>
                                <td>12345678</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <div className="mb-3">
                        <div className="table-responsive">
                          <div className="react-bootstrap-table">
                            <table
                              id="customers"
                              className="table table table-nowrap table-hover mb-0"
                            >
                              <tbody
                                style={{
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              >
                                <tr>
                                  <td
                                    style={{
                                      color: "#009688",
                                      fontSize: "large",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Date
                                  </td>
                                  <td>04-08-2021</td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      color: "#009688",
                                      fontSize: "large",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Time
                                  </td>
                                  <td>12:07:04</td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      color: "#009688",
                                      fontSize: "large",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Payment Type
                                  </td>
                                  <td>Card:</td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      color: "#009688",
                                      fontSize: "large",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Grand Total
                                  </td>
                                  <td>4,170.00</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <DialogTitle sx={{ m: 0, p: 2 }}>
                  Product Details
                  <IconButton
                    aria-label="close"
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  ></IconButton>
                </DialogTitle>
                <hr className="dotted" />
                <div className="table-responsive">
                  <div className="react-bootstrap-table">
                    <table
                      className="table table table-nowrap table-hover mb-0"
                      id="customer"
                    >
                      <thead
                        style={{
                          fontFamily: "IBM Plex Sans,sans-serif",
                        }}
                      >
                        <tr>
                          <th
                            tabindex="0"
                            aria-label="# sortable"
                            className="sortable"
                          >
                            SI
                            <span className="order-4"></span>
                          </th>
                          <th
                            tabindex="0"
                            aria-label="# sortable"
                            className="sortable"
                          >
                            Product Name
                            <span className="order-4"></span>
                          </th>
                          <th
                            tabindex="0"
                            aria-label="# sortable"
                            className="sortable"
                          >
                            Price
                            <span className="order-4"></span>
                          </th>
                          <th
                            tabindex="0"
                            aria-label="# sortable"
                            className="sortable"
                          >
                            Quantity
                            <span className="order-4"></span>
                          </th>
                          <th
                            tabindex="0"
                            aria-label="# sortable"
                            className="sortable"
                          >
                            Total
                            <span className="order-4"></span>
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        style={{
                          fontFamily: "IBM Plex Sans,sans-serif",
                        }}
                      >
                        <tr>
                          <td>1</td>
                          <td>
                            Baby Tricycle With Parental Push Handle and Canopy
                            <br />
                            KBQ-602-Red
                          </td>
                          <td> 3,723.21</td>
                          <td> 1</td>
                          <td>3,723.21</td>
                        </tr>
                        <tr
                          style={{
                            color: "#009688",
                            fontSize: "large",
                            fontWeight: "500",
                          }}
                        >
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <td> Total</td>
                          <td>3,723.21</td>
                        </tr>
                        <tr
                          style={{
                            color: "#009688",
                            fontSize: "large",
                            fontWeight: "500",
                          }}
                        >
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <td> SGST</td>
                          <td>223.39</td>
                        </tr>
                        <tr
                          style={{
                            color: "#009688",
                            fontSize: "large",
                            fontWeight: "500",
                          }}
                        >
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <td> CGST</td>
                          <td>223.39</td>
                        </tr>
                        <tr
                          style={{
                            color: "#009688",
                            fontSize: "large",
                            fontWeight: "500",
                          }}
                        >
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <td> Grand Total</td>
                          <td>₹ 4,170.00</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </AvForm>
            </Col>
          </Row>
        </DialogContent>
      </Dialog>
      {/* End Modal Form */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(POS));
