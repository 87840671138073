import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from 'moment';
import $ from 'jquery';
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CSVLink } from "react-csv";
import { getDate } from "../../../helpers/globalFunctions";
import { formatMoney } from "../../../helpers/generalFunctions";
import "./topCustomers.scss"

const TopCustomers = (props) => {
  const [reportForTable, setreportForTable] = useState([]);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    handleTableData();
  }, []);

  const data = {
    columns: [
      {
        label: "#",
        field:"id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "customer_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Address",
        field: "address",
        sort: "asc",
        width: 400,
      },
      {
        label: "Mobile",
        field: "address_contact_number",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total Products",
        field: "total_products",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total Amount",
        field: "total",
        sort: "asc",
        width: 200,
      }
    ],
    rows: reportForTable,
  };

  function handleTableData(from_date=null,to_date=null){
    var url=`${API_URL}report/top_customer`;
    if(from_date!=null && to_date!=null)
    {
      url=url+"?from_date="+from_date+"&to_date="+to_date;
    }
    else if(from_date!=null)
    {
      url=url+"?from_date="+from_date;
    }
    else if(to_date!=null)
    {
      url=url+"?to_date="+to_date;
    }
    axios.get(url,
    {
      headers: {
        'x-access-token': accessToken,
      },
    })
    .then((res) => {  
      var result = res.data.data;
      let reportData=[];
      let dataToBeExported = [];
      result.map((item, index) => {
        let exportItem = {};

        item.id = index + 1;
        item.address="";
        if(item.shipping_address!=null)
        {
          item.address=item.shipping_address;
        }
        if(item.building_or_flat_no!=null)
        {
          item.address=item.address+" "+item.building_or_flat_no;
        }
        if(item.landmark!=null)
        {
          item.address=item.address+" "+item.landmark;
        }
        if(item.customer_location!=null)
        {
          item.address=item.address+" "+item.customer_location;
        }
        if(item.customer_pincode!=null)
        {
          item.address=item.address+" "+item.customer_pincode;
        }
        item.total = formatMoney(item.total);

        //setting export data
        exportItem.Id = item.id;
        exportItem.Name = item.customer_name;
        exportItem.Address = item.address;
        exportItem.Mobile = item.address_contact_number;
        exportItem.Total_Products = item.total_products;
        exportItem.Total_Amount = item.total;

        reportData.push(item);
        dataToBeExported.push(exportItem);
      });
      setreportForTable(reportData);
      setDataToBeExported(dataToBeExported);

    });
  }


  let handleDate=()=>{
    var date1=$("#from_date").val();
    var date2=$("#to_date").val();
    handleTableData(date1,date2);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Report - Top Customers" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mt-4" style={{ paddingTop: "5px" }}>
                        <Button color="success" type="submit">
                          <CSVLink
                            data={dataToBeExported}
                            filename={"Cococa_top_customers_report_"+getDate(new Date())+".xls"}
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="topCustomerId"
                    responsive
                    striped
                    bordered
                    data={data}
                    info={false}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={25}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
    
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, {  })(TopCustomers));
