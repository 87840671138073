import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import jwt_decode from 'jwt-decode';
import { MDBDataTable } from 'mdbreact';
import { Row, Col, Card, CardBody } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';

import {
  getCommunitySchedule,
  apiError,
  toggleCommunityActiveStatus,
} from '../../../store/actions';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import './schedule.scss';

const CommunitySchedule = (props) => {
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [communityObject, setCommunityObject] = useState({});
  const [communityScheduleTemp, setCommunityScheduleTemp] = useState([]);
  const [userId, setUserId] = useState(1);
  const [communityIdTobeUpdated, setCommunityIdToBeUpdated] = useState(null);

  const { communitySchedules, statusCommunityResponse, error } = useSelector(
    (state) => state.communityschedules
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCommunitySchedule());
  }, [dispatch]);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = jwt_decode(localStorage.getItem('authUser'));
      console.log(obj);

      setUserId(obj.user);
      setCommunityObject({ ['auth_userid']: userId });
    }
  }, [userId]);

  useEffect(() => {
    if (statusCommunityResponse.type === 'success') {
      toastr.success(statusCommunityResponse.message);
    } else if (statusCommunityResponse.type === 'failure') {
      toastr.error(error.data.message, statusCommunityResponse.message);
    }
  }, [statusCommunityResponse]);

  useEffect(() => {
    let communityScheduleArray = JSON.parse(JSON.stringify(communitySchedules));

    let communitySchedulesdata = [];

    communityScheduleArray.map((item, index) => {
      // item.fname = item.User.fname + ' ' + item.User.lname;
      item.auth_userid = userId;
      if (item.community_is_mon_schedule === 0) {
        console.log('Before' + toggleSwitch);

        item.display_monday = (
          <div style={{ textAlign: 'center', width: '100%', display: 'flex' }}>
            <div
              class="form-check form-switch mb-3"
              style={{ textAlign: 'center' }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="customSwitch2"
                name="community_is_mon_schedule"
                // value={toggleSwitch}
                defaultChecked
                onClick={(e) => {
                  console.log('Items are ' + item.community_id);
                  item.community_is_mon_schedule = 1;
                  console.log(
                    'Monday value is ' + item.community_is_mon_schedule
                  );

                  dispatch(toggleCommunityActiveStatus(item));

                  settoggleSwitch(!toggleSwitch);
                  dispatch(getCommunitySchedule());
                  console.log(toggleSwitch);
                }}
              />
            </div>
          </div>
        );
      } else {
        item.display_monday = (
          <div style={{ textAlign: 'center', width: '100%' }}>
            <div
              class="form-check form-switch mb-3"
              style={{ textAlign: 'center' }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="customSwitch2"
                name="community_is_mon_schedule"
                // value={toggleSwitch}
                onClick={(e) => {
                  console.log(e);
                  console.log('Items are ' + item);
                  item.community_is_mon_schedule = 0;
                  dispatch(toggleCommunityActiveStatus(item));
                  settoggleSwitch(!toggleSwitch);
                  dispatch(getCommunitySchedule());
                }}
              />
            </div>
          </div>
        );
      }

      if (item.community_is_tue_schedule === 0) {
        item.display_tuesday = (
          <div style={{ textAlign: 'center', width: '100%', display: 'flex' }}>
            <div
              class="form-check form-switch mb-3"
              style={{ textAlign: 'center' }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="tue-checkbox"
                name="community_is_tue_schedule"
                defaultChecked
                onClick={(e) => {
                  console.log(
                    'Items Tuesday are ' + item.community_is_tue_schedule
                  );
                  item.community_is_tue_schedule = 1;
                  dispatch(toggleCommunityActiveStatus(item));
                  settoggleSwitch(!toggleSwitch);
                }}
              />
            </div>
          </div>
        );
      } else {
        item.display_tuesday = (
          <div style={{ textAlign: 'center', width: '100%' }}>
            <div
              class="form-check form-switch mb-3"
              style={{ textAlign: 'center' }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="tue-checkbox"
                name="community_is_tue_schedule"
                value={toggleSwitch}
                onClick={(e) => {
                  console.log('Items Tuesday are ' + item);
                  item.community_is_tue_schedule = 0;
                  dispatch(toggleCommunityActiveStatus(item));
                  settoggleSwitch(!toggleSwitch);
                }}
              />
            </div>
          </div>
        );
      }
      if (item.community_is_wed_schedule === 0) {
        item.display_wed = (
          <div style={{ textAlign: 'center', width: '100%', display: 'flex' }}>
            <div
              class="form-check form-switch mb-3"
              style={{ textAlign: 'center' }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="wed-checkbox"
                name="community_is_wed_schedule"
                value={toggleSwitch}
                defaultChecked
                onClick={(e) => {
                  item.community_is_wed_schedule = 1;
                  dispatch(toggleCommunityActiveStatus(item));
                  settoggleSwitch(!toggleSwitch);
                }}
              />
            </div>
          </div>
        );
      } else {
        item.display_wed = (
          <div style={{ textAlign: 'center', width: '100%' }}>
            <div
              class="form-check form-switch mb-3"
              style={{ textAlign: 'center' }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="wed-checkbox"
                name="community_is_wed_schedule"
                value={toggleSwitch}
                onClick={(e) => {
                  item.community_is_wed_schedule = 0;
                  dispatch(toggleCommunityActiveStatus(item));
                  settoggleSwitch(!toggleSwitch);
                }}
              />
            </div>
          </div>
        );
      }
      if (item.community_is_thu_schedule === 0) {
        item.display_thurs = (
          <div style={{ textAlign: 'center', width: '100%', display: 'flex' }}>
            <div
              class="form-check form-switch mb-3"
              style={{ textAlign: 'center' }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="thu-checkbox"
                name="community_is_thu_schedule"
                defaultChecked
                onClick={(e) => {
                  item.community_is_thu_schedule = 1;
                  dispatch(toggleCommunityActiveStatus(item));
                  settoggleSwitch(!toggleSwitch);
                }}
              />
            </div>
          </div>
        );
      } else {
        item.display_thurs = (
          <div style={{ textAlign: 'center', width: '100%' }}>
            <div
              class="form-check form-switch mb-3"
              style={{ textAlign: 'center' }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="thu-checkbox"
                name="community_is_thu_schedule"
                onClick={(e) => {
                  item.community_is_thu_schedule = 0;
                  dispatch(toggleCommunityActiveStatus(item));
                  settoggleSwitch(!toggleSwitch);
                }}
              />
            </div>
          </div>
        );
      }
      if (item.community_is_fri_schedule === 0) {
        item.display_friday = (
          <div style={{ textAlign: 'center', width: '100%', display: 'flex' }}>
            <div
              class="form-check form-switch mb-3"
              style={{ textAlign: 'center' }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="fri-checkbox"
                name="community_is_fri_schedule"
                defaultChecked
                onClick={(e) => {
                  item.community_is_fri_schedule = 1;
                  dispatch(toggleCommunityActiveStatus(item));
                  settoggleSwitch(!toggleSwitch);
                }}
              />
            </div>
          </div>
        );
      } else {
        item.display_friday = (
          <div style={{ textAlign: 'center', width: '100%', display: 'flex' }}>
            <div
              class="form-check form-switch mb-3"
              style={{ textAlign: 'center' }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="fri-checkbox"
                name="community_is_fri_schedule"
                onClick={(e) => {
                  item.community_is_fri_schedule = 0;
                  dispatch(toggleCommunityActiveStatus(item));
                  settoggleSwitch(!toggleSwitch);
                }}
              />
            </div>
          </div>
        );
      }
      if (item.community_is_sat_schedule === 0) {
        item.display_sat = (
          <div style={{ textAlign: 'center', width: '100%', display: 'flex' }}>
            <div
              class="form-check form-switch mb-3"
              style={{ textAlign: 'center' }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="sat-checkbox"
                name="community_is_sat_schedule"
                defaultChecked
                onClick={(e) => {
                  item.community_is_sat_schedule = 1;
                  dispatch(toggleCommunityActiveStatus(item));
                  settoggleSwitch(!toggleSwitch);
                }}
              />
            </div>
          </div>
        );
      } else {
        item.display_sat = (
          <div style={{ textAlign: 'center', width: '100%', display: 'flex' }}>
            <div
              class="form-check form-switch mb-3"
              style={{ textAlign: 'center' }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="sat-checkbox"
                name="community_is_sat_schedule"
                onClick={(e) => {
                  item.community_is_sat_schedule = 0;
                  dispatch(toggleCommunityActiveStatus(item));
                  settoggleSwitch(!toggleSwitch);
                }}
              />
            </div>
          </div>
        );
      }
      if (item.community_is_sun_schedule === 0) {
        item.display_sun = (
          <div style={{ textAlign: 'center', width: '100%', display: 'flex' }}>
            <div
              class="form-check form-switch mb-3"
              style={{ textAlign: 'center' }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="sun-checkbox"
                name="community_is_sun_schedule"
                defaultChecked
                onClick={(e) => {
                  item.community_is_sun_schedule = 1;
                  dispatch(toggleCommunityActiveStatus(item));
                  settoggleSwitch(!toggleSwitch);
                }}
              />
            </div>
          </div>
        );
      } else {
        item.display_sun = (
          <div style={{ textAlign: 'center', width: '100%', display: 'flex' }}>
            <div
              class="form-check form-switch mb-3"
              style={{ textAlign: 'center' }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="sun-checkbox"
                name="community_is_sun_schedule"
                value={toggleSwitch}
               
                onClick={(e) => {
                  item.community_is_sun_schedule = 0;
                  dispatch(toggleCommunityActiveStatus(item));
                  settoggleSwitch(!toggleSwitch);
                }}
              />
            </div>
          </div>
        );
      }
      // item.id = index + 1;
      communitySchedulesdata.push(item);
    });
    setCommunityScheduleTemp(communitySchedulesdata);
  }, [dispatch, toggleSwitch, userId, communitySchedules]);

  const data = {
    columns: [
      {
        label: '#',
        field: 'community_id',
        sort: 'asc',
        width: 10,
      },

      {
        label: 'Community Name',
        field: 'community_name',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Monday',
        field: 'display_monday',

        sort: 'asc',
        width: 70,
      },
      {
        label: 'Tuesday',
        field: 'display_tuesday',

        sort: 'asc',
        width: 70,
      },
      {
        label: 'Wednesday',
        field: 'display_wed',

        sort: 'asc',
        width: 70,
      },
      {
        label: 'Thursday',
        field: 'display_thurs',

        sort: 'asc',
        width: 70,
      },
      {
        label: 'Friday',
        field: 'display_friday',

        sort: 'asc',
        width: 70,
      },
      {
        label: 'Saturday',
        field: 'display_sat',

        sort: 'asc',
        width: 70,
      },
      {
        label: 'Sunday',
        field: 'display_sun',

        sort: 'asc',
        width: 70,
      },
    ],
    rows: communityScheduleTemp,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Home" breadcrumbItem="Community Schedule" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <MDBDataTable responsive bordered data={data} paging={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default CommunitySchedule;
