import {
  GET_SPOT_PRICE,
  GET_SPOT_PRICE_SUCCESS,
  GET_SPOT_PRICE_FAIL,
  ADD_SPOT_PRICE,
  ADD_SPOT_PRICE_SUCCESS,
  ADD_SPOT_PRICE_FAIL,
  ADD_MULTIPLE_SPOT_PRICE,
  ADD_MULTIPLE_SPOT_PRICE_SUCCESS,
  ADD_MULTIPLE_SPOT_PRICE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  spotPrices: [],
  addingSpotPrice: false,
  addSpotPriceResponse: {},
  spotPrice: {},
  multipleSpotPrice: {},
  error: {},
  addMultipleSpotPriceResponse:{},
  addingMultipleSpotPrice: false,
};

const spotPrices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SPOT_PRICE:
      return {
        ...state,
        params: action.payload,
      };

    case GET_SPOT_PRICE_SUCCESS:
      return {
        ...state,
        spotPrices: action.payload.data,
      };

    case GET_SPOT_PRICE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_SPOT_PRICE:
      return {
        ...state,
        addingSpotPrice: true,
        spotPrice: action.payload,
      };

    case ADD_SPOT_PRICE_SUCCESS:
      let newSpotPrice = [JSON.parse(JSON.stringify(action.payload.data[0]))];
      newSpotPrice.push(...state.spotPrices);
      return {
        ...state,
        addingSpotPrice: false,
        addSpotPriceResponse: {
          type: "success",
          message: "Spot price updated successfully",
        },
        spotPrices: newSpotPrice,
        error: {},
      };

    case ADD_SPOT_PRICE_FAIL:
      return {
        ...state,
        addingSpotPrice: false,
        addSpotPriceResponse: {
          type: "failure",
          message: "Updating spot price failed",
        },
        error: action.payload,
      };

    case ADD_MULTIPLE_SPOT_PRICE:
      return {
        ...state,
        addingMultipleSpotPrice: true,
        multipleSpotPrice: action.payload,
      };

    case ADD_MULTIPLE_SPOT_PRICE_SUCCESS:
      return {
        ...state,
        addingMultipleSpotPrice: false,
        addMultipleSpotPriceResponse: {
          type: "success",
          message: "Spot price updated successfully",
        },
        error: {},
      };

    case ADD_MULTIPLE_SPOT_PRICE_FAIL:
      return {
        ...state,
        addingMultipleSpotPrice: false,
        addMultipleSpotPriceResponse: {
          type: "failure",
          message: "Updating spot price failed",
        },
        error: action.payload,
      };

    default:
      return state;
  }
};

export default spotPrices;
