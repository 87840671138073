import { takeEvery, put, call, takeLatest } from "redux-saga/effects";
import {
  GET_SPOT_PRICE,
  ADD_SPOT_PRICE,
  ADD_MULTIPLE_SPOT_PRICE,
} from "./actionTypes";
import {
  getAllSpotPriceSuccess,
  getAllSpotPriceFail,
  addSpotPriceSuccess,
  addSpotPriceFail,
  addMultipleSpotPriceSuccess,
  addMultipleSpotPriceFail,
} from "./actions";
import {
  getAllSpotPrice,
  addSpotPrice,
  addMultipleSpotPrice,
} from "../../helpers/backend_helper";

function* fetchSpotPrice() {
  try {
    const response = yield call(getAllSpotPrice);
    yield put(getAllSpotPriceSuccess(response));
  } catch (error) {
    yield put(getAllSpotPriceFail(error));
  }
}
function* onAddSpotPrice({ payload: data }) {
  try {
    const response = yield call(addSpotPrice, data);
    yield put(addSpotPriceSuccess(response));
  } catch (error) {
    yield put(addSpotPriceFail(error.response));
  }
}

function* onAddMultipleSpotPrice({ payload: data }) {
    try {
      const response = yield call(addMultipleSpotPrice, data);
      yield put(addMultipleSpotPriceSuccess(response));
    } catch (error) {
      yield put(addMultipleSpotPriceFail(error.response));
    }
  }

function* spotPriceSaga() {
  yield takeEvery(GET_SPOT_PRICE, fetchSpotPrice);
  yield takeEvery(ADD_SPOT_PRICE, onAddSpotPrice);
  yield takeEvery(ADD_MULTIPLE_SPOT_PRICE,onAddMultipleSpotPrice);
}
export default spotPriceSaga;
