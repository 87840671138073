import {
    GET_COMMUNITIES_OPTIONS,
    GET_COMMUNITIES_OPTIONS_SUCCESS,
    GET_COMMUNITIES_OPTIONS_FAIL,
  } from './actionTypes';
  
  export const getCommunitiesOptions = () => ({
    type: GET_COMMUNITIES_OPTIONS,
  });
  
  export const getCommunitiesOptionsSuccess = (communities) => ({
    type: GET_COMMUNITIES_OPTIONS_SUCCESS,
    payload: communities,
  });
  
  export const getCommunitiesOptionsFail = (error) => ({
    type: GET_COMMUNITIES_OPTIONS_FAIL,
    payload: error,
  });