import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  Progress,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  getUsers,
  addUser,
  deleteUser,
  apiError,
  getPrivilagesOptions,
  getCompaniesOptions,
  getBranchesOptions,
  updateUser,
  //getPrivilagesOptions,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import "./user.scss";

const AddSuppliers = (props) => {
  const [uploadProgress, setUploadProgress] = useState();
  const [usersForTable, setUsersForTable] = useState([]);
  const [accountType, setAccountType] = useState("");

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Company Name",
        field: "district",
        sort: "asc",
        width: 400,
      },
      {
        label: "Supplier Name",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Email",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mobile",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Account No.",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "    GST No.",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: " Billing Rate",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
    ],
    rows: usersForTable,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Add Suppliers" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    // onValidSubmit={(e, v) => {
                    //   handleValidSubmit(e, v);
                    // }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Company Name
                          </Label>
                          <AvField
                            name="companyName"
                            placeholder="Company Name"
                            type="text"
                            errorMessage="Enter Company Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Company Logo
                          </Label>

                          {/* {productObject.product_image_main &&
                          productObject.product_image_main.length > 2 ? ( */}
                          {/* <div className="img-wraps"> */}
                          {/* {productObject.product_image_main &&
                              productObject.product_image_main.startsWith(
                                'http'
                              ) ? (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                //   src={`${productObject.product_image_main}`}
                                />
                              ) : (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                //   src={`${API_URL}public/uploads/product-images/${productObject.product_image_main}`}
                                />
                              )}

                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                // onClick={deleteProductImage}
                                style={{ width: '200px' }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : ( */}
                          <AvField
                            name="product_image_main"
                            type="file"
                            errorMessage="Select Image"
                            className="form-control"
                            id="validationCustom04"
                            //  id="getFile" style={{display:"none"}}
                            // onChange={handleChangeInput}
                            //   onChange={uploadImage}
                          />
                          {/* )} */}

                          {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              {" "}
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Supplier Name
                          </Label>
                          <AvField
                            name="SupplierName"
                            placeholder="Supplier Name"
                            type="text"
                            errorMessage="Enter Supplier Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Supplier Email
                          </Label>
                          <AvField
                            name="SupplierEmail"
                            placeholder="Supplier Email"
                            type="email"
                            errorMessage="Enter Supplier Email"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Supplier Mobile
                          </Label>
                          <AvField
                            name="SupplierMobile"
                            placeholder="SupplierMobile"
                            type="number"
                            errorMessage="Enter Supplier Mobile"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Supplier Landline
                          </Label>
                          <AvField
                            name="SupplierLandline"
                            placeholder="Supplier Landline"
                            type="number"
                            errorMessage="Enter Supplier Landline"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Supplier Fax
                          </Label>
                          <AvField
                            name="SupplierFax"
                            placeholder="Supplier Fax"
                            type="text"
                            errorMessage="Enter Supplier Fax"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Supplier Website
                          </Label>
                          <AvField
                            name="SupplierWebsite"
                            placeholder="Supplier Website"
                            type="text"
                            errorMessage="Enter Supplier Website"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            className="mb-3"
                            type="textarea"
                            rows="1"
                            label="Address "
                            name="address"
                            id="address"
                            placeholder="Address"
                            errorMessage="This value is required."
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[0-9a-zA-Z]+$",
                                errorMessage: "Only Alphanumeric",
                              },
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label> Country</Label>
                          <Select
                            name="customer_community_id"
                            //   value={selectCommunity}
                            //   onChange={(value) => {
                            //     handleSelectedCommunities(value);
                            //   }}
                            //   options={communitiesOptionsGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>State</Label>
                          <Select
                            name="customer_community_id"
                            //   value={selectCommunity}
                            //   onChange={(value) => {
                            //     handleSelectedCommunities(value);
                            //   }}
                            //   options={communitiesOptionsGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>City</Label>
                          <Select
                            name="customer_community_id"
                            //   value={selectCommunity}
                            //   onChange={(value) => {
                            //     handleSelectedCommunities(value);
                            //   }}
                            //   options={communitiesOptionsGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Account Number
                          </Label>
                          <AvField
                            name="AccountNumber"
                            placeholder="Account Number"
                            type="number"
                            errorMessage="Enter Account Number"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">PAN Number</Label>
                          <AvField
                            name="PANNumber"
                            placeholder="PAN Number"
                            type="number"
                            errorMessage="Enter PAN Number"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">GST Number</Label>
                          <AvField
                            name="GSTNumber"
                            placeholder="GST Number"
                            type="number"
                            errorMessage="Enter GST Number"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Lattitude</Label>
                          <AvField
                            name="Lattitude"
                            placeholder="Lattitude"
                            type="number"
                            errorMessage="Enter Lattitude"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Longitude</Label>
                          <AvField
                            name="Longitude"
                            placeholder="Longitude"
                            type="number"
                            errorMessage="Enter Longitude"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="1">
                        <div className="mt-4">
                          <Button color="primary" type="submit">
                            Save
                          </Button>
                        </div>
                      </Col>
                      <Col md="1">
                        <div className="mt-4">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => setAccountType("")}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    paging={false}
                    info={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(AddSuppliers));
