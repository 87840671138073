import {
  GET_SECTION_TYPES,
  GET_SECTION_TYPES_FAIL,
  GET_SECTION_TYPES_SUCCESS,
  ADD_SECTION_TYPE,
  ADD_SECTION_TYPE_FAIL,
  ADD_SECTION_TYPE_SUCCESS,
  CHANGE_STATUS_SECTION_TYPE,
  CHANGE_STATUS_SECTION_TYPE_SUCCESS,
  CHANGE_STATUS_SECTION_TYPE_FAIL,
  // GET_PRODUCT,
  // GET_PRODUCT_FAIL,
  // GET_PRODUCT_SUCCESS,
  UPDATE_SECTION_TYPE,
  UPDATE_SECTION_TYPE_FAIL,
  UPDATE_SECTION_TYPE_SUCCESS,
  DELETE_SECTION_TYPE,
  DELETE_SECTION_TYPE_FAIL,
  DELETE_SECTION_TYPE_SUCCESS,
} from "./actionTypes";

export const getSectionTypes = () => ({
  type: GET_SECTION_TYPES,
});

export const getSectionTypesSuccess = (sectionType) => ({
  type: GET_SECTION_TYPES_SUCCESS,
  payload: sectionType,
});

export const getSectionTypesFail = (error) => ({
  type: GET_SECTION_TYPES_FAIL,
  payload: error,
});

export const addSectionType = (sectionType) => ({
  type: ADD_SECTION_TYPE,
  payload: sectionType,
});

export const addSectionTypeSuccess = (sectionType) => ({
  type: ADD_SECTION_TYPE_SUCCESS,
  payload: sectionType,
});

export const addSectionTypeFail = (sectionType) => ({
  type: ADD_SECTION_TYPE_FAIL,
  payload: sectionType,
});

export const toggleSectionTypeActiveStatus = (sectionType, auth_user) => ({
  type: CHANGE_STATUS_SECTION_TYPE,
  payload: sectionType,
  auth_data: auth_user,
});

export const toggleSectionTypeActiveStatusSuccess = (sectionType) => ({
  type: CHANGE_STATUS_SECTION_TYPE_SUCCESS,
  payload: sectionType,
});

export const toggleSectionTypeActiveStatusFail = (error) => ({
  type: CHANGE_STATUS_SECTION_TYPE_FAIL,
  payload: error,
});

// export const getProduct = (product) => ({
//   type: GET_PRODUCT,
//   payload: product,
// });

// export const getProductSuccess = (product) => ({
//   type: GET_PRODUCT_SUCCESS,
//   payload: product,
// });

// export const getProductFail = (error) => ({
//   type: GET_PRODUCT_FAIL,
//   payload: error,
// });

export const updateSectionType = (sectionType) => ({
  type: UPDATE_SECTION_TYPE,
  payload: sectionType,
});

export const updateSectionTypeSuccess = (sectionType) => ({
  type: UPDATE_SECTION_TYPE_SUCCESS,
  payload: sectionType,
});

export const updateSectionTypeFail = (error) => ({
  type: UPDATE_SECTION_TYPE_FAIL,
  payload: error,
});

export const deleteSectionType = (sectionType, auth_user) => ({
  type: DELETE_SECTION_TYPE,
  payload: sectionType,
  auth_data: auth_user,
});

export const deleteSectionTypeSuccess = (sectionType) => ({
  type: DELETE_SECTION_TYPE_SUCCESS,
  payload: sectionType,
});

export const deleteSectionTypeFail = (error) => ({
  type: DELETE_SECTION_TYPE_FAIL,
  payload: error,
});
