import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Label,Button } from "reactstrap";
import $ from "jquery";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import Select from "react-select";
import "./notifyme.scss";
const NotifyMe = (props) => {
  const [details, setDetails] = useState([]);
  const [productList,setProductList] = useState([]);
  const [selectedProduct,setSelectedProduct] = useState(null);
  const [master,setMaster] = useState({});
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    handleTableData();
    fetch_all_products();
  }, []);
  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    setMaster({
        ...master,
        [e.target.name]:e.target.value
    });
    let productId = master?.product_id?master.product_id:"";
    handleTableData(date1, date2,productId);
  };
  function handleTableData(from_date = "", to_date = "",product_id="") {
    var url = `${API_URL}report/notifyme`;
    url = url + "?from_date=" + from_date + "&to_date=" + to_date + "&product_id=" + product_id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let reportData = [];
        var result = res.data.data;
        
        result.map((item, index) => {
          item.id = index + 1;
          item.notify_date = moment(item.notify_date).format("DD-MM-YYYY");
          reportData.push(item);
        });
        setDetails(reportData);
      });
  }

  function fetch_all_products() {
    axios
      .get(`${API_URL}product`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var product_data =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label: item.product_name_english,
              value: item.product_id,
            };
          });
          console.log('product_data: ', product_data);
        setProductList([
          {
            options: product_data,
          },
        ]);
      });
  }
  const handleSelectChange = (selected) => {
      setSelectedProduct(selected);
      setMaster({
          ...master,
          ["product_id"]:selected.value
      });
      let date1 = master?.from_date?master.from_date:"";
      let date2 = master?.to_date?master.to_date:"";
      handleTableData(date1, date2,selected.value);
   }
  const handleProductSearch = (query) => {
    let url = `${API_URL}section_product/search_product`;
    axios({
      method: "post",
      url: url,
      data: {
        product_name_english: query,
      },
      headers: {
        "x-access-token": accessToken,
      },
    }).then((res) => {
      let result = res.data.data;
      console.log("result: ", result);
      var data =
        res.data.data &&
        res.data.data.map((item) => {
          return {
            label: item.product_name_english,
            value: item.product_id
          };
        });
      setProductList([
        {
          options: data,
        },
      ]);
    });
  };
  const reset = () => {
    setSelectedProduct(null);
    setMaster({
        from_date:"",
        to_date:"",
        product_id:""
    });
    handleTableData();
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "notify_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Product Name",
        field: "product_name_english",
        sort: "asc",
        width: 400,
      },
      {
        label: "Customer",
        field: "customer_name",
        sort: "asc",
        width: 200,
      },
    //   {
    //     label: "Status",
    //     field: "Status",
    //     sort: "asc",
    //     width: 200,
    //   },
    ],
    rows: details,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Notify Me" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            onChange={handleDate}
                            value={master?.from_date}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            onChange={handleDate}
                            value={master?.to_date}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Product</Label>
                        <Select
                          name="product_id"
                          value={selectedProduct}
                          onChange={(selected) => {
                            handleSelectChange(selected);
                          }}
                          options={productList}
                          classNamePrefix="select2-selection"
                          onInputChange={(input) =>
                            handleProductSearch(input)
                          }
                        />
                      </div>
                    </Col>
                    <Col md="1">
                        <div className="mt-4" style={{paddingTop:"5px"}}>
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                  </Row>

                  <MDBDataTable
                    id="notifymeId"
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NotifyMe;
