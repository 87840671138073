import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  getUsers,
  addUser,
  deleteUser,
  apiError,
  getPrivilagesOptions,
  getCompaniesOptions,
  getBranchesOptions,
  updateUser,
  //getPrivilagesOptions,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import "./user.scss";

const DeliveryBoyReport = (props) => {
  const [usersForTable, setUsersForTable] = useState([]);
  const [dataForTable, setDataForTable] = useState([]);
  const [accountType, setAccountType] = useState("");

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Order ID",
        field: "district",
        sort: "asc",
        width: 400,
      },

      {
        label: "Customer Name",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mobile",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Send Date",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Product Name",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Location",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
    ],
    rows: usersForTable,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Deliveryboy Report" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    // onValidSubmit={(e, v) => {
                    //   handleValidSubmit(e, v);
                    // }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Sender</Label>
                          <Select
                            name="customer_community_id"
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">From</Label>
                          <div className="col-md-10">
                            <input
                              className="form-control"
                              type="date"
                              id="example-date-input"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">To</Label>
                          <div className="col-md-10">
                            <input
                              className="form-control"
                              type="date"
                              id="example-date-input"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="1">
                        <div className="mt-4">
                          <Button color="primary" type="submit">
                            Search
                          </Button>
                        </div>
                      </Col>
                      <Col md="1">
                        <div className="mt-4">
                          <Button color="danger" type="submit">
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={false}
                  />
                  <div className="float-end">
                  <div className="mt-4">
                    <input
                      type="text"
                      id="lname"
                      style={{ textAlign: "center" }}
                      name="lname"
                      disabled
                      placeholder="Grand Total "
                    />
                    <input />
                  </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(
  connect(mapStateToProps, { apiError })(DeliveryBoyReport)
);
