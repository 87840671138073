import {
  GET_COMMUNITY_SCHEDULE,
  GET_COMMUNITY_SCHEDULE_SUCCESS,
  GET_COMMUNITY_SCHEDULE_FAIL,
  CHANGE_STATUS_COMMUNITY,
  CHANGE_STATUS_COMMUNITY_SUCCESS,
  CHANGE_STATUS_COMMUNITY_FAIL,
} from './actionTypes';

const INIT_STATE = {
  communitySchedules: [],
  statusCommunityResponse: {},
  productIdToBeDeleted: '',
  product: {},
  error: {},
  community: {},
};

const communitySchedule = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMMUNITY_SCHEDULE:
      return {
        ...state,
        params: action.payload,
      };

    case GET_COMMUNITY_SCHEDULE_SUCCESS:
      return {
        ...state,
        communitySchedules: action.payload.data,
      };

    case GET_COMMUNITY_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CHANGE_STATUS_COMMUNITY:
      return {
        ...state,
        community: action.payload,
      };

    case CHANGE_STATUS_COMMUNITY_SUCCESS:
      let newunitarr = [];
      // let newUnits1 = state.community.filter((community) => {
      //   if (community.community_id === state.community.unit_id) {
      //     console.log(JSON.parse(JSON.stringify(action.payload.data[0])));
      //     newunitarr.push(JSON.parse(JSON.stringify(action.payload.data[0])));
      //   } else {
      //     newunitarr.push(community);
      //   }
      // });

      return {
        ...state,

        statusCommunityResponse: {
          type: 'success',
          message: 'Status Changed successfully',
        },
        community: newunitarr,
      };

    // return {
    //   ...state,

    //   statusCommunityResponse: {
    //     type: 'success',
    //     message: 'Status Changed successfully',
    //   },
    // };

    case CHANGE_STATUS_COMMUNITY_FAIL:
      return {
        ...state,
        statusCommunityResponse: {
          type: 'failure',
          message: 'Change Status failed',
        },
        error: action.payload,
      };
    default:
      return state;
  }
};

export default communitySchedule;
