import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import jwt_decode from "jwt-decode";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import moment from 'moment';
import axios from "axios";
import $ from "jquery";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  InputGroup,
  Progress,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import {
  getSectionBanners,
  apiError,
  getSectionTypes,
  addSectionBanner,
  updateSectionBanner,
  deleteSectionBanner,
  toggleSectionBannerActiveStatus,
  //getPrivilagesOptions,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import "./user.scss";

const OfferBanner = (props) => {
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [bannersForTable, setBannersForTable] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [sectionBannerIdTobeUpdated, setSectionBannerIdToBeUpdated] =
    useState(null);
  const [sectionBannerToBeDeleted, setSectionBannerToBeDeleted] =
    useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [activeStatus, setActiveStatus] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const formRef = useRef(null);
  const [uploadProgress, setUploadProgress] = useState();
  const [sectionId, setSectionId] = useState("");
  const {
    sectionBanners,
    addingSectionBanner,
    updatingSectionBanner,
    addSectionBannerResponse,
    updateSectionBannerResponse,
    deleteSectionBannerResponse,
    statusSectionBannerResponse,
    error,
  } = useSelector((state) => state.sectionBanner);
  const [userId, setUserId] = useState(1);
  const { sectionTypes } = useSelector((state) => state.sectionType);
  const [invalidsectionposition, setinvalidsectionposition] = useState('none');
  const [invalidsection, setinvalidsection] = useState('none');
  const dispatch = useDispatch();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [sectionPositionData, setSectionPositionData] = useState("");
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const sectionPositionList = [
    {
      label: "Left Large Banner",
      value: 1,
    },
    {
      label: "Left Top",
      value: 2,
    },
    {
      label: "Left Bottom",
      value: 3,
    },
    {
      label: "Right Top",
      value: 4,
    },
    {
      label: "Right Bottom",
      value: 5,
    },
  ];
  useEffect(() => {
    dispatch(getSectionBanners());
    dispatch(getSectionTypes());
    const sectionList = sectionTypes.map((item) => ({
      label: item.section_name,
      value: item.section_id,
    }));
  }, []);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = jwt_decode(localStorage.getItem("authUser"));
      setUserId(obj.user);
      setBannerData({ ["auth_userid"]: userId });
    }
  }, [userId]);

  useEffect(() => {
    let sectionDuplicate = JSON.parse(JSON.stringify(sectionBanners));
    let bannerData = [];
    console.log(sectionDuplicate);
    sectionDuplicate.map((item, index) => {
      item.from_date = moment(item.section_banner_from_date).format('DD/MM/YYYY');
      item.to_date = moment(item.section_banner_to_date).format('DD/MM/YYYY');
      item.section_banner_from_date = moment(item.section_banner_from_date).format('YYYY-MM-DD');
      item.section_banner_to_date = moment(item.section_banner_to_date).format('YYYY-MM-DD');
      if(item.section_banner_position==1)
      {
        item.section_banner_position1 = "Left Large Banner";
      }
      else if(item.section_banner_position==2)
      {
        item.section_banner_position1 = "Left Top";
      }
      else if(item.section_banner_position==3)
      {
        item.section_banner_position1 = "Left Bottom";
      }
      else if(item.section_banner_position==4)
      {
        item.section_banner_position1 = "Right Top";
      }
      else if(item.section_banner_position==5)
      {
        item.section_banner_position1 = "Right Bottom";
      }
      item.banner_status1 = (
        <div style={{ textAlign: "center", width: "100%" }}>
          <div
            class="form-check form-switch mb-3"
            style={{ textAlign: "center", marginLeft: "40%" }}
          >
            <input
              type="checkbox"
              class="form-check-input"
              id="customSwitch2"
              checked={(item.section_image_active_status==0)?true:false}
              onClick={(e) => {
                dispatch(
                  toggleSectionBannerActiveStatus(
                    item.section_banner_id,
                    userId
                  )
                );
                settoggleSwitch(!toggleSwitch);
              }}
            />
          </div>
        </div>
      );

      item.action = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            className="far fa-edit"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.5em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              preUpdateOfferBanner(item);
            }}
          ></i>
          <i
            className="far fa-trash-alt"
            style={{ fontSize: "1em", cursor: "pointer" }}
            onClick={() => {
              setSectionBannerToBeDeleted(item.section_banner_id);
              setConfirmDeleteAlert(true);
            }}
          ></i>
        </div>
      );
      item.id = index + 1;
      item.section_name = item.section?.section_name;
      //item.from_date = item.section_banner_from_date;
      //item.to_date = item.section_banner_to_date;

      // item.section_icons = "";
      if (item.section_banners_web != "") {
        item.web_icon = (
          <div style={{ textAlign: "center" }}>
            <img
              src={
                API_URL +
                `public/uploads/section_banner/${item.section_banners_web}`
              }
              style={{ width: "100%"}}
            />
          </div>
        );
      }
      if (item.section_banners_app != "") {
        item.app_icon = (
          <div style={{ textAlign: "center" }}>
            <img
              src={
                API_URL +
                `public/uploads/section_banner/${item.section_banners_app}`
              }
              style={{ width: "100%"}}
            />
          </div>
        );
      }
      bannerData.push(item);
    });
    setBannersForTable(bannerData);
  }, [sectionBanners]);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Section",
        field: "section_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Banner",
        field: "section_banner_name",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Offer For",
      //   field: "localbodytype",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Position",
        field: "section_banner_position1",
        sort: "asc",
        width: 200,
      },
      {
        label: "From",
        field: "from_date",
        sort: "asc",
        width: 200,
      },
      {
        label: "To",
        field: "to_date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Web",
        field: "web_icon",
        sort: "asc",
        width: 200,
      },
      {
        label: "App",
        field: "app_icon",
        sort: "asc",
        width: 200,
      },
      {
        label: "URL",
        field: "section_banners_url",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "banner_status1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: bannersForTable,
  };

  useEffect(() => {
    if (addSectionBannerResponse.type === "success") {
      toastr.success(addSectionBannerResponse.message);
      setBannerData({["auth_userid"]:userId});
    } else if (addSectionBannerResponse.type === "failure") {
      toastr.error(error.data.message, addSectionBannerResponse.message);
    }
  }, [addSectionBannerResponse]);

  useEffect(() => {
    if (updateSectionBannerResponse.type === "success") {
      setShowModal(false);
      setSectionBannerIdToBeUpdated(null);
      setBannerData({["auth_userid"]:userId});
      toastr.success(updateSectionBannerResponse.message);
    } else if (updateSectionBannerResponse.type === "failure") {
      toastr.error(error.data.message, updateSectionBannerResponse.message);
    }
  }, [updateSectionBannerResponse]);

  useEffect(() => {
    if (deleteSectionBannerResponse.type === "success") {
      toastr.success(deleteSectionBannerResponse.message);
    } else if (deleteSectionBannerResponse.type === "failure") {
      toastr.error(error.data.message, deleteSectionBannerResponse.message);
    }
  }, [deleteSectionBannerResponse]);

  useEffect(() => {
    if (statusSectionBannerResponse.type === "success") {
      toastr.success(statusSectionBannerResponse.message);
    } else if (statusSectionBannerResponse.type === "failure") {
      toastr.error(error.data.message, statusSectionBannerResponse.message);
    }
  }, [statusSectionBannerResponse]);

  let preUpdateOfferBanner = (item) => {
    console.log(item, "item");
    if (item.section_id) {
      let newSectionId = {
        value:item.section_id,
        label:item.section_name
      }
      console.log(newSectionId);
      setSectionId(newSectionId);
      bannerData.section_id = newSectionId;
    }
    let bannerActiveStatus = activeStatusList.filter(
      (it) => it.value === item.section_banner_status
    );
    setActiveStatus(bannerActiveStatus);
    bannerData.section_banner_status = bannerActiveStatus;

    
    let bannerposition = sectionPositionList.filter(
      (it) => it.value === item.section_banner_position
    );
    setSectionPositionData(bannerposition);
    bannerData.section_banner_position = bannerposition;

    setSectionBannerIdToBeUpdated(item.section_banner_id);
    
    if(item.section_banner_position === 1) {
      $("#app_size").text('600x280');
    }
    else{
      $("#app_size").text('480x280');
    }
    setBannerData(item);
  };

  let onImageUpload = (e, from) => {
    let fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}section_banner/uploadBannerSingleImage`, fd, {
        headers: {
          "x-access-token": accessToken,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setBannerData({
            ...bannerData,
            [`${
              from === "app" ? "section_banners_app" : "section_banners_web"
            }`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const activeStatusList = [
    {
      label: "Active",
      value: 1,
    },
    {
      label: "Inactive",
      value: 0,
    },
  ];

  const deleteBannerWebIcon = () => {
    setBannerData({
      ...bannerData,
      [`section_banners_web`]: "",
    });
  };

  const deleteBannerAppIcon = () => {
    setBannerData({
      ...bannerData,
      [`section_banners_app`]: "",
    });
  };

  const sectionList = sectionTypes.map((item) => ({
    label: item.section_name,
    value: item.section_id,
  }));

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setBannerData({
      ...bannerData,
      [name]: value,
    });
  };
  function handleFileSize(e)
  {
    console.log(' e.value: ',  e.value);
    if(typeof e.value!="undefined")
    {
      setinvalidsectionposition('none');
      $("#section_banner_position").attr('class',"select2-selection");
    }
    else
    {
      setinvalidsectionposition('block');
      $("#section_banner_position").attr('class',"select2-selection border border-danger");
    }
    setSectionPositionData(e);
    setBannerData({
      ...bannerData,
      [`section_banner_position`]: e.value,
    });
    if(e.value==1)
    {
      $("#app_size").text('600x280');
    }
    else
    {
      $("#app_size").text('480x280');
    }
  }

  const handleValidSubmitUnit = (event) => {
    event.preventDefault();
    if(typeof bannerData.section_id=="undefined")
    {
      setinvalidsection('block');
      $("#section_id").attr('class',"select2-selection border border-danger");
      toTop();
      return;
    }
    else
    {
      setinvalidsection('none');
      $("#section_id").attr('class',"select2-selection");
    }

    if(typeof bannerData.section_banner_position=="undefined")
    {
      setinvalidsectionposition('block');
      $("#section_banner_position").attr('class',"select2-selection border border-danger");
      toTop();
      return;
    }
    else
    {
      setinvalidsectionposition('none');
      $("#section_banner_position").attr('class',"select2-selection");
    }
   
    if (
      bannerData.section_banners_app &&
      bannerData.section_banners_app.length > 2
    ) {
      if (
        bannerData.section_banners_app.indexOf("uploads/section_banner/") > -1
      ) {
        setBannerData({
          ...bannerData,
          [`section_banners_app`]: bannerData.section_banners_app.slice(
            bannerData.section_banners_app.indexOf("uploads/section_banner/") +
              23
          ),
        });
      }
    }
    if (
      bannerData.section_banners_web &&
      bannerData.section_banners_web.length > 2
    ) {
      if (
        bannerData.section_banners_web.indexOf("uploads/section_banner/") > -1
      ) {
        setBannerData({
          ...bannerData,
          [`section_banners_web`]: bannerData.section_banners_web.slice(
            bannerData.section_banners_web.indexOf("uploads/section_banner/") +
              23
          ),
        });
      }
    }

    sectionBannerIdTobeUpdated
      ? dispatch(updateSectionBanner(bannerData))
      : dispatch(addSectionBanner(bannerData));

    formRef.current.reset();
    setActiveStatus("");
    setSectionId("");
    setSectionPositionData("");
    setBannerData({ ["auth_userid"]: userId });
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteSectionBanner(sectionBannerToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Offer Banner" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitUnit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="sectionId">Choose Section</Label>
                          <Select
                            name="section_id"
                            id="section_id"
                            classNamePrefix="select2-selection"
                            validate={{ required: { value: true } }}
                            value={sectionId}
                            onChange={(value) => {
                              setSectionId(value);
                              bannerData.section_id = value?.value;
                              if(typeof value.value!="undefined")
                                {
                                  setinvalidsection('none');
                                  $("#section_id").attr('class',"select2-selection");
                                }
                                else
                                {
                                  setinvalidsection('block');
                                  $("#section_id").attr('class',"select2-selection border border-danger");
                                }
                            }}
                            options={sectionList}
                          />
                        </div>
                        <div style={{ display:invalidsection,Width: '100%',marginTop:'0.25rem',color: '#f46a6a',fontSize:'87.5%'}}>Select Section</div>
                        
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="sectionBannerPosition">
                            Banner Position
                          </Label>
                          {/* <AvField
                            name="section_banner_position"
                            placeholder="Banner Position"
                            type="number"
                            value={bannerData.section_banner_position}
                            errorMessage="Enter Banner Position"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="sectionBannerPosition"
                            onChange={handleChangeInput}
                          /> */}
                            <Select
                            name="section_banner_position"
                            value={sectionPositionData}
                            onChange={(value) => {
                              handleFileSize(value);
                            }}
                            id="section_banner_position"
                            options={sectionPositionList}
                            classNamePrefix="select2-selection"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                        <div style={{ display:invalidsectionposition,Width: '100%',marginTop:'0.25rem',color: '#f46a6a',fontSize:'87.5%'}}>Select Position</div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="bannerName">Banner Name</Label>
                          <AvField
                            name="section_banner_name"
                            placeholder="Banner Name"
                            type="text"
                            value={bannerData.section_banner_name}
                            errorMessage="Enter Banner Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="bannerName"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                     

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="url">URL</Label>
                          <AvField
                            name="section_banners_url"
                            placeholder="Banners URL"
                            type="text"
                            value={bannerData.section_banners_url}
                            errorMessage="Enter URL"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="url"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3" style={{display:'none'}}>
                        <div className="mb-3">
                          <Label htmlFor="activeStatus">Active Status</Label>
                          <Select
                            name="section_banners_status"
                            value={activeStatus}
                            onChange={(value) => {
                              setActiveStatus(value);
                              bannerData.section_banner_status = value?.value;
                            }}
                            id="activeStatus"
                            options={activeStatusList}
                            classNamePrefix="select2-selection"
                            validate={{ required: { value: false } }}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>From Date</Label>
                          {/* <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="dd M,yyyy"
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                              }}
                              value={bannerData.section_banner_from_date}
                              onChange={(date) => {
                                bannerData.section_banner_from_date = date[0];
                              }}
                            />
                          </InputGroup> */}
                           <AvField
                            name="section_banner_from_date"
                            placeholder="Section Start Date"
                            type="date"
                            value={bannerData.section_banner_from_date}
                            errorMessage="Select Start Date"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="section_banner_from_date"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>To Date</Label>
                          {/* <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="dd M,yyyy"
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                              }}
                              value={bannerData.section_banner_to_date}
                              onChange={(date) => {
                                bannerData.section_banner_to_date = date[0];
                              }}
                            />
                          </InputGroup> */}
                          <AvField
                            name="section_banner_to_date"
                            placeholder="Section End Date"
                            type="date"
                            value={bannerData.section_banner_to_date}
                            errorMessage="Select End Date"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="section_banner_to_date"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="webImage">Web Image(<span id="web_size">1000x667</span>)</Label>
                          {bannerData.section_banners_web &&
                          bannerData.section_banners_web.length > 2 ? (
                            <div div className="img-wraps">
                              {bannerData.section_banners_web &&
                              bannerData.section_banners_web.startsWith(
                                "http"
                              ) ? (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${bannerData.section_banners_web}`}
                                />
                              ) : (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${API_URL}public/uploads/section_banner/${bannerData.section_banners_web}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteBannerWebIcon}
                                style={{ width: "200px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="section_banners_web"
                              id="webImage"
                              type="file"
                              errorMessage="Upload Web Image"
                              className="form-control"
                              onChange={(e) => onImageUpload(e, "web")}
                              validate={{ required: { value: true } }}
                            />
                          )}
                          {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )}
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="appImage">App Image(<span id="app_size">480x280</span>)</Label>
                          {bannerData.section_banners_app &&
                          bannerData.section_banners_app.length > 2 ? (
                            <div div className="img-wraps">
                              {bannerData.section_banners_app &&
                              bannerData.section_banners_app.startsWith(
                                "http"
                              ) ? (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${bannerData.section_banners_app}`}
                                />
                              ) : (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${API_URL}public/uploads/section_banner/${bannerData.section_banners_app}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteBannerAppIcon}
                                style={{ width: "200px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="section_banners_app"
                              id="appImage"
                              type="file"
                              errorMessage="Upload App Image"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              onChange={(e) => onImageUpload(e, "app")}
                            />
                          )}
                          {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )}
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mt-4">
                          {sectionBannerIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="Submit"
                              disabled={updatingSectionBanner ? true : false}
                            >
                              {updatingSectionBanner ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingSectionBanner ? true : false}
                            >
                              {addingSectionBanner ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    info={false}
                    entries={25}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};
export default withRouter(connect(mapStateToProps, { apiError })(OfferBanner));
