import {
  GET_HARVESTORDERLISTS,
  GET_HARVESTORDERLISTS_FAIL,
  GET_HARVESTORDERLISTS_SUCCESS,
} from './actionTypes';

export const getHarvestlist = (orderDate) => ({
  type: GET_HARVESTORDERLISTS,
  payload: orderDate,
});

export const getHarvestlistSuccess = (deliverylists) => ({
  type: GET_HARVESTORDERLISTS_SUCCESS,
  payload: deliverylists,
});

export const getHarvestlistsFail = (error) => ({
  type: GET_HARVESTORDERLISTS_FAIL,
  payload: error,
});

// export const getDeliverylist = (deliverylist) => ({
//   type: GET_DELIVERYLIST,
//   payload: deliverylist,
// });

// export const getDeliverylistSuccess = (deliverylist) => ({
//   type: GET_DELIVERYLIST_SUCCESS,
//   payload: deliverylist,
// });

// export const getDeliverylistFail = (error) => ({
//   type: GET_DELIVERYLIST_FAIL,
//   payload: error,
// });
