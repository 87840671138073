  
import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';

import {
  GET_CUSTOMER,
  ADD_CUSTOMER,
  DELETE_CUSTOMER,
  UPDATE_CUSTOMER,
  GET_CUSTOMERS,
  GET_CUSTOMERORDER,
} from './actionTypes';

import {
  getCustomersSuccess,
  getCustomersFail,
  getCustomerSuccess,
  getCustomerFail,
  addCustomerFail,
  addCustomerSuccess,
  updateCustomerFail,
  updateCustomerSuccess,
  deleteCustomerFail,
  deleteCustomerSuccess,
  getCustomerOrderSuccess,
  getCustomerOrderFail,
} from './actions';

//Include Both Helper File with needed methods
import {
  getCustomers,
  getCustomer,
  addCustomer,
  updateCustomer,
  deleteCustomer,
  getCustomerOrder,
} from '../../helpers/backend_helper';

function* fetchCustomers() {
  try {
    const response = yield call(getCustomers);
    yield put(getCustomersSuccess(response));
  } catch (error) {
    yield put(getCustomersFail(error));
  }
}
/// get Customer Order
function* getCustomerOrderList({ payload: customerId }) {
  try {
    console.log('Customer Id is Saga' + customerId);
    const response = yield call(getCustomerOrder, customerId);
    console.log('Customer order response' + response);
    yield put(getCustomerOrderSuccess(response));
  } catch (error) {
    yield put(getCustomerOrderFail(error.error));
  }
}

function* onGetCustomer() {
  try {
    const response = yield call(getCustomer);
    yield put(getCustomerSuccess(response));
  } catch (error) {
    yield put(getCustomerFail(error.response));
  }
}

function* onAddCustomer({ payload: customer }) {
  try {
    const response = yield call(addCustomer, customer);
    yield put(addCustomerSuccess(response));
  } catch (error) {
    yield put(addCustomerFail(error.response));
  }
}

function* onUpdateCustomer({ payload: customer }) {
  //console.log(customer);
  try {
    const response = yield call(updateCustomer, customer);
    yield put(updateCustomerSuccess(response));
  } catch (error) {
    yield put(updateCustomerFail(error.response));
  }
}

function* onDeleteCustomer({ payload: customerId, auth_data: auth_user }) {
  try {
    const response = yield call(deleteCustomer, customerId, auth_user);
    yield put(deleteCustomerSuccess(response));
  } catch (error) {
    yield put(deleteCustomerFail(error.response));
  }
}

function* customerSaga() {
  yield takeEvery(GET_CUSTOMERORDER, getCustomerOrderList);
  yield takeEvery(GET_CUSTOMERS, fetchCustomers);
  yield takeEvery(GET_CUSTOMER, onGetCustomer);
  yield takeEvery(ADD_CUSTOMER, onAddCustomer);
  yield takeEvery(UPDATE_CUSTOMER, onUpdateCustomer);
  yield takeEvery(DELETE_CUSTOMER, onDeleteCustomer);
}

export default customerSaga;