export const GET_SECTION_PRODUCT = 'GET_SECTION_PRODUCT';
export const GET_SECTION_PRODUCT_SUCCESS = 'GET_SECTION_PRODUCT_SUCCESS';
export const GET_SECTION_PRODUCT_FAIL = 'GET_SECTION_PRODUCT_FAIL';

export const ADD_SECTION_PRODUCT = 'ADD_SECTION_PRODUCT';
export const ADD_SECTION_PRODUCT_SUCCESS = 'ADD_SECTION_PRODUCT_SUCCESS';
export const ADD_SECTION_PRODUCT_FAIL = 'ADD_SECTION_PRODUCT_FAIL';

export const UPDATE_SECTION_PRODUCT = 'UPDATE_SECTION_PRODUCT';
export const UPDATE_SECTION_PRODUCT_SUCCESS = 'UPDATE_SECTION_PRODUCT_SUCCESS';
export const UPDATE_SECTION_PRODUCT_FAIL = 'UPDATE_SECTION_PRODUCT_FAIL';

export const DELETE_SECTION_PRODUCT = 'DELETE_SECTION_PRODUCT';
export const DELETE_SECTION_PRODUCT_SUCCESS = 'DELETE_SECTION_PRODUCT_SUCCESS';
export const DELETE_SECTION_PRODUCT_FAIL = 'DELETE_SECTION_PRODUCT_FAIL';

export const GET_SECTION_PRODUCT_SEARCH = 'GET_SECTION_PRODUCT_SEARCH';
export const GET_SECTION_PRODUCT_SEARCH_SUCCESS = 'GET_SECTION_PRODUCT_SEARCH_SUCCESS';
export const GET_SECTION_PRODUCT_SEARCH_FAIL = 'GET_SECTION_PRODUCT_SEARCH_FAIL';