import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  Table
} from 'reactstrap'
import { MDBDataTable } from 'mdbreact'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import $ from 'jquery'
import accessToken from '../../../helpers/jwt-token-access/accessToken'
import axios from 'axios'
import { apiError } from '../../../store/actions'


const GstInOut = props => {
  const [
    gstSummaryTable,
    setGstSummaryTable,
    gstBalancetable,
    setGstBalanceTable
  ] = useState([])
  const API_URL = process.env.REACT_APP_APIURL || 'http://localhost:3099/'

  const data1 = {
    columns: [
      {
        label: '#Purch No',
        field: 'n_PurchaseNo',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Date',
        field: 'd_date',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Taxable',
        field: 'n_Taxable',
        sort: 'asc',
        width: 150
      },
      {
        label: 'SGST',
        field: 'n_SGST',
        sort: 'asc',
        width: 150
      },
      {
        label: 'CGST',
        field: 'n_CGST',
        sort: 'asc',
        width: 150
      },
      {
        label: 'IGST',
        field: 'n_IGST',
        sort: 'asc',
        width: 150
      },
      {
        label: 'GST',
        field: 'n_GST',
        sort: 'asc',
        width: 150
      }
    ],
    rows: gstSummaryTable
  };
  const data2 = {
    columns: [
      {
        label: '#Inv No',
        field: 'n_InvoiceNo',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Date',
        field: 'd_InvDate',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Taxable',
        field: 'n_InvTaxable',
        sort: 'asc',
        width: 150
      },
      {
        label: 'SGST',
        field: 'n_InvSGST',
        sort: 'asc',
        width: 150
      },
      {
        label: 'CGST',
        field: 'n_InvCGST',
        sort: 'asc',
        width: 150
      },
      {
        label: 'IGST',
        field: 'n_InvIGST',
        sort: 'asc',
        width: 150
      },
      {
        label: 'GST',
        field: 'n_InvGST',
        sort: 'asc',
        width: 150
      }
    ],
    rows: gstBalancetable
  };
  let handleDate = () => {
    var date1 = $('#from_date').val()
    var date2 = $('#to_date').val()
    // handleTableData(date1,date2);
  }
  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumbs title='Home' breadcrumbItem='Gst Report' />
        </div>
      </div>
      <Row>
        <Col className='col-12'>
          <Card>
            <CardBody>
              <Row>
                <Col md='3'>
                  <div className='mb-3'>
                    <Label htmlFor='validationCustom05'>From</Label>
                    <div className='col-md-10'>
                      <input
                        className='form-control'
                        type='date'
                        id='from_date'
                        name='from_date'
                        onChange={handleDate}
                      />
                    </div>
                  </div>
                </Col>
                <Col md='3'>
                  <div className='mb-3'>
                    <Label htmlFor='validationCustom05'>To</Label>
                    <div className='col-md-10'>
                      <input
                        className='form-control'
                        type='date'
                        id='to_date'
                        name='to_date'
                        onChange={handleDate}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row md='8'>
                <Col>
                  <Table className='table table-bordered'>
                    <thead>
                      <tr>
                        <th scope='col' colspan='4'>
                          Summary
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan='2'>Input Tax-Purchase</td>
                        <td colspan='2'>Output Tax-Sales</td>
                      </tr>
                      <tr>
                        <th scope='row'>Particulars</th>
                        <th scope='row'>Amount</th>
                        <th scope='row'>Particulars</th>
                        <th scope='row'>Amount</th>
                      </tr>
                      <tr>
                        <td>dummy</td>
                        <td>1200</td>
                        <td>dummy</td>
                        <td>1200</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table className='table table-bordered'>
                    <thead>
                      <tr>
                        <th scope='col' colspan='4'>
                          Balance
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>dummy</td>
                        <td>1200</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <MDBDataTable
                responsive
                striped
                bordered
                data={data1}
                info={true}
                searching={true}
              />
              <MDBDataTable
                responsive
                striped
                bordered
                data={data2}
                info={false}
                searching={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}
const mapStateToProps = state => {}

export default withRouter(connect(mapStateToProps, { apiError })(GstInOut))
