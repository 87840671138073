import {
  GET_ACCDETAILS,
  GET_ACCDETAILS_FAIL,
  GET_ACCDETAILS_SUCCESS,
  ADD_ACCDETAIL,
  ADD_ACCDETAIL_FAIL,
  ADD_ACCDETAIL_SUCCESS,
  UPDATE_ACCDETAIL,
  UPDATE_ACCDETAIL_FAIL,
  UPDATE_ACCDETAIL_SUCCESS,
  DELETE_ACCDETAIL,
  DELETE_ACCDETAIL_FAIL,
  DELETE_ACCDETAIL_SUCCESS,
  GET_ACCDETAIL_OPTIONS,
  GET_ACCDETAIL_OPTIONS_SUCCESS,
  GET_ACCDETAIL_OPTIONS_FAIL,
} from './actionTypes';

export const getAllAccountDetails = () => ({
  type: GET_ACCDETAILS,
});

export const getAllAccountDetailsSuccess = (accdetail) => ({
  type: GET_ACCDETAILS_SUCCESS,
  payload: accdetail,
});

export const getAllAccountDetailsFail = (error) => ({
  type: GET_ACCDETAILS_FAIL,
  payload: error,
});

export const addAccountDetail = (accdetail) => ({
  type: ADD_ACCDETAIL,
  payload: accdetail,
});

export const addAccountDetailSuccess = (accdetail) => ({
  type: ADD_ACCDETAIL_SUCCESS,
  payload: accdetail,
});

export const addAccountDetailFail = (error) => ({
  type: ADD_ACCDETAIL_FAIL,
  payload: error,
});

export const updateAccountDetail = (accdetail) => ({
  type: UPDATE_ACCDETAIL,
  payload: accdetail,
});

export const updateAccountDetailSuccess = (accdetail) => ({
  type: UPDATE_ACCDETAIL_SUCCESS,
  payload: accdetail,
});

export const updateAccountDetailFail = (error) => ({
  type: UPDATE_ACCDETAIL_FAIL,
  payload: error,
});

export const deleteAccountDetail = (accdetail, auth_user) => ({
  type: DELETE_ACCDETAIL,
  payload: accdetail,
  auth_data: auth_user,
});

export const deleteAccountDetailSuccess = (accdetail) => ({
  type: DELETE_ACCDETAIL_SUCCESS,
  payload: accdetail,
});

export const deleteAccountDetailFail = (error) => ({
  type: DELETE_ACCDETAIL_FAIL,
  payload: error,
});

export const accountDetailOptions = () => ({
  type: GET_ACCDETAIL_OPTIONS,
});

export const accountDetailOptionsSuccess = (privilages) => ({
  type: GET_ACCDETAIL_OPTIONS_SUCCESS,
  payload: privilages,
});

export const accountDetailOptionsFail = (error) => ({
  type: GET_ACCDETAIL_OPTIONS_FAIL,
  payload: error,
});
