import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';

import {
  GET_COMMUNITY_SCHEDULE,
  GET_COMMUNITY_SCHEDULE_SUCCESS,
  GET_COMMUNITY_SCHEDULE_FAIL,
  CHANGE_STATUS_COMMUNITY,
  CHANGE_STATUS_COMMUNITY_SUCCESS,
  CHANGE_STATUS_COMMUNITY_FAIL,
} from './actionTypes';

import {
  getCommunityScheduleSuccess,
  getCommunityScheduleFail,
  toggleCommunityActiveStatusSuccess,
  toggleCommunityActiveStatusFail,
} from './actions';

//Include Both Helper File with needed methods
import {
  getCommunitySchedule,
  toggleCommunityActiveStatus,
} from '../../helpers/backend_helper';

function* fetchCommunitySchedule() {
  try {
    const response = yield call(getCommunitySchedule);
    yield put(getCommunityScheduleSuccess(response));
  } catch (error) {
    yield put(getCommunityScheduleFail(error));
  }
}

function* onToggleCommunityScheduleActiveStatus({ payload: community }) {
  try {
    const response = yield call(toggleCommunityActiveStatus, community);
    yield put(toggleCommunityActiveStatusSuccess(response));
  } catch (error) {
    yield put(toggleCommunityActiveStatusFail(error.response));
  }
}


function* communityScheduleSaga() {
  yield takeEvery(GET_COMMUNITY_SCHEDULE, fetchCommunitySchedule);

  yield takeEvery(
    CHANGE_STATUS_COMMUNITY,
    onToggleCommunityScheduleActiveStatus
  );
}

export default communityScheduleSaga;
