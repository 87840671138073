/* USERS */

export const GET_SECTIONBANNERS = "GET_SECTIONBANNERS";
export const GET_SECTIONBANNERS_SUCCESS = "GET_SECTIONBANNERS_SUCCESS";
export const GET_SECTIONBANNERS_FAIL = "GET_SECTIONBANNERS_FAIL";

export const ADD_SECTIONBANNER = "ADD_SECTIONBANNER";
export const ADD_SECTIONBANNER_SUCCESS = "ADD_SECTIONBANNER_SUCCESS";
export const ADD_SECTIONBANNER_FAIL = "ADD_SECTIONBANNER_FAIL";

export const UPDATE_SECTIONBANNER = "UPDATE_SECTIONBANNER";
export const UPDATE_SECTIONBANNER_SUCCESS = "UPDATE_SECTIONBANNER_SUCCESS";
export const UPDATE_SECTIONBANNER_FAIL = "UPDATE_SECTIONBANNER_FAIL";

export const DELETE_SECTIONBANNER = "DELETE_SECTIONBANNER";
export const DELETE_SECTIONBANNER_SUCCESS = "DELETE_SECTIONBANNER_SUCCESS";
export const DELETE_SECTIONBANNER_FAIL = "DELETE_SECTIONBANNER_FAIL";

export const CHANGE_STATUS_SECTIONBANNER = "CHANGE_STATUS_SECTIONBANNER";
export const CHANGE_STATUS_SECTIONBANNER_SUCCESS =
  "CHANGE_STATUS_SECTIONBANNER_SUCCESS";
export const CHANGE_STATUS_SECTIONBANNER_FAIL =
  "CHANGE_STATUS_SECTIONBANNER_FAIL";
