export const GET_STOCKLOGS = 'GET_STOCKLOGS';
export const GET_STOCKLOGS_SUCCESS = 'GET_STOCKLOGS_SUCCESS';
export const GET_STOCKLOGS_FAIL = 'GET_STOCKLOGS_FAIL';

export const GET_STOCKLOG = 'GET_STOCKLOG';
export const GET_STOCKLOG_SUCCESS = 'GET_STOCKLOG_SUCCESS';
export const GET_STOCKLOG_FAIL = 'GET_STOCKLOG_FAIL';

export const ADD_STOCKLOG = 'ADD_STOCKLOG';
export const ADD_STOCKLOG_SUCCESS = 'ADD_STOCKLOG_SUCCESS';
export const ADD_STOCKLOG_FAIL = 'ADD_STOCKLOG_FAIL';

export const UPDATE_STOCKLOG = 'UPDATE_STOCKLOG';
export const UPDATE_STOCKLOG_SUCCESS = 'UPDATE_STOCKLOG_SUCCESS';
export const UPDATE_STOCKLOG_FAIL = 'UPDATE_STOCKLOG_FAIL';

export const DELETE_STOCKLOG = 'DELETE_STOCKLOG';
export const DELETE_STOCKLOG_SUCCESS = 'DELETE_STOCKLOG_SUCCESS';
export const DELETE_STOCKLOG_FAIL = 'DELETE_STOCKLOG_FAIL';
