import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import $ from "jquery";
import { push } from "connected-react-router";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  getOrders,
  getOrder,
  apiError,
  //getPrivilagesOptions,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link,withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { formatMoney } from "../../../helpers/generalFunctions";
// import "./user.scss";

const ViewOrder = (props) => {
  const [viewOrderForTable, setViewOrderForTable] = useState([]);
  const [orderViewModalOpen, setOrderViewModalOpen] = useState(null);
  const [master, setMaster] = useState({});
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const dispatch = useDispatch();
  const { orders, order } = useSelector((state) => state.orders);
  console.log("orders:.......>>> ", orders);
  console.log("order:---- ", order);
  useEffect(() => {
    dispatch(getOrders());
  }, []);

  useEffect(() => {
    let viewOrders = JSON.parse(JSON.stringify(orders));
    let ordersData = [];
    viewOrders.map((item, index) => {
      item.id = index + 1;
      item.date = moment(item.order_date).local().format("DD/MM/YYYY");
      item.time = moment(item.order_time, "h:mm:ss.*").format("hh:mm a");
      if (item.order_payment_status === 1)
        item.payment_status = (
          <div
            class="btn-group btn-group-sm"
            role="group"
            aria-label="Basic example"
          >
            <button
              class="btn btn-danger"
              style={{ padding: "1px", minWidth: "50px" }}
            >
              Not Paid
            </button>
          </div>
        );
      else if (item.order_payment_status === 2)
        item.payment_status = (
          <div
            class="btn-group btn-group-sm"
            role="group"
            aria-label="Basic example"
          >
            <button
              class="btn btn-success"
              style={{ padding: "1px", minWidth: "50px" }}
            >
              Paid
            </button>
          </div>
        );
      else if (item.order_payment_status === 3)
        item.payment_status = (
          <div
            class="btn-group btn-group-sm"
            role="group"
            aria-label="Basic example"
          >
            <button
              class="btn btn-warning"
              style={{ padding: "1px", minWidth: "50px" }}
            >
              Failed
            </button>
          </div>
        );
      item.amount_column = (
        <div className="text-right">{item.order_grand_amount.toFixed(2)}</div>
      );
      if (item.order_from == 0) item.order_from = "CRM";
      else if (item.order_from == 1) item.order_from = "Website";
      else if (item.order_from == 2) item.order_from = "Android";
      else if (item.order_from == 3) item.order_from = "IOS";
      else if (item.order_from == 4) item.order_from = "Whatsapp";
      else if (item.order_from == 5) item.order_from = "POS";
      // item.action = (
      //   <div style={{ display: "flex", justifyContent: "center" }}>
      //     <i
      //       className="fas fa-file-alt"
      //       style={{
      //         fontSize: "1em",
      //         cursor: "pointer",
      //         marginLeft: "0.5em",
      //         marginRight: "0.5em",
      //       }}
      //       onClick={() => {
      //         dispatch(getOrder(item.order_id));
      //         // showSingleOrder();
      //         setOrderViewModalOpen(true);
      //       }}
      //     ></i>
      //     <i
      //       className="far fa-edit"
      //       style={{
      //         fontSize: "1em",
      //         cursor: "pointer",
      //         marginLeft: "0.5em",
      //         marginRight: "0.5em",
      //       }}
      //       onClick={() => {
      //         window.location.assign("/add-order/" + item.order_id);
      //       }}
      //     ></i>
      //   </div>
      // );

      ordersData.push(item);
    });
    setViewOrderForTable(ordersData);
  }, [orders]);

  // const showSingleOrder = (item) => {
  //   console.log(order, "order");
  // };
  let handleDate = () => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    //handleTableData(date1, date2);
  };
  // const printOrder = () => {
  //   window.print();
  // }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Order#",
        field: "orders_unique_id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Source",
        field: "order_from",
        sort: "asc",
        width: 100,
      },
      {
        label: "Name",
        field: "order_customer_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Moblie",
        field: "order_contact_number",
        sort: "asc",
        width: 100,
      },
      {
        label: "Amount",
        field: "amount_column",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "payment_status",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "Action",
      //   field: "action",
      //   sort: "disabled",
      //   width: 100,
      // },
    ],
    rows: viewOrderForTable,
  };
  let dataLog = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "SKU",
        field: "sku",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 200,
      },
      {
        label: "HSN",
        field: "hsn",
        sort: "asc",
        width: 200,
      },
      {
        label: "Unit",
        field: "unit",
        sort: "asc",
        width: 200,
      },
      {
        label: "Qty",
        field: "qty",
        sort: "asc",
        width: 200,
      },
      {
        label: "Rate",
        field: "rate",
        sort: "asc",
        width: 200,
      },
      {
        label: "MRP",
        field: "mrp",
        sort: "asc",
        width: 200,
      },
      {
        label: "Discount",
        field: "discount",
        sort: "asc",
        width: 200,
      },
      {
        label: "GST(%)",
        field: "gst",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 200,
      },
    ],
  };
  return (
    <React.Fragment>
      <Modal
        isOpen={orderViewModalOpen}
        toggle={() => setOrderViewModalOpen(!orderViewModalOpen)}
        size="xl"
      >
        {/* <ModalHeader charCode="Y">Order Details</ModalHeader> */}
        <div className="modal-header">
          <h5 className="modal-title mt-0">Order Details </h5>
          <button
            type="button"
            onClick={() => {
              setOrderViewModalOpen(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      <h4 className="float-end font-size-16">
                        {/* Order Estimate # {order?.orders_unique_id} */}
                        <span>
                          {"Order Estimate"}
                          &nbsp;
                        </span>
                        <span>#</span>
                        <span style={{ color: "blue" }}>
                          {order?.orders_unique_id}
                        </span>
                      </h4>
                      <div className="mb-4">
                        {/* <img src={logo} alt="logo" height="20" /> */}
                      </div>
                      <div className="text-muted">
                        {/* <p className="mb-1">test</p>
                        <p className="mb-1">
                          <i className="uil uil-envelope-alt me-1"></i>{" "}
                          test@gmail.com
                        </p>
                        <p>
                          <i className="uil uil-phone me-1"></i> 9511235689
                        </p> */}
                      </div>
                    </div>
                    <hr className="my-4" />
                    <Row>
                      <Col sm="6">
                        <div className="text-muted">
                          {/* <h5 className="font-size-16 mb-3">Billed To:</h5> */}
                          <h5 className="font-size-15 mb-2">
                            {order.order_customer_name}
                          </h5>
                          <p className="mb-1">{order.order_contact_number}</p>
                          {/* <p className="mb-1">{"test"}</p>
                          <p>{"test"}</p> */}
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="text-muted text-sm-end">
                          <div>
                            <h5 className="font-size-16 mb-1">ORD Date:</h5>
                            <p>{order.order_date}</p>
                          </div>
                          <div className="mt-4">
                            <h5 className="font-size-16 mb-1">ORD Time:</h5>
                            <p>
                              {moment(order.order_time, "h:mm:ss.*").format(
                                "hh:mm a"
                              )}
                            </p>
                          </div>
                          <div className="mt-4">
                            <h5 className="font-size-16 mb-1">
                              Delivery Date:
                            </h5>
                            <p></p>
                          </div>
                          <div className="mt-4">
                            <h5 className="font-size-16 mb-1">
                              Delivery Time:
                            </h5>
                            <p></p>
                          </div>
                          <div className="mt-4">
                            <h5 className="font-size-16 mb-1">
                              Delivery Type:
                            </h5>
                            <p></p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="py-2">
                      <h5 className="font-size-15">Order summary</h5>
                      <div className="table-responsive">
                        <Table className="table-nowrap table-centered mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: "70px" }}>#</th>
                              <th>SKU</th>
                              <th>Name</th>
                              <th>Image</th>
                              <th>HSN</th>
                              <th>Unit</th>
                              <th>Qty</th>
                              <th>Rate</th>
                              <th>MRP</th>
                              <th>Discount</th>
                              <th>GST(%)</th>
                              <th
                                className="text-end"
                                style={{ width: "120px" }}
                              >
                                Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* {map(
                              invoiceDetail.orderSummary.items,
                              (item, key) => (
                                <tr key={key}>
                                  <td>{item.id}</td>
                                  <td>
                                    <h5 className="font-size-15 mb-1">{item.item}</h5>
                                    <ul className="list-inline mb-0">
                                      <li className="list-inline-item">Color : <span className="fw-medium">{item.color}</span></li>{" "}
                                      <li className="list-inline-item">Size : <span className="fw-medium">{item.size}</span></li>
                                    </ul>
                                  </td>
                                  <td>{item.price}</td>
                                  <td>{item.quantity}</td>
                                  <td className="text-end">{item.price}</td>
                                </tr>
                              )
                            )} */}
                            {/* <tr>
                              <th colSpan="4" className="text-end">
                                Total Value :
                              </th>
                              <td className="text-end"></td>
                            </tr>
                            <tr>
                              <th colSpan="4" className="border-0 text-end">
                                GST :
                              </th>
                              <td className="border-0 text-end"></td>
                            </tr>
                            <tr>
                              <th colSpan="4" className="border-0 text-end">
                                Total Taxable :
                              </th>
                              <td className="border-0 text-end"></td>
                            </tr>
                            <tr>
                              <th colSpan="4" className="border-0 text-end">
                                Total Discount :
                              </th>
                              <td className="border-0 text-end"></td>
                            </tr>
                            <tr>
                              <th colSpan="4" className="border-0 text-end">
                                Delivery Amount :
                              </th>
                              <td className="border-0 text-end"></td>
                            </tr>
                            <tr>
                              <th colSpan="4" className="border-0 text-end">
                                Grand Total
                              </th>
                              <td className="border-0 text-end">
                                <h4 className="m-0"></h4>
                              </td>
                            </tr> */}
                          </tbody>
                        </Table>
                      </div>
                      <Row className="pt-4">
                        <Col lg={7}>
                          <Row>
                            <Col md={12}>
                              <div className="table-responsive">
                                <Table className="table-nowrap table-centered mb-0">
                                  <thead>
                                    <tr>
                                      <th>GST%</th>
                                      <th>Taxable Value ₹</th>
                                      <th>IGST %</th>
                                      <th>IGST ₹</th>
                                      <th>GST ₹</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={1}></Col>
                        <Col>
                          <Table className="table-nowrap table-centered mb-0">
                            <tbody>
                              <tr>
                                <th colSpan="4" className="text-end">
                                  Total Value :
                                </th>
                                <td className="text-end"></td>
                              </tr>
                              <tr>
                                <th colSpan="4" className="border-0 text-end">
                                  GST :
                                </th>
                                <td className="border-0 text-end"></td>
                              </tr>
                              <tr>
                                <th colSpan="4" className="border-0 text-end">
                                  Total Taxable :
                                </th>
                                <td className="border-0 text-end"></td>
                              </tr>
                              <tr>
                                <th colSpan="4" className="border-0 text-end">
                                  Total Discount :
                                </th>
                                <td className="border-0 text-end"></td>
                              </tr>
                              <tr>
                                <th colSpan="4" className="border-0 text-end">
                                  Delivery Amount :
                                </th>
                                <td className="border-0 text-end"></td>
                              </tr>
                              <tr>
                                <th colSpan="4" className="border-0 text-end">
                                  Grand Total
                                </th>
                                <td className="border-0 text-end">
                                  <h4 className="m-0"></h4>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                      {/* <div className="d-print-none mt-4">
                        <div className="float-end">
                          <Link to="#" onClick={printOrder} className="btn btn-success waves-effect waves-light me-1"><i className="fa fa-print"></i></Link>{" "}
                          <Link to="#" className="btn btn-primary w-md waves-effect waves-light">Close</Link>
                        </div>
                      </div> */}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="View Orders" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {/* <Row className="mb-3">
                    <div
                      style={{
                        float: "right",
                        width: "990px",
                        //top: "10px",
                        textAlign: "right",
                        paddingRight: "16px",
                        color:"#07b907",
                        fontWeight:"600"
                      }}
                    >
                      <span>
                        <span>{"Total Order Count:"}</span>
                      </span>
                    </div>
                    <div  style={{
                        float: "right",
                        width: "965px",
                        top: "10px",
                        textAlign: "right",
                        paddingRight: "16px",
                        color:"#07b907",
                        fontWeight:"600"
                      }}>
                      <span>
                        <span>{"Total Amount: "}</span>
                      </span>
                    </div>
                  </Row> */}
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Order From</Label>
                        <Select
                          name="order_from_id"
                          value={selectedOrder}
                          // onChange={(selected) => {
                          //   handleSelectedChange(selected,"orderfrom");
                          // }}
                          options={[
                            { label: "Online", value: 0 },
                            { label: "Crm", value: 1 },
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Status</Label>
                        <Select
                          name="status_id"
                          value={selectedStatus}
                          // onChange={(selected) => {
                          //   handleSelectedChange(selected,"status");
                          // }}
                          options={[
                            { label: "Approved", value: 1 },
                            { label: "Pending", value: 0 },
                            { label: "Cancelled", value: 2 },
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={false}
                    entries={25}
                    disableRetreatAfterSorting={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};
export default withRouter(connect(mapStateToProps, { apiError })(ViewOrder));
