import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';

import {
  GET_PRODUCT,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  GET_PRODUCTS,
  CHANGE_STATUS_PRODUCT,
  CHANGE_STATUS_BESTSELLER,
} from './actionTypes';

import {
  getProductsSuccess,
  getProductsFail,
  getProductSuccess,
  getProductFail,
  addProductFail,
  addProductSuccess,
  updateProductFail,
  updateProductSuccess,
  deleteProductFail,
  deleteProductSuccess,
  toggleProductActiveStatusSuccess,
  toggleProductActiveStatusFail,
  toggleBestSellerActivesStatusSuccess,
  toggleBestSellerActivesStatusFail,
} from './actions';

//Include Both Helper File with needed methods
import {
  getProducts,
  getProduct,
  addProduct,
  updateProduct,
  deleteProduct,
  toggleProductActiveStatus,
  toggleBestSellerActivesStatus,
} from '../../helpers/backend_helper';

function* fetchProducts() {
  try {
    const response = yield call(getProducts);
    yield put(getProductsSuccess(response));
  } catch (error) {
    yield put(getProductsFail(error));
  }
}

function* onGetProduct() {
  try {
    const response = yield call(getProduct);
    yield put(getProductSuccess(response));
  } catch (error) {
    yield put(getProductFail(error.response));
  }
}

function* onAddProduct({ payload: product }) {
  try {
    const response = yield call(addProduct, product);
    yield put(addProductSuccess(response));
  } catch (error) {
    yield put(addProductFail(error.response));
  }
}

function* onUpdateProduct({ payload: product }) {
  //console.log(product);
  try {
    const response = yield call(updateProduct, product);
    yield put(updateProductSuccess(response));
  } catch (error) {
    yield put(updateProductFail(error.response));
  }
}

function* onDeleteProduct({ payload: productId, auth_data: auth_user }) {
  try {
    const response = yield call(deleteProduct, productId, auth_user);
    yield put(deleteProductSuccess(response));
  } catch (error) {
    yield put(deleteProductFail(error.response));
  }
}

function* onToggleProductActiveStatus({
  payload: productId,
  auth_data: auth_user,
}) {
  try {
    const response = yield call(
      toggleProductActiveStatus,
      productId,
      auth_user
    );
    yield put(toggleProductActiveStatusSuccess(response));
  } catch (error) {
    yield put(toggleProductActiveStatusFail(error.response));
  }
}
function* onToggleBestSellerStatus({
  payload: productId,
  auth_data: auth_user,
}) {
  try {
    const response = yield call(
      toggleBestSellerActivesStatus,
      productId,
      auth_user
    );
    yield put(toggleBestSellerActivesStatusSuccess(response));
  } catch (error) {
    yield put(toggleBestSellerActivesStatusFail(error.response));
  }
}
function* productSaga() {
  yield takeEvery(GET_PRODUCTS, fetchProducts);
  yield takeEvery(GET_PRODUCT, onGetProduct);
  yield takeEvery(ADD_PRODUCT, onAddProduct);
  yield takeEvery(UPDATE_PRODUCT, onUpdateProduct);
  yield takeEvery(DELETE_PRODUCT, onDeleteProduct);
  yield takeEvery(CHANGE_STATUS_PRODUCT, onToggleProductActiveStatus);
  yield takeEvery(CHANGE_STATUS_BESTSELLER, onToggleBestSellerStatus);
}

export default productSaga;
