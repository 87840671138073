import {
    GET_CREDIT_DEBIT,
    GET_CREDIT_DEBIT_SUCCESS,
    GET_CREDIT_DEBIT_FAIL,
    ADD_CREDIT_DEBIT,
    ADD_CREDIT_DEBIT_SUCCESS,
    ADD_CREDIT_DEBIT_FAIL
} from "./actionTypes";

const INIT_STATE = {
    creditDebits: [],
    addingCrediDebit: false,
    addCrediDebitResponse: {},
    creditDebit: {},
    error: {}
};

const creditDebits = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CREDIT_DEBIT:
            return {
                ...state,
                params: action.payload,
            };

        case GET_CREDIT_DEBIT_SUCCESS:
            return {
                ...state,
                creditDebits: action.payload.data,
            };

        case GET_CREDIT_DEBIT_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case ADD_CREDIT_DEBIT:
            return {
                ...state,
                addingCrediDebit: true,
                creditDebit: action.payload,
            };

        case ADD_CREDIT_DEBIT_SUCCESS:
            let newcreditDebit = [JSON.parse(JSON.stringify(action.payload.data[0]))];
            newcreditDebit.push(...state.creditDebits);
            return {
                ...state,
                addingCrediDebit: false,
                addCrediDebitResponse: {
                    type: 'success',
                    message: 'Credit debit added successfully',
                },
                creditDebits: newcreditDebit,
                error: {},
            };

        case ADD_CREDIT_DEBIT_FAIL:
            return {
                ...state,
                addingCrediDebit: false,
                addCrediDebitResponse: { type: 'failure', message: 'Adding credit debit failed' },
                error: action.payload,
            };
        default:
            return state;
    }
};

export default creditDebits;