import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

import {
  GET_SECTION_TYPES,
  ADD_SECTION_TYPE,
  //   ADD_PRODUCT,
  DELETE_SECTION_TYPE,
  UPDATE_SECTION_TYPE,
  CHANGE_STATUS_SECTION_TYPE,
  //   GET_PRODUCTS
} from "./actionTypes";

import {
  getSectionTypesFail,
  getSectionTypesSuccess,
  addSectionTypeFail,
  addSectionTypeSuccess,
  //   getProductFail,
  //   addProductFail,
  //   addProductSuccess,
  updateSectionTypeFail,
  updateSectionTypeSuccess,
  deleteSectionTypeFail,
  deleteSectionTypeSuccess,
  toggleSectionTypeActiveStatusSuccess,
  toggleSectionTypeActiveStatusFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getSectionType,
  addSectionType,
  //   addProduct,
  updateSectionType,
  deleteSectionType,
  toggleSectionTypeActiveStatus,
} from "../../helpers/backend_helper";

function* fetchSectionTypes() {
  try {
    const response = yield call(getSectionType);
    yield put(getSectionTypesSuccess(response));
  } catch (error) {
    yield put(getSectionTypesFail(error));
  }
}

function* onAddSectionType({ payload: sectionType }) {
  try {
    const response = yield call(addSectionType, sectionType);
    yield put(addSectionTypeSuccess(response));
  } catch (error) {
    yield put(addSectionTypeFail(error.response));
  }
}

function* onUpdateSectionType({ payload: sectionType }) {
  //console.log(product);
  try {
    const response = yield call(updateSectionType, sectionType);
    yield put(updateSectionTypeSuccess(response));
  } catch (error) {
    yield put(updateSectionTypeFail(error.response));
  }
}

function* onDeleteSectionType({
  payload: sectionTypeId,
  auth_data: auth_user,
}) {
  try {
    const response = yield call(deleteSectionType, sectionTypeId, auth_user);
    yield put(deleteSectionTypeSuccess(response));
  } catch (error) {
    yield put(deleteSectionTypeFail(error.response));
  }
}

function* onToggleSectionTypeActiveStatus({
  payload: sectionTypeId,
  auth_data: auth_user,
}) {
  try {
    const response = yield call(
      toggleSectionTypeActiveStatus,
      sectionTypeId,
      auth_user
    );
    yield put(toggleSectionTypeActiveStatusSuccess(response));
  } catch (error) {
    yield put(toggleSectionTypeActiveStatusFail(error.response));
  }
}

function* sectionTypeSaga() {
  yield takeEvery(GET_SECTION_TYPES, fetchSectionTypes);
  yield takeEvery(ADD_SECTION_TYPE, onAddSectionType);
  yield takeEvery(UPDATE_SECTION_TYPE, onUpdateSectionType);
  yield takeEvery(DELETE_SECTION_TYPE, onDeleteSectionType);
  yield takeEvery(CHANGE_STATUS_SECTION_TYPE, onToggleSectionTypeActiveStatus);
}

export default sectionTypeSaga;
