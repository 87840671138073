import {
    GET_SECTION_PRODUCT,
    GET_SECTION_PRODUCT_SUCCESS,
    GET_SECTION_PRODUCT_FAIL,
    ADD_SECTION_PRODUCT,
    ADD_SECTION_PRODUCT_SUCCESS,
    ADD_SECTION_PRODUCT_FAIL,
    UPDATE_SECTION_PRODUCT,
    UPDATE_SECTION_PRODUCT_SUCCESS,
    UPDATE_SECTION_PRODUCT_FAIL,
    DELETE_SECTION_PRODUCT,
    DELETE_SECTION_PRODUCT_SUCCESS,
    DELETE_SECTION_PRODUCT_FAIL,
    GET_SECTION_PRODUCT_SEARCH,
    GET_SECTION_PRODUCT_SEARCH_SUCCESS,
    GET_SECTION_PRODUCT_SEARCH_FAIL
    } from './actionTypes';

    const INIT_STATE = {
        sectionProducts: [],
        sectionProductsSearch:[],
        addingSectionProduct: false,
        deletingSectionProduct: false,
        addSectionProductResponse: {},
        updateSectionProductResponse: {},
        deleteSectionProductResponse: {},
        sectionProductIdToBeDeleted: '',
        sectionProduct: {},
        error: {},
    //    acctypeOptions: [],
      }; 

      const sectionProducts = (state = INIT_STATE, action) => {
        switch (action.type) {
          case GET_SECTION_PRODUCT:
            return {
              ...state,
              params: action.payload,
            };
      
          case GET_SECTION_PRODUCT_SUCCESS:
            return {
              ...state,
              sectionProducts: action.payload.data,
            };
      
          case GET_SECTION_PRODUCT_FAIL:
            return {
              ...state,
              error: action.payload,
            };
          case ADD_SECTION_PRODUCT:
            return {
              ...state,
              addingSectionProduct: true,
              sectionProduct: action.payload,
            };
      
          case ADD_SECTION_PRODUCT_SUCCESS:
            let newSectionProducts = [JSON.parse(JSON.stringify(action.payload.data[0]))];
            console.log('newSectionProducts: ', newSectionProducts);
            newSectionProducts.push(...state.sectionProducts);
            return {
              ...state,
              addingSectionProduct: false,
              addSectionProductResponse: {
                type: 'success',
                message: 'Section product added successfully',
              },
              sectionProducts: newSectionProducts,
              error: {},
            };
      
          case ADD_SECTION_PRODUCT_FAIL:
            return {
              ...state,
              addingSectionProduct: false,
              addSectionProductResponse: { type: 'failure', message: 'Adding section product failed' },
              error: action.payload,
            };
          case DELETE_SECTION_PRODUCT:
            return {
              ...state,
              deletingSectionProduct: true,
              sectionProductIdToBeDeleted: action.payload,
            };
      
          case DELETE_SECTION_PRODUCT_SUCCESS:
            let newData = state.sectionProducts.filter((e) => {
              return e.section_id != state.sectionProductIdToBeDeleted;
            });
            return {
              ...state,
              deletingSectionProduct: false,
              sectionProductIdToBeDeleted: '',
              deleteSectionProductResponse: {
                type: 'success',
                message: 'Section product deleted successfully',
              },
              chartOfAccounts: newData,
            };
      
          case DELETE_SECTION_PRODUCT_FAIL:
            return {
              ...state,
              deletingChartOfAccount: false,
              deleteSectionProductResponse: {
                type: 'failure',
                message: 'Deleting section product failed',
              },
              error: action.payload,
            };
      
          case UPDATE_SECTION_PRODUCT:
            return {
              ...state,
              updatingSectionProduct: true,
              sectionProduct: action.payload,
            };
      
          case UPDATE_SECTION_PRODUCT_SUCCESS:
            let newSectionProduct = [];
            let newSectionProduct1 = state.sectionProducts.filter((sectionProduct) => {
              if (sectionProduct.section_id == state.sectionProduct.section_id) {
                console.log(JSON.parse(JSON.stringify(action.payload.data[0])));
                newSectionProduct.push(JSON.parse(JSON.stringify(action.payload.data[0])));
              } else {
                newSectionProduct.push(sectionProduct);
              }
            });
      
            return {
              ...state,
              updatingSectionProduct: false,
              sectionProduct: '',
              updateSectionProductResponse: {
                type: 'success',
                message: 'Section product updated successfully',
              },
              sectionProducts: newSectionProduct,
            };
      
          case UPDATE_SECTION_PRODUCT_FAIL:
            return {
              ...state,
              updatingSectionProduct: false,
              updateSectionProductResponse: {
                type: 'failure',
                message: 'Updating section product failed',
              },
              error: action.payload,
            };
        //     case GET_SECTION_PRODUCT_SEARCH:
        //       return {
        //         ...state,
        //         acctypeOptions: action.payload.data,
        //       };
        
        //     case GET_ACCTYPE_OPTIONS_FAIL:
        //       return {
        //         ...state,
        //         error: action.payload,
        //       };
      
          default:
            return state;
        }
      };
      
      export default sectionProducts;