import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

import {
  ADD_SECTION_PRODUCT,
  DELETE_SECTION_PRODUCT,
  UPDATE_SECTION_PRODUCT,
  GET_SECTION_PRODUCT,
} from "./actionTypes";

import {
  getAllSectionProductSuccess,
  getAllSectionProductFail,
  addSectionProductSuccess,
  addSectionProductFail,
  updateSectionProductSuccess,
  updateSectionProductFail,
  deleteSectionProductSuccess,
  deleteSectionProductFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getAllSectionProduct,
  addSectionProduct,
  updateSectionProduct,
  deleteSectionProduct,
} from "../../helpers/backend_helper";

function* fetchSectionProduct() {
  try {
    const response = yield call(getAllSectionProduct);
    yield put(getAllSectionProductSuccess(response));
  } catch (error) {
    yield put(getAllSectionProductFail(error));
  }
}

function* onAddSectionProduct({ payload: data }) {
    console.log('payload: ', data);
  try {
    const response = yield call(addSectionProduct, data);
    console.log('response: ', response);
    yield put(addSectionProductSuccess(response));
  } catch (error) {
    yield put(addSectionProductFail(error.response));
  }
}

function* onUpdateSectionProduct({ payload: data }) {
  try {
    const response = yield call(updateSectionProduct, data);
    yield put(updateSectionProductSuccess(response));
  } catch (error) {
    yield put(updateSectionProductFail(error.response));
  }
}

function* ondeleteSectionProduct({ payload: sectionId, auth_data: auth_user }) {
  try {
    const response = yield call(deleteSectionProduct, sectionId, auth_user);
    yield put(deleteSectionProductSuccess(response));
  } catch (error) {
    yield put(deleteSectionProductFail(error.response));
  }
}

function* sectionProductSaga() {
  yield takeEvery(GET_SECTION_PRODUCT, fetchSectionProduct);
  yield takeEvery(ADD_SECTION_PRODUCT, onAddSectionProduct);
  yield takeEvery(UPDATE_SECTION_PRODUCT, onUpdateSectionProduct);
  yield takeEvery(DELETE_SECTION_PRODUCT, ondeleteSectionProduct);
}

export default sectionProductSaga;
