import {
  GET_DELIVERYLISTS,
  GET_DELIVERYLISTS_FAIL,
  GET_DELIVERYLISTS_SUCCESS,
  ADD_DELIVERYLIST,
  ADD_DELIVERYLIST_FAIL,
  ADD_DELIVERYLIST_SUCCESS,
  GET_DELIVERYLIST,
  GET_DELIVERYLIST_FAIL,
  GET_DELIVERYLIST_SUCCESS,
 
} from './actionTypes';

export const getDeliverylists = () => ({
  type: GET_DELIVERYLISTS,
});

export const getDeliverylistsSuccess = (deliverylist) => ({
  type: GET_DELIVERYLISTS_SUCCESS,
  payload: deliverylist,
});

export const getDeliverylistsFail = (error) => ({
  type: GET_DELIVERYLISTS_FAIL,
  payload: error,
});



export const getDeliverylist = (deliverylist) => ({
  type: GET_DELIVERYLIST,
  payload: deliverylist,
});

export const getDeliverylistSuccess = (deliverylist) => ({
  type: GET_DELIVERYLIST_SUCCESS,
  payload: deliverylist,
});

export const getDeliverylistFail = (error) => ({
  type: GET_DELIVERYLIST_FAIL,
  payload: error,
});


