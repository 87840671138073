import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';

import { GET_DELIVERYLIST, GET_DELIVERYORDERLISTS } from './actionTypes';

import {
  getDeliveryOrderlistsSuccess,
  getDeliveryOrderlistsFail,
} from './actions';

//Include Both Helper File with needed methods
import {
  getDeliveryOrderlists,
  getDeliverylist,
} from '../../helpers/backend_helper';

function* fetchDeliverylists({ payload: communityId, orderDate }) {
  console.log('Saga Community id' + communityId);
  console.log('Saga order date' + orderDate);
  try {
    const response = yield call(getDeliveryOrderlists, communityId, orderDate);
    yield put(getDeliveryOrderlistsSuccess(response.data));
  } catch (error) {
    yield put(getDeliveryOrderlistsFail(error));
  }
}

// function* onGetDeliverylist() {
//   try {
//     const response = yield call(getDeliverylist);
//     yield put(getDeliverylistSuccess(response));
//   } catch (error) {
//     yield put(getDeliverylistFail(error.response));
//   }
// }

function* deliveryorderlistSaga() {
  yield takeEvery(GET_DELIVERYORDERLISTS, fetchDeliverylists);
}

export default deliveryorderlistSaga;
