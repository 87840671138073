import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
  Input,
  FormGroup,
  Grid,
} from "reactstrap";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

//Import Breadcrumb
import Breadcrumbs from "./../../components/Common/Breadcrumb";

const Banner = () => {
  const [data, setData] = useState([]);

  const dataTemp = [];

  useEffect(() => {
    setData(dataTemp);
  }, []);

  const bannerData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Banner Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 150,
      },
      {
        label: "Banner for",
        field: "for",
        sort: "asc",
        width: 150,
      },
      {
        label: "Web Image",
        field: "image",
        sort: "disabled",
        width: 270,
      },
      {
        label: "App Image",
        field: "app_image",
        sort: "disabled",
        width: 150,
      },
      {
        label: "URL",
        field: "url",
        sort: "disabled",
        width: 150,
      },
      {
        label: "From Time",
        field: "from",
        sort: "disabled",
        width: 150,
      },
      {
        label: "To Time",
        field: "to",
        sort: "disabled",
        width: 150,
      },
    ],
    rows: data,
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Banner" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <AvField
                          type="select"
                          name="banner_type"
                          label="Banner Type"
                          // helpMessage="please select role"
                        >
                          <option>option 1</option>
                          <option>option 2</option>
                        </AvField>
                      </Col>
                      <Col md="3">
                        <AvField
                          type="select"
                          name="banner_for"
                          label="Banner For"
                          // helpMessage="please select role"
                        >
                          <option>option 1</option>
                          <option>option 2</option>
                        </AvField>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            type="text"
                            name="banner_name"
                            label="Banner Name"
                            // helpMessage="please select role"
                          ></AvField>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            type="text"
                            name="banner_position"
                            label="Banner Position"
                            // helpMessage="please select role"
                          ></AvField>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            type="file"
                            name="web_image"
                            label="Web Image"
                            // helpMessage="please select role"
                          ></AvField>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            type="file"
                            name="app_image"
                            label="App Image"
                            // helpMessage="please select role"
                          ></AvField>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            type="text"
                            name="url"
                            label="URL"
                            // helpMessage="please select role"
                          ></AvField>
                        </div>
                      </Col>
                      <Col md="3">
                        {/* <AvField
                          type="text"
                          name="from_time"
                          label="From Time"
                          // helpMessage="please select role"
                        ></AvField> */}
                        <div className="mb-3">
                          <Label>From Time</Label>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="dd M,yyyy"
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>To Time</Label>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="dd M,yyyy"
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="2">
                        <div style={{ marginTop: "1.8em" }}>
                          <Button color="success" type="submit">
                            <i
                              className="bx bx-save"
                              style={{
                                fontSize: "1em",
                                cursor: "pointer",
                                marginRight: "0.5rem",
                              }}
                            ></i>
                            Save
                          </Button>
                        </div>
                      </Col>
                      <Col md="2">
                        <div style={{ marginTop: "1.8em" }}>
                          <Button
                            color="danger"
                            style={{
                              fontSize: "1em",
                              cursor: "pointer",
                              marginRight: "0.5rem",
                            }}
                          >
                            <i className="bx bx-sync"></i> Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle>View Rules</CardTitle> */}
                  {/* <hr /> */}
                  <MDBDataTable responsive bordered data={bannerData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Banner;
