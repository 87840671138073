import {
  GET_SECTIONBANNERS,
  GET_SECTIONBANNERS_FAIL,
  GET_SECTIONBANNERS_SUCCESS,
  ADD_SECTIONBANNER,
  ADD_SECTIONBANNER_SUCCESS,
  ADD_SECTIONBANNER_FAIL,
  UPDATE_SECTIONBANNER,
  UPDATE_SECTIONBANNER_SUCCESS,
  UPDATE_SECTIONBANNER_FAIL,
  DELETE_SECTIONBANNER,
  DELETE_SECTIONBANNER_SUCCESS,
  DELETE_SECTIONBANNER_FAIL,
  CHANGE_STATUS_SECTIONBANNER,
  CHANGE_STATUS_SECTIONBANNER_SUCCESS,
  CHANGE_STATUS_SECTIONBANNER_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  sectionBanners: [],
  addingSectionBanner: false,
  updatingSectionBanner: false,
  deletingSectionBanner: false,
  addSectionBannerResponse: {},
  updateSectionBannerResponse: {},
  deleteSectionBannerResponse: {},
  statusSectionBannerResponse: {},
  sectionIdToBeDeleted: "",
  sectionbanner: {},
  error: {},
};

const sectionBanner = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SECTIONBANNERS:
      return {
        ...state,
        params: action.payload,
      };

    case GET_SECTIONBANNERS_SUCCESS:
      return {
        ...state,
        sectionBanners: action.payload.data,
      };

    case GET_SECTIONBANNERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_SECTIONBANNER:
      return {
        ...state,
        addingSectionBanner: true,
        sectionbanner: action.payload,
      };

    case ADD_SECTIONBANNER_SUCCESS:
      let newSectionbanners = [
        JSON.parse(JSON.stringify(action.payload.data[0])),
      ];
      newSectionbanners.push(...state.sectionBanners);

      return {
        ...state,
        addingSectionBanner: false,
        addSectionBannerResponse: {
          type: "success",
          message: "Section Banner added successfully",
        },
        sectionBanners: newSectionbanners,
        error: {},
      };

    case ADD_SECTIONBANNER_FAIL:
      return {
        ...state,
        addingSectionBanner: false,
        addSectionBannerResponse: {
          type: "failure",
          message: "Adding Section Banner failed",
        },
        error: action.payload,
      };

    case UPDATE_SECTIONBANNER:
      return {
        ...state,
        updatingSectionBanner: true,
        sectionbanner: action.payload,
      };

    case UPDATE_SECTIONBANNER_SUCCESS:
      let newsectionbannerarr = [];
      state.sectionBanners.filter((sectionbanner) => {
        if (
          sectionbanner.section_banner_id ==
          state.sectionbanner.section_banner_id
        ) {
          console.log(JSON.parse(JSON.stringify(action.payload.data[0])));
          newsectionbannerarr.push(
            JSON.parse(JSON.stringify(action.payload.data[0]))
          );
        } else {
          //console.log(product);
          newsectionbannerarr.push(sectionbanner);
        }
      });

      return {
        ...state,
        updatingSectionBanner: false,
        sectionbanner: "",
        updateSectionBannerResponse: {
          type: "success",
          message: "section banner updated successfully",
        },
        sectionBanners: newsectionbannerarr,
      };

    case UPDATE_SECTIONBANNER_FAIL:
      return {
        ...state,
        updatingSectionBanner: false,
        updateSectionBannerResponse: {
          type: "failure",
          message: "Updating section Banner failed",
        },
        error: action.payload,
      };

    case DELETE_SECTIONBANNER:
      return {
        ...state,
        deletingSectionBanner: true,
        sectionIdToBeDeleted: action.payload,
      };

    case DELETE_SECTIONBANNER_SUCCESS:
      let newSectionBanner = state.sectionBanners.filter((sectionbanner) => {
        return sectionbanner.section_banner_id != state.sectionIdToBeDeleted;
      });
      return {
        ...state,
        sectionIdToBeDeleted: "",
        deletingSectionBanner: false,
        deleteSectionBannerResponse: {
          type: "success",
          message: "section banner deleted successfully",
        },
        sectionBanners: newSectionBanner,
      };

    case DELETE_SECTIONBANNER_FAIL:
      return {
        ...state,
        deletingSectionBanner: false,
        deleteSectionBannerResponse: {
          type: "failure",
          message: "Deleting section banner failed",
        },
        error: action.payload,
      };

    case CHANGE_STATUS_SECTIONBANNER:
      return {
        ...state,
      };

    case CHANGE_STATUS_SECTIONBANNER_SUCCESS:
      return {
        ...state,
        sectionBanners: action.payload.data,
        statusSectionBannerResponse: {
          type: "success",
          message: "Status Changed successfully",
        },
        //products: newCategories2,
      };

    case CHANGE_STATUS_SECTIONBANNER_FAIL:
      return {
        ...state,
        statusSectionBannerResponse: {
          type: "failure",
          message: "Status change failed",
        },
        error: action.payload,
      };

    default:
      return state;
  }
};

export default sectionBanner;
