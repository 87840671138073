import {
  GET_HARVESTORDERLISTS,
  GET_HARVESTORDERLISTS_FAIL,
  GET_HARVESTORDERLISTS_SUCCESS,
} from './actionTypes';

const INIT_STATE = {
  harvestlists: [],
  // deliverylist: {},
  error: {},
};

const Harvestlist = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_HARVESTORDERLISTS:
      console.log('Reducer  Action Payload' + action.payload);
      return {
        ...state,
        params: action.payload,
      };

    case GET_HARVESTORDERLISTS_SUCCESS:
      return {
        ...state,
        harvestlists: action.payload,
      };

    case GET_HARVESTORDERLISTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Harvestlist;
