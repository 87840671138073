export const GET_STOCKS = 'GET_STOCKS';
export const GET_STOCKS_SUCCESS = 'GET_STOCKS_SUCCESS';
export const GET_STOCKS_FAIL = 'GET_STOCKS_FAIL';

export const GET_STOCK = 'GET_STOCK';
export const GET_STOCK_SUCCESS = 'GET_STOCK_SUCCESS';
export const GET_STOCK_FAIL = 'GET_STOCK_FAIL';

export const ADD_STOCK = 'ADD_STOCK';
export const ADD_STOCK_SUCCESS = 'ADD_STOCK_SUCCESS';
export const ADD_STOCK_FAIL = 'ADD_STOCK_FAIL';

export const UPDATE_STOCK = 'UPDATE_STOCK';
export const UPDATE_STOCK_SUCCESS = 'UPDATE_STOCK_SUCCESS';
export const UPDATE_STOCK_FAIL = 'UPDATE_STOCK_FAIL';

export const DELETE_STOCK = 'DELETE_STOCK';
export const DELETE_STOCK_SUCCESS = 'DELETE_STOCK_SUCCESS';
export const DELETE_STOCK_FAIL = 'DELETE_STOCK_FAIL';
