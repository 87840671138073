import {
  GET_THIRDCATEGORIES,
  GET_THIRDCATEGORIES_FAIL,
  GET_THIRDCATEGORIES_SUCCESS,
  ADD_THIRDCATEGORY,
  ADD_THIRDCATEGORY_SUCCESS,
  ADD_THIRDCATEGORY_FAIL,
  UPDATE_THIRDCATEGORY,
  UPDATE_THIRDCATEGORY_FAIL,
  UPDATE_THIRDCATEGORY_SUCCESS,
  DELETE_THIRDCATEGORY,
  DELETE_THIRDCATEGORY_FAIL,
  DELETE_THIRDCATEGORY_SUCCESS,
  CHANGE_STATUS_THIRDCATEGORY,
  CHANGE_STATUS_THIRDCATEGORY_SUCCESS,
  CHANGE_STATUS_THIRDCATEGORY_FAIL,
  GET_THIRDCATEGORY_OPTIONS,
  GET_THIRDCATEGORY_OPTIONS_SUCCESS,
  GET_THIRDCATEGORY_OPTIONS_FAIL,
} from "./actionTypes";

export const getthirdcategories = () => ({
  type: GET_THIRDCATEGORIES,
});

export const getThirdcategoriesSuccess = (subcategories1) => ({
  type: GET_THIRDCATEGORIES_SUCCESS,
  payload: subcategories1,
});

export const getThirdcategoriesFail = (error) => ({
  type: GET_THIRDCATEGORIES_FAIL,
  payload: error,
});

export const addThirdcategory = (subcategory) => ({
  type: ADD_THIRDCATEGORY,
  payload: subcategory,
});

export const addThirdcategorySuccess = (subcategory) => ({
  type: ADD_THIRDCATEGORY_SUCCESS,
  payload: subcategory,
});

export const addThirdcategoryFail = (subcategory) => ({
  type: ADD_THIRDCATEGORY_FAIL,
  payload: subcategory,
});

export const updateThirdcategory = (subcategory) => ({
  type: UPDATE_THIRDCATEGORY,
  payload: subcategory,
});

export const updateThirdcategorySuccess = (subcategory) => ({
  type: UPDATE_THIRDCATEGORY_SUCCESS,
  payload: subcategory,
});

export const updateThirdcategoryFail = (error) => ({
  type: UPDATE_THIRDCATEGORY_FAIL,
  payload: error,
});

export const deleteThirdcategory = (subcategory, auth_user) => ({
  type: DELETE_THIRDCATEGORY,
  payload: subcategory,
  auth_data: auth_user,
});

export const deleteThirdcategorySuccess = (subcategory) => ({
  type: DELETE_THIRDCATEGORY_SUCCESS,
  payload: subcategory,
});

export const deleteThirdcategoryFail = (error) => ({
  type: DELETE_THIRDCATEGORY_FAIL,
  payload: error,
});
export const toggleThirdActiveStatus = (subcategory, auth_user) => ({
  type: CHANGE_STATUS_THIRDCATEGORY,
  payload: subcategory,
  auth_data: auth_user,
});

export const toggleThirdActiveStatusSuccess = (subcategory) => ({
  type: CHANGE_STATUS_THIRDCATEGORY_SUCCESS,
  payload: subcategory,
});

export const toggleThirdActiveStatusFail = (error) => ({
  type: CHANGE_STATUS_THIRDCATEGORY_FAIL,
  payload: error,
});

export const getThirdcategoryOptions = () => ({
  type: GET_THIRDCATEGORY_OPTIONS,
});

export const getThirdcategoryOptionsSuccess = (subcategory) => ({
  type: GET_THIRDCATEGORY_OPTIONS_SUCCESS,
  payload: subcategory,
});

export const getThirdcategoryOptionsFail = (error) => ({
  type: GET_THIRDCATEGORY_OPTIONS_FAIL,
  payload: error,
});
