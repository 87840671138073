import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

import {
  GET_SECTIONBANNERS,
  ADD_SECTIONBANNER,
  UPDATE_SECTIONBANNER,
  DELETE_SECTIONBANNER,
  CHANGE_STATUS_SECTIONBANNER,
} from "./actionTypes";

import {
  getSectionBannersSuccess,
  getSectionBannersFail,
  addSectionBannerSuccess,
  addSectionBannerFail,
  updateSectionBannerSuccess,
  updateSectionBannerFail,
  deleteSectionBannerSuccess,
  deleteSectionBannerFail,
  toggleSectionBannerActiveStatusSuccess,
  toggleSectionBannerActiveStatusFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getSectionBanners,
  addSectionBanner,
  updateSectionBanner,
  deleteSectionBanner,
  toggleSectionBannerActiveStatus,
} from "../../helpers/backend_helper";

function* fetchSectionBanners() {
  try {
    const response = yield call(getSectionBanners);
    yield put(getSectionBannersSuccess(response));
  } catch (error) {
    yield put(getSectionBannersFail(error));
  }
}

function* onAddSectionBanner({ payload: sectionBanner }) {
  try {
    const response = yield call(addSectionBanner, sectionBanner);
    yield put(addSectionBannerSuccess(response));
  } catch (error) {
    yield put(addSectionBannerFail(error.response));
  }
}

function* onUpdateSectionBanner({ payload: sectionBanner }) {
  //console.log(product);
  try {
    const response = yield call(updateSectionBanner, sectionBanner);
    yield put(updateSectionBannerSuccess(response));
  } catch (error) {
    yield put(updateSectionBannerFail(error.response));
  }
}

function* onDeleteSectionBanner({
  payload: sectionBannerId,
  auth_data: auth_user,
}) {
  try {
    const response = yield call(
      deleteSectionBanner,
      sectionBannerId,
      auth_user
    );
    yield put(deleteSectionBannerSuccess(response));
  } catch (error) {
    yield put(deleteSectionBannerFail(error.response));
  }
}

function* onToggleSectionBannerActiveStatus({
  payload: sectionBannerId,
  auth_data: auth_user,
}) {
  try {
    const response = yield call(
      toggleSectionBannerActiveStatus,
      sectionBannerId,
      auth_user
    );
    yield put(toggleSectionBannerActiveStatusSuccess(response));
  } catch (error) {
    yield put(toggleSectionBannerActiveStatusFail(error.response));
  }
}

function* sectionTypeSaga() {
  yield takeEvery(GET_SECTIONBANNERS, fetchSectionBanners);
  yield takeEvery(ADD_SECTIONBANNER, onAddSectionBanner);
  yield takeEvery(UPDATE_SECTIONBANNER, onUpdateSectionBanner);
  yield takeEvery(DELETE_SECTIONBANNER, onDeleteSectionBanner);
  yield takeEvery(
    CHANGE_STATUS_SECTIONBANNER,
    onToggleSectionBannerActiveStatus
  );
}

export default sectionTypeSaga;
