import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';

import {
  GET_BRAND,
  ADD_BRAND,
  DELETE_BRAND,
  UPDATE_BRAND,
  GET_BRANDS,
  CHANGE_STATUS_BRAND,
  GET_BRANDS_OPTIONS
} from './actionTypes';

import {
  getBrandsSuccess,
  getBrandsFail,
  getBrandSuccess,
  getBrandFail,
  addBrandFail,
  addBrandSuccess,
  updateBrandFail,
  updateBrandSuccess,
  deleteBrandFail,
  deleteBrandSuccess,
  togglebrandActiveStatusSuccess,
  togglebrandActiveStatusFail,
  getBrandsOptionsSuccess,
  getBrandsOptionsFail
} from './actions';

//Include Both Helper File with needed methods
import {
  getBrands,
  getBrand,
  addBrand, 
  updateBrand,
  deleteBrand,
  togglebrandActiveStatus,
  //getBrandsOptions
} from '../../helpers/backend_helper';

function* fetchCategories() {
  try {
    const response = yield call(getBrands);
    yield put(getBrandsSuccess(response));
  } catch (error) {
    yield put(getBrandsFail(error));
  }
}
// function* fetchCategoriesOptions() {
//   try {
//     const response = yield call(getBrandsOptions);
//     yield put(getBrandsOptionsSuccess(response.data));
//   } catch (error) {
//     yield put(getBrandsOptionsFail(error));
//   }
// }

function* onGetBrand() {
  try {
    const response = yield call(getBrand);
    yield put(getBrandSuccess(response));
  } catch (error) {
    yield put(getBrandFail(error.response));
  }
}

function* onAddBrand({ payload: brand }) {
  try {
    const response = yield call(addBrand, brand);
    yield put(addBrandSuccess(response));
  } catch (error) {
    yield put(addBrandFail(error.response));
  }
}

function* onUpdateBrand({ payload: brand }) {
  //console.log(brand);
  try {
    const response = yield call(updateBrand, brand);
    yield put(updateBrandSuccess(response));
  } catch (error) {
    yield put(updateBrandFail(error.response));
  }
}

function* onDeleteBrand({ payload: brandId, auth_data: auth_user }) {
  try {
    const response = yield call(deleteBrand, brandId, auth_user);
    yield put(deleteBrandSuccess(response));
  } catch (error) {
    yield put(deleteBrandFail(error.response));
  }
}

function* ontogglebrandActiveStatus({ payload: brandId, auth_data: auth_user }) {
  try {
    const response = yield call(togglebrandActiveStatus, brandId, auth_user);
    yield put(togglebrandActiveStatusSuccess(response));
  } catch (error) {
    yield put(togglebrandActiveStatusFail(error.response));
  }
}

function* brandSaga() {
  yield takeEvery(GET_BRANDS, fetchCategories);
  yield takeEvery(GET_BRAND, onGetBrand);
  yield takeEvery(ADD_BRAND, onAddBrand);
  yield takeEvery(UPDATE_BRAND, onUpdateBrand);
  yield takeEvery(DELETE_BRAND, onDeleteBrand);
  yield takeEvery(CHANGE_STATUS_BRAND, ontogglebrandActiveStatus);
  //yield takeEvery(GET_BRANDS_OPTIONS,fetchCategoriesOptions)
}

export default brandSaga;
