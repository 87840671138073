import {
  GET_BRANDS,
  GET_BRANDS_FAIL,
  GET_BRANDS_SUCCESS,
  ADD_BRAND,
  ADD_BRAND_FAIL,
  ADD_BRAND_SUCCESS,
  GET_BRAND,
  GET_BRAND_FAIL,
  GET_BRAND_SUCCESS,
  UPDATE_BRAND,
  UPDATE_BRAND_FAIL,
  UPDATE_BRAND_SUCCESS,
  DELETE_BRAND,
  DELETE_BRAND_FAIL,
  DELETE_BRAND_SUCCESS,
  CHANGE_STATUS_BRAND,
  CHANGE_STATUS_BRAND_SUCCESS,
  CHANGE_STATUS_BRAND_FAIL,
  GET_BRANDS_OPTIONS,
  GET_BRANDS_OPTIONS_SUCCESS,
  GET_BRANDS_OPTIONS_FAIL,
} from './actionTypes';

export const getBrands = () => ({
  type: GET_BRANDS,
});

export const getBrandsSuccess = (brand) => ({
  type: GET_BRANDS_SUCCESS,
  payload: brand,
});

export const getBrandsFail = (error) => ({
  type: GET_BRANDS_FAIL,
  payload: error,
});

export const addBrand = (brand) => ({
  type: ADD_BRAND,
  payload: brand,
});

export const addBrandSuccess = (brand) => ({
  type: ADD_BRAND_SUCCESS,
  payload: brand,
});

export const addBrandFail = (brand) => ({
  type: ADD_BRAND_FAIL,
  payload: brand,
});

export const getBrand = (brand) => ({
  type: GET_BRAND,
  payload: brand,
});

export const getBrandSuccess = (brand) => ({
  type: GET_BRAND_SUCCESS,
  payload: brand,
});

export const getBrandFail = (error) => ({
  type: GET_BRAND_FAIL,
  payload: error,
});

export const updateBrand = (brand) => ({
  type: UPDATE_BRAND,
  payload: brand,
});

export const updateBrandSuccess = (brand) => ({
  type: UPDATE_BRAND_SUCCESS,
  payload: brand,
});

export const updateBrandFail = (error) => ({
  type: UPDATE_BRAND_FAIL,
  payload: error,
});

export const deleteBrand = (brand, auth_user) => ({
  type: DELETE_BRAND,
  payload: brand,
  auth_data: auth_user,
});

export const deleteBrandSuccess = (brand) => ({
  type: DELETE_BRAND_SUCCESS,
  payload: brand,
});

export const deleteBrandFail = (error) => ({
  type: DELETE_BRAND_FAIL,
  payload: error,
});
export const togglebrandActiveStatus = (brand, auth_user) => ({
  type: CHANGE_STATUS_BRAND,
  payload: brand,
  auth_data: auth_user,
});

export const togglebrandActiveStatusSuccess = (brand) => ({
  type: CHANGE_STATUS_BRAND_SUCCESS,
  payload: brand,
});

export const togglebrandActiveStatusFail = (error) => ({
  type: CHANGE_STATUS_BRAND_FAIL,
  payload: error,
});

export const getBrandsOptions = () => ({
  type: GET_BRANDS_OPTIONS,
});

export const getBrandsOptionsSuccess = (privilages) => ({
  type: GET_BRANDS_OPTIONS_SUCCESS,
  payload: privilages,
});

export const getBrandsOptionsFail = (error) => ({
  type: GET_BRANDS_OPTIONS_FAIL,
  payload: error,
});
