//LOGIN
export const POST_LOGIN = 'user/login';

//PROFILE
export const POST_EDIT_JWT_PROFILE = '/post-jwt-profile';
export const POST_EDIT_PROFILE = '/post-fake-profile';

//USERS
export const GET_USERS = '/user/lists';
export const ADD_USER = '/user';
export const GET_USER = '/user';
export const UPDATE_USER = '/user';
export const DELETE_USER = '/user';
// Menu Options

export const GET_Menu_OPTIONS = '/menu';

// Privilages options
export const GET_PRIVILAGES_OPTIONS = '/privilege';

export const GET_STATES_OPTIONS = '/state';

// Companies options
export const GET_COMPANIES_OPTIONS = '/company/list/options';

// Branches options
export const GET_BRANCHES_OPTIONS = '/branch/list/options';

export const GET_USER_PROFILE = '/user';
// community schedule

export const GET_COMMUNITY_SCHEDULE = '/community/schedule';
export const CHANGE_STATUS_COMMUNITY = '/community/schedule/change_status';

//unit
export const GET_UNITS = '/unit';
export const ADD_UNIT = '/unit';
export const GET_UNIT = '/unit';
export const UPDATE_UNIT = '/unit';
export const DELETE_UNIT = '/unit';
export const GET_UNITS_OPTIONS = '/unit/getOptions';

//area
export const GET_AREAS = '/area';
export const ADD_AREA = '/area';
export const GET_AREA = '/area';
export const UPDATE_AREA = '/area';
export const DELETE_AREA = '/area';
export const GET_AREA_OPTIONS = '/area/getOptions';

//category
export const GET_CATEGORIES = '/category';
export const ADD_CATEGORY = '/category';
export const GET_CATEGORY = '/category';
export const UPDATE_CATEGORY = '/category';
export const DELETE_CATEGORY = '/category';
export const CHANGE_STATUS_CATEGORY = '/category/change_status';
export const GET_CATEGORIES_OPTIONS = '/category/getOptions';


//subcategory
export const GET_SUBCATEGORIES = "/subcategory";
export const ADD_SUBCATEGORY = "/subcategory";
export const GET_SUBCATEGORY = "/subcategory";
export const UPDATE_SUBCATEGORY = "/subcategory";
export const DELETE_SUBCATEGORY = "/subcategory";
export const CHANGE_STATUS_SUBCATEGORY = "/subcategory/change_status";
export const GET_SUBCATEGORY_OPTIONS = "/subcategory/getOptions";

//thirdcategory
export const ADD_THIRDCATEGORY = "/thirdcategory";
export const GET_THIRDCATEGORY = "/thirdcategory";
export const UPDATE_THIRDCATEGORY = "/thirdcategory";
export const DELETE_THIRDCATEGORY = "/thirdcategory";
export const CHANGE_STATUS_THIRDCATEGORY = "/thirdcategory/change_status";
export const GET_THIRDCATEGORY_OPTIONS = "/thirdcategory/getOptions";


//Brands
// export const GET_SUBCATEGORIES = '/subcategory';
// export const ADD_SUBCATEGORY = '/subcategory';
export const GET_BRANDS = '/brands';
export const ADD_BRAND = '/brands';
export const GET_BRAND = '/brands';
export const UPDATE_BRAND = '/brands';
export const DELETE_BRAND = '/brands';
export const CHANGE_STATUS_BRAND = '/brands/change_status';
export const GET_BRANDS_OPTIONS = '/brands/getOptions';

// export const UPDATE_SUBCATEGORY = '/subcategory';
// export const DELETE_SUBCATEGORY = '/subcategory';
// export const CHANGE_STATUS_SUBCATEGORY = '/subcategory/change_status';
// export  const GET_SUBCATEGORY_OPTIONS = '/subcategory/getOptions';





//product
export const GET_PRODUCTS = '/product';
export const ADD_PRODUCT = '/product';
export const GET_PRODUCT = '/product';
export const UPDATE_PRODUCT = '/product';
export const DELETE_PRODUCT = '/product';
export const CHANGE_STATUS_PRODUCT = '/product/change_status';
export const CHANGE_STATUS_BESTSELLER = 'product/change_best_seller_status';

//community
export const GET_COMMUNITIES = '/community';
export const ADD_COMMUNITY = '/community';
export const GET_COMMUNITY = '/community';
export const UPDATE_COMMUNITY = '/community';
export const DELETE_COMMUNITY = '/community';
//communityrequest
export const GET_COMMUNITYREQUESTS = '/communityrequest';
export const ADD_COMMUNITYREQUEST = '/communityrequest';
export const GET_COMMUNITYREQUEST = '/communityrequest';
export const UPDATE_COMMUNITYREQUEST = '/communityrequest';
export const DELETE_COMMUNITYREQUEST = '/communityrequest';
//tax
export const GET_TAXES = '/tax';
export const ADD_TAX = '/tax';
export const GET_TAX = '/tax';
export const UPDATE_TAX = '/tax';
export const DELETE_TAX = '/tax';

//stock
export const GET_STOCKS = '/stock';
export const ADD_STOCK = '/stock';
export const GET_STOCK = '/stock';
export const UPDATE_STOCK = '/stock';
export const DELETE_STOCK = '/stock';

//stocklog
export const GET_STOCKLOGS = '/stocklog';
export const ADD_STOCKLOG = '/stocklog';
export const GET_STOCKLOG = '/stocklog';
export const UPDATE_STOCKLOG = '/stocklog';
export const DELETE_STOCKLOG = '/stocklog';

//order
export const GET_ORDERS = '/vieworders';
export const ADD_ORDER = '/vieworders';
export const GET_ORDER = '/vieworders';
export const UPDATE_ORDER = '/vieworders';
export const DELETE_ORDER = '/vieworders';
export const DELIVER_ORDER = '/vieworders/deliver_order';
export const OUTOF_DELIVER_ORDER = '/vieworders/outof_deliver_order';
export const CANCEL_DELIVER_ORDER = '/vieworders/cancel_order';

// deliverylist
export const GET_DELIVERYLISTS = '/deliverylist';

export const GET_DELIVERYLIST = '/deliverylist';

export const GET_DELIVERYORDERLISTS = '/vieworders/deliverylist';

//customer
export const GET_CUSTOMERS = '/customer';
export const ADD_CUSTOMER = '/customer';
export const GET_CUSTOMER = '/customer';
export const UPDATE_CUSTOMER = '/customer';
export const DELETE_CUSTOMER = '/customer';

//community options
export const GET_COMMUNITIES_OPTIONS = '/community/list';

//HarvestList
export const GET_HARVESTORDERLISTS = '/vieworders/harvestlist';

// Customer Get Order

export const GET_CUSTOMERORDER = '/customer/order'


//SLIDER
export const GET_SLIDERS = '/slider';
export const ADD_SLIDER = '/slider';
export const GET_SLIDER = '/slider';
export const UPDATE_SLIDER = '/slider';
export const DELETE_SLIDER = '/slider';
export const CHANGE_STATUS_SLIDER = '/slider/change_status'

//SECTION TYPES
export const GET_SECTION_TYPES = "/section";
export const GET_SECTION_THIRDCATEGORY = "/section/getOptions";
export const ADD_SECTION_TYPE = "/section";
export const UPDATE_SECTION_TYPE = "/section";
export const DELETE_SECTION_TYPE = "/section";
export const CHANGE_STATUS_SECTION_TYPE = "/section/change_active_status";

//SECTION BANNER
export const GET_SECTIONBANNERS = "/section_banner";
export const ADD_SECTIONBANNER = "/section_banner";
export const UPDATE_SECTIONBANNER = "/section_banner";
export const DELETE_SECTIONBANNER = "/section_banner";
export const CHANGE_STATUS_SECTIONBANNER =
  "/section_banner/change_active_status";
  
//REPORTS
export const GET_STOCK_REPORTS = "/report/stock";

//account type
export const GET_ACCTYPES = '/account_type';
export const ADD_ACCTYPE = '/account_type';
export const GET_ACCTYPE = '/account_type';
export const UPDATE_ACCTYPE = '/account_type';
export const DELETE_ACCTYPE = '/account_type';
export const GET_ACCTYPE_OPTIONS = '/account_type/options';

//account DETAIL
export const GET_ACCDETAILS = '/account_detail';
export const ADD_ACCDETAIL = '/account_detail';
export const GET_ACCDETAIL = '/account_detail';
export const UPDATE_ACCDETAIL = '/account_detail';
export const DELETE_ACCDETAIL = '/account_detail';

// chart of accounts
export const ADD_CHARTOFACC = "/chart_of_account";
export const GET_CHARTOFACC = "/chart_of_account";
export const UPDATE_CHARTOFACC = "/chart_of_account";
export const DELETE_CHARTOFACC = "/chart_of_account";

// credit debit fund
export const GET_CREDIT_DEBIT = "/credit_debit";
export const ADD_CREDIT_DEBIT = "/credit_debit";

// fund transfer
export const GET_FUND_TRANSFER = "/fund_transfer";
export const ADD_FUND_TRANSFER = "/fund_transfer";
export const GET_FUND_SOURCE = "/chart_of_account/fund_source";

//Income Expenses
export const GET_INCOME_EXPENSE = "/income_expense";
export const GET_INCOME_EXPENSE_ACC_TYPE = "/account_type/income_expense_acc_types";
export const ADD_INCOME_EXPENSE = "/income_expense";

//section products
export const GET_SECTION_PRODUCT = "/section_product";
export const ADD_SECTION_PRODUCT = "/section_product/add";
export const UPDATE_SECTION_PRODUCT = "/section_product";
export const DELETE_SECTION_PRODUCT = "section_product";

//spot price
export const GET_SPOT_PRICE = "/spotprice";
export const ADD_SPOT_PRICE = "/spotprice/update";
export const ADD_MULTIPLE_SPOT_PRICE = "/spotprice/multi_save";


