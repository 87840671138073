import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import userSaga from "./users/saga";
import privilagesSaga from "./privilages/saga";
import companiesSaga from "./companies/saga";
import branchesSaga from "./branches/saga";
import menuSaga from './menu/saga';
import unitSaga from './unit/saga';
import communitySaga from './community/saga';
import categorySaga from './category/saga';
import subcategorySaga from './subcategory/saga';
import thirdcategorySaga from './thirdcategory/saga';
import brandSaga from './brand/saga';
import productSaga from './product/saga';
import communityrequestSaga from './communityRequest/saga';
import statesSaga from './states/saga';
import taxSaga  from "./tax/saga";
import stockSaga from './stock/saga';
import stocklogSaga from './stocklog/saga';
import Ordersaga from "./vieworder/saga";
import customersaga from './customer/saga';
import communityScheduleSaga from './communitySchedule/saga';
import deliverylistSaga from './deliverylist/saga';
import communityOptionSaga from './communityOption/saga';
import deliveryorderlistSaga from './deliveryorderlist/saga';
import harvestlistSaga from './harvestOrderlist/saga';
import areaSaga from './area/saga';
import sliderSaga from './slider/saga';
import sectionType from "./sectionType/saga";
import sectionBanner from "./sectionBanner/saga";
import acctypeSaga from "./accountType/saga";
import accdetailSaga from "./accountDetail/saga";
import chartOfAccSaga from "./chartOfAccount/saga";
import creditDebitSaga from "./creditDebit/saga";
import fundTransferSaga from "./fundTransfer/saga";
import incomeExpenseSaga from "./IncomeExpenses/saga";
import sectionProductSaga from "./sectionProducts/saga";
import spotPriceSaga from "./spotPrice/saga";
export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    fork(LayoutSaga),
    fork(userSaga),
    fork(privilagesSaga),
    fork(companiesSaga),
    fork(branchesSaga),
    fork(menuSaga),
    fork(unitSaga),
    fork(communitySaga),
    fork(categorySaga),
    fork(productSaga),
    fork(communityrequestSaga),
    fork(statesSaga),
    fork(taxSaga),
    fork(stockSaga),
    fork(stocklogSaga),
    fork(Ordersaga),
    fork(customersaga),
    fork(communityScheduleSaga),
    fork(deliverylistSaga),
    fork(communityOptionSaga),
    fork(deliveryorderlistSaga),
    fork(harvestlistSaga),
    fork(subcategorySaga),
    fork(thirdcategorySaga),
    fork(brandSaga),
    fork(areaSaga),
    fork(sliderSaga),
    fork(sectionType),
    fork(sectionBanner),
    fork(acctypeSaga),
    fork(accdetailSaga),
    fork(chartOfAccSaga),
    fork(creditDebitSaga),
    fork(fundTransferSaga),
    fork(incomeExpenseSaga),
    fork(sectionProductSaga),
    fork(spotPriceSaga),
  ]);
}
