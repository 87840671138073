import {
  GET_PRODUCTS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  ADD_PRODUCT,
  ADD_PRODUCT_FAIL,
  ADD_PRODUCT_SUCCESS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  CHANGE_STATUS_PRODUCT,
  CHANGE_STATUS_PRODUCT_SUCCESS,
  CHANGE_STATUS_PRODUCT_FAIL,
  CHANGE_STATUS_BESTSELLER,
  CHANGE_STATUS_BESTSELLER_SUCCESS,
  CHANGE_STATUS_BESTSELLER_FAIL,
} from './actionTypes';

const INIT_STATE = {
  products: [],
  addingProduct: false,
  deletingProduct: false,
  updatingproduct:false,
  addProductResponse: {},
  updateProductResponse: {},
  deleteProductResponse: {},
  statusProductResponse: {},
  productIdToBeDeleted: '',
  product: {},
  error: {},
};

const product = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        params: action.payload,
      };

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.data,
      };

    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_PRODUCT:
      return {
        ...state,
        addingProduct: true,
        product: action.payload,
      };

    case ADD_PRODUCT_SUCCESS:
      //let newCategories = [JSON.parse(JSON.stringify(action.payload.data[0]))];
      //newCategories.push(...state.products);

      return {
        ...state,
        addingProduct: false,
        addProductResponse: {
          type: 'success',
          message: 'product added successfully',
        },
        //products: newCategories,
        error: {},
      };

    case ADD_PRODUCT_FAIL:
      return {
        ...state,
        addingProduct: false,
        addProductResponse: {
          type: 'failure',
          message: 'Adding product failed',
        },
        error: action.payload,
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        deletingProduct: true,
        productIdToBeDeleted: action.payload,
      };

    case DELETE_PRODUCT_SUCCESS:
      let newCategories2 = state.products.filter((product) => {
        return product.product_id != state.productIdToBeDeleted;
      });
      return {
        ...state,
        deletingProduct: false,
        productIdToBeDeleted: '',
        deletingProduct: false,
        deleteProductResponse: {
          type: 'success',
          message: 'product deleted successfully',
        },
        products: newCategories2,
      };

    case DELETE_PRODUCT_FAIL:
      return {
        ...state,
        deletingProduct: false,
        deleteProductResponse: {
          type: 'failure',
          message: 'Deleting product failed',
        },
        error: action.payload,
      };

    case UPDATE_PRODUCT:
      return {
        ...state,
        updatingproduct: true,
        product: action.payload,
      };

    case UPDATE_PRODUCT_SUCCESS:
      let newproductarr = [];
      let newCategories1 = state.products.filter((product) => {
        if (product.product_id == state.product.product_id) {
          console.log(JSON.parse(JSON.stringify(action.payload.data[0])));
          newproductarr.push(
            JSON.parse(JSON.stringify(action.payload.data[0]))
          );
        } else {
          //console.log(product);
          newproductarr.push(product);
        }
      });

      return {
        ...state,
        updatingproduct: false,
        product: '',
        updatingproduct: false,
        updateProductResponse: {
          type: 'success',
          message: 'product updated successfully',
        },
        products: newproductarr,
      };

    case UPDATE_PRODUCT_FAIL:
      return {
        ...state,
        updatingproduct: false,
        updateProductResponse: {
          type: 'failure',
          message: 'Updating product failed',
        },
        error: action.payload,
      };

    case CHANGE_STATUS_PRODUCT:
      return {
        ...state,
        // deletingProduct: true,
        // productIdToBeDeleted: action.payload,
      };

    case CHANGE_STATUS_PRODUCT_SUCCESS:
      /* let newCategories2 = state.products.filter((product) => {
          return product.product_id != state.productIdToBeDeleted;
        });*/
      return {
        ...state,
        //deletingProduct: false,
        //productIdToBeDeleted: "",
        //deletingProduct: false,
        products: action.payload.data,
        statusProductResponse: {
          type: 'success',
          message: 'Status Changed successfully',
        },
        //products: newCategories2,
      };

    case CHANGE_STATUS_PRODUCT_FAIL:
      return {
        ...state,
        //deletingProduct: false,
        statusProductResponse: {
          type: 'failure',
          message: 'Deleting product failed',
        },
        error: action.payload,
      };
    case CHANGE_STATUS_BESTSELLER:
      return {
        ...state,
      };

    case CHANGE_STATUS_BESTSELLER_SUCCESS:
      return {
        ...state,

        statusProductResponse: {
          type: 'success',
          message: 'Status Changed successfully',
        },
      };

    case CHANGE_STATUS_BESTSELLER_FAIL:
      return {
        ...state,
        statusProductResponse: {
          type: 'failure',
          message: 'Deleting product failed',
        },
        error: action.payload,
      };
    default:
      return state;
  }
};

export default product;
