import {
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  ADD_USER,
  ADD_USER_FAIL,
  ADD_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
} from './actionTypes';

const INIT_STATE = {
  users: [],
  addingUser: false,
  deletingUser: false,
  addUserResponse: {},
  updateUserResponse: {},
  deleteUserResponse: {},
  userIdToBeDeleted: '',
  user: {},
  error: {},
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        params: action.payload,
      };

    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.data,
      };

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_USER:
      return {
        ...state,
        addingUnit: true,
        user: action.payload,
      };

    case ADD_USER_SUCCESS:
      let newUsers = [JSON.parse(JSON.stringify(action.payload.data[0]))];
      newUsers.push(...state.users);
      console.log(newUsers);
      return {
        ...state,
        addingUser: false,
        addUserResponse: {
          type: 'success',
          message: 'User added successfully',
        },
        users: newUsers,
        error: {},
      };

    case ADD_USER_FAIL:
      return {
        ...state,
        addingUser: false,
        addUserResponse: {
          type: 'failure',
          message: 'Adding user failed',
        },
        error: action.payload,
      };
    case DELETE_USER:
      return {
        ...state,
        deletingUser: true,
        userIdToBeDeleted: action.payload,
      };

    case DELETE_USER_SUCCESS:
      let newusers2 = state.users.filter((user) => {
        // return user.user_id !== state.userIdToBeDeleted;
        console.log('Deleted Id :' + user.id);
        console.log('Selected Id :' + state.userIdToBeDeleted);
        return user.id !== state.userIdToBeDeleted;
      });

      return {
        ...state,
        deletingUser: false,
        userIdToBeDeleted: '',
        deleteUserResponse: {
          type: 'success',
          message: 'User deleted successfully',
        },

        users: newusers2,
      };

    case DELETE_USER_FAIL:
      return {
        ...state,
        deletingUser: false,
        deleteUserResponse: {
          type: 'failure',
          message: 'User deleted successfully',
        },
        error: action.payload,
      };

    case UPDATE_USER:
      return {
        ...state,
        updatingUser: true,
        user: action.payload,
      };

    case UPDATE_USER_SUCCESS:
      let newuserarray = [];
      state.users.filter((user) => {
        if (user.id === state.users.id) {
          console.log(' user id :', user.id);
          console.log(' current id :', state.users.id);
          console.log(JSON.parse(JSON.stringify(action.payload.data[0])));
          newuserarray.push(JSON.parse(JSON.stringify(action.payload.data[0])));
        } else {
          newuserarray.push(user);
        }
      });

      return {
        ...state,
        updatingUser: false,
        user: '',
        updateUserResponse: {
          type: 'success',
          message: 'user updated successfully',
        },
        users: newuserarray,
      };

    case UPDATE_USER_FAIL:
      return {
        ...state,
        updatingUser: false,
        updateUserResponse: {
          type: 'failure',
          message: 'Updating user failed',
        },
        error: action.payload,
      };

    default:
      return state;
  }
};

export default User;
