import moment from "moment";


export const getArraySumByKey = (arr, keyName) =>
arr
  .map((item) => parseFloat(item[keyName] ? item[keyName] : 0))
  .reduce((a, b) => parseFloat(a ? a : 0) + parseFloat(b ? b : 0), 0);
export const getDate = (date) => {

    if (!date) {
        return null;
    }
    let dateValue = date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()) +
        " 00:00:00.000"
        
    return (
        moment(dateValue).format("YYYY-MM-DD")
    );
};
export const getMonth = (date) => {

    if (!date) {
        return null;
    }
    let dateValue = date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()) +
        " 00:00:00.000"

    return (
        moment(dateValue).format("YYYY-MM")
    );
}
const pad = (number) => {
    if (number < 10) {
        return "0" + number;
    }
    return number;
};
export const getDateTime = (date) => {

    
    if (!date) {
        return null;
    }

    let seconds = pad(String(date.getMilliseconds()).padStart(3,'0')).length>3?pad(String(date.getMilliseconds()).padStart(2,'0')):pad(String(date.getMilliseconds()).padStart(3,'0'));
    return (
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()) +
        " " +
        pad(date.getHours()) +
        ":"+
        pad(date.getMinutes()) +
        ":"+
        pad(date.getSeconds()) +
        ":"+seconds
        
    );
};