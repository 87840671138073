import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

import {
  GET_THIRDCATEGORIES,
  ADD_THIRDCATEGORY,
  DELETE_THIRDCATEGORY,
  UPDATE_THIRDCATEGORY,
  CHANGE_STATUS_THIRDCATEGORY,
  GET_THIRDCATEGORY_OPTIONS,
} from "./actionTypes";

import {
  getThirdcategoriesSuccess,
  getThirdcategoriesFail,
  addThirdcategoryFail,
  addThirdcategorySuccess,
  updateThirdcategoryFail,
  updateThirdcategorySuccess,
  deleteThirdcategoryFail,
  deleteThirdcategorySuccess,
  toggleThirdActiveStatusSuccess,
  toggleThirdActiveStatusFail,
  getThirdcategoryOptionsSuccess,
  getThirdcategoryOptionsFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getThirdcategories,
  addThirdcategory,
  updateThirdcategory,
  deleteThirdcategory,
  toggleThirdActiveStatus,
  //getSubcategoryOptions
} from "../../helpers/backend_helper";

function* fetchThirdcategories() {
  try {
    const response = yield call(getThirdcategories);
    yield put(getThirdcategoriesSuccess(response));
  } catch (error) {
    yield put(getThirdcategoriesFail(error));
  }
}
/*function* fetchSubcategoryOptions() {
  try {
    const response = yield call(getSubcategoryOptions);
    yield put(getSubcategoryOptionsSuccess(response.data));
  } catch (error) {
    yield put(getSubcategoryOptionsFail(error));
  }
}*/

// function* onGetThirdcategory() {
//   try {
//     const response = yield call(getThirdcategory);
//     yield put(getThirdcategorySuccess(response));
//   } catch (error) {
//     yield put(getThirdcategoryFail(error.response));
//   }
// }

function* onAddThirdcategory({ payload: subcategory }) {
  try {
    const response = yield call(addThirdcategory, subcategory);
    yield put(addThirdcategorySuccess(response));
  } catch (error) {
    yield put(addThirdcategoryFail(error.response));
  }
}

function* onUpdateThirdcategory({ payload: subcategory }) {
  //console.log(category);
  try {
    const response = yield call(updateThirdcategory, subcategory);
    yield put(updateThirdcategorySuccess(response));
  } catch (error) {
    yield put(updateThirdcategoryFail(error.response));
  }
}

function* onDeleteThirdcategory({
  payload: subcategoryId,
  auth_data: auth_user,
}) {
  try {
    const response = yield call(deleteThirdcategory, subcategoryId, auth_user);
    yield put(deleteThirdcategorySuccess(response));
  } catch (error) {
    yield put(deleteThirdcategoryFail(error.response));
  }
}

function* ontoggleThirdActiveStatus({
  payload: subcategoryId,
  auth_data: auth_user,
}) {
  try {
    const response = yield call(toggleThirdActiveStatus, subcategoryId, auth_user);
    yield put(toggleThirdActiveStatusSuccess(response));
  } catch (error) {
    yield put(toggleThirdActiveStatusFail(error.response));
  }
}

function* subcategorySaga() {
  yield takeEvery(GET_THIRDCATEGORIES, fetchThirdcategories);
  // yield takeEvery(GET_THIRDCATEGORY, onGetThirdcategory);
  yield takeEvery(ADD_THIRDCATEGORY, onAddThirdcategory);
  yield takeEvery(UPDATE_THIRDCATEGORY, onUpdateThirdcategory);
  yield takeEvery(DELETE_THIRDCATEGORY, onDeleteThirdcategory);
  yield takeEvery(CHANGE_STATUS_THIRDCATEGORY, ontoggleThirdActiveStatus);
  //yield takeEvery(GET_SUBCATEGORY_OPTIONS,fetchThirdcategoryOptions)
}

export default subcategorySaga;
