/* USERS */
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';

export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';

export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAIL = 'ADD_PRODUCT_FAIL';

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL';

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL';

export const CHANGE_STATUS_PRODUCT = 'CHANGE_STATUS_PRODUCT';
export const CHANGE_STATUS_PRODUCT_SUCCESS = 'CHANGE_STATUS_PRODUCT_SUCCESS';
export const CHANGE_STATUS_PRODUCT_FAIL = 'CHANGE_STATUS_PRODUCT_FAIL';


export const CHANGE_STATUS_BESTSELLER = 'CHANGE_STATUS_BESTSELLER';

export const CHANGE_STATUS_BESTSELLER_SUCCESS =
  'CHANGE_STATUS_BESTSELLER_SUCCESS';
export const CHANGE_STATUS_BESTSELLER_FAIL = 'CHANGE_STATUS_BESTSELLER_FAIL';