import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  getUsers,
  addUser,
  deleteUser,
  apiError,
  getPrivilagesOptions,
  getCompaniesOptions,
  getBranchesOptions,
  updateUser,
  //getPrivilagesOptions,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import "./user.scss";

const Settings = (props) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Settings" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Basic :</Label>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Select
                          name="customer_community_id"
                          placeholder="--Select customer--"
                          //   value={selectCommunity}
                          //   onChange={(value) => {
                          //     handleSelectedCommunities(value);
                          //   }}
                          //   options={communitiesOptionsGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mb-3">
                        <Label>Customer</Label>
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mb-3">anyone</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3"></Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Select
                          name="customer_community_id"
                          placeholder="--Select Language--"
                          //   value={selectCommunity}
                          //   onChange={(value) => {
                          //     handleSelectedCommunities(value);
                          //   }}
                          //   options={communitiesOptionsGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mb-3">
                        <Label>Language</Label>
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mb-3">English</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Accountings :</Label>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Select
                          name="customer_community_id"
                          placeholder="--Select Currency--"
                          //   value={selectCommunity}
                          //   onChange={(value) => {
                          //     handleSelectedCommunities(value);
                          //   }}
                          //   options={communitiesOptionsGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mb-3">
                        <Label>Currency</Label>
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mb-3">INR</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3"></Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Select
                          name="customer_community_id"
                          placeholder="--Select Date-Format--"
                          //   value={selectCommunity}
                          //   onChange={(value) => {
                          //     handleSelectedCommunities(value);
                          //   }}
                          //   options={communitiesOptionsGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mb-3">
                        <Label>Date-Format</Label>
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mb-3">MM-DD-YYYY</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3"></Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Select
                          name="customer_community_id"
                          placeholder="--Select Account-Month--"
                          //   value={selectCommunity}
                          //   onChange={(value) => {
                          //     handleSelectedCommunities(value);
                          //   }}
                          //   options={communitiesOptionsGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mb-3">
                        <Label>Account-Month</Label>
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mb-3">Feburary</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3"></Col>
                    <Col md="1">
                      <div className="mt-4">
                        <Button color="primary" type="submit">
                          Save
                        </Button>
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mt-4">
                        <Button color="danger" type="reset">
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(Settings));
