import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  getUsers,
  addUser,
  deleteUser,
  apiError,
  getPrivilagesOptions,
  getCompaniesOptions,
  getBranchesOptions,
  updateUser,
  //getPrivilagesOptions,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import "./user.scss";

const ReturnPurchase = (props) => {
  const [usersForTable, setUsersForTable] = useState([]);
  const [dataForTable, setDataForTable] = useState([]);
  const [accountType, setAccountType] = useState("");

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Barcode",
        field: "district",
        sort: "asc",
        width: 400,
      },

      {
        label: "Product Name",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Sku(old-sku)",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "HSN",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Unit",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Qty",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Rate",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mrp",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Discount%",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Net Amount",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "GST%",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Grand Total",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
    ],
    rows: usersForTable,
  };

  const content = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Purchase Number",
        field: "district",
        sort: "asc",
        width: 400,
      },

      {
        label: "Supplier Name",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "localbodytype",
        sort: "asc",
        width: 200,
      }
    ],
    rows: dataForTable,
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Purchase Return" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    // onValidSubmit={(e, v) => {
                    //   handleValidSubmit(e, v);
                    // }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Purchase Number
                          </Label>
                          <AvField
                            name="Category"
                            placeholder="Category"
                            type="text"
                            errorMessage="Category"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                          />
                        </div>
                      </div>
                    </Col>
                      <Col md="1">
                        <div className="mt-4">
                          <Button color="primary" type="submit">
                            Search
                          </Button>
                        </div>
                      </Col>
                      <Label htmlFor="validationCustom05">Supplier Name</Label>

                      <Label htmlFor="validationCustom05">
                        Supplier Address:
                      </Label>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    paging={false}
                    info={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    bordered
                    data={content}
                    searching={true}
                    info={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(ReturnPurchase));
