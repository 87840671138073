import {
  GET_DELIVERYLISTS,
  GET_DELIVERYLISTS_FAIL,
  GET_DELIVERYLISTS_SUCCESS,
  
} from './actionTypes';

const INIT_STATE = {
  deliverylists: [],
  addingTax: false,
  deletingTax: false,
  addTaxResponse: {},
  updateTaxResponse: {},
  deleteTaxResponse: {},
  deliverylistIdToBeDeleted: '',
  deliverylist: {},
  error: {},
};

const Deliverylist = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DELIVERYLISTS:
      return {
        ...state,
        params: action.payload,
      };

    case GET_DELIVERYLISTS_SUCCESS:
      return {
        ...state,
        deliverylists: action.payload.data,
      };

    case GET_DELIVERYLISTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    // case ADD_TAX:
    //   return {
    //     ...state,
    //     addingTax: true,
    //     deliverylist: action.payload,
    //   };

    // case ADD_TAX_SUCCESS:
    //   /*let newTaxes = state.deliverylists.filter((deliverylist) => {
    //       return deliverylist;
    //      });*/
    //   let newTaxes = [JSON.parse(JSON.stringify(action.payload.data[0]))];
    //   newTaxes.push(...state.deliverylists);
    //   //console.log(...state.deliverylists);
    //   //console.log(JSON.parse(JSON.stringify(action.payload.data[0])));
    //   return {
    //     ...state,
    //     addingTax: false,
    //     addTaxResponse: {
    //       type: 'success',
    //       deliverylist: 'Tax added successfully',
    //     },
    //     deliverylists: newTaxes,
    //     error: {},
    //   };

    // case ADD_TAX_FAIL:
    //   return {
    //     ...state,
    //     addingTax: false,
    //     addTaxResponse: { type: 'failure', deliverylist: 'Adding deliverylist failed' },
    //     error: action.payload,
    //   };
    // case DELETE_TAX:
    //   return {
    //     ...state,
    //     deletingTax: true,
    //     deliverylistIdToBeDeleted: action.payload,
    //   };

    // case DELETE_TAX_SUCCESS:
    //   let newTaxes2 = state.deliverylists.filter((deliverylist) => {
    //     return deliverylist.deliverylist_slab_id != state.deliverylistIdToBeDeleted;
    //   });
    //   return {
    //     ...state,
    //     deletingTax: false,
    //     deliverylistIdToBeDeleted: '',
    //     deletingTax: false,
    //     deleteTaxResponse: {
    //       type: 'success',
    //       deliverylist: 'Tax deleted successfully',
    //     },
    //     deliverylists: newTaxes2,
    //   };

    // case DELETE_TAX_FAIL:
    //   return {
    //     ...state,
    //     deletingTax: false,
    //     deleteTaxResponse: {
    //       type: 'failure',
    //       deliverylist: 'Deleting Tax failed',
    //     },
    //     error: action.payload,
    //   };

    // case UPDATE_TAX:
    //   return {
    //     ...state,
    //     updatingTax: true,
    //     deliverylist: action.payload,
    //   };

    // case UPDATE_TAX_SUCCESS:
    //   let newdeliverylistarr = [];
    //   let newTaxes1 = state.deliverylists.filter((deliverylist) => {
    //     if (deliverylist.deliverylist_slab_id == state.deliverylist.deliverylist_slab_id) {
    //       console.log(JSON.parse(JSON.stringify(action.payload.data[0])));
    //       newdeliverylistarr.push(JSON.parse(JSON.stringify(action.payload.data[0])));
    //     } else {
    //       //console.log(deliverylist);
    //       newdeliverylistarr.push(deliverylist);
    //     }
    //   });

    //   return {
    //     ...state,
    //     updatingTax: false,
    //     deliverylist: '',
    //     updatingTax: false,
    //     updateTaxResponse: {
    //       type: 'success',
    //       deliverylist: 'Tax updated successfully',
    //     },
    //     deliverylists: newdeliverylistarr,
    //   };

    // case UPDATE_TAX_FAIL:
    //   return {
    //     ...state,
    //     updatingTax: false,
    //     updateTaxResponse: {
    //       type: 'failure',
    //       deliverylist: 'Updating deliverylist failed',
    //     },
    //     error: action.payload,
    //   };

    default:
      return state;
  }
};

export default Deliverylist;
