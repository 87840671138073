import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';

import {
  GET_AREA,
  ADD_AREA,
  DELETE_AREA,
  UPDATE_AREA,
  GET_AREAS,
  GET_AREAS_OPTIONS
} from './actionTypes';

import {
  getAreasSuccess,
  getAreasFail,
  getAreaSuccess,
  getAreaFail,
  addAreaFail,
  addAreaSuccess,
  updateAreaFail,
  updateAreaSuccess,
  deleteAreaFail,
  deleteAreaSuccess,
  getAreasOptionsSuccess,
  getAreasOptionsFail
} from './actions';

//Include Both Helper File with needed methods
import {
  getAreas,
  getArea,
  addArea,
  updateArea,
  deleteArea,
  getAreasOptions
} from '../../helpers/backend_helper';

function* fetchAreas() {
  try {
    const response = yield call(getAreas);
    yield put(getAreasSuccess(response));
  } catch (error) {
    yield put(getAreasFail(error));
  }
}
function* fetchAreasOptions() {
  try {
    const response = yield call(getAreasOptions);
    yield put(getAreasOptionsSuccess(response.data));
  } catch (error) {
    yield put(getAreasOptionsFail(error));
  }
}

function* onGetArea() {
  try {
    const response = yield call(getArea);
    yield put(getAreaSuccess(response));
  } catch (error) {
    yield put(getAreaFail(error.response));
  }
}

function* onAddArea({ payload: area }) {
  try {
    const response = yield call(addArea, area);
    yield put(addAreaSuccess(response));
  } catch (error) {
    yield put(addAreaFail(error.response));
  }
}

function* onUpdateArea({ payload: area }) {
  //console.log(area);
  try {
    const response = yield call(updateArea, area);
    yield put(updateAreaSuccess(response));
  } catch (error) {
    yield put(updateAreaFail(error.response));
  }
}

function* onDeleteArea({ payload: areaId, auth_data: auth_user }) {
  try {
    const response = yield call(deleteArea, areaId, auth_user);
    yield put(deleteAreaSuccess(response));
  } catch (error) {
    yield put(deleteAreaFail(error.response));
  }
}

function* areaSaga() {
  yield takeEvery(GET_AREAS, fetchAreas);
  yield takeEvery(GET_AREA, onGetArea);
  yield takeEvery(ADD_AREA, onAddArea);
  yield takeEvery(UPDATE_AREA, onUpdateArea);
  yield takeEvery(DELETE_AREA, onDeleteArea);
  yield takeEvery(GET_AREAS_OPTIONS,fetchAreasOptions);
}

export default areaSaga;
