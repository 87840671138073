import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from 'jwt-decode';
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import Select from "react-select";
import {
  getAllFundSource,
  addFundTransfer,
  getAllFundTransfer
} from "../../../store/actions";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";


const Fund = (props) => {

  const [fundTransferList, setFundTransferList] = useState([]);
  const [master, setMaster] = useState({});
  const [selectedFundSourceFrom, setSelectedFundSourceFrom] = useState(null);
  const [selectedFundSourceTo, setSelectedFundSourceTo] = useState(null);
  const [fundSourceOptions, setFundSourceOptions] = useState([]);
  const [userId, setUserId] = useState(1);
  const {
    fundTransfers,
    addingFundTransfer,
    addFundTransferResponse,
    fundSource,
    error
  } = useSelector((state) => state.fundTransfers);

  const dispatch = useDispatch();
  const formRef = useRef();

  useEffect(() => {
    dispatch(getAllFundSource());
    dispatch(getAllFundTransfer());
  }, []);

  useEffect(() => {
    console.log('fundTransfers: ', fundTransfers);
    if (addFundTransferResponse.type === 'success') {
      toastr.success(addFundTransferResponse.message);
    } else if (addFundTransferResponse.type === 'failure') {
      toastr.error(error.data.message, addFundTransferResponse.message);
    }
  }, [addFundTransferResponse]);

  useEffect(() => {
    var data =
      fundSource &&
      fundSource.map((item) => {
        return {
          label: item.acc_chart_name,
          value: item.acc_chart_id,
        };
      });

    setFundSourceOptions([
      {
        options: data,
      },
    ]);
  }, [fundSource]);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = jwt_decode(localStorage.getItem("authUser"));
      setUserId(obj.user);
    }
  }, [userId]);

  useEffect(() => {
    let fundTransferData = JSON.parse(JSON.stringify(fundTransfers));
    console.log('fundTransferData:----> ', fundTransferData);
  
    let data = [];
    fundTransferData.map((item, index) => {
      item.x_User = item.fname;
      item.fname = "";
      if (item.User) {
        item.fname = item.User.fname + ' ' + item.User.lname;
      }
      item.auth_userid = userId;
      item.id = index + 1;
      item.amount_column = (
        <div className="text-right">{item.fund_transfer_amount.toFixed(2)}</div>
       );
      data.push(item);
    });
    setFundTransferList(data);
  }, [fundTransfers]);

  const handleChangeInput = (e) => {
    setMaster({
      ...master,
      ["auth_userid"]: userId,
      [e.target.name]: e.target.value
    })
  }

  const handleSelectedFundSource = (selected, name) => {
    switch (name) {
      case "from":
        setSelectedFundSourceFrom(selected);
        setMaster({
          ...master,
          ["fund_transfer_from"]: selected.value
        });
        break;
      case "to":
        setSelectedFundSourceTo(selected);
        setMaster({
          ...master,
          ["fund_transfer_to"]: selected.value
        });
        break;
      default:
        break;
    }
  };
  const handleValidSubmit = (e, v) => {
    if (selectedFundSourceFrom == null) {
      toastr.error("From fund source is required");
      return;
    }
    if (selectedFundSourceTo == null) {
      toastr.error("To fund source is required");
      return;
    }
    dispatch(addFundTransfer(master));

    formRef.current.reset();
    setSelectedFundSourceFrom(null);
    setSelectedFundSourceTo(null);
    setMaster({ ["auth_userid"]: userId });
    dispatch(getAllFundTransfer());
  }
  const reset = () => {
    setMaster({});
    setSelectedFundSourceFrom(null);
    setSelectedFundSourceTo(null);
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Reference ID",
        field: "fund_transfer_unqid",
        sort: "asc",
        width: 400,
      },
      {
        label: "Transfer From",
        field: "found_from_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Transfer To",
        field: "found_to_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "amount_column",
        sort: "asc",
        width: 200,
      },
      {
        label: "Comment",
        field: "fund_transfer_comment",
        sort: "asc",
        width: 200,
      },
      {
        label: "User",
        field: "x_User",
        sort: "asc",
        width: 200,
      }
    ],
    rows: fundTransferList,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Fund Transfer" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>From Fund Source</Label>
                          <Select
                            name="From_Fund_Source"
                            value={selectedFundSourceFrom}
                            onChange={(value) => {
                              handleSelectedFundSource(value, "from");
                            }}
                            options={fundSourceOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>To Fund Source</Label>
                          <Select
                            name="To_Fund_Source"
                            value={selectedFundSourceTo}
                            onChange={(value) => {
                              handleSelectedFundSource(value, "to");
                            }}
                            options={fundSourceOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Amount</Label>
                          <AvField
                            name="fund_transfer_amount"
                            placeholder="Amount"
                            type="number"
                            errorMessage="Enter Amount"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.fund_transfer_amount}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Comments</Label>
                          <AvField
                            name="fund_transfer_comment"
                            placeholder="Comments"
                            type="text"
                            errorMessage="Enter Comments"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.fund_transfer_comment}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="1">
                        <div className="mt-4">
                          <Button color="primary" type="submit" disabled={addingFundTransfer ? true : false}>
                            {addingFundTransfer ? 'Adding' : 'Submit'}
                          </Button>
                        </div>
                      </Col>
                      <Col md="1">
                        <div className="mt-4">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={false}
                    disableRetreatAfterSorting={true}
                    entries={25}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

// const mapStateToProps = (state) => {};

export default Fund;

// Users.propTypes = {
//   error: PropTypes.any,
//   users: PropTypes.array,
// };
