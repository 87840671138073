import {
  GET_THIRDCATEGORIES,
  GET_THIRDCATEGORIES_FAIL,
  GET_THIRDCATEGORIES_SUCCESS,
  ADD_THIRDCATEGORY,
  ADD_THIRDCATEGORY_FAIL,
  ADD_THIRDCATEGORY_SUCCESS,
  DELETE_THIRDCATEGORY,
  DELETE_THIRDCATEGORY_SUCCESS,
  DELETE_THIRDCATEGORY_FAIL,
  UPDATE_THIRDCATEGORY,
  UPDATE_THIRDCATEGORY_SUCCESS,
  UPDATE_THIRDCATEGORY_FAIL,
  CHANGE_STATUS_THIRDCATEGORY,
  CHANGE_STATUS_THIRDCATEGORY_SUCCESS,
  CHANGE_STATUS_THIRDCATEGORY_FAIL,
  GET_THIRDCATEGORY_OPTIONS_SUCCESS,
  GET_THIRDCATEGORY_OPTIONS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  thirdcategories: [],
  addingThirdcategory: false,
  deletingThirdcategory: false,
  addThirdcategoryResponse: {},
  updateThirdcategoryResponse: {},
  deleteThirdcategoryResponse: {},
  statusThirdcategoryResponse: {},
  thirdcategoryIdToBeDeleted: "",
  thirdcategory: {},
  error: {},
  thirdcategoryOptions: [],
};

const Thirdcategory = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_THIRDCATEGORIES:
      return {
        ...state,
        params: action.payload,
      };

    case GET_THIRDCATEGORIES_SUCCESS:
      return {
        ...state,
        thirdcategories: action.payload.data,
      };

    case GET_THIRDCATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_THIRDCATEGORY:
      return {
        ...state,
        addingThirdcategory: true,
        thirdcategory: action.payload,
      };

    case ADD_THIRDCATEGORY_SUCCESS:
      let newThirdcategories = [
        JSON.parse(JSON.stringify(action.payload.data[0])),
      ];
      newThirdcategories.push(...state.thirdcategories);

      return {
        ...state,
        addingThirdcategory: false,
        addThirdcategoryResponse: {
          type: "success",
          message: "Third category added successfully",
        },
        thirdcategories: newThirdcategories,
        error: {},
      };

    case ADD_THIRDCATEGORY_FAIL:
      return {
        ...state,
        addingThirdcategory: false,
        addThirdcategoryResponse: {
          type: "failure",
          message: "Adding Third category failed",
        },
        error: action.payload,
      };
    case DELETE_THIRDCATEGORY:
      return {
        ...state,
        deletingThirdcategory: true,
        subcategoryIdToBeDeleted: action.payload,
      };

    case DELETE_THIRDCATEGORY_SUCCESS:
      let newThirdcategories2 = state.thirdcategories.filter((subcategory) => {
        return subcategory.subcategory_id != state.subcategoryIdToBeDeleted;
      });
      return {
        ...state,
        deletingThirdcategory: false,
        subcategoryIdToBeDeleted: "",
        deletingThirdcategory: false,
        deleteThirdcategoryResponse: {
          type: "success",
          message: "Third category deleted successfully",
        },
        thirdcategories: newThirdcategories2,
      };

    case DELETE_THIRDCATEGORY_FAIL:
      return {
        ...state,
        deletingThirdcategory: false,
        deleteThirdcategoryResponse: {
          type: "failure",
          message: "Deleting Third category failed",
        },
        error: action.payload,
      };

    case UPDATE_THIRDCATEGORY:
      return {
        ...state,
        updatingThirdcategory: true,
        thirdcategory: action.payload,
      };

    case UPDATE_THIRDCATEGORY_SUCCESS:
      let newsubcategoryarr = [];
      state.thirdcategories.filter((subcategory) => {
        if (subcategory.thrdcate_id == state.thirdcategory.thrdcate_id) {
          console.log(JSON.parse(JSON.stringify(action.payload.data[0])));
          newsubcategoryarr.push(
            JSON.parse(JSON.stringify(action.payload.data[0]))
          );
        } else {
          newsubcategoryarr.push(subcategory);
        }
      });

      return {
        ...state,
        updatingThirdcategory: false,
        thirdcategory: "",
        updatingThirdcategory: false,
        updateThirdcategoryResponse: {
          type: "success",
          message: "Third category updated successfully",
        },
        thirdcategories: newsubcategoryarr,
      };

    case UPDATE_THIRDCATEGORY_FAIL:
      return {
        ...state,
        updatingThirdcategory: false,
        updateThirdcategoryResponse: {
          type: "failure",
          message: "Updating Thirdcategory failed",
        },
        error: action.payload,
      };

    case CHANGE_STATUS_THIRDCATEGORY:
      return {
        ...state,
        // deletingCategory: true,
        // categoryIdToBeDeleted: action.payload,
      };

    case CHANGE_STATUS_THIRDCATEGORY_SUCCESS:
      /* let newCategories2 = state.categories.filter((category) => {
            return category.category_id != state.categoryIdToBeDeleted;
          });*/
      return {
        ...state,
        //deletingCategory: false,
        //categoryIdToBeDeleted: "",
        //deletingCategory: false,
        thirdcategories: action.payload.data,
        statusThirdcategoryResponse: {
          type: "success",
          message: "Status Changed successfully",
        },
        //categories: newCategories2,
      };

    case CHANGE_STATUS_THIRDCATEGORY_FAIL:
      return {
        ...state,
        //deletingCategory: false,
        statusThirdcategoryResponse: {
          type: "failure",
          message: "Deleting Thirdategory failed",
        },
        error: action.payload,
      };
    case GET_THIRDCATEGORY_OPTIONS_SUCCESS:
      return {
        ...state,
        subcategoryOptions: action.payload,
      };

    case GET_THIRDCATEGORY_OPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default Thirdcategory;
