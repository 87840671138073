import {
  GET_AREAS,
  GET_AREAS_FAIL,
  GET_AREAS_SUCCESS,
  ADD_AREA,
  ADD_AREA_FAIL,
  ADD_AREA_SUCCESS,
  GET_AREA,
  GET_AREA_FAIL,
  GET_AREA_SUCCESS,
  UPDATE_AREA,
  UPDATE_AREA_FAIL,
  UPDATE_AREA_SUCCESS,
  DELETE_AREA,
  DELETE_AREA_FAIL,
  DELETE_AREA_SUCCESS,
  GET_AREAS_OPTIONS,
  GET_AREAS_OPTIONS_SUCCESS,
  GET_AREAS_OPTIONS_FAIL,
} from './actionTypes';

export const getAreas = () => ({
  type: GET_AREAS,
});

export const getAreasSuccess = (area) => ({
  type: GET_AREAS_SUCCESS,
  payload: area,
});

export const getAreasFail = (error) => ({
  type: GET_AREAS_FAIL,
  payload: error,
});

export const addArea = (area) => ({
  type: ADD_AREA,
  payload: area,
});

export const addAreaSuccess = (area) => ({
  type: ADD_AREA_SUCCESS,
  payload: area,
});

export const addAreaFail = (error) => ({
  type: ADD_AREA_FAIL,
  payload: error,
});

export const getArea = (area) => ({
  type: GET_AREA,
  payload: area,
});

export const getAreaSuccess = (area) => ({
  type: GET_AREA_SUCCESS,
  payload: area,
});

export const getAreaFail = (error) => ({
  type: GET_AREA_FAIL,
  payload: error,
});

export const updateArea = (area) => ({
  type: UPDATE_AREA,
  payload: area,
});

export const updateAreaSuccess = (area) => ({
  type: UPDATE_AREA_SUCCESS,
  payload: area,
});

export const updateAreaFail = (error) => ({
  type: UPDATE_AREA_FAIL,
  payload: error,
});

export const deleteArea = (area, auth_user) => ({
  type: DELETE_AREA,
  payload: area,
  auth_data: auth_user,
});

export const deleteAreaSuccess = (area) => ({
  type: DELETE_AREA_SUCCESS,
  payload: area,
});

export const deleteAreaFail = (error) => ({
  type: DELETE_AREA_FAIL,
  payload: error,
});

export const getAreasOptions = () => ({
  type: GET_AREAS_OPTIONS,
});

export const getAreasOptionsSuccess = (privilages) => ({
  type: GET_AREAS_OPTIONS_SUCCESS,
  payload: privilages,
});

export const getAreasOptionsFail = (error) => ({
  type: GET_AREAS_OPTIONS_FAIL,
  payload: error,
});
