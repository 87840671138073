export const GET_COMMUNITIES = 'GET_COMMUNITIES';
export const GET_COMMUNITIES_SUCCESS = 'GET_COMMUNITIES_SUCCESS';
export const GET_COMMUNITIES_FAIL = 'GET_COMMUNITIES_FAIL';

export const GET_COMMUNITY = 'GET_COMMUNITY';
export const GET_COMMUNITY_SUCCESS = 'GET_COMMUNITY_SUCCESS';
export const GET_COMMUNITY_FAIL = 'GET_COMMUNITY_FAIL';

export const ADD_COMMUNITY = 'ADD_COMMUNITY';
export const ADD_COMMUNITY_SUCCESS = 'ADD_COMMUNITY_SUCCESS';
export const ADD_COMMUNITY_FAIL = 'ADD_COMMUNITY_FAIL';

export const UPDATE_COMMUNITY = 'UPDATE_COMMUNITY';
export const UPDATE_COMMUNITY_SUCCESS = 'UPDATE_COMMUNITY_SUCCESS';
export const UPDATE_COMMUNITY_FAIL = 'UPDATE_COMMUNITY_FAIL';

export const DELETE_COMMUNITY = 'DELETE_COMMUNITY';
export const DELETE_COMMUNITY_SUCCESS = 'DELETE_COMMUNITY_SUCCESS';
export const DELETE_COMMUNITY_FAIL = 'DELETE_COMMUNITY_FAIL';
