import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import jwt_decode from 'jwt-decode';
import Moment from 'moment';

import SweetAlert from 'react-bootstrap-sweetalert';
import { MDBDataTable } from 'mdbreact';
import { Row, Col, Card, CardBody, Button, Label } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';

import { getHarvestlist } from '../../../store/actions';

// Redux

import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

const HarvestList = (props) => {
  //   const [selecteddeliverylistpremium, setSelectedMessagePremium] = useState(null);

  const [deliverylistObject, setMessageObject] = useState({});
  const [deliverylistsTemp, setDeliverylistsTemp] = useState([]);
  const [orderdate, setOrderDate] = useState('');
  const [userId, setUserId] = useState(1);

  const { harvestlists } = useSelector((state) => state.Harvestlist);

  const dispatch = useDispatch();

  useEffect(() => {
    //
  }, []);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = jwt_decode(localStorage.getItem('authUser'));
      console.log(obj);

      setUserId(obj.user);
      setMessageObject({ ['auth_userid']: userId });
    }
  }, [props.success, props]);

  useEffect(() => {
    let deliverylistsDuplicate = JSON.parse(JSON.stringify(harvestlists));
    let deliverylistData = [];
    deliverylistsDuplicate.map((item, index) => {
      // item.o_customer_community_id =
      //   item.Community.community_address1 +
      //   ' ' +
      //   item.Community.community_address2;
      // item.customer_name = item.customer.customer_name;
      // item.customer_mob = item.customer.customer_mob;
      item.o_date = Moment(item.o_date).format('DD/MM/YYYY');

      item.id = index + 1;

      deliverylistData.push(item);
    });
    setDeliverylistsTemp(deliverylistData);
  }, [harvestlists]);

  const data = {
    columns: [
      {
        label: 'Date',
        field: 'o_date',
        sort: 'asc',
        width: 10,
      },

      {
        label: 'Product Name',
        field: 'o_item_name',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Weight',
        field: 'total',
        sort: 'asc',
        width: 70,
      },
    ],
    rows: deliverylistsTemp,
  };

  const handleChangeOrderDate = (e) => {
    setOrderDate(e.target.value);
    console.log(e.target.value);
  };

  const handleValidSubmitOrder = () => {
    dispatch(getHarvestlist(orderdate));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="HarvestList" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitOrder(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Order Date</Label>
                          <AvField
                            name="createdBy"
                            value={userId}
                            type="hidden"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            // onChange={handleChangeInput}
                          />

                          <AvField
                            name="o_date"
                            value={orderdate}
                            placeholder="Enter Order Date"
                            type="Date"
                            errorMessage="Enter Valid Date"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleChangeOrderDate}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Button color="primary" type="submit">
                      Search
                    </Button>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bdeliverylisted data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HarvestList;
