import {
  GET_COMMUNITY_SCHEDULE,
  GET_COMMUNITY_SCHEDULE_SUCCESS,
  GET_COMMUNITY_SCHEDULE_FAIL,
  CHANGE_STATUS_COMMUNITY,
  CHANGE_STATUS_COMMUNITY_SUCCESS,
  CHANGE_STATUS_COMMUNITY_FAIL,
} from './actionTypes';

export const getCommunitySchedule = () => ({
  type: GET_COMMUNITY_SCHEDULE,
});

export const getCommunityScheduleSuccess = (product) => ({
  type: GET_COMMUNITY_SCHEDULE_SUCCESS,
  payload: product,
});

export const getCommunityScheduleFail = (error) => ({
  type: GET_COMMUNITY_SCHEDULE_FAIL,
  payload: error,
});

export const toggleCommunityActiveStatus = (community) => ({
  type: CHANGE_STATUS_COMMUNITY,
  payload: community,
});

export const toggleCommunityActiveStatusSuccess = (community) => ({
  type: CHANGE_STATUS_COMMUNITY_SUCCESS,
  payload: community,
});

export const toggleCommunityActiveStatusFail = (error) => ({
  type: CHANGE_STATUS_COMMUNITY_FAIL,
  payload: error,
});
