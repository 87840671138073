import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

import Users from './users/reducer';
import privilages from './privilages/reducer';
import companies from './companies/reducer';
import branches from './branches/reducer';
import Menus from './menu/reducer';
import units from './unit/reducer';
import Communities from './community/reducer';
import categories from './category/reducer';
import subcategories from './subcategory/reducer';
import thirdcategory from './thirdcategory/reducer';
import brands from './brand/reducer';
import products from './product/reducer';
import communityrequests from './communityRequest/reducer';
import states from './states/reducer';
import taxes from './tax/reducer';
import stocks from './stock/reducer';
import stocklogs from './stocklog/reducer';
import orders from "./vieworder/reducer";
import customers from "./customer/reducer";
import communityschedules from './communitySchedule/reducer';
import communityOption from './communityOption/reducer';
// import categories from './category/reducer';
import deliverylist from './deliverylist/reducer';
import deliverOrderlist from './deliveryorderlist/reducer';
import Harvestlist from './harvestOrderlist/reducer';

import areas from './area/reducer';
import sliders from './slider/reducer';
import sectionType from "./sectionType/reducer";
import sectionBanner from "./sectionBanner/reducer";
import acctypes from "./accountType/reducer";
import accdetails from "./accountDetail/reducer";
import chartOfAccounts from "./chartOfAccount/reducer";
import creditDebits from "./creditDebit/reducer";
import fundTransfers from "./fundTransfer/reducer";
import incomeExpenses from "./IncomeExpenses/reducer";
import sectionProducts from "./sectionProducts/reducer";
import spotPrices from "./spotPrice/reducer";
const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Users,
  Menus,
  privilages,
  companies,
  branches,
  units,
  Communities,
  categories,
  products,
  communityrequests,
  states,
  taxes,
  stocks,
  stocklogs,
  orders,
  customers,
  communityschedules,
  deliverylist,
  communityOption,
  deliverOrderlist,
  Harvestlist,
  subcategories,
  thirdcategory,
  brands,
  areas,
  sliders,
  sectionType,
  sectionBanner,
  acctypes,
  accdetails,
  chartOfAccounts,
  creditDebits,
  fundTransfers,
  incomeExpenses,
  sectionProducts,
  spotPrices,
});

export default rootReducer;
