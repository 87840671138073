export const GET_ACCTYPES = 'GET_ACCTYPES';
export const GET_ACCTYPES_SUCCESS = 'GET_ACCTYPES_SUCCESS';
export const GET_ACCTYPES_FAIL = 'GET_ACCTYPES_FAIL';

export const ADD_ACCTYPE = 'ADD_ACCTYPE';
export const ADD_ACCTYPE_SUCCESS = 'ADD_ACCTYPE_SUCCESS';
export const ADD_ACCTYPE_FAIL = 'ADD_ACCTYPE_FAIL';

export const UPDATE_ACCTYPE = 'UPDATE_ACCTYPE';
export const UPDATE_ACCTYPE_SUCCESS = 'UPDATE_ACCTYPE_SUCCESS';
export const UPDATE_ACCTYPE_FAIL = 'UPDATE_ACCTYPE_FAIL';

export const DELETE_ACCTYPE = 'DELETE_ACCTYPE';
export const DELETE_ACCTYPE_SUCCESS = 'DELETE_ACCTYPE_SUCCESS';
export const DELETE_ACCTYPE_FAIL = 'DELETE_ACCTYPE_FAIL';

export const GET_ACCTYPE_OPTIONS = 'GET_ACCTYPE_OPTIONS';
export const GET_ACCTYPE_OPTIONS_SUCCESS = 'GET_ACCTYPE_OPTIONS_SUCCESS';
export const GET_ACCTYPE_OPTIONS_FAIL = 'GET_ACCTYPE_OPTIONS_FAIL';