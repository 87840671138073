import {
  GET_DELIVERYORDERLISTS,
  GET_DELIVERYORDERLISTS_FAIL,
  GET_DELIVERYORDERLISTS_SUCCESS,
} from './actionTypes';

export const getDeliveryOrderlists = (CommunityId, orderDate) => ({
  type: GET_DELIVERYORDERLISTS,
  payload: CommunityId,
  orderDate,
});

export const getDeliveryOrderlistsSuccess = (deliverylists) => ({
  type: GET_DELIVERYORDERLISTS_SUCCESS,
  payload: deliverylists,
});

export const getDeliveryOrderlistsFail = (error) => ({
  type: GET_DELIVERYORDERLISTS_FAIL,
  payload: error,
});

// export const getDeliverylist = (deliverylist) => ({
//   type: GET_DELIVERYLIST,
//   payload: deliverylist,
// });

// export const getDeliverylistSuccess = (deliverylist) => ({
//   type: GET_DELIVERYLIST_SUCCESS,
//   payload: deliverylist,
// });

// export const getDeliverylistFail = (error) => ({
//   type: GET_DELIVERYLIST_FAIL,
//   payload: error,
// });
