import {
  GET_AREAS,
  GET_AREAS_FAIL,
  GET_AREAS_SUCCESS,
  ADD_AREA,
  ADD_AREA_FAIL,
  ADD_AREA_SUCCESS,
  DELETE_AREA,
  DELETE_AREA_SUCCESS,
  DELETE_AREA_FAIL,
  UPDATE_AREA,
  UPDATE_AREA_SUCCESS,
  UPDATE_AREA_FAIL,
  GET_AREAS_OPTIONS_SUCCESS,
  GET_AREAS_OPTIONS_FAIL
} from './actionTypes';

const INIT_STATE = {
  areas: [],
  addingArea: false,
  deletingArea: false,
  addAreaResponse: {},
  updateAreaResponse: {},
  deleteAreaResponse: {},
  areaIdToBeDeleted: '',
  area: {},
  error: {},
 areaOptions: [],
};

const Area = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AREAS:
      return {
        ...state,
        params: action.payload,
      };

    case GET_AREAS_SUCCESS:
      return {
        ...state,
        areas: action.payload.data,
      };

    case GET_AREAS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_AREA:
      return {
        ...state,
        addingArea: true,
        area: action.payload,
      };

    case ADD_AREA_SUCCESS:
      /*let newAreas = state.areas.filter((area) => {
          return area;
         });*/
      let newAreas = [JSON.parse(JSON.stringify(action.payload.data[0]))];
      newAreas.push(...state.areas);
      //console.log(...state.areas);
      //console.log(JSON.parse(JSON.stringify(action.payload.data[0])));
      return {
        ...state,
        addingArea: false,
        addAreaResponse: {
          type: 'success',
          message: 'Area added successfully',
        },
        areas: newAreas,
        error: {},
      };

    case ADD_AREA_FAIL:
      return {
        ...state,
        addingArea: false,
        addAreaResponse: { type: 'failure', message: 'Adding area failed' },
        error: action.payload,
      };
    case DELETE_AREA:
      return {
        ...state,
        deletingArea: true,
        areaIdToBeDeleted: action.payload,
      };

    case DELETE_AREA_SUCCESS:
      let newAreas2 = state.areas.filter((area) => {
        return area.area_id != state.areaIdToBeDeleted;
      });
      return {
        ...state,
        deletingArea: false,
        areaIdToBeDeleted: '',
        deletingArea: false,
        deleteAreaResponse: {
          type: 'success',
          message: 'Area deleted successfully',
        },
        areas: newAreas2,
      };

    case DELETE_AREA_FAIL:
      return {
        ...state,
        deletingArea: false,
        deleteAreaResponse: {
          type: 'failure',
          message: 'Deleting Area failed',
        },
        error: action.payload,
      };

    case UPDATE_AREA:
      return {
        ...state,
        updatingArea: true,
        area: action.payload,
      };

    case UPDATE_AREA_SUCCESS:
      let newareaarr = [];
      let newAreas1 = state.areas.filter((area) => {
        if (area.area_id == state.area.area_id) {
          console.log(JSON.parse(JSON.stringify(action.payload.data[0])));
          newareaarr.push(JSON.parse(JSON.stringify(action.payload.data[0])));
        } else {
          //console.log(area);
          newareaarr.push(area);
        }
      });

      return {
        ...state,
        updatingArea: false,
        area: '',
        updatingArea: false,
        updateAreaResponse: {
          type: 'success',
          message: 'Area updated successfully',
        },
        areas: newareaarr,
      };

    case UPDATE_AREA_FAIL:
      return {
        ...state,
        updatingArea: false,
        updateAreaResponse: {
          type: 'failure',
          message: 'Updating area failed',
        },
        error: action.payload,
      };
      case GET_AREAS_OPTIONS_SUCCESS:
        return {
          ...state,
          areaOptions: action.payload,
        };
  
      case GET_AREAS_OPTIONS_FAIL:
        return {
          ...state,
          error: action.payload,
        };

    default:
      return state;
  }
};

export default Area;
