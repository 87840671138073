import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
  Input,
  FormGroup,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const BlockUnblockUser = () => {
  const [data, setData] = useState([]);

  const dataTemp = [
    {
      userid: "22-10-2021 12:05 AM",
      username: "10.1.122.58",
      blstatus: "active",
    },
    {
      userid: "22-10-2021 12:05 AM",
      username: "10.1.122.58",
      blstatus: "inactive",
    },
    {
      userid: "22-10-2021 12:05 AM",
      username: "10.1.122.58",
      blstatus: "active",
    },
  ];

  dataTemp.map((item, index) => {
    item.id = index + 1;
    item.blstatus = (
      <div
        style={{
          textAlign: "center",
        }}
      >
        {item.blstatus === "active" ? (
          <i
            className="fas fa-lock"
            style={{
              fontSize: "1.3em",
              cursor: "pointer",
              marginLeft: "1rem",
              marginRight: "1rem",
              color: "red",
            }}
            onClick={() => null}
          ></i>
        ) : (
          <i
            className="fas fa-unlock"
            style={{
              fontSize: "1.3em",
              cursor: "pointer",
              marginLeft: "1rem",
              marginRight: "1rem",
              color: "green",
            }}
            onClick={() => null}
          ></i>
        )}
      </div>
    );
  });

  useEffect(() => {
    setData(dataTemp);
  }, []);

  const userData = {
    columns: [
      {
        label: "SI#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "User ID",
        field: "userid",
        sort: "asc",
        width: 150,
      },
      {
        label: "User Name",
        field: "username",
        sort: "asc",
        width: 270,
      },
      {
        label: "Blacklist Status",
        field: "blstatus",
        sort: "disabled",
        width: 200,
      },
    ],
    rows: data,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Blocked User" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          {/* <Label htmlFor="validationCustom01">Set Rules</Label> */}
                          <AvField
                            name="userid"
                            placeholder="User ID"
                            type="text"
                            errorMessage="User Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            label="User ID"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          {/* <Label htmlFor="validationCustom01">Set Rules</Label> */}
                          <AvField
                            name="username"
                            placeholder="User Name"
                            type="text"
                            errorMessage="User Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            label="User Name"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          {/* <Label htmlFor="validationCustom01">Set Rules</Label> */}
                          <AvField
                            name="blstatus"
                            placeholder="Select All"
                            type="select"
                            errorMessage="Select Blacklist"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            label="Blacklist Status"
                          >
                            <option>Select All</option>
                          </AvField>
                        </div>
                      </Col>
                      <Col md="2">
                        <div style={{ marginTop: "1.9em" }}>
                          <Button color="success" type="submit">
                            <i
                              className="fas fa-filter"
                              style={{
                                fontSize: "1em",
                                cursor: "pointer",
                                marginRight: "0.5rem",
                              }}
                            ></i>{" "}
                            Search
                          </Button>
                        </div>
                      </Col>
                      <Col md="2">
                        <div style={{ marginTop: "1.9em" }}>
                          <Button color="danger" type="submit">
                            <i
                              className="fas fa-sync"
                              style={{
                                fontSize: "1em",
                                cursor: "pointer",
                                marginRight: "0.5rem",
                              }}
                            ></i>{" "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle>View Rules</CardTitle> */}
                  {/* <hr /> */}
                  <MDBDataTable responsive bordered data={userData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlockUnblockUser;
