import axios from "axios";
import { post, del, get, put } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Login Method
const login = (data) => post(url.POST_LOGIN, data);


export const getUserProfile = () => get(url.GET_USER_PROFILE);

// get users
export const getUsers = () => get(url.GET_USERS);

// add user
export const addUser = (user) => post(url.ADD_USER, user);
export const getUser = (userId) => get(url.GET_USER, userId);
// export const updateUser = (user) => put(url.UPDATE_USER, user);
export const updateUser = (user) => put(url.UPDATE_USER + '/' + user.id, user);
export const deleteUser = (userId) => del(url.DELETE_USER, userId);

// add unit
export const addUnit = (unit) => post(url.ADD_UNIT, unit);
export const getUnits = () => get(url.GET_UNITS);
export const getUnit = (unitId) => get(url.GET_UNIT, unitId);
export const updateUnit = (unit) =>
  put(url.UPDATE_UNIT + '/' + unit.unit_id, unit);
export const deleteUnit = (unitId, auth_user) =>
  del(url.DELETE_UNIT + '/' + auth_user, unitId);


  // add area
export const addArea = (area) => post(url.ADD_AREA, area);
export const getAreas = () => get(url.GET_AREAS);
export const getArea = (areaId) => get(url.GET_AREA, areaId);
export const updateArea = (area) =>
  put(url.UPDATE_AREA + '/' + area.area_id, area);
export const deleteArea = (areaId, auth_user) =>
  del(url.DELETE_AREA + '/' + auth_user, areaId);
  export const getAreasOptions = () => get(url.GET_AREA_OPTIONS);


// add community
export const addCommunity = (community) => post(url.ADD_COMMUNITY, community);
export const getCommunites = () => get(url.GET_COMMUNITIES);
export const getCommunity = (communityId) =>
  get(url.GET_COMMUNITY, communityId);
export const updateCommunity = (community) =>
  put(url.UPDATE_COMMUNITY + '/' + community.community_id, community);
export const deleteCommunity = (communityId, auth_user) =>
  del(url.DELETE_COMMUNITY + '/' + auth_user, communityId);

// add category
export const addCategory = (category) => post(url.ADD_CATEGORY, category);
export const getCategories = () => get(url.GET_CATEGORIES);
export const getCategory = (categoryId) => get(url.GET_CATEGORY, categoryId);
export const updateCategory = (category) =>
  put(url.UPDATE_CATEGORY + '/' + category.category_id, category);
export const deleteCategory = (categoryId, auth_user) =>
  del(url.DELETE_CATEGORY + '/' + auth_user, categoryId);
export const toggleCategoryActiveStatus = (categoryId, auth_user) =>
  del(url.CHANGE_STATUS_CATEGORY + '/' + auth_user, categoryId);


  //add subcategory
  export const addSubcategory = (subcategory) =>
  post(url.ADD_SUBCATEGORY, subcategory);
export const getSubcategories = () => get(url.GET_SUBCATEGORIES);
export const getSubcategory = (subcategoryId) =>
  get(url.GET_SUBCATEGORY, subcategoryId);
export const updateSubcategory = (subcategory) =>
  put(url.UPDATE_SUBCATEGORY + "/" + subcategory.subcategory_id, subcategory);
export const deleteSubcategory = (subcategoryId, auth_user) =>
  del(url.DELETE_SUBCATEGORY + "/" + auth_user, subcategoryId);
export const toggleActiveStatus1 = (subcategoryId, auth_user) =>
  del(url.CHANGE_STATUS_SUBCATEGORY + "/" + auth_user, subcategoryId);
export const getSubcategoryOptions = (category_id) => get(url.GET_SUBCATEGORY_OPTIONS+"?category_id="+category_id);

//add Thirdcategory
export const addThirdcategory = (thirdcategory) =>
  post(url.ADD_THIRDCATEGORY, thirdcategory);
export const getThirdcategories = () => get(url.GET_THIRDCATEGORY);
// export const getThirdcategories = (thirdcategoryId) =>
//   get(url.GET_THIRDCATEGORY, thirdcategoryId);
export const updateThirdcategory = (thirdcategory) =>
  put(
    url.UPDATE_THIRDCATEGORY + "/" + thirdcategory.thrdcate_id,
    thirdcategory
  );
export const deleteThirdcategory = (thirdcategoryId, auth_user) =>
  del(url.DELETE_THIRDCATEGORY + "/" + auth_user, thirdcategoryId);
export const toggleThirdActiveStatus = (thirdcategoryId, auth_user) =>
  del(url.CHANGE_STATUS_THIRDCATEGORY + "/" + auth_user, thirdcategoryId);
        
  //add Brands
  // export const addSubcategory = (subcategory) => post(url.ADD_SUBCATEGORY, subcategory);
  export const addBrand = (brand) => post(url.ADD_BRAND, brand);
   export const getBrands = () => get(url.GET_BRANDS);
   export const getBrand = (barndId) => get(url.GET_BRAND, barndId);
   export const updateBrand = (brand) =>
     put(url.UPDATE_BRAND + '/' + brand.brand_id, brand);
   export const deleteBrand = (brandId, auth_user) =>
     del(url.DELETE_BRAND + '/' + auth_user, brandId);
    export const togglebrandActiveStatus = (brandId, auth_user) => del(url.CHANGE_STATUS_BRAND + '/' + auth_user, brandId);
    
  
// add product
export const addProduct = (product) => post(url.ADD_PRODUCT, product);
export const getProducts = () => get(url.GET_PRODUCTS);
export const getProduct = (productId) => get(url.GET_PRODUCT, productId);
export const updateProduct = (product) =>
  put(url.UPDATE_PRODUCT + '/' + product.product_id, product);
export const deleteProduct = (productId, auth_user) =>
  del(url.DELETE_PRODUCT + '/' + auth_user, productId);
export const toggleProductActiveStatus = (productId, auth_user) =>
  del(url.CHANGE_STATUS_PRODUCT + '/' + auth_user, productId);
export const toggleBestSellerActivesStatus = (productId, auth_user) =>
  del(url.CHANGE_STATUS_BESTSELLER + '/' + auth_user, productId);
// add communityrequest
export const addCommunityrequest = (communityrequest) =>
  post(url.ADD_COMMUNITYREQUEST, communityrequest);
export const getCommunityrequests = () => get(url.GET_COMMUNITYREQUESTS);
export const getCommunityrequest = (communityrequestId) =>
  get(url.GET_COMMUNITYREQUEST, communityrequestId);
export const updateCommunityrequest = (communityrequest) =>
  put(
    url.UPDATE_COMMUNITYREQUEST + '/' + communityrequest.request_id,
    communityrequest
  );
export const deleteCommunityrequest = (communityrequestId, auth_user) =>
  del(url.DELETE_COMMUNITYREQUEST + '/' + auth_user, communityrequestId);

// tax
export const addTax = (tax) => post(url.ADD_TAX, tax);
export const getTaxes = () => get(url.GET_TAXES);
export const getTax = (taxId) => get(url.GET_TAX, taxId);
export const updateTax = (tax) =>
  put(url.UPDATE_TAX + '/' + tax.slab_id, tax);
export const deleteTax = (taxId, auth_user) =>
  del(url.DELETE_TAX + '/' + auth_user, taxId);

// add stock
export const addStock = (stock) => post(url.ADD_STOCK, stock);
export const getStocks = () => get(url.GET_STOCKS);
export const getStock = (stockId) => get(url.GET_STOCK, stockId);
export const updateStock = (stock) =>
  put(url.UPDATE_STOCK + '/' + stock.stock_id, stock);
export const deleteStock = (stockId, auth_user) =>
  del(url.DELETE_STOCK + '/' + auth_user, stockId);

//stocklogs
export const addStocklog = (stocklog) => post(url.ADD_STOCKLOG, stocklog);
export const getStocklogs = () => get(url.GET_STOCKLOGS);
export const getStocklog = (stocklogId) => get(url.GET_STOCKLOG, stocklogId);
export const updateStocklog = (stocklog) =>
  put(url.UPDATE_STOCKLOG + '/' + stocklog.stock_log_id, stocklog);
export const deleteStocklog = (stocklogId, auth_user) =>
  del(url.DELETE_STOCKLOG + '/' + auth_user, stocklogId);

// add order
export const addOrder = (order) => post(url.ADD_ORDER, order);
export const getOrders = () => get(url.GET_ORDERS);
export const getOrder = (orderId) => get(url.GET_ORDER + '/' + orderId);
export const updateOrder = (order) =>
  put(url.UPDATE_ORDER + '/' + order.order_id, order);
export const deleteOrder = (orderId, auth_user) =>
  del(url.DELETE_ORDER + '/' + auth_user, orderId);
export const deliverOrder = (o_id, auth_user) =>
  put(url.DELIVER_ORDER + '/' + auth_user + '/' + o_id);
export const outofdeliverOrder = (o_id, auth_user) =>
  put(url.OUTOF_DELIVER_ORDER + '/' + auth_user + '/' + o_id);
export const cancelOrder = (o_id, auth_user) =>
  put(url.CANCEL_DELIVER_ORDER + '/' + auth_user + '/' + o_id);

// customer
// customer
export const addCustomer = (customer) => post(url.ADD_CUSTOMER, customer);
export const getCustomers = () => get(url.GET_CUSTOMERS);
export const getCustomer = (customerId) => get(url.GET_CUSTOMER, customerId);
export const updateCustomer = (customer) =>
  put(url.UPDATE_CUSTOMER + '/' + customer.customer_id, customer);
export const deleteCustomer = (customerId, auth_user) =>
  del(url.DELETE_CUSTOMER + '/' + auth_user, customerId);
//community options
export const getCommunitiesOptions = () => get(url.GET_COMMUNITIES_OPTIONS);

//community Schedule



export const getCommunitySchedule = () => get(url.GET_COMMUNITY_SCHEDULE);
export const toggleCommunityActiveStatus = (community) =>
  put(url.CHANGE_STATUS_COMMUNITY + '/' + community.community_id, community);
// put(url.CHANGE_STATUS_COMMUNITY + '/' +  communityId);

// get privilages options
export const getPrivilagesOptions = () => get(url.GET_PRIVILAGES_OPTIONS);
//get state list
export const getStatesOptions = () => get(url.GET_STATES_OPTIONS);
//get categories list
export const getCategoriesOptions = () => get(url.GET_CATEGORIES_OPTIONS);
//get units list
export const getUnitsOptions = () => get(url.GET_UNITS_OPTIONS);

// get companies options
export const getCompaniesOptions = () => get(url.GET_COMPANIES_OPTIONS);
// get menu options
export const getMenusOptions = (userId) => get(`${url.GET_Menu_OPTIONS}?id=${userId}`);

// get Branches options
export const getBranchesOptions = (companyId) =>
  get(url.GET_BRANCHES_OPTIONS + '/' + companyId);

export { getLoggedInUser, isUserAuthenticated, login };
// deliverylist

export const getDeliverylists = () => get(url.GET_DELIVERYLISTS);
export const getDeliverylist = (deliverylistId) =>
  get(url.GET_DELIVERYLIST, deliverylistId);

  // export const getDeliveryOrderlists = (communityId) => {
  //   console.log('backend helper' + communityId);
  //   post(url.GET_DELIVERYORDERLISTS, communityId);
  // };
   
  export const getDeliveryOrderlists = (communityId,orderDate) => get(`${url.GET_DELIVERYORDERLISTS}/${communityId}/${orderDate}`);
 
   
  export const getHarvestlist = (orderDate) =>
    get(`${url.GET_HARVESTORDERLISTS}/${orderDate}`);
  
    export const getCustomerOrder = (customerId) =>
      get(`${url.GET_CUSTOMERORDER}/${customerId}`);
   
// add Slider
export const addSlider = (slider) => post(url.ADD_SLIDER, slider);
export const getSliders = () => get(url.GET_SLIDERS);
export const getSlider = (sliderId) => get(url.GET_SLIDER, sliderId);
export const updateSlider = (slider) =>
  put(url.UPDATE_SLIDER + '/' + slider.slider_id, slider);
export const deleteSlider = (sliderId, auth_user) =>
  del(url.DELETE_SLIDER + '/' + auth_user, sliderId);
export const toggleSliderActiveStatus = (sliderId, auth_user) =>
  del(url.CHANGE_STATUS_SLIDER + '/' + auth_user, sliderId);

  //SECTION TYPES
  export const getSectionType = () => get(`${url.GET_SECTION_TYPES}`);
export const getSectionThirdcategory = () =>
  get(`${url.GET_SECTION_THIRDCATEGORY}`);
export const addSectionType = (sectionType) =>
  post(url.ADD_SECTION_TYPE, sectionType);
export const updateSectionType = (sectionType) =>
  put(url.UPDATE_SECTION_TYPE + "/" + sectionType.section_id, sectionType);
export const deleteSectionType = (sectionId, auth_user) =>
  del(url.DELETE_SECTION_TYPE + "/" + auth_user, sectionId);
export const toggleSectionTypeActiveStatus = (sectionId, auth_user) =>
  del(url.CHANGE_STATUS_SECTION_TYPE + "/" + auth_user, sectionId);

  //REPORTS
  export const getReportStock = () => get(`${url.GET_STOCK_REPORTS}`);
//REPORTS
  //section Banners
export const getSectionBanners = () => get(`${url.GET_SECTIONBANNERS}`);
export const addSectionBanner = (sectionBanner) =>post(url.ADD_SECTIONBANNER, sectionBanner);
export const updateSectionBanner = (sectionBanner) =>
    put(url.UPDATE_SECTIONBANNER + "/" + sectionBanner.section_banner_id, sectionBanner);
export const deleteSectionBanner = (sectionId, auth_user) =>
    del(url.DELETE_SECTIONBANNER + "/" + auth_user, sectionId);
export const toggleSectionBannerActiveStatus = (sectionId, auth_user) =>
    del(url.CHANGE_STATUS_SECTIONBANNER + "/" + auth_user, sectionId);

// add account type
export const addAccountType = (acctype) => post(url.ADD_ACCTYPE, acctype);
export const getAllAccountTypes = () => get(url.GET_ACCTYPES);
export const updateAccountType = (acctype) =>
        put(url.UPDATE_ACCTYPE + '/' + acctype.acc_type_id, acctype);
export const deleteAccountType = (acctypeid, auth_user) =>
        del(url.DELETE_ACCTYPE + '/' + auth_user, acctypeid);
export const accountTypeOptions = () => get(url.GET_ACCTYPE_OPTIONS);

// add account detail
export const addAccountDetail = (accdetail) => post(url.ADD_ACCDETAIL, accdetail);
export const getAllAccountDetails = () => get(url.GET_ACCDETAILS);
export const updateAccountDetail = (accdetail) =>
        put(url.UPDATE_ACCDETAIL + '/' + accdetail.acc_detail_id, accdetail);
export const deleteAccountDetail = (accdetailid, auth_user) =>
        del(url.DELETE_ACCDETAIL + '/' + auth_user, accdetailid);

// chart of accounts
export const addChartOfAcc = (data) => post(url.ADD_CHARTOFACC, data);
export const getAllChartOfAcc = () => get(url.GET_CHARTOFACC);
export const updateChartOfAcc = (data) => put(url.UPDATE_CHARTOFACC + '/' + data.acc_chart_id,data);
export const deleteChartOfAcc = (chartDetailId,auth_user) => del(url.DELETE_CHARTOFACC + '/' + auth_user, chartDetailId);

// credit debit fund
export const addCreditDebit = (data) => post(url.ADD_CREDIT_DEBIT, data);
export const getAllCreditDebit = () => get(url.GET_CREDIT_DEBIT);

// fund transfer
export const getAllFundTransfer = () => get(url.GET_FUND_TRANSFER);
export const addFundTransfer = (data) => post(url.ADD_FUND_TRANSFER,data);
export const getAllFundSource = () => get(url.GET_FUND_SOURCE);

// income expenses
export const getIncomeExpenseAccType = () => get(url.GET_INCOME_EXPENSE_ACC_TYPE);
export const addIncomeExpense = (data) => post(url.ADD_INCOME_EXPENSE,data);
export const getAllIncomeExpenses = () => get(url.GET_INCOME_EXPENSE);
//section products
export const getAllSectionProduct = () => get(url.GET_SECTION_PRODUCT);
export const addSectionProduct = (data) => post(url.ADD_SECTION_PRODUCT,data);
export const updateSectionProduct = (data) => put(url.UPDATE_SECTION_PRODUCT,data);
export const deleteSectionProduct = (sectionDetailId,auth_user) => del(url.DELETE_SECTION_PRODUCT + '/' + auth_user, sectionDetailId);

//spot price
export const addSpotPrice = (data) => post(url.ADD_SPOT_PRICE,data);
export const getAllSpotPrice = () => get(url.GET_SPOT_PRICE);
export const addMultipleSpotPrice = (data) => post(url.ADD_MULTIPLE_SPOT_PRICE,data);