/* USERS */

export const GET_SECTION_TYPES = "GET_SECTION_TYPES";
export const GET_SECTION_TYPES_SUCCESS = "GET_SECTION_TYPES_SUCCESS";
export const GET_SECTION_TYPES_FAIL = "GET_SECTION_TYPES_FAIL";

export const GET_THIRDCATEGORIES = "GET_THIRDCATEGORIES";
export const GET_THIRDCATEGORIES_SUCCESS = "GET_THIRDCATEGORIES_SUCCESS";
export const GET_THIRDCATEGORIES_FAIL = "GET_THIRDCATEGORIES_FAIL";

export const ADD_SECTION_TYPE = "ADD_SECTION_TYPES";
export const ADD_SECTION_TYPE_SUCCESS = "ADD_SECTION_TYPES_SUCCESS";
export const ADD_SECTION_TYPE_FAIL = "ADD_SECTION_TYPES_FAIL";

export const UPDATE_SECTION_TYPE = "UPDATE_SECTION_TYPE";
export const UPDATE_SECTION_TYPE_SUCCESS = "UPDATE_SECTION_TYPE_SUCCESS";
export const UPDATE_SECTION_TYPE_FAIL = "UPDATE_SECTION_TYPES_FAIL";

export const DELETE_SECTION_TYPE = "DELETE_SECTION_TYPE";
export const DELETE_SECTION_TYPE_SUCCESS = "DELETE_SECTION_TYPE_SUCCESS";
export const DELETE_SECTION_TYPE_FAIL = "DELETE_SECTION_TYPES_FAIL";

export const CHANGE_STATUS_SECTION_TYPE = "CHANGE_STATUS_SECTION_TYPE";
export const CHANGE_STATUS_SECTION_TYPE_SUCCESS =
  "CHANGE_STATUS_SECTION_TYPE_SUCCESS";
export const CHANGE_STATUS_SECTION_TYPE_FAIL =
  "CHANGE_STATUS_SECTION_TYPE_FAIL";
