export const GET_COMMUNITYREQUESTS = 'GET_COMMUNITYREQUESTS';
export const GET_COMMUNITYREQUESTS_SUCCESS = 'GET_COMMUNITYREQUESTS_SUCCESS';
export const GET_COMMUNITYREQUESTS_FAIL = 'GET_COMMUNITYREQUESTS_FAIL';

export const GET_COMMUNITYREQUEST = 'GET_COMMUNITYREQUEST';
export const GET_COMMUNITYREQUEST_SUCCESS = 'GET_COMMUNITYREQUEST_SUCCESS';
export const GET_COMMUNITYREQUEST_FAIL = 'GET_COMMUNITYREQUEST_FAIL';

export const ADD_COMMUNITYREQUEST = 'ADD_COMMUNITYREQUEST';
export const ADD_COMMUNITYREQUEST_SUCCESS = 'ADD_COMMUNITYREQUEST_SUCCESS';
export const ADD_COMMUNITYREQUEST_FAIL = 'ADD_COMMUNITYREQUEST_FAIL';

export const UPDATE_COMMUNITYREQUEST = 'UPDATE_COMMUNITYREQUEST';
export const UPDATE_COMMUNITYREQUEST_SUCCESS =
  'UPDATE_COMMUNITYREQUEST_SUCCESS';
export const UPDATE_COMMUNITYREQUEST_FAIL = 'UPDATE_COMMUNITYREQUEST_FAIL';

export const DELETE_COMMUNITYREQUEST = 'DELETE_COMMUNITYREQUEST';
export const DELETE_COMMUNITYREQUEST_SUCCESS =
  'DELETE_COMMUNITYREQUEST_SUCCESS';
export const DELETE_COMMUNITYREQUEST_FAIL = 'DELETE_COMMUNITYREQUEST_FAIL';
